import React from "react";
import styles from "./CalificationModal.module.scss";
import { closeCalificationModal } from "../../../store/actions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import VoteReputation from "./VoteReputation/VoteReputation";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const CalificationModal = ({ onClose, closeCalificationModal, userCalificatedData }) => {

  const userFullName = userCalificatedData?.userData?.type === "COMPANY" ? userCalificatedData?.userData?.razon_social : userCalificatedData?.userData?.name + " " + userCalificatedData?.userData?.last_name;
  return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="1"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal_overlay}
          onClick={onClose}
        >
          <div className={styles.MainModal_container}>
          <div className={styles.MainModal}>
            <div className={styles.MainModal_header}>        
                
                <div className={styles.MainModal_userInfo}>
                <img src={userCalificatedData?.userData?.image_profile} alt="user" className={styles.MainModal_userInfo_img}/>
                <div className={styles.MainModal_userInfo_data}>
                 Caliificar a:
                 <span className={styles.MainModal_close} onClick={() => closeCalificationModal()}>
                  <XIcon width={18} height={18} />
                </span>
                <p className={styles.MainModal_userInfo_data_name}>{userFullName}</p>
                </div>
                </div>                        
              </div>
              <div className={styles.MainModal_options}>
                <VoteReputation />
              </div>
            </div>
            </div>
        </motion.div>
      </AnimatePresence>
  );
};

const mapStateToProps = (state) => {
  return {
    userCalificatedData: state.general.calificationModal,
  };
}

export default connect(mapStateToProps, { closeCalificationModal })(CalificationModal);

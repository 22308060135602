import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from './SendQuotePopup.module.scss';
import { resetSuccess, sendQuote } from '../../../store/actions/categoryActions';

const SendQuotePopup = ({ onClose, sendQuote, materials, onSuccess }) => {
  const [quoteData, setQuoteData] = useState({
    name: '',
    city: '',
    receiveFrom: null,
    receiveTo: null,
    currency: 'ARS',
    description: '',
    jurisdiccion: null,
    zone: null,
  });
  const jurisdictionArray = [
    { id: 1, name: "Buenos Aires" },
    { id: 2, name: "Catamarca" },
    { id: 3, name: "Chaco" },
    { id: 4, name: "Chubut" },
    { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
    { id: 6, name: "Córdoba" },
    { id: 7, name: "Corrientes" },
    { id: 8, name: "Entre Ríos" },
    { id: 9, name: "Formosa" },
    { id: 10, name: "Jujuy" },
    { id: 11, name: "La Pampa" },
    { id: 12, name: "La Rioja" },
    { id: 13, name: "Mendoza" },
    { id: 14, name: "Misiones" },
    { id: 15, name: "Neuquén" },
    { id: 16, name: "Río Negro" },
    { id: 17, name: "Salta" },
    { id: 18, name: "San Juan" },
    { id: 19, name: "San Luis" },
    { id: 20, name: "Santa Cruz" },
    { id: 21, name: "Santa Fe" },
    { id: 22, name: "Santiago del Estero" },
    { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
    { id: 24, name: "Tucumán" },
  ]

  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteData(prev => ({
      ...prev,
      [name]: name === 'jurisdiccion' ? Number(value) : value
    }));
  };

  const handleDateChange = (date, name) => {
    setQuoteData(prev => ({
      ...prev,
      [name]: date
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await sendQuote(quoteData, materials);
        onSuccess();
        onClose();

      } catch (error) {
        console.error("Error al enviar la cotización:", error);
       
      }
  };

  const handleOverlayClick = (e) => {
    if (!formRef.current.contains(e.target)) {
      onClose();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleOverlayClick);
    return () => document.removeEventListener('mousedown', handleOverlayClick);
    
  }, []);


  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <h2>Enviar para Cotizar</h2>
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Nombre de la Cotización</label>
            <input
              type="text"
              id="name"
              name="name"
              value={quoteData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="jurisdiccion">Ubicación</label>
            <select
              id="jurisdiccion"
              name="jurisdiccion"
              value={quoteData.jurisdiccion || ''}
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
              required
            >
                <option value="" disabled>Seleccionar una ubicación</option>
                {jurisdictionArray.map((jurisdiction) => (
                  <option value={jurisdiction.id}  key={jurisdiction.id}>{jurisdiction.name}</option>
                ))}
             
            </select>
          </div>
          <div className={styles.formGroup} required>
            <label htmlFor="city">Ciudad</label>
            <input
              type="text"
              id="city"
              name="city"
              value={quoteData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.datePickerWrapper}>
              <label htmlFor="receiveFrom">Recibir desde</label>
              <DatePicker
                selected={quoteData.receiveFrom}
                onChange={(date) => handleDateChange(date, 'receiveFrom')}
                dateFormat="yyyy-MM-dd"
                placeholderText="YYYY-MM-DD"
                id="receiveFrom"
                name="receiveFrom"
                required
                className={styles.datePicker}
              />
            </div>
            <div className={styles.datePickerWrapper}>
              <label htmlFor="receiveTo">Hasta</label>
              <DatePicker
                selected={quoteData.receiveTo}
                onChange={(date) => handleDateChange(date, 'receiveTo')}
                dateFormat="yyyy-MM-dd"
                placeholderText="YYYY-MM-DD"
                id="receiveTo"
                name="receiveTo"
                required
                className={styles.datePicker}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="currency">Moneda</label>
            <select
              id="currency"
              name="currency"
              value={quoteData.currency}
              onChange={handleChange}
              required
            >
              <option value="ARS">Pesos</option>
              <option value="USD">Dólares</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Descripción</label>
            <textarea
              id="description"
              name="description"
              value={quoteData.description}
              onChange={handleChange}
              rows="4"
            ></textarea>
          </div>
          <div className={styles.buttonGroup}>
            <button type="button" onClick={onClose} className={styles.cancelBtn}>Cancelar</button>
            <button type="submit" className={styles.sendBtn}>Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.category.success,
    error: state.category.error,
    loading: state.category.loading,
  };
};

export default connect(mapStateToProps, { sendQuote, resetSuccess })(SendQuotePopup);


import React, { useState } from "react";
import styles from "./PortfolioItemModal.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { Carousel } from 'nuka-carousel';
import EmptyState from "../../UI/EmptyState/EmptyState";
import { ReactComponent as ArrowDownIcon } from "../../../assets/svg/arrowDownIcon.svg";
import PortfolioQuestion from "./PortfolioQuestion";
import { ReactComponent as CommentsIcon } from "../../../assets/svg/commentPost.svg";
import { ReactComponent as ProductIcon } from "../../../assets/svg/ProductIcon.svg";
import { ReactComponent as ServiceIcon } from "../../../assets/svg/ServiceIcon.svg";
import useIsPhone from "../../../hooks/useIsPhone";
import { ReactComponent as DotPost } from "../../../assets/svg/dotPost.svg";

const PortfolioItemModal = ({ item, closeModal, portfolioQuestionsForItem, myPerson, userData }) => {
    const [showQuestions, setShowQuestions] = useState(false);
    const [showQAndA, setShowQAndA] = useState(false);

    const isPhone = useIsPhone();

    // Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const options = { day: 'numeric', month: 'short' };
        return utcDate.toLocaleDateString('es-ES', options);
    };
    const handleAskButton = () => {
        setShowQAndA(true);
    }

    return (
        <AnimatePresence >
            <div className={styles.modalContainer}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.modal}
                >
                    <div className={styles.modalContent}>
                        <Carousel
                            showArrows={true}
                            wrapMode
                            slidesToShow={1}
                            className={styles.modalContent_carousel}
                        >
                            {item?.portfolio_images.map((image, index) => (
                                <div key={index} className={styles.modalContent_carousel_item}>
                                    <img
                                        src={image.image}
                                        alt={index}
                                        className={styles.backgroundImage}
                                    />
                                    <img
                                        src={image.image}
                                        alt={item.title}
                                        className={styles.centeredImage}
                                    />
                                </div>
                            ))}
                            <button className={styles.closeButton} onClick={closeModal}>
                                X
                            </button>
                            <p className={styles.modalContent_info_location}>{item?.zone}</p>
                            <p className={styles.modalContent_info_dates}>{formatDate(item?.startDate)} - {formatDate(item?.endDate)}</p>
                        </Carousel>
                        <div className={styles.modalContent_body}>
                            <div className={styles.modalContent_info}>
                                <div className={styles.modalContent_info_pill} />
                                <h2 className={styles.modalContent_info_title}>{item.name}</h2>
                                {item?.type && (
                                    <div className={styles.modalContent_info_icons}>
                                        {item.type === "producto" ?
                                            <span className={styles.modalContent_info_icons_backGround} title="Producto"><ProductIcon width={20} height={20} /></span> :
                                            <span className={styles.modalContent_info_icons_backGround} title="Servicio"><ServiceIcon width={25} height={25} /></span>}
                                        {item?.price && <p className={styles.modalContent_info_price}>$ {item.price}</p>}
                                        {item?.currencyType && <p className={styles.modalContent_info_price}>{item.currencyType}</p>}
                                        {item?.priceUnit && <p className={styles.modalContent_info_price}>{item.priceUnit}</p>}
                                    </div>
                                )}
                                {!showQuestions && (
                                    <motion.div
                                        className={styles.modalContent_info_description}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {item.description}
                                    </motion.div>
                                )}
                            </div>
                            {isPhone && (
                                <div className={styles.modalContent_footer}>
                                    <h4 className={styles.modalContent_footer_title} onClick={() => setShowQuestions(!showQuestions)}>
                                        Preguntas
                                        <ArrowDownIcon
                                            className={showQuestions ? styles.arrowDown : styles.arrowUp}
                                            width={20}
                                            height={20}
                                            title={showQuestions ? "Ocultar preguntas" : "Mostrar preguntas"}
                                             />
                                    </h4>
                                    <AnimatePresence>
                                        {showQuestions && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: "auto", transition: { duration: 0.3 } }}
                                                exit={{ opacity: 0, height: 0 }}
                                                className={styles.modalContent_footer_questions}
                                            >
                                                <div className={styles.modalContent_footer_questions_container}>
                                                    {portfolioQuestionsForItem && portfolioQuestionsForItem.length > 0 ? (
                                                        portfolioQuestionsForItem.map((question) => (
                                                            <div key={question.id} className={styles.modalContent_footer_question}>
                                                                <p className={styles.modalContent_footer_question_text}>{question.question}</p>

                                                                {question.answers && question.answers.length > 0 ? (
                                                                    question.answers.map((answer) => (
                                                                        <div key={answer.id} className={styles.modalContent_footer_answer}>
                                                                            <p className={styles.modalContent_footer_answer_text}>{answer.answer}</p>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p className={styles.modalContent_footer_answer}>No hay respuestas aún.</p>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <EmptyState title="Nadie hizo preguntas ¡Hacé la primera!" icon={<CommentsIcon width={30} height={30} />} isPortfolio />
                                                    )}
                                                </div>
                                                {!myPerson &&
                                                    <button className={styles.modalContent_footer_button} onClick={handleAskButton}>Preguntar</button>
                                                }
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            )}
                        </div>
                    </div>
                    {!isPhone && (
                        <div className={styles.modalContent_footer}>

                            <AnimatePresence>

                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className={styles.modalContent_footer_questions}
                                ><h4 className={styles.modalContent_footer_title}>
                                        Preguntas
                                    </h4>
                                    <div className={styles.modalContent_footer_questions_container}>
                                        {portfolioQuestionsForItem && portfolioQuestionsForItem.length > 0 ? (
                                            portfolioQuestionsForItem.map((question) => (
                                                <div key={question.id} className={styles.modalContent_footer_question}>
                                                    <div className={styles.modalContent_footer_question_text}>{question.question} 
                                                        <DotPost width={20} height={20} />
                                                        <p className={styles.modalContent_footer_question_text_time}><relative-time lang="es" tense="past" datetime={question?.created_at} /></p>
                                                        </div>
                                                    {question.answers && question.answers.length > 0 ? (
                                                        question.answers.map((answer) => (
                                                            <div key={answer.id} className={styles.modalContent_footer_answer}>
                                                                <img src={answer.user.image_profile} alt={answer.user.name} className={styles.modalContent_footer_answer_image} />
                                                                <p className={styles.modalContent_footer_answer_text}>{answer.answer}</p>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p className={styles.modalContent_footer_answer}>No hay respuestas aún.</p>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <EmptyState title="Nadie hizo preguntas ¡Hacé la primera!" icon={<CommentsIcon width={30} height={30} />} isPortfolio />
                                        )}

                                    </div>
                                    {!myPerson &&
                                        <button className={styles.modalContent_footer_button} onClick={handleAskButton}>Preguntar</button>
                                    }
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    )}
                </motion.div>

            </div>
            {showQAndA && <PortfolioQuestion closeModal={() => setShowQAndA(false)} portfolioId={item.id} userData={userData} />}
        </AnimatePresence>
    );
}

export default PortfolioItemModal;

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


html {
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
    --color-main: ${(props) => props.theme.colors.main};
    --color-mainDark: ${(props) => props.theme.colors.mainDark};
    --color-mainDarker: ${(props) => props.theme.colors.mainDarker};
    --color-mainLight: ${(props) => props.theme.colors.mainLight};
    --color-mainBlue: ${(props) => props.theme.colors.mainBlue};
    --color-mainBlueBorder: ${(props) => props.theme.colors.mainBlueBorder};
    --color-text: ${(props) => props.theme.colors.textColor};
    --color-white: ${(props) => props.theme.colors.whiteColor};
    --color-black: ${(props) => props.theme.colors.blackColor};
    --color-success: ${(props) => props.theme.colors.success};
    --color-error: ${(props) => props.theme.colors.errorRed};
    --color-warning: ${(props) => props.theme.colors.warning};
    --shadow: ${(props) => props.theme.colors.shadow};
    .gmnoprint {
        display: none;
    }
}
h1{
    font-family: 'Manrope', sans-serif;
    font-size: 3.4rem;
    // line-height: 61px;
    text-align: center;
}
.swal2-popup {
    width: 24em!important;
    border-radius: 0!important;
    font-family: 'Manrope', sans-serif;
    .swal2-actions {
        .swal2-styled.swal2-confirm  {
                outline: none;
                border-radius: 7px;
                background-color: var(--color-mainBlue);

                &:focus {
                    box-shadow: none;
                }
            
        }
    }
}
a, a:visited, a:hover{
    text-decoration: none;
  }

@media ${(props) => props.theme.mediaQueries.small}{
    font-size: 60%;
}
@media ${(props) => props.theme.mediaQueries.smallest}{
    font-size: 55%;
}

`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as SelectIcon } from "../../../../assets/svg/selecticon.svg";
import { ReactComponent as Add } from "../../../../assets/svg/addSubPlaceholder.svg";
import { getSubprojectsType } from "../../../../store/actions";
import { ReactComponent as SelectIconActive } from "../../../../assets/svg/selecticonactive.svg";
import { ReactComponent as RubroSelectedIcon } from "../../../../assets/svg/rubroselected.svg";
import { fireAnalytics } from "../../../../utils/firebase";
import { connect } from "react-redux";

const SelectContainer = styled.div`
width: 100%;
    background: #234BA2;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-size: 13px;
    cursor: pointer;
    font-size: 13px;
    
  span {
    font-weight: 300;
  }
  input {
    display: none;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 2.5%;
    width: 18px;
    height: 18px;
  }
  svg path {
    fill: white;
  }
  &.opened {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 5px;
    background: #ffffff;
    color: #2b3b5b;
    font-weight: 600;
    font-size: 12px;
    svg path {
      fill: #2b3b5b;
    }
  }
`;

const Rubro = styled.div`
      color: #2b3b5b;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1em;
    cursor: pointer;
    padding: 5px;

  &:hover {
    background: #EDF0F7;
    color:#234BA2;
    width:100%
  }
`;


const RubrosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const P = styled.p`
margin-left:25px;
padding: 10px;
`;
const Relative = styled.div`
position: relative;
`;
const Absolute = styled.div`
position: absolute;
transform:translateY(50%);
left: 30px;
`;

const RubroSubQuotationSelect = ({ clear, rubros, getSubprojectsType, projectToEdit, ...props }) => {
  const [openSelect, setOpenSelect] = useState(true);
  const [rubroSelected, setRubroSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [highLightedRubro, setHighlightedRubro] = useState('');

  const resetClear = () => {
    setOpenSelect(false);
  };

  useEffect(() => {
    if (clear && !rubroSelected) {
      resetClear();
    } else if (clear) {
      setRubroSelected("");
      setOpenSelect(false);
    }
  }, [clear, rubroSelected]);



  const handleRubroSelect = (value, name) => {
    fireAnalytics("save_subproject", { category: "publishing" });
    props.setFieldValue(props.inputName, value);
    setRubroSelected(name);
    setOpenSelect(false);
    setHighlightedRubro(name)
  };

  // Resaltamos y ordenamos un rubro primero en la lista
  const updatedRubros = [...rubros];
  const cotizacionIndex = updatedRubros.findIndex((rubro) => rubro.name === "MultiRubro");

  if (cotizacionIndex !== -1) {
    const cotizacionRubro = updatedRubros.splice(cotizacionIndex, 1)[0];
    updatedRubros.unshift(cotizacionRubro);
  }

  const handleMenuClick = () => {
    setIsLoading(true);
    getSubprojectsType()
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }
      , 1000);
  }, [rubros, updatedRubros]);

  return (
    <SelectContainer
      className={openSelect || rubroSelected !== "" ? "opened" : ""}
      onClick={() => setOpenSelect(!openSelect)}
    >
      <RubrosContainer onClick={handleMenuClick}>
        {openSelect ? (
          isLoading ? (
            <Rubro>Cargando...</Rubro>) :
            rubros || props.rubros ? (
              updatedRubros.map((rubro, index) => {
                const isSelectedRubro = rubro.name === highLightedRubro || rubro.name === "MultiRubro";
                const rubroStyle = isSelectedRubro ? { fontWeight: 'bolder', fontSize: '16px' } : {};
                return (
                  <Rubro
                    key={index}
                    onClick={() =>
                      handleRubroSelect(`${rubro.id}*${rubro.name}`, rubro.name)
                    }
                    style={rubroStyle}
                  >
                    {rubro.name}
                  </Rubro>
                );
              })
            ) : null
        ) :
          <Relative>
            <Absolute> <Add /></Absolute>
            <P>
              Añadir rubros a cotizar. <span>(ej.: Albañilería)</span>
            </P>
          </Relative>
        }
      </RubrosContainer>

      <input name={props.inputName} id={props.inputName} />
      {rubroSelected !== "" ? (
        <RubroSelectedIcon />
      ) : openSelect ? (
        <SelectIconActive />
      ) : (
        <SelectIcon />
      )}
    </SelectContainer>
  );
};
const mapStateToProps = (state) => {
  return {
    rubros: state.profile.subQuotations,
    projectToEdit: state.profile.projectToEdit,
  };
};


export default connect(mapStateToProps, { getSubprojectsType })(RubroSubQuotationSelect);
import * as actionTypes from "../actions/types";
const initialState = {
    loading: false,
    categories: [],
    error: null,
    success: false,
    categoryList: [],
  };
  
  const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_CATEGORIES_REQUEST:
        return { ...state, loading: true };
      case actionTypes.GET_CATEGORIES_SUCCESS:
        return { ...state, loading: false, categories: action.payload };  
        case 'SEND_QUOTE_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case actionTypes.SEND_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
      case actionTypes.GET_CATEGORY_LIST:
        return {
            ...state,
            categoryList: action.payload,
        };
        case actionTypes.GET_CATEGORY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
      case actionTypes.RESET_SUCCESS:
            return {
                ...state,
                success: false,
            };
    case actionTypes.SEND_QUOTE_FAIL:   
      case actionTypes.GET_CATEGORIES_ERROR:
        return {
            ...state,
            error: action.payload,
        };
        default:
        return state;
  }
  };
  
  export default categoryReducer;
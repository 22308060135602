import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const BaseButtonStyle = styled.button`
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: auto;
  outline: none;
  font-size: 15.5px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  background-color: var(--color-mainBlue);
  border-radius: 30px;
  color: ${({ colored }) => (colored === "white" ? "var(--color-mainBlue)" : "var(--color-white)")};
  box-shadow: ${({ shadow }) =>
    shadow ? "0px 20px 15px -15px var(--color-mainDark);" : null};
  width: ${({ widthed }) => (widthed === "xs" ? "140px" : "100%")};
  background-color: ${({ colored }) => {
    if (colored === "red") return "var(--color-error)";
    if (colored === "green") return "var(--color-success)";
    if (colored === "white") return "var(--color-white)";
    if (colored === "main") return "var(--color-main)";
    return "var(--color-mainBlue)";
  }};
  border-radius: ${({ bordered }) => (bordered ? "7px" : null)};
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    color: ${({ colored }) => (colored === "white" ? "var(--color-mainBlue)" : "var(--color-white)")};
  }

  ${({ disabled }) =>
    disabled
      ? `
        opacity: 0.4;
        pointer-events: none;
      `
      : null};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;


const StyledLink = BaseButtonStyle.withComponent(Link);
const StyledButton = BaseButtonStyle.withComponent("button");

const Button = ({ disabled, children, colored, bordered, link, typebtn, ...rest }) => {

  const Component = typebtn === "link" ? StyledLink : StyledButton;

  return (
    <Component
      to={typebtn === "link" ? `/${link}` : undefined} 
      colored={colored}
      bordered={bordered}
      disabled={disabled}
      {...rest}
      whilehover={{ scale: 1.2 }}
      whiletap={{ scale: 0.8 }}
    >
      {children}
    </Component>
  );
};

export default Button;

import React from "react";
import styles from "./AllProjectsSkelenton.module.scss";
const AllProjectsSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.user}>
          <div className={styles.userImg}></div>
          <div className={styles.info}></div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.titleContainer_title}></div>
          <div className={styles.titleContainer_input}></div>
        </div>
      </div>
      <div className={styles.AllProjectsMasonry}>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>
        <div className={styles.AllProjectsMasonry_item}></div>

      </div>
    </div>
  );
};
export default AllProjectsSkeleton;

import React from "react";
import styles from "./Modal.module.scss";
import { AnimatePresence, motion } from "framer-motion";

const modal = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: { delay: 0.2 },
	},
};

const Modal = ({ children, isOpen, setIsOpen, disableOutsideClick }) => {
	const handleBackdropClick = () => {
		if (disableOutsideClick) return;
		setIsOpen && setIsOpen(false);
	}
	return (
		<AnimatePresence>
			{
				isOpen && (
						<div className={styles.backdrop} onClick={handleBackdropClick}>
								<motion.div
								variants={modal}
								initial='hidden'
								animate='visible'
								exit='hidden'
								className={styles.container}>
									{children}
							</motion.div>
						</div>
				)
			}
		</AnimatePresence>
	);
};

export default Modal;

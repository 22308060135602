import React, { useState } from 'react';
import styles from './MaterialTable.module.scss';

export default function MaterialTable({ materials = {}, onMaterialsChange, setNote, note, isEditable = true }) {
  const [showNoteInput, setShowNoteInput] = useState(false);

  const handleInputChange = (categoryId, itemId, field, value) => {
    if (!isEditable) return; // No hacer nada si no es editable
    const updatedMaterials = materials.map((category) => {
      if (category.category_id === categoryId) {
        return {
          ...category,
          materials: category.materials.map((item) =>
            item.id === itemId ? { ...item, [field]: value } : item
          ),
        };
      }
      return category;
    });
    onMaterialsChange(updatedMaterials);
  };

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>Material</th>
            <th>Unidad</th>
            <th>Cantidad</th>
            <th>Precio Unidad</th>
            <th>Descuento</th>
            <th>IVA*</th>
            <th>Precio Total</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((category) => (
            <React.Fragment key={category.key}>
              <tr className={styles.categoryRow}>
                <td colSpan="7" className={styles.categoryName}>{category.name}</td>
              </tr>
              {category.materials.map((item) => (
                <tr key={item.id} className={styles.itemRow}>
                  <td className={styles.itemName}>{item.name}</td>
                  <td>{item.unit}</td>
                  <td>
                      <span>{item.amount || '---'}</span>
                    
                  </td>
                  <td>
                    {isEditable ? (
                      <input
                        type="text"
                        className={styles.input_table}
                        value={item.priceUnit || ''}
                        onChange={(e) => handleInputChange(category.category_id, item.id, 'priceUnit', e.target.value)}
                      />
                    ) : (
                      <span>{item.priceUnit || '---'}</span>
                    )}
                  </td>
                  <td>
                    {isEditable ? (
                      <input
                        type="text"
                        className={styles.input_table}
                        value={item.discount || ''}
                        onChange={(e) => handleInputChange(category.category_id, item.id, 'discount', e.target.value)}
                      />
                    ) : (
                      <span>{item.discount || '---'}</span>
                    )}
                  </td>
                  <td>{(item.priceUnit * 0.21).toFixed(2)}</td>
                  <td>{(item.amount * item.priceUnit * (1 - item.discount / 100)).toFixed(2)}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Nota de descripción */}
      {isEditable && (
        <>
          <div className={styles.addNoteButton}>
            <button
              className={`${styles.button} ${styles.button_add_note}`}
              onClick={() => setShowNoteInput((prev) => !prev)}
            >
              {showNoteInput ? 'Cerrar Nota' : 'Añadir Nota'}
            </button>
          </div>
          {showNoteInput && (
            <div className={styles.noteInputContainer}>
              <textarea
                className={styles.noteInput}
                placeholder="Escribe una breve descripción..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

import React from "react";
import PrivateQuotationCard from "./PrivateQuotationCard";
import { connect } from "react-redux";
import {
  setPostulationProjectId,
  openNDAModal,
  closeNDAModal,
  setResponsiveFall,
  goToProjectId,
} from "../../store/actions/index";
import PublicQuotationItem from "./PublicQuotations/PublicQuotationItem";

const QuotationsList = ({
  dashboardView,
  onFocusRef,
  refProjectId,
  typeViewer,
  item,
  userData,
  setModalOpen,
  isEditing,
  setIsEditing,
  idForEdit,
  setIdForEdit,
  showSubprojects = false,
  ...props
}) => {

  return item.type === "PRIVATE" ? (
   <PrivateQuotationCard
      item={item}
      />
  ) : (
      <PublicQuotationItem  item={item} isQuotation />
  );

};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardView: ownProps.dashboardView || false,
    item: ownProps.item,
  };
};

export default
  connect(mapStateToProps, {
    setPostulationProjectId,
    openNDAModal,
    closeNDAModal,
    goToProjectId,
    setResponsiveFall,
  })(QuotationsList);

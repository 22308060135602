import React, { Component } from "react";
import userImg from "../../assets/svg/user.svg";
import emptyImg from "../../assets/images/emptyImg.png";
import "./FileUpload.scss";
import { connect } from "react-redux";
import { setImage } from "../../store/actions/index";

class FileUpload extends Component {
  _isMounted = false;
  state = {
    image: this.props.file && this.props.file.name ? this.props.file : null,
    fileName: "Buscar en tu computadora",
  };
  componentDidMount() {
    if(this.props.file && this.props.file.name){
      this.imageChangeHandler({target: {files:[this.props.file]}})
    }
    this._isMounted = true;
  }
  imageChangeHandler = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 50000000) {
      if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);

        this.setState({
          fileName: event.target.files[0].name.slice(0, 10) + "...",
        });
      } else {
        this.setState({ fileName: "jpeg/png unicamente" });
      }
    } else {
      this.setState({
        fileName: "No puede superar 500kb",
      });
    }
    this.props.setImage(event.target.files[0]);
  };

  imageChangeHandler2 = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 50000000) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
      this.props.setImage(event.target.files[0]);
    }
  };
  render() {
    let image = this.state.image;
    let userSVG = this.state.image === null ? <object className='user-svg--absolute' aria-label='user' data={userImg} type='image/svg+xml'></object> : null;
    return (
      <>
        <div className='img-container'>
          <div className='main-user__image' style={image !== null ? { border: "none" } : null}>
            {image !== null ? null : userSVG}
            <img src={image !== null ? image : emptyImg} alt='Profile' />
            <input className='main-user__input' id='file' type='file' onChange={this.imageChangeHandler2} accept='image/*' />
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { setImage })(FileUpload);

import React, { useEffect } from "react";
import styles from "./SkillsEdit.module.scss";
import { connect } from "react-redux";
import { ReactComponent as AllSkillsIcon } from "../../../../assets/svg/allskills.svg";
import {
    getSkills,
    addSkill,
    getUserSkill,
    deleteSkillFromProfile,
    openMainModal,
  } from "../../../../store/actions";
  
  const SkillsEdit = ({
    getSkills,
    getUserSkill,
    userSkills,
    skills,
    userData,
    addSkill,
    openMainModal,
    deleteSkillFromProfile,
    editSkills,
    setEditSkills,
    setFieldValue,
  }) => {


    useEffect(() => {
        getSkills();
        getUserSkill(userData.id);
    }, []);

    //Maneja eliminar los rubros del perfil
const handleDeleteSkill = (skillId, id) => {
    openMainModal({
      title: "¿Estas seguro que deseas eliminar el rubro?",
      body: null,
      isSuccess: false,
      confirmHandler: () => {
        deleteSkillFromProfile(skillId, id)
        setEditSkills(false)},
    });
  };

    return (
        <div className={styles.Skills_main}>
        <div className={styles.Skills_main_userSkills}>
          {Array.isArray(userSkills) &&
           userSkills.length > 0 ? (
            userSkills.map((skill, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.Skills_main_skill} ${
                    editSkills ? styles.delete : ""
                  }`}
                  onClick={() => (editSkills ? handleDeleteSkill(skill.id, userData.id) : null)}
                >
                  {skill.name}
                </div>
              );
            })
            ) : (
                <div className={styles.Skills_main_skill}>
                    No hay rubros seleccionados
                </div>
            )}
        </div>
        {editSkills &&
        <div className={styles.Skills_AllSkills}>
            <div className={styles.Skills_heading}>
              <AllSkillsIcon />
              <h2 className={styles.Skills_heading_text}>Seleccionar rubros</h2>
            </div>
            <div className={styles.Skills_main}>
              <div className={styles.Skills_main_AllSkills}>
                {Array.isArray(skills) &&
                skills.map((skill, index) => {
                  let skillExist = false;
                  userSkills.map((userSkill) => {
                    return (skillExist = userSkill.id === skill.id);
                    });
                  return !skillExist ? (
                    <div
                      key={index}
                      className={`${styles.Skills_main_skill} ${
                        editSkills ? styles.add : ""
                      }`}
                      onClick={() => addSkill(skill.id)}
                    >
                      {skill.name}
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          </div>
        }
        </div>
            
    );
    };

    const mapStateToProps = (state) => {
        return {
            userData: state.general.userData,
            skills: state.register.skills,
            userSkills: state.register.userSkills,
          };
        };
        
        export default connect(mapStateToProps, {
          getSkills,
          addSkill,
          getUserSkill,
          deleteSkillFromProfile,
          openMainModal,

        })(SkillsEdit);

    
import React, { useState } from "react";
import styles from "./ProjectAnswer.module.scss";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { answerProjectQuestion } from "../../../../store/actions";

const ProjectAnswer = ({ question, closeModal, answerProjectQuestion, projectId }) => {
    const [answerValue, setAnswerValue] = useState("");

    const handleSubmit = () => {
        if (answerValue) {
            answerProjectQuestion(question.id, answerValue, projectId);
            closeModal();
        }
    }


    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.overLay}>
                <div className={styles.body}>
                    <button className={styles.closeButton} onClick={closeModal} >X</button>
                    <div className={styles.question}>
                        <h4 className={styles.questionTitle}>Pregunta:</h4>
                        <p className={styles.questionTitle}>{question.question}</p>
                        <h4 className={styles.questionTitle}>Respuesta:</h4>
                        <div className={styles.inputContainer}>
                            <input
                                onChange={(e) => setAnswerValue(e.target.value)}
                                type="text"
                                className={styles.input}
                                placeholder="Escribí tu respuesta" />
                            <button className={styles.sendButton} onClick={handleSubmit}>Enviar</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

const mapStateToProps = (state) => {
    return {

    };
};

export default connect(mapStateToProps, {answerProjectQuestion})(ProjectAnswer);
import React, { useState } from "react";
import styles from "./GuestAccountModal.module.scss";
import alertWarning from "../../../assets/svg/modalAtencion.svg"
import { logoutFromGuestAccount, closeSuscriptionModal } from "../../../store/actions/";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { uniqueId } from "lodash";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const FreeTrialModal = ({ closeSuscriptionModal, logoutFromGuestAccount}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDashboard, _] = useState(location.pathname == "/dashboard")

    const registerAccount = () => {
      closeSuscriptionModal()
      logoutFromGuestAccount(navigate)
    }

    const closeModal = () => {
        if (!isDashboard) {
            navigate("/dashboard");
        }
        closeSuscriptionModal()
        
      }
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          variants={backdrop}
          initial='hidden'
          animate='visible'
          exit='hidden'
          className={styles.GuestModal_overlay}
          onClick={closeModal}
          key={uniqueId()}
        ></motion.div>
        <motion.div
          variants={modal}
          initial='hidden'
          animate='visible'
          exit='hidden'
          className={styles.GuestModal}
        >
          <div className={styles.GuestModal_ImageContainer}>       
          </div>
          <div className={styles.GuestModal_FreeTrial}>
              <div className={styles.GuestModal_title}>
                  <img src={alertWarning} className={styles.GuestModal_titlealertWarning}/>
              </div>
              <h3>Cuenta de invitado</h3>
              <p>Esta usando una cuenta de invitado para ver el dashboard</p>
              <p>Es necesario tener una cuenta para acceder a las funcionalidades</p>
              <div
              className={styles.GuestModal_PlanContaineritem_success}
              onClick={registerAccount}
              >
                  Registrar cuenta
              </div>
              <div
              className={styles.GuestModal_PlanContaineritem_decline}
              onClick={closeModal}
              >
                  {
                      isDashboard ? "Volver" : "Volver al dashboard"
                  }
              </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.general.userData,
    isGuestAccount: state.general.isGuestAccount,
  };
};

export default connect(mapStateToProps, { closeSuscriptionModal, logoutFromGuestAccount })(FreeTrialModal);

import { combineReducers } from "redux";
import registerReducer from "./registerReducer";
import generalReducer from "./generalReducer";
import profileReducer from "./profileReducer";
import dashboardReducer from "./dashboardReducer";
import categoryReducer from "./categoryReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["showSubprojects", "projectIdForSub", "sendSubprojects", "sendProject", "projectBudgets", "sendSubQuotations"]
};

const generalPersistConfig = {
  key: "general",
  storage: storage,
  blacklist: ["recoverOpen", "profileVisualizer"]
};
const registerPersistConfig = {
  key: "register",
  storage: storage,
  blacklist: ["images", "imagesPreview", "openEdit", "personaForm", "empresaForm"]
};
const categoryPersistConfig = {
  key: "category",
  storage: storage,
  blacklist: ["categories"]
};


export default combineReducers({
  register: persistReducer(registerPersistConfig, registerReducer),
  general: persistReducer(generalPersistConfig, generalReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  category: persistReducer(categoryPersistConfig, categoryReducer),
  dashboard: dashboardReducer,
});

import React from "react";
import styled from "styled-components";
import successIcon from "../../../assets/svg/success.svg";
import errorIcon from "../../../assets/svg/error.svg";
import emailIcon from "../../../assets/svg/email.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const messageResponse = (props) => {
  let imgIcon = "";
  let colorTitle = "";
  switch (props.type) {
    case "success":
      imgIcon = successIcon;
      colorTitle = "#17d66f";
      break;
    case "error":
      imgIcon = errorIcon;
      colorTitle = "#d6174a";
      break;
    case "email":
      imgIcon = emailIcon;
      colorTitle = "#3f66b3";
      break;
    default:
      break;
  }

  return (
    <Container>
      <div>
        <img src={imgIcon} alt="" style={{width: "8.5rem"}}/>
        <h4 style={{ color: colorTitle, margin: "1rem", fontSize: "2.8rem" }}>
          {props.title}
        </h4>
        <p>{props.children}</p>
      </div>
    </Container>
  );
};

export default messageResponse;

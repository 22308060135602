import axios from "axios";
import environment from 'environment';

// onUploadProgress: p => {
//   let progress = Math.round((p.loaded * 100) / p.total);
// }

const instance = axios.create({
  baseURL: environment.api.dashboard,
});

/* instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const compareURL = `${instance.defaults.baseURL}/oauth/token`;
    if (error?.response?.status === 401 && error?.response?.config?.url !== "/oauth/token") {
    // if (error.response.config.url !== compareURL && error.response.status === 401) {
      // const baseURL = error.response.config.baseURL;
      const baseURL = window.location.origin;
      localStorage.removeItem("access-RefreshToken-Edify");
      localStorage.removeItem("access-Token-Edify");
      window.location.replace(baseURL)
      // if (baseURL === "https://api.edify.la") {
      //   window.location.replace("https://app.edify.la");
      // } else {
      //   window.location.replace("http://test.app.edify.la");
      // }
    }
    return Promise.reject(error);
  }
); */

export default instance;

import React from "react";
import styles from "./MainModalPlace.module.scss";
import { ReactComponent as Edify } from "../../../assets/svg/edifyMarketplace.svg";
import { closeMainModal } from "../../../store/actions/profileActions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { uniqueId } from "lodash";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const MarketModal = ({ closeMainModal }) => {


  return (
    <>
      <AnimatePresence exitBeforeEnter >
        <motion.div
          key={uniqueId()}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={closeMainModal}
        ></motion.div>
        <motion.div
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal}
        >
          <div>
            <Edify />{" "}
          </div>
          <h3>Estamos construyendo una nueva herramienta para vos.</h3>
          <p>
            Pronto tendrás una nueva herramienta que permitirá contactarte con
            más profesionales y conseguir o realizar nuevas cotizaciones.
          </p>
          
            
          
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { closeMainModal })(MarketModal);

import React, { useState, useEffect } from "react";
import styles from "./WorkZoneSelector.module.scss";
import JurisdictionList from "../UI/JurisdictionList/JurisdictionList";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { getCities } from "../../store/actions";
import { connect } from "react-redux";
import { motion } from "framer-motion";

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
}

const WorkZoneSelector = ({ handleChange, setFieldValue, values, getCities, cities, validateForm, validateField }) => {
    const [showJurisdictionList, setShowJurisdictionList] = useState(false);
    const [showCityList, setShowCityList] = useState(false);
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [cityInputValue, setCityInputValue] = useState('');


    const handleAddWorkZone = (city) => {
        const newWorkZone = [
            ...values.workZone,
            {
                jurisdiccion: values.selectedJurisdiction.id,
                name: values.selectedJurisdiction.name,
                city: city.name
            }
        ];
        setFieldValue('workZone', newWorkZone);
        setFieldValue('city', city.name);
        setFieldValue('selectedJurisdiction', null);
        setFieldValue('city', "");
        setShowCityList(false);
        setShowCitySuggestions(false);
        setCityInputValue('');

    };

    useEffect(() => {
        if (values.selectedJurisdiction) {
            getCities(values.selectedJurisdiction?.id)
        }
    }, [values.selectedJurisdiction?.id]);

    const handleCitySuggestions = (value) => {
        if (value.length < 3) {
            setShowCitySuggestions(false)
        } else if (value.length >= 3) {
            setShowCityList(false);
            setShowCitySuggestions(true);
        }
        setCityInputValue(value)
    }

    const matchingCities = cities?.filter((city) => {
        return city.name.toLowerCase().includes(cityInputValue.toLowerCase())
    })

    return (
        <>
            <motion.div 
                variants={variants}
                initial="hidden"
                animate="visible"
            className={styles.body_item}>
                {!values.allCountry && (
                    <div className={styles.body_formGroup_workZone_inputsContainer}>
                        <div className={styles.body_item_workZoneContainer}>
                            <input
                                type='text'
                                id='jurisdictionList'
                                name='jurisdictionList'
                                placeholder='Provincia'
                                className={styles.body_item_workZoneInput}
                                value={values.selectedJurisdiction?.name || ''}
                                readOnly
                                onChange={handleChange}
                            />
                            <ArrowDownIcon
                                title='Ver lista de provincias'
                                width={15}
                                height={15}
                                className={showJurisdictionList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp}
                                onClick={() => setShowJurisdictionList(!showJurisdictionList)} />
                        </div>
                        {showJurisdictionList && (
                            <ul className={styles.body_item_workZoneList}>
                                {JurisdictionList.map((jurisdiction) => (
                                    <li
                                        key={jurisdiction.id}
                                        className={styles.body_item_workZoneList_item}
                                        onClick={() => {
                                            setFieldValue('selectedJurisdiction', { id: jurisdiction.id, name: jurisdiction.name });
                                            setShowJurisdictionList(false);
                                        }}>
                                        {jurisdiction.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div className={`${styles.body_item_workZoneCityContainer} ${!values.selectedJurisdiction && styles.disabledInput}`}>
                            <input
                                type='text'
                                id='workZone'
                                name='workZone'
                                placeholder='Ciudad'
                                value={cityInputValue}
                                onChange={(e) => handleCitySuggestions(e.target.value)}
                                className={`${styles.body_item_workZoneInput} ${!values.selectedJurisdiction && styles.disabledInput}`}
                            />
                            <ArrowDownIcon
                                title='Ver lista de ciudades'
                                width={15}
                                height={15}
                                className={`${showCityList ? styles.body_item_workZoneArrowDown : styles.body_item_workZoneArrowUp} ${!values.selectedJurisdiction && styles.disabledInput}`}
                                onClick={() => setShowCityList(!showCityList)} />
                        </div>
                        {showCityList && !showCitySuggestions && (
                            <ul className={styles.body_item_workCityList}>
                                {cities?.map((city) => (
                                    <li
                                        key={city.name}
                                        className={styles.body_item_workCityList_item}
                                        onClick={() => { handleAddWorkZone(city) }}
                                    >
                                        {city.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {showCitySuggestions && !showCityList && (
                            <ul className={styles.body_item_workCityList}>
                                {matchingCities.map((city) => (
                                    <li
                                        key={city.name}
                                        className={styles.body_item_workCityList_item}
                                        onClick={() => { handleAddWorkZone(city) }}
                                    >
                                        {city.name}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                )}
            </motion.div>
            {values.workZone.length > 0 && (
                <div className={styles.body_item_workZoneSelected}>
                    {values.workZone.map((workZone, index) => (
                        <div
                            key={index}
                            className={styles.body_item_workZoneSelected_item}
                            onClick={() => { setFieldValue('workZone', values.workZone.filter((_, i) => i !== index)) }}>
                            {workZone.name} - {workZone.city.split(",")[0]}&nbsp;X
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        cities: state.register.cities,
    }
}

export default connect(mapStateToProps, { getCities })(WorkZoneSelector);
import React from "react";
import styles from "./RepAndComments.module.scss";
import { ReactComponent as StarEmpty } from "../../../../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as StarFull } from "../../../../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarHalf } from "../../../../../assets/svg/halfStarSvg.svg";
import CheckedProfile from "../../../../../assets/images/perfilChecked.png";
import { useNavigate } from "react-router-dom";

const RepAndComments = ({ comment, ...props }) => {
  const navigate = useNavigate();

  let stars = [];
  let score = comment?.stars;
  for (let i = 0; i < 5; i++) {
    if (score >= 1) {
      stars.push(<StarFull key={i} width={15} height={15} />);
    } else if (score >= 0.5) {
      stars.push(<StarHalf key={i} width={15} height={15} />);
    } else {
      stars.push(<StarEmpty key={i} width={15} height={15} />);
    }
    score--;
  }

  //extraer las skills que fueron calificadaas
  const skills = comment?.skills?.map((skill) => {
    return skill.skill.name;
  });

  const handleRedirectToProfile = () => {
    const userSenderFullName = comment?.userSender?.type === "COMPANY" ? comment?.userSender?.razon_social : comment?.userSender?.name + " " + comment?.userSender?.last_name;
    navigate(`/profesionales/${comment?.userSender?.id}/${userSenderFullName}`);
  };

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentContainer_userSender}>
        <div className={styles.commentContainer_userSender_header}>
          <div className={styles.commentContainer_userSender_body}>
            <div className={styles.commentContainer_userSender_body_title}>USUARIO</div>
            <div className={styles.commentContainer_userSender_body_imageContainer} onClick={handleRedirectToProfile}>
              <div className={styles.commentContainer_userSender_body_user}>
                <img className={styles.commentContainer_userSender_body_imageContainer_img} src={comment?.userSender?.image_profile} alt="user" />
                <div className={styles.commentContainer_userSender_body_data}>
                  <p>{comment?.userSender?.type === "COMPANY" ? comment?.userSender?.razon_social : comment?.userSender?.name + " " + comment?.userSender?.last_name}</p>
                </div>
              </div>
              <div className={styles.commentContainer_userSender_body_user_validate}>
                {comment?.userSender?.validated ? <img src="https://cdn-icons-png.flaticon.com/128/3472/3472620.png" alt="verified" className={styles.commentContainer_userSender_body_user_validate_image} />
                  : <img src="https://cdn-icons-png.flaticon.com/128/25/25333.png" alt="not verified" className={styles.commentContainer_userSender_body_user_validate_image} />}
                <p className={styles.commentContainer_userSender_body_user_validate_txt}>{comment?.userSender?.validated ? "Verificado" : "No verificado"}</p>
              </div>
            </div>
          </div>
          <div className={styles.commentContainer_skills}>
            <div className={styles.commentContainer_skills_header}>
              <div>RUBRO</div>
            </div>
            <div className={styles.commentContainer_skills_body}>
              <p>{skills?.join(", ")}</p>
            </div>
          </div>
        </div>

        <div className={styles.commentContainer_commentData}>
          <div className={styles.commentContainer_comment}>
            <div className={styles.commentContainer_comment_header}>
              <div>CALIFICACIÓN</div>
            </div>
            <div className={styles.commentContainer_comment_body}>
              <p className={styles.commentContainer_comment_body_txt}>"{comment?.comment} "</p>
              <div className={styles.commentContainer_date_body}>
                <p>creado {new Date(comment?.createdAt).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.commentContainer_score}>
        <div className={styles.commentContainer_score_header}>
          <div>PUNTUACIÓN</div>
        </div>
        <div className={styles.commentContainer_score_body}>
          {stars}
        </div>
        <div className={styles.commentContainer_score_footer}>
          <p>{comment?.stars}</p>
        </div>
      </div>
    </div>
  );
};

export default RepAndComments;

import React from "react";
import { connect } from "react-redux";
import { loginGuestAccount } from "../../store/actions";
import styles from "./InvitePage.module.scss";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const InvitePage = (props) => {  
  const navigate = useNavigate()
  const [loginAnimation, setLoginAnimation] = useState(false)

  useEffect(() => {
    doAnimation()
  },[])

  const doAnimation = () => {
    setLoginAnimation(true)
    const awaitThreeSeconds = new Promise((resolve) => setTimeout(() => resolve(),3000))
    Promise.all([awaitThreeSeconds, props.loginGuestAccount()])
      .then(() => {
        navigate("/dashboard")
      })
      .catch((error) => console.error(error))
  }

  return (
    <div className={styles.resumenContainer}>
      <InitialWrapper animation={loginAnimation}>
        <div style={{height: "400px"}}></div>
      </InitialWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginAnimation: state.general.loginAnimation,
  };
};

export default connect(mapStateToProps, {
  loginGuestAccount,
})(InvitePage);

import React, { useEffect, useState } from "react";
import { recoverPasswordAction, setSuccessFalse } from "../../store/actions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import Input from "../../components/UI/Forms/Input/Input";
import Button from "../../components/UI/Forms/Button/Button";
import { fireAnalytics }  from '../../utils/firebase.js';
import styles from "./ForgotPasswordPage.module.scss";
import MessageResponse from "../../components/UI/MessageResponse/MessageResponse";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";

const FormGroup = styled.div`
    margin: 15px 0;
    position: relative;
    width: 100%;
  `;

const ForgotPasswordPage = (props) => {
  const [disabled,setDisabled] = useState(false)
  const recoverSchema = Yup.object().shape({
    email: Yup.string()
      .email("El mail no es valido")
      .required("El mail es requerido"),
  });
  
  const handleBack = () => {
    setDisabled(false)
    props.setSuccessFalse();
  };
  const handleSubmit = (values) =>{
    props.recoverPasswordAction(values);
    setDisabled(true)
    fireAnalytics('recover_password', { category: 'authentication'});
  }

  useEffect(() => {
    fireAnalytics('password_recovery', { category: 'navigation'});
  }, []);

  return (
   
    <InitialWrapper>
      {props.success ? (
        <>
          <MessageResponse type="email" title="¡Listo!">
            Te hemos enviado un correo a tu casilla
          </MessageResponse>
          <FormGroup>
            <div onClick={() => handleBack()}>
              <Button to="/" typebtn="link">
                Volver al inicio
              </Button>
            </div>
          </FormGroup>
        </>
      ) : (
        <div className={styles.formContainer}>
          <h3 className={styles.formTitle}>¿Olvidaste tu contraseña?</h3>
          <p className={styles.formSubtitle}>
            No te preocupes, envíanos tu mail
            <br /> para que puedas iniciar el proceso de recuperación.
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={recoverSchema}
            onSubmit={(values) => handleSubmit(values)}>
            {({ handleSubmit, isValid, errors }) => (
              <>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <FormGroup>
                    <Field
                      className={`${errors.email ? "error" : null}`}
                      placeholder={`${errors.email ? errors.email : "E-mail"}`}
                      type="email"
                      name="email"
                      component={Input}
                    />
                  </FormGroup>

                  <FormGroup>
                    <button
                      className={`${styles.btn} ${
                        errors.email ? styles.error : null
                      } ${disabled ? styles.disabled : null}`}
                      type="submit"
                      disabled={disabled}>
                      Recuperar Contraseña
                    
                    </button>
                  </FormGroup>
                </form>
                <div className={styles.goBack}>
                  <Link to="/">{"< Volver"}</Link>
                </div>
              </>
            )}
          </Formik>
        </div>
      )}
    </InitialWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.general.success,
  };
};

export default connect(mapStateToProps, {
  recoverPasswordAction,
  setSuccessFalse,
})(ForgotPasswordPage);

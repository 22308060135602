import React, { useEffect, useState } from "react";
import styles from "./AllRegisteredUsers.module.scss";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import {
  getAllUsersBySkills,
  resetSelectedSkill,
  setGoBackToCategoryList,
  getUsersBySkillName,
  closeFilterUsersModal,
} from "../../store/actions";
import { ReactComponent as Search } from "../../assets/svg/searchFilter.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrowUpIcon.svg";
import { ReactComponent as Filter } from "../../assets/svg/filterNav.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/xClose.svg";


const AllRegisteredUsers = ({ 
  loading, 
  getAllUsersBySkills,
  resetSelectedSkill,
  setGoBackToCategoryList,
  completeSkillsList,
  getUsersBySkillName,
  selectedSkill,
  setIsLoading,
  setShowInfo,
  setSelectedSkillsList,
  setInitialState,
  initialState,
  selectedSkillsList,
  closeFilterUsersModal,
  setCheckedItems,
  checkedItems,
  handleMakeSearch,
  resetCheckboxState,
  ...props }) => {
  const [selectedLetter, setSelectedLetter] = useState("A");
  const [suggestions, setSuggestions] = useState([]);
  const [showSearchByLetter, setShowSearchByLetter] = useState(false);
  const [noResultMessage, setNoResultMessage] = useState("");
  const [searhInput, setSearchInput] = useState("");


  useEffect(() => {
    setCheckedItems((prevCheckedItems) => {
        const initialCheckedItems = { ...prevCheckedItems };
        selectedSkillsList.forEach(skill => {
            initialCheckedItems[skill] = true;
        });
        return initialCheckedItems;
    });
}, [selectedSkillsList]);


  const handleResetSelectedSkill = () => {
    resetSelectedSkill();
    setShowInfo(false);
    setSelectedSkillsList([]);
    setInitialState(false)
    setGoBackToCategoryList(false)
    resetCheckboxState();
    closeFilterUsersModal();
  };


  const handleSkillsToggle = (skillName) => {

      const updatedSelectedSkillsList = selectedSkillsList.includes(skillName)
        ? selectedSkillsList.filter((skill) => skill !== skillName)
        : [...selectedSkillsList, skillName];

      setSelectedSkillsList(updatedSelectedSkillsList);

      if (updatedSelectedSkillsList.length === 0) {
        setInitialState(false);
        setGoBackToCategoryList(false);
      }
    
    // Update checkedItems state
     setCheckedItems((prevCheckedItems) => {
    const updatedCheckedItems = { ...prevCheckedItems };
    updatedSelectedSkillsList.forEach(skill => {
      updatedCheckedItems[skill] = true;
    });
    // Uncheck items that are no longer in the selectedSkillsList
    Object.keys(updatedCheckedItems).forEach(skill => {
      if (!updatedSelectedSkillsList.includes(skill)) {
        updatedCheckedItems[skill] = false;
      }
    });
    return updatedCheckedItems;
  });
  };


  //Funcion que maneja el boton de buscar
  const handleSearchClick = (searchValue) => {
    handleSkillsToggle(searchValue.toLowerCase());
    setShowInfo(true);
    setSuggestions([]);
    setSearchInput("");
    setNoResultMessage("");
  };

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  const handleInputChange = (e) => {
    const search = e.target.value.toLowerCase();
    generateSuggestions(search);
    setSearchInput(search);

    if (suggestions.length === 0) {
      setNoResultMessage("No se encontraron resultados");
    }

    if (search === "") {
      setNoResultMessage("");
    }

    if (e.key === "Enter") {
      if (suggestions.length > 0) {
        const matchingSuggestion = suggestions.find(suggestion =>
          suggestion.name.toLowerCase() === search
        );
        if (matchingSuggestion) {
          handleSearchClick(search);
          setNoResultMessage("");
          setSearchInput("");
        } else {
          setSuggestions([]);
          setNoResultMessage("La búsqueda no coincide con ninguna sugerencia");
        }
      } 
    }
  };

  const generateSuggestions = (search) => {
    if (search === "") {
      setSuggestions([]);
    } else {
      const newSuggestions = completeSkillsList.filter((skill) =>
        skill.name.toLowerCase().startsWith(search)
      );
      setSuggestions(newSuggestions);
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.allSkillsContainer_titleContainer}>
        <p className={styles.allSkillsContainer_title}>Filtrar usuarios</p>
      </div>
      {selectedSkillsList.length !== 0 && (
        <motion.div
          className={styles.selectedSkill_container}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >

          {selectedSkillsList.map((skill) => (
            <motion.div
              className={styles.selectedSkill}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              key={skill}
            >
              <div className={styles.selectedSkill_item} key={skill}>
                <p className={styles.selectedSkill_item_text}>{skill.charAt(0).toUpperCase() + skill.slice(1)}</p>
                <motion.div
                  className={styles.selectedSkill_item_close}
                  onClick={() => handleSkillsToggle(skill)}
                  whileHover={{ scale: 1.1 }}
                >
                  <p className={styles.selectedSkill_item_close_text}>X</p>
                </motion.div>
              </div>
            </motion.div>
          ))}
          <motion.div
            className={styles.selectedSkill_closeAll}
            onClick={() => handleResetSelectedSkill()}
          >
            <Filter width={12} height={12} className={styles.selectedSkill_closeAll_svg} />
            <p className={styles.selectedSkill_closeAll_text}>Limpiar filtros</p>
          </motion.div>
        </motion.div>
      )}
      <div className={styles.allSkillsContainer}>

        <div className={styles.filterByName}>
          <input
            className={styles.filterByName_field}
            type="text"
            placeholder="Buscar por rubro"
            onChange={handleInputChange}
            onKeyDown={handleInputChange}
            value={searhInput}
          />
          <Search className={styles.filterByName_filterSvg} />
          {noResultMessage !== "" && (
            <ul className={styles.filterByName_suggestions}>
              <li className={styles.filterByName_suggestions_item}>
                <div className={styles.filterByName_suggestions_item_container}>
                  <p className={styles.filterByName_suggestions_item_name}>
                    {noResultMessage}
                  </p>
                </div>
              </li>
            </ul>
          )}
          {Array.isArray(suggestions) && suggestions.length > 0 && (
            <ul className={styles.filterByName_suggestions}>
              {suggestions.map((suggestion) => (
                <li className={styles.filterByName_suggestions_item} key={suggestion.name}>
                  <div className={styles.filterByName_suggestions_item_container} onClick={() => handleSearchClick(suggestion.name)}>
                    <Search className={styles.filterByName_suggestions_svgList} />
                    <p className={styles.filterByName_suggestions_item_name}>
                      {suggestion.name}
                      ({suggestion.users})
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.searchByLetter}>
        <div className={styles.searchByLetter_containerText}>
          <p className={styles.searchByLetter_text}>Letra</p>
          <motion.div onClick={() => setShowSearchByLetter(!showSearchByLetter)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.searchByLetter_iconContainer}>
            {showSearchByLetter ? (
              <ArrowUp width={20} height={20} className={styles.searchByLetter_svg} onClick={() => setShowSearchByLetter(!showSearchByLetter)} />
            ) : (
              <ArrowDown width={20} height={20} className={styles.searchByLetter_svg} onClick={() => setShowSearchByLetter(!showSearchByLetter)} />
            )}
          </motion.div>
        </div>
        {showSearchByLetter && (
          <motion.div
            className={styles.searchByLetter_container}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "100%", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className={styles.searchByLetter_containerLetters}>
              {Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ').filter((letter) =>
                completeSkillsList.some((skill) => skill.name.startsWith(letter))
              ).map((letter) => (
                <motion.div
                  key={letter}
                  className={`${styles.searchByLetter_item} ${selectedLetter === letter ? styles.searchByLetter_item_selected : ''}`}
                  onClick={() => handleLetterClick(letter)}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <p className={styles.searchByLetter_item_text}>{letter}</p>
                </motion.div>
              ))}
            </div>
            <div className={styles.skillListSearch}>
              {Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ').filter((letter) =>
                completeSkillsList.some((skill) => skill.name.startsWith(letter))
              ).map((letter) => {
                return (
                    selectedLetter === letter && (
                      completeSkillsList
                        .filter((skill) => skill.name.startsWith(letter))
                        .map((skill) => {
                          const { name, users } = skill;
                          const lowercaseName = name.toLowerCase();
                          const isChecked = checkedItems[lowercaseName]

                          return (
                            <div key={name} className={styles.skillListContainer_column_item} onClick={() =>  handleSkillsToggle(name.toLowerCase())}>
                              <label>
                                <motion.input
                                  type="checkbox"
                                  checked={isChecked}
                                  className={`${styles.skillListContainer_column_item_checkbox} ${styles.customCheckbox}`}
                                />
                              </label>
                              <div className={styles.skillListContainer_item} >
                                <p className={styles.skillListContainer_item_name} >
                                  {name}
                                </p>
                                <p
                                  className={styles.skillListContainer_item_quantity}
                                >
                                  ({users})
                                </p>
                              </div>
                            </div>
                          );
                        })
                    )
                )
              })}
            </div>

          </motion.div>
        )}
      </div>
      <div className={styles.closeModal} onClick={() => closeFilterUsersModal()}>
        <p className={styles.closeModalText}>
          <CloseIcon width={15} height={15} />
        </p>
      </div>
      <div className={styles.search} onClick={() => handleMakeSearch()}>
        <p className={`${styles.search_searchText} ${selectedSkillsList.length === 0 && styles.disabled}`}>Aplicar filtros</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    completeSkillsList: state.register.skillsList,
    loading: state.dashboard.loading,
    userData: state.general.userData,
    selectedSkill: state.register.selectedSkill,
  };
};

export default connect(mapStateToProps, {
  setGoBackToCategoryList,
  getAllUsersBySkills,
  getUsersBySkillName,
  resetSelectedSkill,
  closeFilterUsersModal,
})(AllRegisteredUsers);

import React from "react";
import UploadPhotos from "./UploadPhotos"; 
import "./modalStyles.scss"; 
import { connect } from "react-redux";
import { openIdentityModal, sendIdentityPhotos } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const PhotoUploadModal = ({closeModal, onSubmit, handleSendPhotos, identityModal, sendIdentityPhotos, openIdentityModal}) => {
  const navigate = useNavigate();

  //Definimos función para enviar fotos en caso que se haga desde el formulario de inscripción
  const handleSendPhotosFromForm = (files) => {
    const email =  identityModal?.emailToValidate;
    sendIdentityPhotos(files, email, navigate);
  }

  const handleCloseModal = () => {
    openIdentityModal(false);
  }

  return (
    <div>
          <div className="modal-overlay" onClick={closeModal} ></div>
          <div className="modal-container">
            <div className="modal-body"  onClick={(e) => e.stopPropagation()}>
              {identityModal?.isOpen ? (
                <UploadPhotos
                  closeModal={handleCloseModal}
                  handleSendPhotos={handleSendPhotosFromForm}
                />
              ) : (
              <UploadPhotos
                onSubmit={onSubmit}
                closeModal={closeModal}
                handleSendPhotos={handleSendPhotos}
              />
              )}
            </div>
          </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    identityModal: state.register.identityModal,
  };
};

export default connect(mapStateToProps, {sendIdentityPhotos, openIdentityModal})(PhotoUploadModal);

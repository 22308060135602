import React, { useState } from 'react';
import styles from './AddCustomMaterialPopup.module.scss';


const AddCustomMaterialPopup = ({ onClose, onAdd, categories }) => {
  const [newMaterial, setNewMaterial] = useState({
    categoryId: '',
    name: '',
    unit: '',
    price: '',
    quantity:'',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMaterial(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd({
      ...newMaterial,
      id: Date.now().toString(),
      price: parseFloat(newMaterial.price),
      temporary: true,
    });
  };

  return (
    <div className={styles['popup-overlay']}>
      <div className={styles.popup}>
        <h2>Añadir Material</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles['form-group']}>
            <label htmlFor="category">Categoría</label>
            <select
              id="categoryId"
              name="categoryId"
              value={newMaterial.categoryId}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Seleccione una categoría</option>
              {categories?.data?.map((category) => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="name">Material</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newMaterial.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="unit">Unidad</label>
            <select
              id="unit"
              name="unit"
              value={newMaterial.unit}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Selecciona una unidad</option>
              <option value="kg">Kilogramos (kg)</option>
              <option value="gr">Gramos (gr)</option>
              <option value="m">Metros (m)</option>
              <option value="cm">Centimetros (cm)</option>
              <option value="l">Litros (l)</option>
              <option value="ml">Mililitros (ml)</option>
              <option value="m2">Metro Cuadrado (m2)</option>
              <option value="cm2">Centímetro Cuadrado (cm2)</option>
              <option value="m3">Metro Cúbico (m3)</option>
              <option value="cm3">Centímetro Cúbico (cm3)</option>
              <option value="u">Unidad (u)</option>
            </select>
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="price">Cantidad</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={newMaterial.quantity}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles['button-group']}>
            <button type="button" onClick={onClose} className={styles['cancel-btn']}>Cancelar</button>
            <button type="submit" className={styles['accept-btn']}>Aceptar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomMaterialPopup;



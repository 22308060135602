import React from "react";
import styles from "./FeedCardSkeleton.module.scss"; 

const FeedCardSkeleton = () => {
    return (
        <div className={styles.feedCardSkeleton}>
            <div className={styles.feedCardSkeletonHeader}>
                <div className={styles.feedCardSkeletonHeaderAvatar}></div>
                <div className={styles.feedCardSkeletonHeaderInfo}>
                    <div className={styles.feedCardSkeletonHeaderInfoTitle}></div>
                    <div className={styles.feedCardSkeletonHeaderInfoDate}></div>
                </div>
            </div>
            <div className={styles.feedCardSkeletonContent}>
                <div className={styles.feedCardSkeletonContentText}></div>
                <div className={styles.feedCardSkeletonContentText}></div>
                <div className={styles.feedCardSkeletonContentText}></div>
                <div className={styles.feedCardSkeletonContentText}></div>
            </div>
        </div>
    );
};

export default FeedCardSkeleton;

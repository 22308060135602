import React, { useState } from "react";
import styles from "./TutorialItem.module.scss";
import CreatePublicationGif from '../../assets/video/createPublicationGif.gif';
import CreatePublicationGif2 from '../../assets/video/CreateObraStep2.gif';
import CreatePublicationGif3 from '../../assets/video/createPublicationStep3Gif.gif';
import CreatePublcationGif4 from '../../assets/video/createPublicationStep4Gif.gif';
import CreateQuotationGif3 from '../../assets/video/quotationGif1.gif';
import CreateQuotationGif2 from '../../assets/video/createQuotationStep2.gif';
import CreateQuotationGif4 from '../../assets/video/quotationCreateGif4.gif';
import CreateFeedGif1 from '../../assets/video/createFeedStep2.gif';
import CreateFeedGif2 from '../../assets/video/createFeed2.gif';
import Lottie from "react-lottie";
import checkMarkBlue from "../../assets/lotties/checkMarkBlue.json";
import TutorialSteps from "./TutorialSteps";
import { motion } from "framer-motion";

const TutorialItem = ({ selectedItem, setSelectedItem }) => {
    const [stepCount, setStepCount] = useState(1);

    const handleSteps = (step) => {
        setStepCount(step);
    }


    return (
        <>
            {selectedItem === 1 && (
                <>
                    {stepCount === 1 && (
                        <>
                            <motion.div
                                className={styles.tutorialModal_contentList_seeMore_item}
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                            >
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublicationGif} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p}>1. Para comenzar dirigite al menu de la izquerda y hace clic en <b>Crear publicación</b></p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>{selectedItem === 1 ? "Volver al menú" : "Atras"}</button>
                                <TutorialSteps stepCount={stepCount} progress={"20%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Siguiente</button>
                            </div>
                        </>
                    )}
                    {stepCount === 2 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublicationGif2} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img2} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>2. Seleccioná la opción de <b>Obra Privada</b> del pop-up.</p>
                                </div>

                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(1)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"40%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(3)}>Siguiente</button>
                            </div>
                        </>)}
                    {stepCount === 3 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex3}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublicationGif3} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img3} />

                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>3. Completá los campos <b>requeridos:</b> Nombre, ubicación, fecha de finaliziación del periodo de recepción de ofertas y una breve descripción. También podés
                                        agregar de forma opcional hasta 5 imágenes.</p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"60%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(4)}>Siguiente</button>
                            </div>
                        </>)}
                    {stepCount === 4 && (
                        <>
                            <motion.div className={styles.tutorialModal_contentList_seeMore_itemNotFlex4}
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }} >
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublcationGif4} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img4} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>4. Seleccioná al menos 1 <b>rubro para cotizar</b>. Completá con todos los datos que desees si bien ninguno es requerido,
                                        cuantos más datos completes, más preciso será el presupuesto que recibas.</p><br />
                                    <p>Clic en <b>Crear rubro</b>. Si querés agregar más rubros al proyecto clic en <b>Si</b> sino indica <b>No, deseo finalizar y publicar el proyecto</b></p>

                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(3)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"80%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>Volver al menu</button>
                            </div>
                        </>)}
                    {selectedItem === 1 && (
                    <ul className={styles.tutorialModal_List}>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={styles.tutorialModal_List_item_checkMarkContainer}>
                                {stepCount !== 1 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>1</p>
                                    </motion.div>
                                }


                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 1 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Comenza la publicación</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 2 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 2 && stepCount > 2 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>2</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 2 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Selecciona obra privada</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 3 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 3 && stepCount > 3 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>3</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 3 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Completa los campos requeridos</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 4 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 4 && stepCount > 4 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>4</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 4 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Selecciona al menos 1 rubro para cotizar y ¡Listo!</p>
                            </div>
                        </li>
                    </ul>
                    )}
                </>
            )}
            {selectedItem === 2 && (
                <>
                    {stepCount === 1 && (
                        <>
                            <motion.div
                                className={styles.tutorialModal_contentList_seeMore_item}
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                            >
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublicationGif} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p}>1. Para comenzar dirigite al menu de la izquerda y hace clic en <b>Crear publicación</b></p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>{selectedItem === 1 ? "Volver al menú" : "Atras"}</button>
                                <TutorialSteps stepCount={stepCount} progress={"20%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Siguiente</button>
                            </div>
                        </>
                    )}
                    {stepCount === 2 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreateQuotationGif2} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img2} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>2. Seleccioná la opción de <b>Solicitud de cotización de materiales</b> del pop-up.</p>
                                </div>

                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(1)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"40%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(3)}>Siguiente</button>
                            </div>
                        </>)}
                    {stepCount === 3 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex3}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreateQuotationGif3} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img3} />

                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>3. Completá los campos <b>requeridos:</b> nombre, provincia, fecha de inicio y finaliziación del periodo de recepción de ofertas y una breve descripción. Además
                                        podés sumarle la ciudad o barrio específico y la moneda en la cual quieras recibir la cotización</p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"60%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(4)}>Siguiente</button>
                            </div>
                        </>)}
                    {stepCount === 4 && (
                        <>
                            <motion.div className={styles.tutorialModal_contentList_seeMore_itemNotFlex4}
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }} >
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreateQuotationGif4} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img4} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>4. Seleccioná al menos 1 <b>rubro para cotizar</b> y adjuntá una planilla de excel con el listado de materiales
                                        que necesites.</p><br />
                                    <p>Clic en <b>Crear rubro</b>. Si querés agregar más rubros al proyecto clic en <b>volver</b> sino indica <b>Publicar</b></p>

                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(3)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"80%"} />
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>Volver al menu</button>
                            </div>
                        </>)}
                        {selectedItem === 2 && (
                    <ul className={styles.tutorialModal_List}>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={styles.tutorialModal_List_item_checkMarkContainer}>
                                {stepCount !== 1 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>1</p>
                                    </motion.div>
                                }


                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 1 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Comenza la publicación</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 2 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 2 && stepCount > 2 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>2</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 2 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Selecciona solicitud de cotización de materiales</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 3 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 3 && stepCount > 3 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>3</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 3 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Completa los campos requeridos</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 4 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 4 && stepCount > 4 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>4</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 4 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Selecciona al menos 1 rubro para cotizar y ¡Listo!</p>
                            </div>
                        </li>
                    </ul>
                    )}
                </>
            )}
            {selectedItem === 3 && (
                <>
                    {stepCount === 1 && (
                        <>
                            <motion.div
                                className={styles.tutorialModal_contentList_seeMore_item}
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                            >
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreatePublicationGif} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p}>1. Para comenzar dirigite al menu de la izquerda y hace clic en <b>Crear publicación</b></p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>{selectedItem === 1 ? "Volver al menú" : "Atras"}</button>
                                <TutorialSteps stepCount={stepCount} progress={"20%"} totalSteps={3}/>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Siguiente</button>
                            </div>
                        </>
                    )}
                    {stepCount === 2 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreateFeedGif1} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img2} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>2. Seleccioná la opción de <b>artículo en el muro</b> del pop-up.</p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(1)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"40%"} totalSteps={3}/>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(3)}>Siguiente</button>
                            </div>
                        </>)}
                    {stepCount === 3 && (
                        <>
                            <motion.div
                                initial={{ x: "35%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeInOut", duration: 0.5 }}
                                className={styles.tutorialModal_contentList_seeMore_itemNotFlex3}>
                                <div className={styles.tutorialModal_contentList_seeMore_item_itemBackGround}>
                                    <img src={CreateFeedGif2} alt="Crear publicación" className={styles.tutorialModal_contentList_seeMore_item_img3} />
                                </div>
                                <div className={styles.tutorialModal_contentList_seeMore_item_text}>
                                    <p className={styles.tutorialModal_contentList_seeMore_item_text_p3}>3. Podés escribir un artículo solo de <b>texto</b> o podés agregar una <b>imagen</b> o <b>video</b> para acompañar tu publicación.</p>
                                    <p>Cuando tengas el contenido listo hace clic en <b>Publicar.</b></p>
                                </div>
                            </motion.div>
                            <div className={styles.tutorialModal_contentList_seeMore_item_stepsContainer}>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => handleSteps(2)}>Atras</button>
                                <TutorialSteps stepCount={stepCount} progress={"60%"} totalSteps={3}/>
                                <button className={styles.tutorialModal_contentList_seeMore_item_stepsContainer_button} onClick={() => setSelectedItem(null)}>Volver al menú</button>
                            </div>
                        </>)}
                </>)}
                {selectedItem === 3 && (
                <ul className={styles.tutorialModal_List}>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={styles.tutorialModal_List_item_checkMarkContainer}>
                                {stepCount !== 1 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>1</p>
                                    </motion.div>
                                }


                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 1 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Comenza la publicación</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 2 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 2 && stepCount > 2 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>2</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 2 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Selecciona solicitud de artículo en el muro</p>
                            </div>
                        </li>
                        <li className={styles.tutorialModal_List_item}>
                            <div className={`${styles.tutorialModal_List_item_checkMarkContainer} ${stepCount < 3 ? styles.emptyCheckMark : ""}`}>
                                {stepCount !== 3 && stepCount > 3 ?
                                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: checkMarkBlue,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        height={80}
                                        width={80}
                                    />
                                    :
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <p>3</p>
                                    </motion.div>
                                }
                            </div>
                            <div className={`${styles.tutorialModal_List_item_txtContainer} ${stepCount !== 3 ? styles.notDone : ""}`}>
                                <p className={styles.tutorialModal_List_item_txt}>Completá con un texto, foto o video y ¡Listo!</p>
                            </div>
                        </li>
                    </ul>
                )}
        </>
    )
}

export default TutorialItem;



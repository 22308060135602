import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { closeAll } from "../../../../store/actions/index";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(39, 39, 39, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1004;
  width: 100%;
  height: 100vh;
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  transition: all 0.1s;
`;

const Backdrop = (props) => {
  return (
    <Wrapper
      onClick={() => {
        !props.newModalOpen && props.closeAll();
        if (props.closeModal) props.closeModal();
      }}
      opened={props.opened}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    newModalOpen: state.general.newModalOpen,
  };
};

export default connect(mapStateToProps, { closeAll })(Backdrop);

import React, { useState, useEffect, useRef } from "react";
import styles from "./ProfileEditModal.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import ImageCropper from "./imageCropper";
import { ReactComponent as PencilEditIcon } from "../../../assets/svg/pencilEditIcon.svg";
import { ReactComponent as CameraIcon } from "../../../assets/svg/cameraIconSvg.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/xClose.svg";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import errorData from "../../../assets/lotties/errorLogo.json";
import { useFormik } from "formik";
import SkillsEdit from "./SkillsEdit/SkillsEdit";
import {
    sendSkillsProfile,
    testDescriptionEdit,
    testPersonaEdit,
    testEmpresaEdit
} from "../../../store/actions";
import { isEqual } from "lodash";
import * as Yup from "yup";


const buttonVariants = {
    rest: {
        scale: 1,
        backgroundColor: '#234ba2',
        color: '#fff',
        border: 'none',
    },
    hover: {
        scale: 1.05,
        transition: { duration: 0.2 },
    },
    press: {
        scale: 0.9,
        transition: { duration: 0.3 },
    },
    processing: {
        scale: 1,
        backgroundColor: '#2ecc71',
        color: '#fff',
    },
    sent: {
        scale: 1,
        backgroundColor: '#2ecc71',
        color: '#fff',
    },
};


const backdrop = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    },
};
const modal = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { delay: 0.2 },
    },
};

const modalFormDatos = {
    hidden: {
        opacity: 0,
        y: 20, 
    },
    visible: {
        opacity: 1,
        y: 0, 
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 25,
            bounce: 0.2,
        },
    },
    exit: {
        opacity: 0,
        y: 20, 
    },
};

const modalFormRedes = {
    hidden: {
        opacity: 0,
        y: 20, 
    },
    visible: {
        opacity: 1,
        y: 0, 
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 25,
            bounce: 0.2,
        },
    },
    exit: {
        opacity: 0,
        y: 20, 
    },
};

const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};



const ProfileEditModal = ({
    setIsEditing,
    userData,
    sendSkillsProfile,
    testDescriptionEdit,
    userSkills,
    testPersonaEdit,
    testEmpresaEdit,
    ...props }) => {
    const [isOn, setIsOn] = useState(false);
    const [isOnRedes, setIsOnRedes] = useState(false);
    const [isOnDescription, setIsOnDescription] = useState(false);
    const [editDescriptionStyles, setEditDescriptionStyles] = useState(false);
    const [isOnSkills, setIsOnSkills] = useState(false);
    const [editSkills, setEditSkills] = useState(false);
    const [buttonState, setButtonState] = useState('rest');
    const [hasChanges, setHasChanges] = useState(false);
    const [editImg, setEditImg] = useState(false);
    const [cropImage, setCropImage] = useState(false)
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [croppedImgUrl, setCroppedImageUrl] = useState(null);
    const [editModes, setEditModes] = useState({
        userName: false,
        type: false,
        rubro: false,
        profession: false,
        address: false,
        phone: false,
        webSite: false,
        instagram: false,
        facebook: false,
        linkedin: false,
        twitter: false,
        skills: false,
        description: false,
    });

    const toggleSwitch = (value) => {
        if (value === "datos") {
            setIsOn(!isOn);
        } else if (value === "redes") {
            setIsOnRedes(!isOnRedes);
        } else if (value === "description") {
            setIsOnDescription(!isOnDescription);
        } else if (value === "skills") {
            setIsOnSkills(!isOnSkills);
        }
    };

    const addHttpToUrl = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `http://${url}`;
        }
        return url;
    };

    const formik = useFormik({
        initialValues: {
            userName: userData?.name === undefined || userData.name === null ? "" : userData.name,
            lastName: userData?.last_name === undefined || userData.last_name === null ? "" : userData.last_name,
            razonSocial: userData?.razon_social === undefined || userData.razon_social === null ? "" : userData.razon_social,
            type: userData?.type,
            rubro: userData.rubro === undefined || userData.rubro === null ? "" : userData.rubro,
            profession: userData.profession === undefined || userData.profession === null ? "" : userData.profession,
            phone: userData.phone === undefined || userData.phone === null ? "" : userData.phone,
            address: userData.address === undefined || userData.address === null ? "" : userData.address,
            webSite: userData.url_site_web === undefined || userData.url_site_web === null ? "" : userData.url_site_web,
            instagram: userData.instagram === undefined || userData.instagram === null ? "" : userData.instagram,
            facebook: userData.facebook === undefined || userData.facebook === null ? "" : userData.facebook,
            twitter: userData.twitter === undefined || userData.twitter === null ? "" : userData.twitter,
            linkedin: userData.linkedin === undefined || userData.linkedin === null ? "" : userData.linkedin,
            description: userData.description === undefined || userData.description === null || userData.description === "" ? "" : userData.description,
            image: userData.image_profile === undefined || userData.image_profile === null ? "" : userData.image_profile,
            userSkills: userSkills
        },
        validationSchema: Yup.object({
            razonSocial: Yup.string()
                .when('type', {
                    is: 'COMPANY',
                    then: Yup.string()
                        .required("Campo requerido")
                        .notOneOf(["Agregar"], "Coloque un nombre válido")
                        .max(50, "Máximo 50 caracteres"),
                }),
            webSite: Yup.string().url("Ingrese una cuenta válida (ej: www.mi_sitio_web.com)")
                .transform(addHttpToUrl),
            twitter: Yup.string().url("Ingrese una cuenta válida (ej: www.twitter.com/mi_cuenta)")
                .transform(addHttpToUrl),
            facebook: Yup.string().url("Ingrese una cuenta válida (ej: www.facebook.com/mi_cuenta)")
                .transform(addHttpToUrl),
            instagram: Yup.string().url("Ingrese una cuenta válida (ej: www.instagram.com/mi_cuenta)")
                .transform(addHttpToUrl),
            linkedin: Yup.string().url("Ingrese una cuenta válida (ej: www.linkedin.com/mi_cuenta)")
                .transform(addHttpToUrl),

        }),
        validateOnBlur: true,
    });

    const handleChange = (e) => {
        setHasChanges(true);
        formik.handleChange(e);
    }

    useEffect(() => {
        const formikUserSkillsString = JSON.stringify(formik.values.userSkills);
        const userSkillsString = JSON.stringify(userSkills);

        if (formikUserSkillsString !== userSkillsString ||
            file !== null) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [formik.values.userSkills, userSkills, file]);



    //Maneja cerrar el modal
    const handleGoBack = () => {
        setIsEditing(false);
    }

    const onCancel = () => {
        setCropImage(false);
    }

    const handleEditIconClick = (fieldName) => {
        const inputElement = document.getElementById(fieldName);

        if (inputElement) {
            inputElement.focus();

            if (fieldName === "description") {
                setEditDescriptionStyles(!editDescriptionStyles);
                setEditModes((prevEditModes) => ({
                    ...prevEditModes,
                    [fieldName]: !prevEditModes[fieldName],
                }));
            } else {
                setEditModes((prevEditModes) => ({
                    ...prevEditModes,
                    [fieldName]: !prevEditModes[fieldName],
                }));
            }
        }
    };


    const handleClick = (e) => {
        setButtonState('processing');

        const adjustSocialMediaUrls = (values) => {
            const socialMediaFields = ['instagram', 'facebook', 'linkedin', 'twitter', 'webSite'];
            socialMediaFields.forEach(field => {
                if (values[field] && !/^https?:\/\//i.test(values[field])) {
                    values[field] = `https://${values[field]}`;
                }
            });
        };
        

        // Ajustar las URLs de las redes sociales
        adjustSocialMediaUrls(formik.values);
        if (formik.values.description !== userData.description) {
            testDescriptionEdit(formik.values.description);
        }
        if (formik.values.userSkills !== userSkills) {
            sendSkillsProfile(userSkills);
        }

        const newProfileValues = {
            name: formik.values.userName,
            last_name: formik.values.lastName,
            razon_social: formik.values.razonSocial,
            type: formik.values.type,
            rubro: formik.values.rubro,
            profession: formik.values.profession,
            address: formik.values.address,
            phone: formik.values.phone,
            url_site_web: formik.values.webSite,
            instagram: formik.values.instagram,
            facebook: formik.values.facebook,
            linkedin: formik.values.linkedin,
            twitter: formik.values.twitter,
            image: formik.values.image,
        }

        if (formik.values.type === "PERSON" && !isEqual(newProfileValues, formik.initialValues)) {
            testPersonaEdit(newProfileValues, newProfileValues.image);
        } else if (formik.values.type === "COMPANY" && !isEqual(newProfileValues, formik.initialValues)) {
            testEmpresaEdit(newProfileValues, newProfileValues.image);
        }


        setTimeout(() => {
            setButtonState('sent');
            handleGoBack();
        }, 2000);
    };

    const setCroppedImageFor = (zoom, aspect, crop, croppedImageUrl, id) => {
        setCroppedImageUrl(croppedImageUrl);
        setCropImage(!cropImage);
    }

    const inputFileRef = useRef(null);

    const handleEditImgClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }

        setEditImg(!editImg);
    };

    const handleInputClick = (fieldName) => {
        formik.setFieldValue(fieldName, "Agregar");

    };


    return (
        <AnimatePresence>
            <motion.div
                key="1"
                variants={backdrop}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={styles.profileModal_overlay}

            ></motion.div>
            <motion.div
                key="2"
                variants={modal}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className={styles.profileModal}
            >
                <div className={styles.profileModal_container}>
                    <div className={styles.profileModal_header}>
                        <div
                            className={styles.profileModal_header_decline}
                            onClick={() => handleGoBack()}
                        >
                            <CloseIcon width={15} height={15} />
                        </div>
                        <h3 className={styles.profileModal_header_title}>Editar perfil</h3>
                    </div>
                    <div className={styles.profileModal_body}>
                        <div className={styles.profileModal_body_imgContainer}>
                            <div className={styles.profileModal_body_imgContainer_image}>
                                <img className={styles.profileModal_body_imgContainer_image_img} src={imagePreview ? imagePreview : userData?.image_profile} alt="profile" />
                                <div className={styles.profileModal_body_imgContainer_edit} onClick={handleEditImgClick}>
                                    <CameraIcon width={20} height={20} />
                                </div>
                            </div>
                            <label htmlFor="image" className={styles.labelHidden}>
                                <input
                                    ref={inputFileRef}
                                    type="file"
                                    name="image"
                                    id="image"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setImagePreview(URL.createObjectURL(file));
                                            setFile(file);
                                            formik.setFieldValue("image", file);
                                        }
                                    }}
                                    className={styles.userInfo_label}
                                    accept="image/*"
                                />
                            </label>
                        </div>
                        <form className={styles.profileModal_body_form} onSubmit={formik.handleSubmit} >
                            <div className={styles.profileModal_body_datosPersonalesContainer}>
                                <div className={styles.profileModal_body_datosPersonalesContainer_title}>
                                    <p className={styles.profileModal_body_datosPersonalesContainer_title_txt}>Datos personales</p>
                                    <div className={styles.profileModal_body_switch} data-ison={isOn} onClick={() => toggleSwitch("datos")}>
                                        <motion.div className={styles.profileModal_body_handle} layout />
                                    </div>
                                </div>
                                {isOn &&
                                    <motion.div
                                        className={styles.profileModal_form}
                                        variants={modalFormDatos}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                    >
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="userName" className={styles.profileModal_form_inputContainer_title}>Nombre</label>
                                                <input
                                                    id="userName"
                                                    name="userName"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.userName === "" ? "" : formik.values.userName}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.userName && styles.editMode}`}
                                                    readOnly={!editModes.userName}
                                                />
                                            </div>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="lastName" className={styles.profileModal_form_inputContainer_title}>Apellido</label>
                                                <input
                                                    id="lastName"
                                                    name="lastName"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.lastName === "" ? "" : formik.values.lastName}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.userName && styles.editMode}`}
                                                    readOnly={!editModes.userName}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.userName && styles.editMode_svg}`} onClick={() => handleEditIconClick("userName")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="type" className={styles.profileModal_form_inputContainer_title}>Tipo de cuenta</label>
                                                <select
                                                    id="type"
                                                    name="type"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={formik.values.type}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.type && styles.editMode}`}
                                                    disabled={!editModes.type}
                                                >
                                                    <option value="PERSON">Personal</option>
                                                    <option value="COMPANY">Empresa</option>
                                                </select>
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.type && styles.editMode_svg}`} onClick={() => handleEditIconClick("type")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        {formik.values.type === "COMPANY" &&
                                            <div className={styles.profileModal_form_container}>
                                                <div className={styles.profileModal_form_inputContainer}>
                                                    <label htmlFor="razonSocial" className={styles.profileModal_form_inputContainer_title}>Razón social</label>
                                                    <div className={`${formik.errors.razonSocial ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                        {formik.errors.razonSocial ? (
                                                            <Lottie
                                                                options={{
                                                                    animationData: errorData,
                                                                    ...defaultOptions,
                                                                }}
                                                                height={35}
                                                                width={35}
                                                            />) : null}                                                            
                                                    <input
                                                        id="razonSocial"
                                                        name="razonSocial"
                                                        type="text"
                                                        onChange={handleChange}
                                                        placeholder="Agregar"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.razonSocial === "" ? "" : formik.values.razonSocial}
                                                        onClick={() => handleInputClick("razonSocial")}
                                                        className={`${styles.profileModal_form_inputContainer_input} ${editModes.razonSocial && styles.editMode} ${formik.errors.razonSocial && styles.error}`}
                                                        readOnly={!editModes.razonSocial}
                                                    />
                                                    </div>
                                                    {formik.errors.razonSocial ? (
                                                        <div className={styles.profileModal_form_inputContainer_error}>{formik.errors.razonSocial}</div>
                                                    ) : null}
                                                </div>
                                                <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.razonSocial && styles.editMode_svg}`} onClick={() => handleEditIconClick("razonSocial")}>
                                                    <PencilEditIcon width={20} height={20} />
                                                </div>
                                            </div>}
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="rubro" className={styles.profileModal_form_inputContainer_title}>Rubro</label>
                                                <input
                                                    id="rubro"
                                                    name="rubro"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.rubro === "" ? "" : formik.values.rubro}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.rubro && styles.editMode}`}
                                                    readOnly={!editModes.rubro}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.rubro && styles.editMode_svg}`} onClick={() => handleEditIconClick("rubro")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="profession" className={styles.profileModal_form_inputContainer_title}>Profesión</label>
                                                <input
                                                    id="profession"
                                                    name="profession"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.profession === "" ? "" : formik.values.profession}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.profession && styles.editMode}`}
                                                    readOnly={!editModes.profession}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.profession && styles.editMode_svg}`} onClick={() => handleEditIconClick("profession")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="address" className={styles.profileModal_form_inputContainer_title}>Dirección</label>
                                                <input
                                                    id="address"
                                                    name="address"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.address === "" ? "" : formik.values.address}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.address && styles.editMode}`}
                                                    readOnly={!editModes.address}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.address && styles.editMode_svg}`} onClick={() => handleEditIconClick("address")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="phone" className={styles.profileModal_form_inputContainer_title}>Teléfono</label>
                                                <input
                                                    id="phone"
                                                    name="phone"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.phone === "" ? "" : formik.values.phone}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.phone && styles.editMode}`}
                                                    readOnly={!editModes.phone}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.phone && styles.editMode_svg}`} onClick={() => handleEditIconClick("phone")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="webSite" className={styles.profileModal_form_inputContainer_title}>Sitio Web</label>
                                                <div className={`${formik.errors.webSite ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                    {formik.errors.webSite ? (
                                                        <Lottie
                                                            options={{
                                                                animationData: errorData,
                                                                ...defaultOptions,
                                                            }}
                                                            height={35}
                                                            width={35}
                                                        />) : null}
                                                <input
                                                    id="webSite"
                                                    name="webSite"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.webSite === "" ? "" : formik.values.webSite}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.webSite && styles.editMode} ${formik.errors.webSite && styles.error}`}
                                                    readOnly={!editModes.webSite}
                                                />
                                                </div>
                                                {formik.errors.webSite ? (
                                                    <div className={styles.profileModal_form_inputContainer_error}>{formik.errors.webSite}</div>
                                                ) : null}
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.webSite && styles.editMode_svg}`} onClick={() => handleEditIconClick("webSite")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                    </motion.div>}
                            </div>
                            <div className={styles.profileModal_body_datosPersonalesContainer}>
                                <div className={styles.profileModal_body_datosPersonalesContainer_title}>
                                    <p className={styles.profileModal_body_datosPersonalesContainer_title_txt}>Redes</p>
                                    <div className={styles.profileModal_body_switch} data-ison={isOnRedes} onClick={() => toggleSwitch("redes")}>
                                        <motion.div className={styles.profileModal_body_handle} layout />
                                    </div>
                                </div>
                                {isOnRedes &&
                                    <motion.div
                                        className={styles.profileModal_form}
                                        variants={modalFormRedes}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                    >
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="instagram" className={styles.profileModal_form_inputContainer_title}>Instagram</label>
                                                <div className={`${formik.errors.instagram ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                    {formik.errors.instagram ? (
                                                        <Lottie
                                                            options={{
                                                                animationData: errorData,
                                                                ...defaultOptions,
                                                            }}
                                                            height={35}
                                                            width={35}
                                                        />) : null}
                                                <input
                                                    id="instagram"
                                                    name="instagram"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.instagram === "" ? "" : formik.values.instagram}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.instagram && styles.editMode} ${formik.errors.instagram && styles.error}`}
                                                    readOnly={!editModes.instagram}
                                                />
                                                </div>
                                                {formik.errors.instagram ? (
                                                    <div className={styles.profileModal_form_inputContainer_error}>{formik.errors.instagram}</div>
                                                ) : null}
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.instagram && styles.editMode_svg}`} onClick={() => handleEditIconClick("instagram")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="facebook" className={styles.profileModal_form_inputContainer_title}>Facebook</label>
                                                <div className={`${formik.errors.facebook ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                    {formik.errors.facebook ? (
                                                        <Lottie
                                                            options={{
                                                                animationData: errorData,
                                                                ...defaultOptions,
                                                            }}
                                                            height={35}
                                                            width={35}
                                                        />) : null}
                                                    <input
                                                        id="facebook"
                                                        name="facebook"
                                                        type="text"
                                                        placeholder="Agregar"
                                                        onChange={handleChange}
                                                        value={formik.values.facebook === "" ? "" : formik.values.facebook}
                                                        className={`${styles.profileModal_form_inputContainer_input} ${editModes.facebook && styles.editMode} ${formik.errors.facebook && styles.error}`}
                                                        readOnly={!editModes.facebook}
                                                    />
                                                </div>
                                                {formik.errors.facebook ? (
                                                    <div className={styles.profileModal_form_inputContainer_error}>{formik.errors.facebook}</div>
                                                ) : null}
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.facebook && styles.editMode_svg}`} onClick={() => handleEditIconClick("facebook")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="linkedin" className={styles.profileModal_form_inputContainer_title}>LinkedIn</label>
                                                <div className={`${formik.errors.linkedin ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                    {formik.errors.linkedin ? (
                                                        <Lottie
                                                            options={{
                                                                animationData: errorData,
                                                                ...defaultOptions,
                                                            }}
                                                            height={35}
                                                            width={35}
                                                        />) : null}
                                                    <input
                                                        id="linkedin"
                                                        name="linkedin"
                                                        type="text"
                                                        placeholder="Agregar"
                                                        onChange={handleChange}
                                                        value={formik.values.linkedin === "" ? "" : formik.values.linkedin}
                                                        className={`${styles.profileModal_form_inputContainer_input} ${editModes.linkedin && styles.editMode} ${formik.errors.linkedin && styles.error}`}
                                                        readOnly={!editModes.linkedin}
                                                    />
                                                </div>
                                                {formik.errors.linkedin ? (
                                                    <div className={styles.profileModal_form_inputContainer_error}>
                                                        {formik.errors.linkedin}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.linkedin && styles.editMode_svg}`} onClick={() => handleEditIconClick("linkedin")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="twitter" className={styles.profileModal_form_inputContainer_title}>Twitter</label>
                                                <div className={`${formik.errors.twitter ? styles.profileModal_form_inputContainer_inputError : ""}`}>
                                                    {formik.errors.twitter ? (
                                                        <Lottie
                                                            options={{
                                                                animationData: errorData,
                                                                ...defaultOptions,
                                                            }}
                                                            height={35}
                                                            width={35}
                                                        />) : null}
                                                <input
                                                    id="twitter"
                                                    name="twitter"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.twitter === "" ? "" : formik.values.twitter}
                                                    className={`${styles.profileModal_form_inputContainer_input} ${editModes.twitter && styles.editMode} ${formik.errors.twitter && styles.error}`}
                                                    readOnly={!editModes.twitter}
                                                />
                                                </div>
                                                {formik.errors.twitter ? (
                                                    <div className={styles.profileModal_form_inputContainer_error}>{formik.errors.twitter}</div>
                                                ) : null}
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.twitter && styles.editMode_svg}`} onClick={() => handleEditIconClick("twitter")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                    </motion.div>}
                            </div>
                            <div className={styles.profileModal_body_datosPersonalesContainer}>
                                <div className={styles.profileModal_body_datosPersonalesContainer_title}>
                                    <p className={styles.profileModal_body_datosPersonalesContainer_title_txt}>Descripción</p>
                                    <div className={styles.profileModal_body_switch} data-ison={isOnDescription} onClick={() => toggleSwitch("description")}>
                                        <motion.div className={styles.profileModal_body_handle} layout />
                                    </div>
                                </div>
                                {isOnDescription &&
                                    <motion.div
                                        className={styles.profileModal_form}
                                        variants={modalFormRedes}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                    >
                                        <div className={styles.profileModal_form_container}>
                                            <div className={styles.profileModal_form_inputContainer}>
                                                <label htmlFor="description" className={styles.profileModal_form_inputContainer_title}>Descripción</label>
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    type="text"
                                                    placeholder="Agregar"
                                                    onChange={handleChange}
                                                    value={formik.values.description === "" ? "" : formik.values.description}
                                                    className={`${styles.profileModal_form_inputContainer_textArea} ${editDescriptionStyles && styles.profileModal_form_inputContainer_textArea_edit}`}
                                                />
                                            </div>
                                            <div className={`${styles.profileModal_form_inputContainer_logo} ${editModes.description && styles.editMode_svg}`} onClick={() => handleEditIconClick("description")}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                    </motion.div>}
                            </div>
                            <div className={styles.profileModal_body_datosPersonalesContainer}>
                                <div className={styles.profileModal_body_datosPersonalesContainer_title}>
                                    <p className={styles.profileModal_body_datosPersonalesContainer_title_txt}>Mis rubros</p>
                                    <div className={styles.profileModal_body_switch} data-ison={isOnSkills} onClick={() => toggleSwitch("skills")}>
                                        <motion.div className={styles.profileModal_body_handle} layout />
                                    </div>
                                </div>
                                {isOnSkills &&
                                    <motion.div
                                        className={styles.profileModal_form}
                                        variants={modalFormRedes}
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                    >
                                        <div className={styles.profileModal_form_container}>
                                            <SkillsEdit editSkills={editSkills} setEditSkills={setEditSkills} setFieldValue={formik.setFieldValue} />

                                            <div title="Editar" className={`${styles.profileModal_form_inputContainer_logo} ${editModes.skills && styles.editMode_svg}`} onClick={() => {
                                                setEditSkills(!editSkills);
                                                setEditModes((prevEditModes) => ({
                                                    ...prevEditModes,
                                                    skills: !prevEditModes.skills,
                                                }));
                                            }}>
                                                <PencilEditIcon width={20} height={20} />
                                            </div>
                                        </div>
                                    </motion.div>}
                            </div>
                        </form>
                        <div className={styles.buttonContainer}>
                            {hasChanges && Object.values(formik.errors).every(error => !error) && (
                                <motion.button
                                    variants={buttonVariants}
                                    whileHover="hover"
                                    whileTap="press"
                                    initial="rest"
                                    animate={buttonState}
                                    onClick={handleClick}
                                    className={styles.buttonContainer_btn}

                                >
                                    {buttonState === 'rest' && 'Guardar cambios'}
                                    {buttonState === 'processing' && 'Procesando...'}
                                    {buttonState === 'sent' && 'Guardado'}
                                </motion.button>)}
                            <button className={styles.buttonContainer_btn} onClick={() => handleGoBack()}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

const mapStateToProps = (state) => {
    return {
        userSkills: state.register.userSkills,
    };
}

export default connect(mapStateToProps, {
    sendSkillsProfile,
    testDescriptionEdit,
    testPersonaEdit,
    testEmpresaEdit
})(ProfileEditModal);
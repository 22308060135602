import React, { useState } from 'react';
import styles from './WorkersMaps.module.scss';
import MapsLoading from '../../Loading/MapLoading';
import { connect } from 'react-redux';
import { openSuscriptionModal } from '../../../../store/actions';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import WorkersData from './WorkersData/WorkersData';
import WorkerContact from './WorkerContact/WorkerContact';
import SmallWorkerMapItem from '../SmallWorkerMap/SmallWorkerMapItem';


const WorkersMaps = ({
    ciudadesArray,
    setIsFiltered,
    isFiltered,
    setCoordinatesArray,
    setZoom,
    setCenter,
    loading,
    initialCenter,
    initialZoom,
    coordinatesArray,
    setShowSmallMap,
    showSmallMap,
    zoom,
    center,
    bounds,
    setBounds,
}) => {

    const [uniqueWorker, setUniqueWorker] = useState(null);
    const [multipleWorkersSameCity, setMultipleWorkersSameCity] = useState(null);
    const [alternativeCoordinates, setAlternativeCoordinates] = useState(null);
    const [polygonBounds, setPolygonBounds] = useState(null);
    const [showWorkerContact, setShowWorkerContact] = useState(false);
    const [workerToPass, setWorkerToPass] = useState(null);
    const [isMarker, setIsMarker] = useState(false);

    const handleCloseSmallMap = () => {
        setShowSmallMap(false);
        setUniqueWorker(null);
        setAlternativeCoordinates(null);
        setMultipleWorkersSameCity(null);
        setCenter(initialCenter);
        setZoom(initialZoom);
        setPolygonBounds(null);
    }

    const handleWorkerContact = (worker) => {
        setShowWorkerContact(true);
        setWorkerToPass(worker);
    }

    return (
        <div className={styles.workerMapContainer}>
            <div className={styles.workerMapContainer_map}>
                {loading && <MapsLoading />}
                <MapContainer
                    center={center}
                    zoom={zoom}
                    style={{ height: '100%', width: '100%' }}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >
                    <TileLayer
                        url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    />
                    <WorkersData
                        ciudadesArray={ciudadesArray}
                        setCoordinatesArray={setCoordinatesArray}
                        setBounds={setBounds}
                        setZoom={setZoom}
                        setCenter={setCenter}
                        coordinatesArray={coordinatesArray}
                        bounds={bounds}
                        center={center}
                        zoom={zoom}
                        setShowSmallMap={setShowSmallMap}
                        setUniqueWorker={setUniqueWorker}
                        uniqueWorker={uniqueWorker}
                        setMultipleWorkersSameCity={setMultipleWorkersSameCity}
                        multipleWorkersSameCity={multipleWorkersSameCity}
                        setAlternativeCoordinates={setAlternativeCoordinates}
                        setPolygonBounds={setPolygonBounds}
                        polygonBounds={polygonBounds}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        setIsMarker={setIsMarker}
                    />
                    <ZoomControl position="topleft" />
                </MapContainer>
            </div>
            {showSmallMap && !isFiltered && (
                <SmallWorkerMapItem
                    worker={isMarker ? uniqueWorker.cluster.properties : multipleWorkersSameCity}
                    onClose={handleCloseSmallMap}
                    onContact={handleWorkerContact}
                    isMarker={isMarker}
                />
                

            )}
            {showWorkerContact && <WorkerContact setShowWorkerContact={setShowWorkerContact} worker={workerToPass} setWorkerToPass={setWorkerToPass} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isUserPremium: state.general.isUserPremium,
    };
};

export default connect(mapStateToProps, { openSuscriptionModal })(WorkersMaps);





import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../components/Layout/Layout";

const PrivateRoute = ({
  element: Component,
  auth,
  hasLayout,
  isDashboard,
  isPremiumContent,
  isUserPremium,
  ...rest
}) => {
  const location = useLocation();
  let authValueOne = localStorage.getItem("access-RefreshToken-Edify");
  let authValueTwo = localStorage.getItem("access-Token-Edify");

  const premiumRedirectToDashboard = (component) => {
    if (isPremiumContent) {
      if (isUserPremium) {
        return component;
      } else {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
      }
    } else {
      return component;
    }
  };

  if (!authValueOne || !authValueTwo) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return hasLayout ? (
    <Layout isDashboard={isDashboard}>
      {premiumRedirectToDashboard(<Component {...rest} />)}
    </Layout>
  ) : (
    premiumRedirectToDashboard(<Component {...rest} />)
  );
};

const mapStateToProps = (state) => ({
  auth: state.general.isAuth,
  data: state.general.userData,
  isUserPremium: state.general.isUserPremium,
});

export default connect(mapStateToProps)(PrivateRoute);



import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { setSendSubQuotation, addSubQuotationToQuotation } from "../../../../store/actions/index";
import RubroSubQuotationSelect from "../../../MarketPlace/subCotizacion/nuevaSubCotizacion/RubroSubQuotationSelect";
import styles from "./NuevaSubCotizacion.module.scss";
import { ReactComponent as AddDescription } from "../../../../assets/svg/addDescriptionPlaceholder.svg";
import { ReactComponent as Cotizacion } from "../../../../assets/svg/cotizacionPlaceholder.svg";
import { ReactComponent as Plus } from "../../../../assets/svg/plus.svg";
import { ReactComponent as Trash } from "../../../../assets/svg/description-sub.svg";

const NuevaSubCotizacion = ({addSubQuotationToQuotation, setSendSubQuotation,  ...props}) => {
  const [fileName, setFileName] = useState('');
  const [clear, setClear] = useState(false);

  const resetClear = () => {
    setClear(false);
  };

  const formik = useFormik({
    initialValues: {
      nameSub: '',
      descriptionSub: '',
      endDateSub: '',
      file: null,
    },
    validationSchema: Yup.object({
      nameSub: Yup.string(),
      descriptionSub: Yup.string(),
      endDateSub: Yup.date(),
      file: Yup.mixed().required('Debes adjuntar un archivo.').nullable(),
    }),
    onSubmit: (values, { resetForm }) => {
      if (props.isEditing) {
        addSubQuotationToQuotation(values, props.projectIdForSub, values.nameSub.split("*")[0], props.isEditing);
        resetForm();
      } else {
        setSendSubQuotation(values);
        resetForm();
      }
      setFileName('');
      setClear(true);
    },
 
  });

  return (
    <div>
      <form>
        <div className={styles.fieldWrapper}>
          <RubroSubQuotationSelect
            inputName="nameSub"
            rubros={props.subQuotations}
            setFieldValue={formik.setFieldValue}
            clear={clear}
            resetClear={resetClear}
          />
        </div>
        <div className={formik.values.nameSub ? styles.containerSubproject : ""}>
          <div
            className={props.subProjectStyleForm ? styles.fieldWrapper_hidden : `${styles.fieldWrapper_content} ${formik.values.nameSub && styles.open}`}
          >
            <h3 className={styles.tituloRubro}>{formik.values.nameSub.split("*")[1]}</h3>
            <div className={styles.tituloDescripcion}>
              <AddDescription />
              <h4>Descripción</h4>
            </div>
            <div className={styles.fieldWrapper}>
              <div className={styles.relative}>
                <textarea
                  id="descriptionSub"
                  name="descriptionSub"
                  placeholder="Añade una breve descripción del rubro."
                  value={formik.values.descriptionSub}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className={styles.tituloPlanilla}>
              <Cotizacion />
              <h4>Excel de cotización</h4><i>*obligatorio</i>
            </div>
            <div className={styles.fieldWrapperPlanilla_container}>
              <label
                htmlFor="fileType"
                className={styles.fieldWrapperPlanilla}
              >
                <p className={styles.fieldWrapperPlanilla_placeHolder}>
                  {fileName === "" ? "Click para adjuntar un archivo Excel" : fileName.substring(0, 40) + "..."}
                </p>
                <input
                  className={styles.fileName}
                  id="fileType"
                  name="file"
                  type="file"
                  accept=".pdf, .xlsx, .xls"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    formik.setFieldValue("file", file);
                    setFileName(file?.name || '');
                  }}
                />
              </label>
            </div>
            <div className={styles.buttonContainer}>
              <button
                type="button"
                className={`${styles.subbutton} ${styles.delete}`}
                onClick={() => {
                  formik.resetForm();
                  setClear(true);
                }}
              >
                <Trash />
                Cancelar
              </button>
              <button
                type="button"
                className={styles.subbutton}
                disabled={!formik.isValid || !formik.values.file}
                onClick={() => formik.handleSubmit()}
              >
                <Plus />
                Crear rubro
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subQuotations: state.profile.subQuotations,
  quotationToEdit: state.profile.quotationToEdit,
});

export default connect(mapStateToProps, { setSendSubQuotation, addSubQuotationToQuotation })(NuevaSubCotizacion);


import React, { useState, useEffect } from "react";
import styles from "./CotizacionDeMateriales.module.scss";
import styled from "styled-components";
import CalculadoraDeMateriales from "../../components/MarketPlace/CalculadoraDeMateriales/CalculadoraDeMateriales";
import FondoCalculadora from "../../assets/images/fondoCalculadora.jpg";
import FondoListado from "../../assets/images/fondoListado.jpg";
import CommingSoonBanner from "../../components/Dashboard/Banner/CommingSoonBanner";
import Botonera from "../../components/Botonera/Botonera";
import { motion } from "framer-motion";
import useSuscriptionModalTimeout from "../../hooks/useSuscriptionModalTimeout";
import Loading from "../../components/UI/Loading/Loading";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import { connect } from "react-redux";
import { clearProjects, filterQuotations } from "../../store/actions";
import FilterQuotation from "../../components/Filters/FilterQuotations/FilterQuotation";
import { ReactComponent as NoResultsIcon } from "../../assets/svg/NoResultsMagnifier.svg";
import Pagination from "../../hooks/pagination";
import { faUpload, faFileArrowDown, faCircleInfo, faDatabase, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewNav from "../../components/Nav/NewNav";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const CotizacionDeMateriales = ({
  filterQuotations,
  quotations,
}) => {
  const [showCalculator, setShowCalculator] = useState(false);
  const [showQuotationsList, setShowQuotationsList] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const pageNumberLimit = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  useSuscriptionModalTimeout();

  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: quotations,
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    const initialFilter = {
      sort: "DESC",
      asociatedWords: null,
      zone: null,
      minAmount: "",
      maxAmount: "",
      currency: "$",
      jurisdiction: null,
      organism: null,
      workType: null,
      dateRange: { dateFrom: null, dateTo: null },
      projectType: "Publica",
    }
    setIsLoading(true);
    filterQuotations(initialFilter, currentPage, pageNumberLimit);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, [currentPage]);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showCalculatorComponent = () => {
    setShowCalculator(true);
    setShowQuotationsList(false);
  };

  const showQuotationsListComponent = () => {
    setShowCalculator(false);
    setShowQuotationsList(true);
  };

  const showOptionsComponent = () => {
    setShowCalculator(false);
    setShowQuotationsList(false);
  };

  const handleOnClickMail = () => {
    window.location.href = "mailto: gonzalo@edify.la subject=Quiero%20publicitar%20mis%20productos%20en%20edify";
  };


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      {!showCalculator && !showQuotationsList && (
        <div className={styles.generalContainer}>
          <div className={styles.container}>
            <motion.div 
              whileHover={{ scale: 1.01, transition: {duration: 0.3}, backgroundColor: "#edf0f7"}} 
              className={styles.container_section} 
              initial={{ x: -500 }} 
              animate={{ x: 0 }} 
              transition={{ duration: 0.3 }}
              onClick={showQuotationsListComponent}
              >
              <div className={styles.container_section_description}>
                <div className={styles.container_section_title}>Revisa los pedidos de cotización de materiales existentes</div>
                <ul className={styles.container_section_description_txt}>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faFilter} size="2x" />
                    Usá el filtrador para encontrar los pedidos de cotización públicos o privados.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faDownload} size="2x" />
                    Para presupuestar en pedidos privados de usuarios de edify, decargá la planilla con los requerimientos desde la publicación y enviá tu cotización.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faCircleInfo} size="2x" />
                    Podés ver todos los detalles de cada pedido de cotización haciendo click en "ver más".</li>
                </ul>
                <div className={styles.container_section_backdropList}>
                  <div className={styles.container_section_button} >Ver los pedidos de cotización de materiales</div>
                  <img src={FondoListado} alt="FondoListado" className={styles.container_background} />
                </div>
              </div>

            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.01, transition: {duration: 0.3}, backgroundColor: "#edf0f7" }} 
              className={styles.container_section} initial={{ x: 500 }} 
              animate={{ x: 0 }} 
              transition={{ duration: 0.3 }}
              onClick={showCalculatorComponent}
              >
              <div className={styles.container_section_description}>
                <div className={styles.container_section_backdropCalculator}>
                  <div className={styles.container_section_buttonCalculator} >Calculadora de costos de materiales</div>
                  <img src={FondoCalculadora} alt="FondoCalculadora" className={styles.container_backgroundCalculator} />
                  <div className={styles.container_section_disclaimer}>
                    <p>*Los precios que se muestran son estimativos y pueden variar. Edify no se responsabiliza por la precisión de los precios mostrados.</p>
                  </div>
                </div>
                
                <ul className={styles.container_section_description_txt}>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faDatabase} size="2x" />
                    Podés armar tu pedido consultando nuestra base de datos de materiales.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faFileArrowDown} size="2x" />
                    Al finalizar, vas a poder descargar tu estimación de costos e imprimirla.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <FontAwesomeIcon icon={faUpload} size="2x" />
                    Si querés una cotización real por parte de la comunidad de edify, podés publicar tu pedido de cotización.</li>
                </ul>
                <div className={styles.container_section_title}>Obtené una estimación de costos de materiales por parte de edify</div>
              </div>

            </motion.div>
          </div>
        </div>
      )}

      {showCalculator && (
        <div className={styles.containerCalculator}>
          <div className={styles.containerCalculator_buttonsContainer}>
            <button className={styles.containerCalculator_button} onClick={showOptionsComponent}>Volver a la selección de opciones</button>
            <button className={styles.containerCalculator_button} onClick={showQuotationsListComponent}>Mostrar Lista de Cotizaciones</button>
          </div>
           {/* <CalculadoraDeMateriales />  */}
          <CommingSoonBanner />
          {/* <CotizacionPage /> */}
        </div>
      )}

      {showQuotationsList && (
        <div className={styles.containerList}>
          <div className={styles.containerList_buttonsContainer}>
            <button className={styles.containerCalculator_button} onClick={showOptionsComponent}>Volver</button>
            <button className={styles.containerCalculator_button} onClick={showCalculatorComponent}>Ir a la calculadora</button>
          </div>
          <FilterQuotation setIsFiltered={setIsFiltered} setIsLoading={setIsLoading} />
          {isLoading ? (
            <Loading noModal />
          ) : (
            <div className={styles.cotizacionDeMaterialesContainer}>
              {!quotations.entities || quotations.entities.length === 0 ? (
                <div className={styles.emptyState}>
                  <EmptyState title="No hay resultados para su búsqueda" icon={<NoResultsIcon width={80} height={80} />} />
                </div>
              ) : (
                <Pagination
                  {...paginationAttributes}
                  onPrevClick={onPrevClick}
                  onNextClick={onNextClick}
                  onPageChange={onPageChange}
                  isQuotation
                />
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  quotations: state.profile.quotations,
});

export default connect(mapStateToProps, {
  clearProjects,
  filterQuotations,
})(CotizacionDeMateriales);

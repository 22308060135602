import React, { useState, useEffect, useRef } from "react";
import styles from "./DetailsForm.module.scss";
import { connect } from "react-redux";
import { ReactComponent as Name } from "../../../assets/svg/addPlaceholder.svg"
import { ReactComponent as DateIcon } from "../../../assets/svg/datePlaceholder.svg"
import { ReactComponent as Pencil } from "../../../assets/svg/pencilEditIcon.svg"
import { ReactComponent as Location } from "../../../assets/svg/locationPlaceholder.svg"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ReactComponent as FolderIcon } from "../../../assets/svg/input-img.svg";
import { openMainModal, deleteImageFromProject } from "../../../store/actions";
import "./DetailsFormCarousel.css";
import Subproyectos from "../../Proyectos/Subproyectos/Subproyectos";

const DetailsForm = ({
  formik,
  imageFile,
  fileName,
  fromNew,
  zone,
  isEditing,
  projectForm,
  openMainModal,
  onImageFilesChange,
  image,
  minDate,
  imageEdit,
  imagesFromProject,
  setChangedImages,
  deleteImageFromProject,
  projectId,
  setMaxDateForSubProjects,
  setMinDateForSubProjects,
  subProjects,
  subProjectsLength,
  projectIdForSub,
  postulationProject,
  closeModal,
  maxDateForSubProjects,
  minDateForSubProjects,
  subprojectsEdit,
  ...props
}) => {
  let {
    name,
    description,
    endDate,
    startDate,
  } = formik.values;

  const formattedEndDate = endDate?.split('T')[0];
  const formattedStartDate = startDate?.split('T')[0];
 

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [city, setCity] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const inputRef = useRef(null);

  const handleChange = async (event) => {
    const value = event.target.value;

    setCity(value);
    setShowSuggestions(true);
    formik.setFieldValue('zone', value.trim());

    if (value.trim().length < 3) {
      setLoadingError(true)
    } else {
      setLoadingError(false)
    }

    if (value.trim().length >= 3) {
      setLoadingSuggestions(true);

      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${value}&format=json&limit=5&countrycodes=ar`);
        const data = await response.json();

        const citySuggestions = data.map(item => ({
          name: item.display_name,
          lat: item.lat,
          lon: item.lon
        }));
        if (citySuggestions.length > 0) {
          setSuggestions(citySuggestions);
        } else {
          setSuggestions([{ name: "No hemos encontrado ninguna sugerencia para tu búsqueda" }]);
        }

      } catch (error) {
        console.error('Error al realizar la solicitud a la API:', error);
        setShowSuggestions(false);
      } finally {
        setLoadingSuggestions(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }
    , []);

  const handleSelect = (city) => {
    setCity(city);
    formik.setFieldValue('zone', city);
    setShowSuggestions(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      formik.setFieldValue('zone', city);
      setShowSuggestions(false);
    }
  }

  useEffect(() => {
    if (isEditing) {
      const imagesFromEdit = imagesFromProject.map((image) => ({
        image: image.image,
        id: image.id,
      })
      );
      setImageUrls(imagesFromEdit);
      setImagenes(imagesFromEdit);
    }
  }, [imagesFromProject, isEditing]);

  const imageChangeHandler = (event) => {
    const files = event.target.files;
    const updatedFiles = Array.from(files);
    if (updatedFiles.length + (imagenes.length || (imagesFromProject?.length || 0)) > 5) {
      openMainModal({
        title: "No se pueden agregar más de 5 imágenes",
        body: "Por favor, elimine alguna de las imágenes existentes ¡Muchas gracias!",
        isSuccess: true,
      });
      return;
    }

    // Leer el contenido de los archivos y generar las URLs de imagen
    updatedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result;

        setImagenes((prevImagenes) => {
          const newImages = [...prevImagenes, imageUrl];
          setCurrentImageIndex(newImages.length - 1);
          return newImages;
        });
        setImageUrls((prevImageUrls) => {
          const newUrls = [...prevImageUrls, imageUrl];
          setCurrentImageIndex(newUrls.length - 1);
          return newUrls;
        });
        onImageFilesChange((prevFiles) => {
          const newImages = [...prevFiles, file];
          setCurrentImageIndex(newImages.length - 1);
          return newImages;
        });
        setChangedImages(true);
      };
      reader.readAsDataURL(file);
    });
  };

  //Funcion para quitar las imagenes antes de subirlas
  const handleImageDelete = (index, id) => {
    const updatedImages = [...imagenes];
    updatedImages.splice(index, 1);
    setImagenes(updatedImages);

    const updatedUrls = [...imageUrls];
    updatedUrls.splice(index, 1);
    setImageUrls(updatedUrls);

    if (isEditing && imagesFromProject.length > 0) {
      deleteImageFromProject(id, projectId);
    }
    setCurrentImageIndex(0);
  };

  //Definimos de donde tomamos el array de imagenes a mostrar
  const imagesToDisplay = isEditing
  ? [
      ...imagesFromProject,
      ...imagenes.filter(
        (image) => !imagesFromProject.some((projectImage) => projectImage.id === image.id)
      ),
    ]
  : imagenes;

  return (
    <div className={styles.generalContainer}>
      <section className={styles.carouselContainer}>
        {imagesToDisplay && imagesToDisplay.length > 0 ? (
          <Carousel
            className={styles.carousel}
            selectedItem={currentImageIndex}
            onChange={(index) => setCurrentImageIndex(index)}
            showThumbs={false}
            showStatus={false}
          >
            {imagesToDisplay.map((image, index) => (
              <div key={index} className={styles.customImgContainer}>
                <img src={isEditing && index < imagesFromProject.length ? image.image : image} alt="" className={styles.customImgContainer_img} />
                <button onClick={() => handleImageDelete(index, image.id)} className={styles.deleteButton}>
                  X
                </button>
                <div className={styles.emptyContainer_withImage}>
                  <label
                    htmlFor="image"
                    className={`${styles.emptyContainer_imageMask} ${styles.withImage}`}
                  >
                    <FolderIcon width={15} height={15} />
                    <span className={`${styles.emptyContainer_text} ${styles.withImage}`}>Adjuntar imagen</span>
                    <input
                      className={styles.emptyContainer_inputHidden}
                      type="file"
                      name="image"
                      id="image"
                      onChange={imageChangeHandler}
                      style={{ display: "none" }}
                      accept="image/*"
                      multiple
                    />
                  </label>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <div className={styles.emptyContainer}>
            <label
              htmlFor="image"
              className={styles.emptyContainer_imageMask}
            >
              <FolderIcon width={15} height={15} />
              <span className={styles.emptyContainer_text}>Adjuntar imagen</span>
              <input
                className={styles.emptyContainer_inputHidden}
                type="file"
                name="image"
                id="image"
                onChange={imageChangeHandler}
                style={{ display: "none" }}
                accept="image/*"
                multiple
              />
            </label>
          </div>
        )}
      </section>
      <section className={styles.infoContainer}>
        <div className={styles.infoContainer_itemContainer}>
          <div className={styles.infoContainer_item}>
            <div className={styles.infoContainer_item_title}>NOMBRE DEL PROYECTO</div>
            <div className={styles.infoContainer_item_inputContainer}>
              <Name className={styles.infoContainer_item_icon} />
              <input
                type="text"
                id="name"
                name="name"
                value={name || formik.values.name}
                className={`${styles.infoContainer_item_input} ${formik.errors.name && formik.dirty && styles.error}`}
                placeholder="Ej.: Torre Saint Muratz"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
            </div>
          </div>
          <div className={styles.infoContainer_item}>
            <div className={styles.infoContainer_item_title}>UBICACIÓN</div>
            <div className={styles.infoContainer_item_inputContainer}  ref={inputRef}>
              <Location className={styles.infoContainer_item_icon} />
              <input
                type="text"
                id="zone"
                name="zone"
                value={city || formik.values.zone}
                className={`${styles.infoContainer_item_input} ${formik.errors.zone && formik.dirty && styles.error}`}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Escribe el nombre de la ciudad..."
              />
              {showSuggestions && (
                <ul className={styles.cityOptionList}>
                  {loadingError && (
                    <li className={styles.cityOptionList_item}>Por favor, ingrese al menos 3 caracteres</li>
                  )}
                  {loadingSuggestions ? (
                    <li className={styles.cityOptionList_item}>Cargando sugerencias...</li>
                  ) : (
                    suggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => handleSelect(suggestion.name)} className={styles.cityOptionList_item}>
                        {suggestion.name}
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className={styles.infoContainer_itemContainer}>
          <div className={styles.infoContainer_item}>
            <div className={styles.infoContainer_item_title}>INICIO DE LA PUBLICACIÓN</div>
            <div className={styles.infoContainer_item_inputContainer}>
              <DateIcon className={styles.infoContainer_item_icon} />
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={isEditing ? formattedStartDate : formik.values.startDate}
                  className={`${styles.infoContainer_item_inputDate} ${formik.errors.startDate && formik.dirty && styles.error}`}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMinDateForSubProjects(e.target.value);
                  }}
                  min={minDate}
                />
            </div>
          </div>
          <div className={styles.infoContainer_item}>
            <div className={styles.infoContainer_item_title}>FINALIZACIÓN DE LA PUBLICACIÓN</div>
            <div className={styles.infoContainer_item_inputContainer} >
              <DateIcon className={styles.infoContainer_item_icon} />
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={isEditing ? formattedEndDate : formik.values.endDate}
                  min={isEditing ? formik.values?.startDate?.split("T")[0] : formik.values.startDate}
                  className={`${styles.infoContainer_item_inputDate} ${formik.errors.endDate && formik.dirty && styles.error}`}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMaxDateForSubProjects(e.target.value);
                  }}
                />
            </div>
          </div>
        </div>
        <div className={styles.infoContainer_item}>
          <div className={styles.infoContainer_item_title}>DESCRIPCION GENERAL</div>
          <div tabIndex="0" className={`${styles.infoContainer_item_inputAreaContainer} ${formik.errors.description && formik.dirty && styles.error }`}>
            <Pencil className={styles.infoContainer_item_iconPencil} />
            <textarea
              id="description"
              name="description"
              value={description || formik.values.description}
              placeholder="Añade una breve descripción..."
              className={styles.infoContainer_item_inputAreaContainer_inputArea}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
          </div>
        </div>
        <section className={styles.infoContainer_item}>
          <h4 className={styles.infoContainer_item_title}>
            AGREGAR RUBROS A COTIZAR AL PROYECTO:
          </h4>
          {formik.isValid ? (
          <Subproyectos
            subprojectsEdit={subprojectsEdit}
            isEditing={isEditing}
            projectIdForSub={projectIdForSub}
            closeModal={closeModal}
            maxDateForSubProjects={maxDateForSubProjects}
            minDateForSubProjects={minDateForSubProjects}
          />
          ) : (
            <div className={styles.subProjectEmptyMessage} >
              <p>Debes completar los campos obligatorios para poder agregar rubros a cotizar</p>
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    projectForm: state.profile.projectForm,
  };
};

export default connect(mapStateToProps, {
  openMainModal,
  deleteImageFromProject,
})(DetailsForm);



import React from "react";
import styles from "./PresupuestosConfirm.module.scss";
import styled from "styled-components";
import { connect } from "react-redux";
import { validateBudgetRequest } from "../../../store/actions/";
import { ReactComponent as Presupuesto } from "../../../assets/svg/presupuesto.svg";
import { ReactComponent as PresupuestoCheck } from "../../../assets/svg/check-pres.svg";
import { ReactComponent as PresupuestoNegate } from "../../../assets/svg/negate-pres.svg";
import Planos from "../../../assets/images/verPlanosFicha.png";
import MainContentWrapper from "../../../components/Layout/MainContentWrapper/MainContentWrapper";
import { motion } from "framer-motion";
import { opacityVariants } from "../../../utils/FramerConfigs";
import { useNavigate } from "react-router-dom";

const Container = styled(motion.div)`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PresupuestosConfirm = ({
  id,
  userFullName,
  subProjectName,
  validateBudgetRequest,
  budget,
}) => {

  const navigate = useNavigate();
  
  return (
    <Container
      variants={opacityVariants}
      initial="out"
      animate="in"
      transition={opacityVariants.transition(0)}>
      <MainContentWrapper title="Presupuestos">
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>¡{userFullName} te ha enviado un presupuesto!</h2>
          </div>
          <div className={styles.presupuesto}>
            <div className={styles.presupuestoTitle}>
              <h3>
                <span>Proyecto:</span> {subProjectName}
              </h3>
              <h4>
                <span>Subproyecto: </span>
                {subProjectName}
              </h4>
            </div>
            <div className={styles.presupuestoItem}>
              <Presupuesto />
              <a href={budget} target="_blank" rel="noopener noreferrer">
                <img src={Planos} alt="Planos" />
                <span>Descargar Presupuesto</span>
              </a>
            </div>
            <div className={styles.presupuestoAction}>
              <button
                className={styles.rechazar}
                onClick={() => validateBudgetRequest(id, "NEGATED", navigate)}>
                <PresupuestoNegate /> Rechazar
              </button>
              <button
                className={styles.aceptar}
                onClick={() => validateBudgetRequest(id, "ALLOWED", navigate)}>
                <PresupuestoCheck /> Aceptar
              </button>
            </div>
          </div>
        </div>
      </MainContentWrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    budgetRequest: state.dashboard.budgetRequest,
  };
};

export default connect(mapStateToProps, {
  validateBudgetRequest,
})(PresupuestosConfirm);

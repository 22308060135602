import React, { useState } from 'react';
import styles from './FaqQuestions.module.scss';
import { motion } from 'framer-motion';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrowDownIcon.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/svg/arrowUpIcon.svg';
import { ReactComponent as ErrorIconPlan } from "../../assets/svg/cancelBlack.svg";
import { ReactComponent as CheckIcon } from "../../assets/svg/checkmark.svg";
import { ReactComponent as ArrowDownSmall } from "../../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowUpSmall } from "../../assets/svg/arrow-up.svg";
import { ReactComponent as CreateIcon } from "../../assets/svg/addPublicationIcon.svg";
import { ReactComponent as ObraPrivadaIcon } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplaceActive.svg";
import { ReactComponent as NotifActive } from "../../assets/svg/nav-notif-active.svg";
import { ReactComponent as MuroIcon } from "../../assets/svg/nav-proyectos-active.svg";


const planArray = [
    {
        planId: "0",
        title: "Plan Básico",
        subtitle: "Standard",
        pricing: 0,
        frecuency: "GRATIS",
        type: "Gratuito",
        pricing_aditional: "Gratis",
        itemList: [
            { id: 1, text: "Ingreso a la plataforma" },
        ],
    },
    {
        planId: "1",
        title: "Plan Mensual",
        subtitle: "Premium",
        pricing: 1500,
        frecuency: "(IVA Incluido)",
        type: "Premium",
        pricing_aditional: "*se ajustara de forma trimestral por el índice CAC",
        itemList: [
            { id: 1, text: "Ingreso a la plataforma" },
            { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
            { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
            { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },
        ],
    },
    {
        planId: "2",
        title: "Plan Semestral",
        subtitle: "Premium",
        pricing: 6000,
        frecuency: "(IVA Incluido)",
        type: "Premium",
        pricing_aditional: "20% de descuento",
        itemList: [
            { id: 1, text: "Ingreso a la plataforma" },
            { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
            { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
            { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },

        ],
    },
    {
        planId: "3",
        title: "Plan Anual",
        subtitle: "Premium",
        pricing: 10800,
        frecuency: "(IVA Incluido)",
        type: "Premium",
        pricing_aditional: "40% de descuento",
        itemList: [
            { id: 1, text: "Ingreso a la plataforma" },
            { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
            { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
            { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },
        ],
    },
];

const FaqQuestions = () => {
    const [showContent, setShowContent] = useState({
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
    });

    const handleOnClick = (question) => {
        setShowContent((prevShowContent) => ({
            ...prevShowContent,
            [question]: !prevShowContent[question],
        }));
    }

    return (
        <div className={styles.faqQuestions}>
            <ul className={styles.questionList}>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Qué es Edify?
                        {showContent["1"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("1")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("1")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["1"] ? 1 : 0, y: showContent["1"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["1"] ? styles.show : ""}`}
                    >
                        <p>Edify es una plataforma que conecta a todos los sectores de la construcción, permitiéndote cotizar o recibir presupuestos
                            para diversos proyectos, ya sea en el ámbito de obras privadas o la solicitud de cotización de materiales. Además, funciona
                            como un canal para comercializar tus productos o servicios a través del muro de Edify.
                        </p>
                    </motion.div>
                </motion.li>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Cómo puedo usar Edify?
                        {showContent["2"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["2"] ? 1 : 0, y: showContent["2"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["2"] ? styles.show : ""}`}
                    >
                    <div className={styles.questionList_answer_subtitle}>
                        <p>Recibir presupuestos para obras privadas en Edify</p>
                        {showContent["2.6"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.6")}>
                                <ArrowUpSmall width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.6")}>
                                <ArrowDownSmall width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["2.6"] ? 1 : 0, y: showContent["2.6"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["2.6"] ? styles.show : ""}`}
                    >
                        <p>Para recibir presupuestos en Edify, simplemente publica tu proyecto especificando los rubros y plazos deseados. (&nbsp;<b><CreateIcon width={15} height={15} />"Crear publicación"</b>)
                            Cada rubro que publiques puede tener un plazo determinado, distinto al de los demás.<br />
                            <br />
                            A su vez, <b>tienes el control</b> de quién accede a la documentación de tu proyecto, asegurando que solo los proveedores
                            seleccionados puedan descargarla y enviar una cotización. Edify no participa ni interviene en el proceso.
                        </p>
                    </motion.div>
                        <div className={styles.questionList_answer_subtitle}>
                            <p>Publicar una obra privada en Edify</p>
                            {showContent["2.1"] ? (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.1")}>
                                    <ArrowUpSmall width={20} height={20} />
                                </div>
                            ) : (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.1")}>
                                    <ArrowDownSmall width={20} height={20} />
                                </div>
                            )}
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: showContent["2.1"] ? 1 : 0, y: showContent["2.1"] ? 0 : -10 }}
                            transition={{ duration: 0.3 }}
                            className={`${styles.questionList_answer} ${showContent["2.1"] ? styles.show : ""}`}
                        >
                            <p>Publicar una obra privada es fácil:</p><br /><br />
                            <p>1. Haz clic en  <b><CreateIcon width={15} height={15} />"Crear publicación"</b>.<br />
                                2. Selecciona <b><ObraPrivadaIcon width={15} height={15} />"Obra privada"</b>.<br />
                                3. Completa el formulario con detalles del proyecto, incluyendo nombre, ubicación, plazos, y descripción.<br />
                                4. Haz clic en el botón <b><Plus width={15} height={15} />“Crear rubro”</b> y selecciona el correspondiente.<br />
                                5. Si lo deseas, puedes agregar otro rubro diferente al mismo proyecto haciendo click en <b>“Si”</b> y volver al paso 4.<br />
                                De lo contrario, debes  indicar <b>“No, deseo finalizar y publicar el proyecto”</b>.</p><br /><br />
                            <p>Ten en cuenta que cuantos más datos incluyas en la publicación (documentación específica, fechas, descripciones, planos o
                                links a una dirección de dropBox, Google drive o similar), mejores van a ser los presupuestos que recibas.</p>
                        </motion.div>
                        <div className={styles.questionList_answer_subtitle}>
                            Cotizar o enviar presupuestos para obras privadas
                            {showContent["2.2"] ? (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.2")}>
                                    <ArrowUpSmall width={20} height={20} />
                                </div>
                            ) : (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.2")}>
                                    <ArrowDownSmall width={20} height={20} />
                                </div>
                            )}
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: showContent["2.2"] ? 1 : 0, y: showContent["2.2"] ? 0 : -10 }}
                            transition={{ duration: 0.3 }}
                            className={`${styles.questionList_answer} ${showContent["2.2"] ? styles.show : ""}`}
                        >
                            <p>Cuando existan proyectos de obra privada publicados y desees cotizar en uno de ellos, debes postularte para descargar y enviar el presupuesto:</p><br /><br />
                            <p>1. Busca el proyecto en la sección de <b><ObraPrivadaIcon width={15} height={15} />"Obras Privadas"</b>.<br />
                                2. Haz clic en <b>"Cotizar"</b>, acepta los términos y condiciones y haz clic en <b>"¡Quiero postularme!"</b>.<br />
                                3. Una vez autorizado, descarga la documentación.<br />
                                4. Una vez listo el presupuesto, haz clic en “Subir presupuesto” para enviar tu cotización.</p><br /><br />
                            <p>En la sección de "Notificaciones" podrás ver el estado de tu solicitud. A su vez, te enviaremos por mail todos los movimientos respecto de la misma.<br /><br />
                                En Edify no se publican precios y las negociaciones son entre las partes involucradas.</p>
                        </motion.div>
                        <div className={styles.questionList_answer_subtitle}>
                            Recibir cotizaciones de materiales para la construcción
                            {showContent["2.3"] ? (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.3")}>
                                    <ArrowUpSmall width={20} height={20} />
                                </div>
                            ) : (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.3")}>
                                    <ArrowDownSmall width={20} height={20} />
                                </div>
                            )}
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: showContent["2.3"] ? 1 : 0, y: showContent["2.3"] ? 0 : -10 }}
                            transition={{ duration: 0.3 }}
                            className={`${styles.questionList_answer} ${showContent["2.3"] ? styles.show : ""}`}
                        >
                            <p>Para recibir cotizaciones de materiales, crea una publicación en Edify detallando los materiales que necesitas.
                                Recibirás múltiples presupuestos de diferentes proveedores, lo que te permite elegir la mejor oferta.</p><br /><br />
                            <p>1. Haz clic en  <b><CreateIcon width={15} height={15} />"Crear publicación"</b>.<br />
                                2. Selecciona <b><MarketPlace width={15} height={15} />"Cotización de materiales"</b>.<br />
                                3. Completa el formulario siguiendo los pasos requeridos:<br /><br />
                                <span className={styles.questionList_answer_bullet}></span>Nombre de la cotización.<br />
                                <span className={styles.questionList_answer_bullet}></span>Selecciona una de las jurisdicciones de la lista.<br />
                                <span className={styles.questionList_answer_bullet}></span>Plazo de recepción de cotizaciones (inicio y fin).<br />
                                <span className={styles.questionList_answer_bullet}></span>Breve descripción.<br />
                                <span className={styles.questionList_answer_bullet}></span>Elige al menos 1 rubro de la lista.<br />
                                <span className={styles.questionList_answer_bullet}></span>Adjunta una planilla de Excel de cotización con los requerimientos necesarios.<br />
                                <span className={styles.questionList_answer_bullet}></span> Haz clic en <b><Plus width={15} height={15} />“Agregar rubro”</b>.<br />
                                <span className={styles.questionList_answer_bullet}></span>Haz clic en <b>“Publicar cotización de materiales”</b>.</p><br /><br />
                            <p>Una vez terminado este proceso, la solicitud de cotización quedará publicada en la sección <b><MarketPlace width={15} height={15} />"Cotización de materiales"</b> dentro
                                de <b>"Tipo de solicitud de cotización"</b>&nbsp; <b>“Privadas”</b>.  Los presupuestos llegan a través de la plataforma y los podrás descargar en la sección <b><NotifActive width={15} height={15} /> “Notificaciones”</b>.
                                También te llegarán avisos a la casilla de mail cada vez que un usuario te haya enviado un presupuesto.</p>
                        </motion.div>
                        <div className={styles.questionList_answer_subtitle}>
                            Cotizar o enviar presupuesto de cotización de materiales
                            {showContent["2.4"] ? (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.4")}>
                                    <ArrowUpSmall width={20} height={20} />
                                </div>
                            ) : (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.4")}>
                                    <ArrowDownSmall width={20} height={20} />
                                </div>
                            )}
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: showContent["2.4"] ? 1 : 0, y: showContent["2.4"] ? 0 : -10 }}
                            transition={{ duration: 0.3 }}
                            className={`${styles.questionList_answer} ${showContent["2.4"] ? styles.show : ""}`}
                        >
                            <p>Si existen solicitudes de cotización de materiales y queres enviar un presupuesto tiene que:</p><br /><br />
                            <p>1. Haz click en la sección <b><MarketPlace width={15} height={15} />"Cotización de materiales"</b>.<br />
                            2. Utiliza el filtro en la parte superior central de la pantalla. En el primer casillero, indica el <b>“tipo de solicitud de cotización”</b> como <b>“Privada”</b>.<br />
                            3. Haz clic en <b>“Buscar”</b>.<br />
                            4. En la publicación de interés, haz click en <b>“Ver Más”</b>.<br />
                            5. En el menú desplegable, selecciona <b>“Descargar Plantilla de Cotización”</b>.<br />
                            6. Con el presupuesto listo, regresa al menú de <b>“Ver Más”</b> y elige <b>“Subir presupuesto”</b>.</p><br /><br />

                            <p>¡Listo! Tu presupuesto ha sido enviado al creador de la publicación, quien te informará sobre los siguientes pasos si resultas elegido. Las negociaciones son privadas entre las partes.</p>
                            </motion.div>
                            <div className={styles.questionList_answer_subtitle}>
                            Publicar un artículo en el muro de Edify
                            {showContent["2.5"] ? (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.5")}>
                                    <ArrowUpSmall width={20} height={20} />
                                </div>
                            ) : (
                                <div className={styles.questionList_question_icon} onClick={() => handleOnClick("2.5")}>
                                    <ArrowDownSmall width={20} height={20} />
                                </div>
                            )}
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: showContent["2.5"] ? 1 : 0, y: showContent["2.5"] ? 0 : -10 }}
                            transition={{ duration: 0.3 }}
                            className={`${styles.questionList_answer} ${showContent["2.5"] ? styles.show : ""}`}
                        >
                            <p>Publicar un artículo en el muro de Edify es sencillo:</p><br /><br />
                            <p>1. Haz clic en  <b><CreateIcon width={15} height={15} />"Crear publicación"</b>.<br />
                            2. Selecciona <b><MuroIcon width={15} height={15} />"Artículo en el muro"</b>.<br />
                            3. Comparte textos, fotos, o videos relacionados con la construcción<br />
                            4. Promociona tus servicios o productos.</p><br /><br />
                            <p>El objetivo de esta sección es generar un vínculo laboral y empresarial entre usuarios de toda
                            la comunidad y abrirles la ventana a un nuevo canal de comercialización. Las publicaciones pueden
                            ser solo de texto y ser acompañadas por una foto o video, también es posible comentar y realizar 
                            recomendaciones de publicaciones de otros usuarios</p>
                            </motion.div>
                        </motion.div>
                </motion.li>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Cuál es el costo de Edify?
                        {showContent["3"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("3")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("3")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["3"] ? 1 : 0, y: showContent["3"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["3"] ? styles.show : ""}`}
                    >
                        <div className={styles.SuscriptionModal_PlanContainerGrid}>
                            <table className={styles.SuscriptionModal_PlanTable}>
                                <thead>
                                    <tr>
                                        <th>{null}</th>
                                        {planArray.map((plan, index) => (
                                            <th key={index} className={styles.SuscriptionModal_planTableHeader}>
                                                {plan.planId === "3" && (
                                                    <div className={styles.SuscriptionModal_planTableHeader_recommendPlan}>
                                                        <p className={styles.SuscriptionModal_planTableHeader_recommendPlan_txt}>RECOMENDADO</p>
                                                    </div>
                                                )}
                                                <div className={styles.SuscriptionModal_PlanContainerPlantitle}>
                                                    <h3>{plan.title}</h3>
                                                    <p className={styles.SuscriptionModal_planTableHeader_price}>$ {plan.pricing.toLocaleString("es-AR")}</p>
                                                </div>

                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Tipo de plan</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.type}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Descuento</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.pricing_aditional}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Acceso a la plataforma</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.itemList.some(item => item.id === 1) ? (
                                                    <CheckIcon width={15} height={15} />
                                                ) : (
                                                    <ErrorIconPlan width={15} height={15} />
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Publicaciónes ilimitadas de obras privadas</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.itemList.some(item => item.id === 2) ? (
                                                    <CheckIcon width={15} height={15} />
                                                ) : (
                                                    <ErrorIconPlan width={15} height={15} />
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Publicaciónes ilimitadas de artículos en el muro</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.itemList.some(item => item.id === 3) ? (
                                                    <CheckIcon width={15} height={15} />
                                                ) : (
                                                    <ErrorIconPlan width={15} height={15} />
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className={styles.SuscriptionModal_planTableItems_row}>Postulaciones para cotizar en proyectos existentes</td>
                                        {planArray.map((plan, index) => (
                                            <td key={index} className={styles.SuscriptionModal_planTableItems}>
                                                {plan.itemList.some(item => item.id === 4) ? (
                                                    <CheckIcon width={15} height={15} />
                                                ) : (
                                                    <ErrorIconPlan width={15} height={15} />
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </motion.div>
                </motion.li>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Qué pasa si se me vence la suscripción?
                        {showContent["4"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("4")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("4")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["4"] ? 1 : 0, y: showContent["4"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["4"] ? styles.show : ""}`}
                    >
                        <p>Todos nuestros planes son de renovación automática tomando en cuenta la frecuencia del plan elegido
                            (mensual, semestral o anual). Si no deseas renovar tu suscripción, puedes cancelarla en cualquier momento
                            desde tu <b>perfil</b> de usuario. Si tu suscripción vence y cancelaste la renovacion automática, tu cuenta pasará a ser gratuita y podrás seguir
                            disfrutando de los beneficios del plan básico.
                        </p>
                    </motion.div>
                </motion.li>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Cuáles son los medios de pago?
                        {showContent["5"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("5")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("5")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["5"] ? 1 : 0, y: showContent["5"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["5"] ? styles.show : ""}`}
                    >
                        <p>Los medios de pago disponibles son:</p><br /><br/>
                        <p>- Tarjeta de crédito o débito.<br />
                            - Link de Mercado Pago.<br />
                            - Transferencia bancaria.</p>
                    </motion.div>
                </motion.li>
                <motion.li
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className={styles.questionList_question}
                >
                    <div className={styles.questionList_question_title}>
                        ¿Cómo dar de baja mi cuenta?
                        {showContent["7"] ? (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("7")}>
                                <ArrowUpIcon width={20} height={20} />
                            </div>
                        ) : (
                            <div className={styles.questionList_question_icon} onClick={() => handleOnClick("7")}>
                                <ArrowDownIcon width={20} height={20} />
                            </div>
                        )}
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: showContent["7"] ? 1 : 0, y: showContent["7"] ? 0 : -10 }}
                        transition={{ duration: 0.3 }}
                        className={`${styles.questionList_answer} ${showContent["7"] ? styles.show : ""}`}
                    >
                        <p>Para darte de baja, simplemente cancela la renovación automática de tu suscripción desde tu <b>perfil</b> de usuario. Al hacerlo cuando 
                            tu suscripción venza, tu cuenta pasará a ser gratuita y podrás seguir disfrutando de los beneficios del plan básico. Edify no
                            cobra comisiones por cancelación de suscripciones.
                        </p><br/><br/>
                        <p>Para los casos de <b>prueba gratuita</b>, dado que <b>no se requiere tarjeta de crédito</b> para su activación, una vez completado el
                        período de prueba, tu cuenta pasará a tener el plan básico</p><br/><br/>
                        <p>Si tienes alguna duda o necesitas ayuda, no dudes en contactarnos a través de nuestro mail contacto@edify.la o por whatsApp 1168580968.</p>
                    </motion.div>
                </motion.li>
            </ul>
        </div>
    );
}

export default FaqQuestions;
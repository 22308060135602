import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import styles from "./NewProject.module.scss";
import { useNavigate } from "react-router-dom";
import {
  deleteProyecto,
  openMainModal,
  closeMainModal,
  resetSubProjects,
  resetIdProject,
  updateProyecto,
  getProjectsByType,
  resetProjectToEdit,
  openEdifySpinner,
  createNewProject,
} from "../../store/actions";
import { ReactComponent as Pencil } from "../../assets/svg/pencil-new.svg";
import { ReactComponent as Publicar } from "../../assets/svg/publicarProyecto.svg";
import useFileUpload from "../../hooks/useFileUpload";
import DetailsForm from "../UI/DetailsForm/DetailsForm";
import { motion } from "framer-motion";
import { fireAnalytics } from "../../utils/firebase";

const variants = {
  visible: {
    scale: 1.0,
  },
  hidden: {
    scale: 1.1,
  },
};

const NewProject = ({
  userData,
  openMainModal,
  isEditing,
  postulationProject = {},
  updateProyecto,
  deleteProyecto,
  closeModal,
  step1Project,
  subProjectsToEdit,
  createNewProject,
  closeMainModal,
  openEdifySpinner,
  resetSubProjects,
  subProjects,
  ...props
}) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(isEditing ? postulationProject.proyecto_images[0]?.image : "");
  const [minDate, setMinDate] = useState('');
  const [minDateForSubProjects, setMinDateForSubProjects] = useState('');
  const [maxDateForSubProjects, setMaxDateForSubProjects] = useState('');
  const [imageEdit, setImageEdit] = useState([]);
  const [changedImages, setChangedImages] = useState(false);
  const [hasSubprojects, setHasSubprojects] = useState(false);
  const [imageFile, imageChangeHandler, fileName] = useFileUpload(
    image,
    setImage
  );

  const ProyectoSchema = Yup.object().shape({
    name: Yup.string().required("El campo es requerido"),
    description: Yup.string().required("El campo es requerido"),
    endDate: Yup.date().required("El campo es requerido"),
    startDate: Yup.date().required("El campo es requerido"),
    zone: Yup.string().required("El campo es requerido"),
  });

  const initialValues = {
    name: isEditing ? postulationProject.name : "",
    description: isEditing ? postulationProject.description : "",
    endDate: isEditing ? postulationProject.endDate : "",
    startDate: isEditing ? postulationProject.startDate : "",
    zone: isEditing ? postulationProject.zone : "",
    image: isEditing ? postulationProject.proyecto_images[0]?.image : "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ProyectoSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });


  //Array de imagenes existentes en el proyecto
  const imagesFromProject = postulationProject?.proyecto_images ? postulationProject?.proyecto_images : [];

  useEffect(() => {
    if (subProjects.length > 0) {
      setHasSubprojects(true);
    } else if (isEditing && postulationProject.subproyecto.length > 0) {
      setHasSubprojects(true);
    } else {
      setHasSubprojects(false);
    }
  }, [subProjects]);

  // Maneja el texto del botón de publicar proyecto paso 1
  let contentPaso = formik.isValid && hasSubprojects ? "Publicar" : "Completá los campos";

  useEffect(() => {
    resetProjectToEdit();
    resetSubProjects();
    resetIdProject();
  }, []);

  const divToScrollRef = useRef(null);

  //Nueva funcion para crear un proyecto 
  const sendNewProject = () => {
    const newProject = {
      ...formik.values,
      image: imageEdit,
    };
      fireAnalytics("publish_project", { category: "publishing" });
      createNewProject(newProject, subProjects, navigate);
      closeModal(); 
  }

  //Funcion para manejar el cerrado del modal
  const handleCloseModal = () => {
    openMainModal({
      title: "¿Deseas salir de la creación del proyecto?",
      body: "Al cerrar la ventana, perderás los cambios realizados.",
      isSuccess: false,
      iconType: "warning",
      confirmHandler: () => {
        closeModal();
        closeMainModal();
      },
    });
  };


  // Botón de eliminar proyecto 
  const deleteHandler = () => {
    const projectId = postulationProject.id;
    deleteProyecto(parseInt(projectId), navigate);
  };

  // Función para editar el proyecto 
  const updateProject = () => {
    const updateProject = {
      ...formik.values,
    };
    let imageToSend = imageEdit
    if (
      formik.values.description === "" &&
      formik.values.endDate === "" &&
      formik.values.name === "" &&
      formik.values.zone === ""
    ) {
      openMainModal({
        title: "¡Atención!",
        body:
          formik.values.description === "" &&
            formik.values.endDate === "" &&
            formik.values.name === ""
            ? "Los campos de nombre, descripción y fecha de finalización no pueden estar vacíos"
            : formik.values.zone === postulationProject.zone
              ? "Debes seleccionar una ubicación diferente a la actual"
              : "Lamentablemente no hemos podido procesar su solicitud de actualización ya que no se han detectado cambios en el proyecto",
        isSuccess: true,
        confirmHandler: null,
      });
    } else {
      openMainModal({
        title: "¿Deseas guardar los cambios efectuados?",
        body: "Si tenías usuarios postulados en el proyecto, se le notificarán de los cambios realizados. ",
        isSuccess: false,
        confirmHandler: () => {
          updateProyecto(updateProject, imageToSend, postulationProject.id);
        },
      });
    }
  };

  //Setea la imagen a enviar
  const handleImageFilesChange = (file) => {
    setImageEdit(file);
  };

  //obtenemos fecha mínima para publicar el proyecto
  useEffect(() => {
    const hoy = new Date();
    let dd = hoy.getDate();
    let mm = hoy.getMonth() + 1;
    let yyyy = hoy.getFullYear();
    const minDate = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
    setMinDate(minDate);
  }, []);

  return (
    <>
      {isEditing ? (
        <motion.div
          className={styles.newProject}
          initial="hidden"
          animate="visible"
          variants={variants}
          sizeTransition
        >
          <header className={styles.newProject_header}>
            <div className={styles.newProject_header_txtContainer}>
              <Pencil width={15} height={15} />
              <div>Editor de proyecto</div>
            </div>
            <div className={styles.close} onClick={() => closeModal()}>
              <span className={styles.cancel}>
                Cancelar
              </span>
            </div>
          </header>
          <main className={styles.newProject_container}>
            <div className={styles.newProject_fromNewContainer}>
              <section className={styles.newProject_containerSection}>
                <DetailsForm
                  imageFile={imageFile}
                  imageChangeHandler={imageChangeHandler}
                  onImageFilesChange={handleImageFilesChange}
                  formik={formik}
                  fileName={fileName}
                  minDate={minDate}
                  isEditing={isEditing}
                  imagesFromProject={imagesFromProject}
                  projectId={postulationProject.id}
                  setChangedImages={setChangedImages}
                  subprojectsEdit={postulationProject.subproyecto}
                  projectIdForSub={postulationProject.id}
                  closeModal={closeModal}
                  maxDateForSubProjects={formik.values.endDate}
                  setMaxDateForSubProjects={setMaxDateForSubProjects}
                  minDateForSubProjects={formik.values.startDate}
                  setMinDateForSubProjects={setMinDateForSubProjects}
                />
              </section>
            </div>
            <div className={styles.newProject_buttonContainer}>
              <button
                className={`${styles.newProject_buttonDelete_button} ${styles.delete}`}
                onClick={deleteHandler}
              >
                Eliminar proyecto
              </button>
              {(formik.dirty || changedImages) && <div
                className={styles.newProject_button}
                onClick={updateProject}
              >
                <div className={styles.contentStyle}>
                  <Publicar /> Guardar cambios
                </div>
              </div>}
            </div>
          </main>
        </motion.div>) : (
        <motion.div
          className={styles.newProject}
          initial="hidden"
          animate="visible"
          variants={variants}
          sizeTransition
        >
            <header className={styles.newProject_header}>
              <div className={styles.newProject_header_txtContainer}>
                <Pencil width={15} height={15} />
                <div>Crear proyecto</div>
              </div>
              <div className={styles.close} onClick={() => handleCloseModal()}>
                <span className={styles.cancel}>
                  Cancelar
                </span>
              </div>
            </header>
            <main ref={divToScrollRef} className={styles.newProject_container}>
                <section className={styles.newProject_containerSection}>
                  <DetailsForm
                    imageFile={imageFile}
                    imageChangeHandler={imageChangeHandler}
                    formik={formik}
                    fileName={fileName}
                    minDate={minDate}
                    fromNew
                    onImageFilesChange={handleImageFilesChange}
                    projectId={postulationProject.id}
                    setChangedImages={setChangedImages}
                    setMaxDateForSubProjects={setMaxDateForSubProjects}
                    setMinDateForSubProjects={setMinDateForSubProjects}
                    subprojectsEdit={postulationProject.subproyecto}
                    isEditing={isEditing}
                    projectIdForSub={step1Project.id}
                    closeModal={closeModal}
                    maxDateForSubProjects={maxDateForSubProjects}
                    minDateForSubProjects={minDateForSubProjects}
                    subProjectsLength={subProjects.length}
                  />
                </section>
                <div className={`${styles.newProject_buttonContainer}`}>
                  <button
                    className={`${styles.newProject_button} ${(!formik.isValid || !hasSubprojects) && styles.disabled}`}
                    disabled={!formik.isValid || !hasSubprojects}
                    onClick={sendNewProject}>
                    <div className={styles.contentStyle}>
                      <Publicar /> {contentPaso}
                    </div>
                  </button>
                </div>
            </main>
        </motion.div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    showSubprojects: state.profile.showSubprojects,
    sendProject: state.profile.sendProject,
    projectIdForSub: state.profile.projectIdForSub,
    step1Project: state.profile.projectStep1Id,
    formStep2Style: state.profile.styleForm,
    edifySpinner: state.general.edifySpinner,
    subProjectsToEdit: state.profile.projectToEdit,
    subProjects: state.profile.sendSubprojects,
  };
};

export default connect(mapStateToProps, {
  resetIdProject,
  openMainModal,
  updateProyecto,
  deleteProyecto,
  getProjectsByType,
  createNewProject,
  closeMainModal,
  openEdifySpinner,
  resetSubProjects,
})(NewProject);

import React from 'react';
import edifyLogo from "../../../assets/images/edify-logo.png";
import styles from './MapsLoading.module.scss';

const MapsLoading = () => {
    return (
        <div className={styles.loaderOverlay}>
            <div className={styles.loader}>
                <img src={edifyLogo} alt="Loading..." className={styles.loaderImage} />
                <div className={styles.dots}>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                </div>
            </div>
        </div>
    );
};

export default MapsLoading;

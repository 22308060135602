export default function (baseApi, authApi) {
  return {
    intervals: {
      logout: 3600, // 1 hour in seconds
    },
    api: {
      dashboard: `${baseApi}`,
      schedule: `${baseApi}`,
      auth: `${authApi}`,
    },
    token: {
      auth: process.env.AUTH_TOKEN,
    },
    isProduction: false,
    isDevelopment: true,
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyAeaNRiL-eRdXQFWWeAsXZuq-kWGdG2YBg",
      authDomain: "edify-d38e2.firebaseapp.com",
      databaseURL: "https://edify-d38e2.firebaseio.com",
      projectId: "edify-d38e2",
      storageBucket: "edify-d38e2.appspot.com",
      messagingSenderId: "253945977641",
      appId: "1:253945977641:web:6e2dd8bd602feb6acdf538",
      measurementId: "G-PBEDZVS48E",
    },
  };
}

import React, { useState } from "react";
import styles from "./PortfolioAnswer.module.scss";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { answerPortfolioQuestion } from "../../../store/actions";

const PortfolioAnswer = ({ portfolioQuestions, closeModal, answerPortfolioQuestion, portfolioId}) => {
    const [answerValue, setAnswerValue] = useState("");
    const [questionId, setQuestionId] = useState(0);

    const handleSubmit = () => {
        if (answerValue) {
            answerPortfolioQuestion(questionId, answerValue, portfolioId);
            closeModal();
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.overLay}>
                <div className={styles.body}>
                    <button className={styles.closeButton} onClick={closeModal} >X</button>
                    {portfolioQuestions.map((question, index) => (
                        <div key={index} className={styles.question}>
                            <h4 className={styles.questionTitle}>Pregunta:</h4>
                            <p className={styles.questionTitle}>{question.question}</p>
                            <h4 className={styles.questionTitle}>Respuesta:</h4>
                            <div className={styles.inputContainer}>
                                <input 
                                    onChange={(e) => {setAnswerValue(e.target.value); setQuestionId(question.id)}}
                                    type="text" 
                                    className={styles.input} 
                                    placeholder="Escribí tu respuesta" />
                                <button className={styles.sendButton} onClick={handleSubmit}>Enviar</button>
                            </div>
                        </div>
                    ))}
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

const mapStateToProps = (state) => {
    return {

    };
};

export default connect(mapStateToProps, {answerPortfolioQuestion})(PortfolioAnswer);
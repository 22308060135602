import React, { useState, useEffect } from "react";
import styles from "./NewMaterialBudget.module.scss";
import { ReactComponent as Pencil } from "../../assets/svg/pencil-new.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/xClose.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Lottie from "react-lottie";
import errorData from "../../assets/lotties/errorLogo.json";
import { ReactComponent as Name } from "../../assets/svg/addPlaceholder.svg";
import { ReactComponent as Location } from "../../assets/svg/locationPlaceholder.svg";
import { ReactComponent as Date } from "../../assets/svg/datePlaceholder.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/arrowUpIcon.svg";
import Subcotizaciones from "../MarketPlace/subCotizacion/Subcotizaciones";
import { ReactComponent as DescriptionIcon } from "../../assets/svg/pencilEditIcon.svg"
import { connect } from "react-redux";
import Button from "../UI/Forms/Button/Button";
import { ReactComponent as Publicar } from "../../assets/svg/publicarProyecto.svg";
import AlertWarning from "../../assets/svg/modalAtencion.svg";
import { fireAnalytics } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";
import Loading from "../UI/Loading/Loading";
import {
  setCotizacionNueva,
  getSubquotationType,
  updateQuotation,
} from "../../store/actions";

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const NewMaterialBudget = ({
  closeModal,
  postulationQuotation,
  getSubquotationType,
  setCotizacionNueva,
  subQuotations,
  isEditing,
  subQuotationsToEdit,
  updateQuotation,
}) => {

 const navigate = useNavigate();
  const [selectedJurisdiction, setSelectedJurisdiction] = useState({
    id: "",
    name: "",
  });
  const [showJurisdiction, setShowJurisdiction] = useState(false);
  const [warningContent, setWarningContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMainModal, setShowMainModal] = useState(true);
  const [moneda, setMoneda] = useState("Pesos Arg");
  const [showMoneda, setShowMoneda] = useState(false);
  const [isInitialDifferent, setIsInitialDifferent] = useState(false);
  const [date, setDate] = useState("");
  const [finishAndPublish, setFinishAndPublish] = useState(false);

  useEffect(() => {
    getSubquotationType();
  }, []);

  //Definimos el array de jurisdicciones
  const jurisdictionArray = [
    { id: 1, name: "Buenos Aires" },
    { id: 2, name: "Catamarca" },
    { id: 3, name: "Chaco" },
    { id: 4, name: "Chubut" },
    { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
    { id: 6, name: "Córdoba" },
    { id: 7, name: "Corrientes" },
    { id: 8, name: "Entre Ríos" },
    { id: 9, name: "Formosa" },
    { id: 10, name: "Jujuy" },
    { id: 11, name: "La Pampa" },
    { id: 12, name: "La Rioja" },
    { id: 13, name: "Mendoza" },
    { id: 14, name: "Misiones" },
    { id: 15, name: "Neuquén" },
    { id: 16, name: "Río Negro" },
    { id: 17, name: "Salta" },
    { id: 18, name: "San Juan" },
    { id: 19, name: "San Luis" },
    { id: 20, name: "Santa Cruz" },
    { id: 21, name: "Santa Fe" },
    { id: 22, name: "Santiago del Estero" },
    { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
    { id: 24, name: "Tucumán" },
  ]

  const setJurisdiction = (id, name) => {
    setSelectedJurisdiction({ id, name });
    setShowJurisdiction(false);
  };
 
const initialValues = {
    name: isEditing ? postulationQuotation?.quotation?.name : "",
    description: isEditing ? postulationQuotation?.quotation?.description : "",
    endDate: isEditing ? postulationQuotation?.quotation?.endDate.slice(0, 10) : "",
    zone: isEditing ? postulationQuotation?.quotation?.zone : "",
    startDate: isEditing ? postulationQuotation?.quotation?.startDate.slice(0, 10) : "",
    moneda: isEditing ? postulationQuotation?.quotation?.moneda : moneda,
    jurisdiction: isEditing ? postulationQuotation?.quotation?.jurisdiccion.id : selectedJurisdiction.id,
  };

  const CotizacionSchema = Yup.object().shape({
    name: Yup.string().required("*requerido"),
    description: Yup.string().required("*requerido"),
    endDate: Yup.date().required("*requerido"),
    startDate: Yup.date().required("*requerido"),
    jurisdiction: Yup.string().required("*requerido"),
  }); 

const formik = useFormik({
    initialValues,
    validationSchema: CotizacionSchema,
    validateOnMount: true,
     
  });
  
  useEffect(() => {
    const isDifferent = Object.keys(formik.initialValues).some(
      key => formik.initialValues[key] !== formik.values[key]
    );
    setIsInitialDifferent(isDifferent);
  }, [formik.values, formik.initialValues]);

  useEffect(() => {
    if (subQuotationsToEdit.length > 0 && !isEditing && isInitialDifferent) {
      setFinishAndPublish(true);
    } else {
      setFinishAndPublish(false);
    }
  }, [subQuotationsToEdit, isInitialDifferent])

  const submitHandler = () => {
    setWarningContent(true);
    fireAnalytics("publishing_create_quotation", { category: "navigation" });
    //isEditing ? updateQuotation(values) : sendNewQuotation(values);

  };

  const handleUpdate = (values, quotationId, isEditing) => {
    updateQuotation(values, quotationId, isEditing);
    closeModal && closeModal();
  };

  const sendNewQuotation = (values) => {
    setLoading(true);
    const newQuotation = {
      ...values,
    };

    if (
      subQuotationsToEdit.length &&
      values.description !== "" &&
      values.endDate !== "" &&
      values.name !== "" &&
      selectedJurisdiction.name !== ""
    ) {
      fireAnalytics("publish_quotation", { category: "publishing" });
      setCotizacionNueva(newQuotation, subQuotationsToEdit, navigate)

      setTimeout(() => {
        setLoading(false);
        closeModal && closeModal();
      }, 2000);
    }
  };

  return (
    <section className={`${styles.wrapper} ${showMainModal ? "" : styles.hideComponent}`}>
      {warningContent ? (
        <div className={styles.warningBody}>
          <div className={styles.warningBody_header}>
            <div className={styles.warningBody_header_title}>Atención</div>
          </div>
          <div className={styles.warningBody_textContainer}>
            <div className={styles.warningBody_containerIcon}>
              <img className={styles.warningBody_containerIcon_image} src={AlertWarning} alt="Alerta" />
            </div>
            <div className={styles.warningBody_title}>¿Estás seguro que deseas publicar tu solicitud de cotización de materiales?</div>
            <div className={styles.warningBody_subTitle}>La misma estará abierta a toda la comunidad y disponible para su descarga.</div>
            <div className={styles.warningBody_buttonsContainer}>
              <div onClick={() => setWarningContent(false)} className={styles.warningBody_buttonsContainer_no}>Volver</div>
              <div className={styles.warningBody_buttonsContainer_yes} onClick={() => sendNewQuotation(formik.values)}>Publicar</div>
            </div>
          </div>
          {loading && <Loading />}
        </div>
      ) : (
        <>
          <header className={styles.top}>
            <span className={styles.title}>
              <Pencil width={15} height={15} />
              {isEditing ? "Editar solicitud de cotización de materiales" : "Crear solicitud de cotización de materiales"}
            </span>
            <span
              className={styles.close}
              onClick={() => closeModal && closeModal()}
            >
              <CloseIcon width={15} height={15} />
            </span>
          </header>
          <form className={styles.content}>
            <div className={styles.formGroup}>
              <div className={styles.labelName}>NOMBRE DE LA COTIZACIÓN
              </div>
              <div className={styles.iconGroup}>
                <Name />
                <input
                  className={`${styles.input} ${formik.errors.name && styles.errorBorder}`}
                  name={"name"}
                  placeholder="Ej: Hormigón para losa"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name ? (
                  <div className={styles.error}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.jurisdiction}>
              <div className={styles.jurisdiction_label}>UBICACIÓN</div>
              <div className={styles.jurisdiction_input} onClick={() => setShowJurisdiction(!showJurisdiction)}>
                <div className={styles.jurisdiction_svgContainer}>
                  <Location className={styles.jurisdiction_svg} />
                  <p className={styles.jurisdiction_svgContainer_info}>
                  {isEditing && selectedJurisdiction.name === "" ? postulationQuotation?.quotation?.jurisdiccion.name :
                    selectedJurisdiction.name ? selectedJurisdiction.name : "Seleccionar provincia"}
                    </p>
                </div>
                {showJurisdiction ? (
                  <div className={styles.jurisdiction_arrowContainer}>
                    <ArrowUp width={15} height={15} />
                  </div>) : (<div className={styles.jurisdiction_arrowContainer}>

                    <ArrowDown width={15} height={15} />
                  </div>)}
                {showJurisdiction && (
                  <div className={styles.jurisdiction_options}>
                    {jurisdictionArray.map((jurisdiction) => (
                      <div className={styles.jurisdiction_options_item} key={jurisdiction.id}

                        onClick={() => {
                          setJurisdiction(jurisdiction.id, jurisdiction.name);
                          formik.setFieldValue("jurisdiction", jurisdiction.id)
                        }}>
                        {jurisdiction.name}
                      </div>
                    ))}
                  </div>
                )}
                {formik.errors.jurisdiction ? (
                  <div className={styles.error_jurisdiction}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.labelName}>CIUDAD</label>
              <div className={styles.iconGroup}>
                <Location />
                <input
                  className={styles.input}
                  name={"zone"}
                  placeholder="Ej: Rio Cuarto"
                  onChange={formik.handleChange}
                  value={formik.values.zone}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                ¿DESDE CUANDO QUERÉS RECIBIR COTIZACIONES?
              </label>
              <div className={styles.iconGroup}>
                <Date />
                <input
                  type="date"
                  className={`${styles.input} ${formik.errors.startDate && styles.errorBorder}`}
                  name={"startDate"}
                  placeholder="22/11/2024"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                />
                {formik.errors.startDate ? (
                  <div className={styles.error}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                ¿HASTA CUANDO QUERÉS RECIBIR COTIZACIONES?
              </label>
              <div className={styles.iconGroup}>
                <Date />
                <input
                  type="date"
                  className={`${styles.input} ${formik.errors.endDate && styles.errorBorder}`}
                  name={"endDate"}
                  placeholder="22/11/2024"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                />
                {formik.errors.endDate ? (
                  <div className={styles.error}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.moneda}>
              <div className={styles.moneda_label}>MONEDA</div>
              <div className={styles.moneda_input} onClick={() => setShowMoneda(!showMoneda)}>
                <div className={styles.moneda_svgContainer}>
                  <Location className={styles.moneda_svg} />
                  <p className={styles.moneda_svgContainer_info}>
                  {moneda ? moneda : "Seleccionar moneda"}
                  </p>
                </div>
                {showMoneda ? (
                  <div className={styles.moneda_arrowContainer}>
                    <ArrowUp width={15} height={15} />
                  </div>) : (<div className={styles.moneda_arrowContainer}>

                    <ArrowDown width={15} height={15} />
                  </div>)}
                {showMoneda && (
                  <div className={styles.moneda_options}>
                    <div className={styles.moneda_options_item} onClick={() => { setMoneda("Pesos Arg"); formik.setFieldValue("moneda", "ARS") }}>Pesos Arg</div>
                    <div className={styles.moneda_options_item} onClick={() => { setMoneda("Dolar"); formik.setFieldValue("moneda", "USD") }}>Dolar</div>
                  </div>
                )}
              </div>
            </div>
            
            <div className={`${styles.fullContent} ${styles.description}`}>
              <span className={styles.contentTitleDescription}>
                AÑADIR DESCRIPCIÓN
              </span>
              <div className={styles.descriptionText}>
                <DescriptionIcon width={15} height={15} className={styles.descriptionText_icon}/>
                <textarea
                  type="textarea"
                  className={styles.defaultControl}
                  name={"description"}
                  placeholder="Añade un breve descripción."
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                />
                {formik && formik.errors.description ? (
                  <div className={styles.error_description}>
                    <Lottie
                      options={{
                        animationData: errorData,
                        ...defaultOptions,
                      }}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.fullContent}>
              <span className={styles.contentTitle}>
                SELECCIONAR RUBRO A COTIZAR: 
                {!isEditing && subQuotationsToEdit?.length === 0 && (
                  <div className={styles.error_subQuotations}>
                  <Lottie
                    options={{
                      animationData: errorData,
                      ...defaultOptions,
                    }}
                    height={30}
                    width={30}
                  />
                </div>)}
              </span>
              {isEditing ? (
                <Subcotizaciones
                  subprojectsEdit={postulationQuotation?.subquotation}
                  isEditing
                  projectIdForSub={postulationQuotation?.quotation?.id}
                />
              ) : (
                <Subcotizaciones isEditing={false}/>
              )}
            </div>
            {finishAndPublish && (
              Object.keys(formik.errors).length > 0 ? (
                <div className={styles.fullContent}>
                  <Button className={styles.buttonPublishQuotation} disabled>
                    <span className={styles.submit}>
                      <Publicar />
                      Completar los datos obligatorios
                    </span>
                  </Button>
                </div>
              ) : (
                <div onClick={() => submitHandler(formik.values)} className={styles.fullContent}>
                  <Button className={styles.buttonPublishQuotation}>
                    <span className={styles.submit}>
                      
                      Publicar cotización de Materiales
                    </span>
                  </Button>
                </div>
              )
            )}
            {formik.dirty && isEditing && (
              <div className={styles.buttonsEditContainer}>
                <div className={styles.buttonsEditContainer_btnCancel} onClick={() => closeModal && closeModal()}>
                  Cancelar
                </div>
                <div className={styles.buttonsEditContainer_btnAccept} onClick={() => handleUpdate(formik.values, postulationQuotation?.quotation?.id, isEditing)}>
                  <Publicar />
                Guardar
              </div>
              </div>
            )}
          </form>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {

  return {
    subQuotations: state.profile.subQuotations,
    subQuotationsToEdit: state.profile.sendSubQuotations,
    postulationQuotation: state.profile.quotationToEdit,

  };
};

export default connect(mapStateToProps, {
  getSubquotationType,
  setCotizacionNueva,
  updateQuotation,
})(NewMaterialBudget);

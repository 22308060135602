import { useState, useEffect } from "react";

export default function useFileUpload(image, fileHandler) {
  const [imageFile, setImageFile] = useState(image);
  const [fileName, setFileName] = useState("");

  const imageChangeHandler = (event) => {
    if (event.target.files && event.target.files[0] && event.target.files[0].size < 50000000) {
      if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImageFile(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      setFileName(event.target.files[0].name);
      fileHandler(event.target.files[0]);
    }
  };

  useEffect(() => {
    setImageFile(image);
    return () => {
      setImageFile(null);
    };
  }, [image]);



  return [imageFile, imageChangeHandler, fileName];
}

import React, { useState, useEffect, useRef } from "react";
import styles from "./HeaderNav.module.scss";
import { connect } from "react-redux";
import Logo from "../../assets/images/logoBig.png";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import Modal from "../../components/Modal/Modal";
import NewProject from "../../components/NewProject/NewProject";
import NewMaterialBudget from "../../components/NewMaterialBudget/NewMaterialBudget";
import SolicitudesWrapper from "../Solicitudes/SolicitudesWrapper/SolicitudesWrapper";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ReactComponent as Search } from "../../assets/svg/misProyectosLittle.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplace.svg";
import { ReactComponent as ProyectosActive } from "../../assets/svg/nav-proyectos-active.svg";
import { ReactComponent as Faq } from "../../assets/svg/FAQ.svg";
import { ReactComponent as FaqActive } from "../../assets/svg/FAQActive.svg";
import { ReactComponent as Notif } from "../../assets/svg/nav-notif.svg";
import { ReactComponent as NotifActive } from "../../assets/svg/nav-notif-active.svg";
import { ReactComponent as WorkOffer } from "../../assets/svg/trabajadoresIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CreatePublicationIcon } from "../../assets/svg/crearPublicacion.svg";
import {
    closeSuscriptionModal,
    setActivateFreeTrial,
    openSuscriptionModal,
    openFeedTextModal,
    resetProjectToEdit,
    userInfoNavStyle,
    setProjectFormStyle,
    openWorkOfferModal,
    closeWorkOfferModal,
} from "../../store/actions";
import Tooltip from "../UI/ToolTip/ToolTip";



const HeaderNav = ({
    userData,
    isUserPremium,
    openFeedTextModal,
    openSuscriptionModal,
    openWorkOfferModal,
    resetProjectToEdit,
    userInfoNavStyle,
    newNotifications,
}) => {
    const [modalPublication, setModalPublication] = useState(false);
    const [isOpenProject, setModalOpenProject] = useState(false);
    const [isOpenMaterialBudget, setModalOpenMaterialBudget] = useState(false);
    const [changedLocation, setChangedLocation] = useState(false);
    const [isActive, setIsActive] = useState(null);
    const [showNotiications, setShowNotifications] = useState(false);
    const [showToolTip, setShowToolTip] = useState({
        notifications: {show: false, text: "Notificaciones"},
        faq: {show: false, text: "Ayuda"},
        createPublication: {show: false, text: "Crear publicación"},
    });
    const navigate = useNavigate();
    const notificationRef = useRef(null);

    const handleClickOutside = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setShowNotifications(false); 
        }
    };

    useEffect(() => {
        if (showNotiications) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showNotiications]);

    const handleHelp = () => {
        setModalPublication(false);
        navigate("/faq");
    };

    const handleClose = () => {
        setModalPublication(false);
        userInfoNavStyle(false)
    }

    const handleOpenModalPublication = () => {
        setProjectFormStyle(false);
        resetProjectToEdit();
        setModalPublication(true);
        userInfoNavStyle(false);
    };

    const handleNewOpenProject = () => {
        if (isUserPremium) {
            setModalOpenProject(true);
        } else {
            openSuscriptionModal();
        }
    };

    const handleNewMaterialBudget = () => {
        if (isUserPremium) {
            setModalOpenMaterialBudget(true);
        } else {
            openSuscriptionModal();
        }
    };

    const handleNewFeedPost = () => {
        if (isUserPremium) {
            openFeedTextModal();
        } else {
            openSuscriptionModal();
        }
    };


    const completeName =
        userData.type === "COMPANY"
            ? userData.razon_social
            : `${userData.name} + " " + ${userData.last_name}`;

    const handleRedirect = (title) => {
        setChangedLocation(!changedLocation);
        if (title === "Perfil") {
            userInfoNavStyle(false)
            navigate(`/profesionales/${userData.id}/${completeName}`);
        } if (title === "notificaciones") {
            navigate("/solicitudes");
        } if (title === "FAQ") {
            navigate("/faq");
        }
    }

    useEffect(() => {
        if (
            navigate?.location?.pathname ===
            `/profesionales/${userData.id}/${completeName}`
        ) {
            setIsActive("Perfil");
        } if (
            navigate?.location?.pathname === "/solicitudes"
        ) {
            setIsActive("notificaciones");
        } if (
            navigate?.location?.pathname === "/faq"
        ) {
            setIsActive("FAQ");
        } else if (
            navigate?.location?.pathname !== "/solicitudes" &&
            navigate?.location?.pathname !== `/profesionales/${userData.id}/${completeName}` &&
            navigate?.location?.pathname !== "/faq"
        ) {
            setIsActive(null);
        }
    }, [navigate?.location?.pathname]);

    //CARDS

    const modalPublicationContent = [
        {
            title: "Obra privada",
            logo: <Search width={30} height={30} />,
            items: [
                "Indica el lugar, las fechas y una descripción.",
                "Selecciona uno o más rubros para los que desees recibir presupuestos.",
                "Adjunta planos, especificaciones técnicas y cómputo métrico.",
            ],
            handleClick: handleNewOpenProject,
            color: "#234BA2",
            border: "2px solid #234BA2",
        },
        {
            title: "Cotización de materiales",
            logo: <MarketPlace width={30} height={30} className={styles.cardLogo_quotation} />,
            items: [
                "Indica el nombre y lugar.",
                "Programa las fechas para la recepción de presupuestos.",
                "Selecciona un rubro y adjunta un archivo Excel con la lista de materiales a cotizar.",
            ],
            handleClick: handleNewMaterialBudget,
            color: "#45A7FF",
            border: "2px solid #45A7FF",
        },
        {
            title: "Artículo en el muro",
            logo: <ProyectosActive width={30} height={30} />,
            items: [
                "Comparte un artículo, noticia o novedad con la comunidad.",
                "Puedes adjuntar imágenes, videos o solo texto.",
                "Recibe comentarios, recomienda publicaciones y conecta con otros usuarios.",
            ],
            handleClick: handleNewFeedPost,
            color: "#7000FF",
            border: "2px solid #7000FF",
        },
        {
            title: "Oferta de trabajo",
            logo: <WorkOffer width={30} height={30} className={styles.cardLogo_workOffer} />,
            items: [
                "Selecciona el lugar, la fecha y el rubro que estás buscando.",
                "Puedes agregar condiciones salariales y requisitos.",
                "Finalmente, elige el modo de contacto y publica tu oferta.",
            ],
            handleClick: openWorkOfferModal,
            color: "#22af86",
            border: "2px solid #22af86",
        },
    ];

    const handleTooltip = (tooltip) => {
        setShowToolTip((prevState) => ({
            ...prevState,
            [tooltip]: { ...prevState[tooltip], show: !prevState[tooltip].show },
        }));
    };
    return (
        <nav className={styles.header}>
            <div className={styles.logoContainer}>
                <img src={Logo} alt="Logo" className={styles.logoContainer_logo} />
            </div>
            <div className={styles.navIcons}>
                <div className={styles.nav_navItem}>
                        <button className={styles.nav_navItem_createButton} onClick={() => handleOpenModalPublication()}>
                            <CreatePublicationIcon width={15} height={15} />
                            Crear publicación                           
                        </button>
                    </div>
                <div className={`${styles.navIcons_navItemIcon} ${isActive === "FAQ" && styles.isActive}`} 
                    onMouseEnter={() => handleTooltip("faq")}
                    onMouseLeave={() => handleTooltip("faq")}
                    onClick={() => handleRedirect("FAQ")}>
                    <div className={styles.navIcons_navItemIcon_notification}>
                        {isActive === "FAQ" ? (
                            <FaqActive width={20} height={20} />
                        ) : (
                            <Faq width={20} height={20} />
                        )}
                    </div>
                    {showToolTip.faq.show && (
                        <Tooltip text={showToolTip.faq.text} />
                    )}
                </div>
                <div className={`${styles.navIcons_navItemIcon} ${isActive === "notificaciones" && styles.isActive}`} 
                    onMouseEnter={() => handleTooltip("notifications")}
                    onMouseLeave={() => handleTooltip("notifications")}
                    onClick={() => setShowNotifications(!showNotiications)}>
                    <div className={styles.navIcons_navItemIcon_notification}>
                        {newNotifications > 0 && (
                            <div className={styles.navIcons_navItemNotification}>
                                {newNotifications}
                            </div>
                        )}
                        {isActive === "notificaciones" ? (
                            <NotifActive width={20} height={20} />
                        ) : (
                            <Notif width={20} height={20} />
                        )}
                    </div>
                    {showNotiications && (
                        <div ref={notificationRef}>
                            <SolicitudesWrapper />
                        </div>
                    )}
                    {showToolTip.notifications.show && (
                        <Tooltip text={showToolTip.notifications.text} fromNav={false} fromHeaderNav />
                    )}
                </div>

            </div>
            {modalPublication && (
                <motion.div
                    className={styles.modal}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className={styles.modal_container}>
                        <div
                            className={styles.modal_container_close}
                            onClick={handleClose}
                        >
                            Cancelar
                        </div>
                        <div className={styles.modal_container_body}>
                            <div className={styles.modal_container_options}>
                                <div className={styles.modal_container_title}>
                                <FontAwesomeIcon icon={faSquarePlus} />
                                    ¿Qué tipo de publicación querés crear?
                                </div>
                                <div className={styles.modal_container_options_container}>
                                    {modalPublicationContent.map((content, index) => (
                                        <motion.div
                                            key={index}
                                            className={styles.card}
                                            onClick={() => {
                                                content.handleClick();
                                                setModalPublication(false);
                                            }}
                                        >
                                            <div className={styles.cardInner}>
                                                <div className={styles.cardFront} style={{border: content.border}}>
                                                    {content.logo}
                                                    <div className={styles.cardTitle}
                                                        style={{ color: content.color }}
                                                    >{content.title}</div>
                                                </div>
                                                <div className={styles.cardBack} style={{ backgroundColor: content.color }}>
                                                    <ul className={styles.cardItems}>
                                                        {content.items.map((item, i) => (
                                                            <li key={i}>{item}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.modal_container_footer}
                            onClick={handleHelp}
                        >
                            ¿Necesitas ayuda?
                            <Faq width={15} height={12} />
                        </div>
                    </div>
                </motion.div>
            )}
            <Modal isOpen={isOpenMaterialBudget}>
                <NewMaterialBudget
                    closeModal={() => setModalOpenMaterialBudget(false)}
                />
            </Modal>
            <Modal isOpen={isOpenProject}>
                <NewProject closeModal={() => setModalOpenProject(false)} />
            </Modal>
        </nav>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        isUserPremium: state.general.isUserPremium,
        newNotifications: state.dashboard.newNotifications,
        solicitudesList: state.dashboard.solicitudesList,
    };
}

export default connect(mapStateToProps, {
    closeSuscriptionModal,
    setActivateFreeTrial,
    openFeedTextModal,
    openSuscriptionModal,
    resetProjectToEdit,
    userInfoNavStyle,
    openWorkOfferModal,
    closeWorkOfferModal,
})(HeaderNav);
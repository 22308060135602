import React from "react";
import styles from "./MainModal.module.scss";
import Logo from "../../../assets/images/logo.png";
import { closeMainModal } from "../../../store/actions/profileActions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as Publicar } from "../../../assets/svg/publicarProyecto.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error.svg";
import { ReactComponent as EmailIcon } from "../../../assets/svg/email.svg";
import { ReactComponent as WarningIcon } from "../../../assets/svg/modalAtencion.svg";

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
    scale: 1.1,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
    scale: 1.0,
  },
};


const MainModal = ({ closeMainModal, mainModalConfig }) => {

  const {
    isSuccess,
    title,
    body,
    confirmHandler,
    customCloseMainModal,
    yesLabel = "si",
    noLabel = "Volver",
    noActionNeeded,
    iconType,
  } = mainModalConfig;

  function handleGoBack() {
    if (customCloseMainModal) {
      customCloseMainModal();
    } else {
      closeMainModal();
    }
  }

  const icon = (type, width = "100px", height = "100px") => {
    switch (type) {
      case "success":
        return <SuccessIcon width={width} height={height} />;
      case "error":
        return <ErrorIcon width={width} height={height} />;
      case "email":
        return <EmailIcon width={150} height={height} />;
      case "warning":
        return <WarningIcon width={width} height={height} />;
      default:
        return null;
    }
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="1"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.MainModal_overlay}
          onClick={closeMainModal}
        >
          <motion.div
            key="2"
            variants={modal}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={styles.MainModal}
          >
            <div className={styles.edifyIcon}>
              <img src={Logo} alt="Edify" className={styles.edifyIcon_img} />
            </div>
            <div className={styles.MainModal_body}>
              
              <h3>{title}</h3>
              {iconType && (
               <div className={styles.iconContainer}>
               {icon(iconType, "100px", "100px")} 
             </div>
              )}
              <div className={styles.bodyTxt}>{body}</div>
              {isSuccess && noActionNeeded ? (
                null
              ) : (
                <div className={styles.MainModal_options}>
                  {isSuccess ? (
                    <div
                      className={styles.MainModal_optionsitem_success}
                      onClick={() => { handleGoBack(); }}
                    >
                      {noLabel}
                    </div>
                  ) : (
                    <>
                      <div
                        className={styles.MainModal_optionsitem}
                        onClick={confirmHandler}
                      >
                        {" "}
                        <span className={styles.margin}>
                          <Publicar />{" "}
                        </span>
                        {yesLabel}

                      </div>
                      <div
                        className={styles.MainModal_optionsitem_decline}
                        onClick={() => handleGoBack()}
                      >
                        {noLabel}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    mainModalConfig: state.general.mainModalConfig,
  };
};

export default connect(mapStateToProps, { closeMainModal })(MainModal);

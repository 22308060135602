import React, { useState } from "react";
import styles from "./WorkOfferAplicationSolicitud.module.scss";
import { connect } from "react-redux";
import { setViewNotification, deleteNotification } from "../../../../store/actions";
import LoadingDots from "../../../UI/Loading/LoadingDots";
import { ReactComponent as TrashIcon } from "../../../../assets/svg/trashIcon.svg";
import { ReactComponent as DownloadCloudIcon } from "../../../../assets/svg/downloadCloudIcon.svg";
import { ReactComponent as WhatsAppIcon } from "../../../../assets/svg/whatsAppIcon.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/svg/gmailIcon.svg";
import { ReactComponent as TrabajadoresIcon } from "../../../../assets/svg/trabajadoresIcon.svg";

const WorkOfferAplicationSolicitud = ({ contact, setViewNotification, deleteNotification }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { view, createdAt } = contact;
    const { coverLetter, cv, name, phone, email } = contact?.jobPostulation;

    const handleSetViewNotification = () => {
        setIsLoading(true);
        setViewNotification(contact?.id);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    }

    const handleDeleteNotification = () => {
        deleteNotification(parseInt(contact?.id));
    }

    const formatTimeFromNow = (time) => {
        const date = new Date(time);
        const now = new Date();
        const diff = now - date;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (seconds < 60) {
            return `${seconds === 1 ? '1 segundo' : `${seconds} segundos`}`;
        } else if (minutes < 60) {
            return `${minutes === 1 ? '1 minuto' : `${minutes} minutos`}`;
        } else if (hours < 24) {
            return `${hours === 1 ? '1 hora' : `${hours} horas`}`;
        } else if (days < 30) {
            return `${days === 1 ? '1 día' : `${days} días`}`;
        } else if (months < 12) {
            return `${months === 1 ? '1 mes' : `${months} meses`}`;
        } else {
            return `${years === 1 ? '1 año' : `${years} años`}`;
        }
    }

    const handleWhatsAppClick = () => {
        // Generar la URL de WhatsApp
        const messageToSend = "Hola! Recibí tu postulación a la oferta laboral en Edify, me interesa tu perfil. ¿Podemos agendar una reunión?";
        const whatsAppURL = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phone)}&text=${encodeURIComponent(messageToSend)}`;
        // Abrir la ventana de WhatsApp en una nueva pestaña
        window.open(whatsAppURL, '_blank');
    }

    const handleEmailClick = () => {
        // Generar la URL de correo electrónico
        const emailSubject = encodeURIComponent("Re: Contacto desde edify.la");
        const emailBody = encodeURIComponent("Hola! Recibí tu postulación a la oferta laboral en Edify, me interesa tu perfil. ¿Podemos agendar una reunión?");
        const emailURL = `mailto:${encodeURIComponent(name)}?subject=${emailSubject}&body=${emailBody}`;
        // Abrir la ventana de correo electrónico en una nueva pestaña
        window.open(emailURL, '_blank');
    }

    return (
        <div className={styles.container}>
            <header className={styles.container_header}>
                <div className={styles.container_header_title}>
                    <TrabajadoresIcon width={20} height={20} />
                    <div className={styles.container_header_title_txt}>
                        Tenemos un nuevo candidato para tu oferta de trabajo
                    </div>
                </div>
                <div className={styles.container_buttons} title="Eliminar notificación">
                    {view === null && !isLoading &&
                        <div className={styles.container_isViewed} title="Marcar como vista" onClick={() => handleSetViewNotification()}>
                            <div className={styles.container_isViewed_checked}></div>
                        </div>
                    }

                    {isLoading && <div className={styles.container_isViewed}> <LoadingDots blueDots /> </div>}
                    <div className={styles.container_buttons_trash} onClick={() => handleDeleteNotification()}>
                        <TrashIcon width={20} height={20} />
                    </div>
                </div>
            </header>
            <div className={styles.container_body}>
                <div className={styles.container_body_message}>
                    <div className={styles.container_body_message_subTitle}>
                        <div className={styles.container_body_message_txt}>
                            <b className={styles.container_body_message_user}>{name}&nbsp;</b>
                            aplicó para tu oferta de trabajo. Esta es su información de contacto:
                            {cv &&
                                <div className={styles.downloadContainer} title="Descargar CV">
                                    <a
                                        className={styles.download}
                                        href={cv}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => setViewNotification(contact?.id)}
                                    >
                                        <DownloadCloudIcon width={25} height={25} />
                                    </a>
                                    <p>Descargar CV</p>
                                </div>
                            }
                            {phone &&
                                <div className={styles.container_body_message_contact} onClick={handleWhatsAppClick}>
                                    <WhatsAppIcon width={25} height={25} title="Enviar WhatsApp" className={styles.container_body_message_contact_whatsApp} />
                                    <p>{phone}</p>
                                </div>}
                            {email &&
                                <div className={styles.container_body_message_contact} onClick={handleEmailClick}>
                                    <EmailIcon width={25} height={25} title="Enviar mail" />
                                    <p>{email}</p>
                                </div>}
                            {coverLetter && <p className={styles.container_body_coverLetter}>"{coverLetter}"</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container_body_message_buttons}>
                <p>Hace &nbsp;{formatTimeFromNow(createdAt)}</p>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {

    };
}

export default connect(mapStateToProps, { setViewNotification, deleteNotification })(WorkOfferAplicationSolicitud);
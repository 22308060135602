import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './CarouselInitialWrapper.module.scss';
import Imagen4 from '../../../../assets/images/InitialWrapperCarouselImg/Carousel_4.png';
import Imagen1 from '../../../../assets/images/InitialWrapperCarouselImg/Carousel_1.png';
import Imagen2 from '../../../../assets/images/InitialWrapperCarouselImg/Carousel_2.png';
import Imagen3 from '../../../../assets/images/InitialWrapperCarouselImg/Carousel_3.png';

const slides = [
  {
    title: "El Partner Perfecto para tu Proyecto",
    description: ["Conectá con socios y proveedores en todo el país para hacer realidad tu proyecto."],
    image: Imagen1
  },
  {
    title: "Calculadora de Costos de Construcción",
    description: ["Obtené estimaciones precisas en segundos: materiales, proveedores y logística a tu alcance."],
    image: Imagen2
  },
  {
    title: "Muro de Publicaciones",
    description: [
      "Descubrí licitaciones, proyectos, proveedores y más.",
      "¡No te pierdas ninguna oportunidad!"
    ],
    image: Imagen3
  },
  {
    title: "Bolsa de Trabajo Nacional",
    description: ["Conectamos a quienes buscan talento con quienes necesitan trabajo en todo el país."],
    image: Imagen4
  }
];

const CarouselInitialWrapper = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoadedCount, setImagesLoadedCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleImageLoad = () => {
    setImagesLoadedCount((count) => count + 1);
  };

  const allImagesLoaded = imagesLoadedCount === slides.length;

  return (
    <motion.div
      className={styles.carousel}
      initial={{ opacity: 0 }}
      animate={{ opacity: allImagesLoaded ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <div className={styles.sliderWrapper}>
        <motion.div
          className={styles.slider}
          animate={{ x: -currentIndex * 100 + "%" }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          {slides.map((slide, index) => (
            <div key={index} className={styles.slide}>
              <div className={styles.slide_imgContainer}>
                <img 
                  src={slide.image} 
                  alt={slide.title} 
                  className={styles.slide_img} 
                  onLoad={handleImageLoad} 
                />
                <div className={styles.textContent}>
                  <h2 className={styles.textContent_h2}>{slide.title}</h2>
                  {slide.description.map((text, idx) => (
                    <p key={idx} className={styles.textContent_p}>{text}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      </div>

      <div className={styles.indicators}>
        {slides.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${index === currentIndex ? styles.active : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default CarouselInitialWrapper;



import React, { useEffect, useState, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSignOutAlt,
  faBars,
  faCheckCircle,
  faUserCheck,
  faImage,
  faTimesCircle,
  faClock,
  faTimes,
  faExpand
} from "@fortawesome/free-solid-svg-icons";
import "./backofficeLayout.scss";
import { connect } from "react-redux";
import { fetchIdentityPhotos, validatedIdentityWorker } from "../../store/actions/registerActions";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const ITEMS_PER_PAGE = 5;

const BackofficeLayout = ({ fetchIdentityPhotos, workBagUsersToValidate, validatedIdentityWorker }) => {
  const modalRef = useRef(null);
  const [users, setUsers] = useState(workBagUsersToValidate || []);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleVerify = (userId, isValid) => {
    validatedIdentityWorker(userId, isValid);
    fetchIdentityPhotos();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchIdentityPhotos();
      setUsers(workBagUsersToValidate);
    }, 5000);
    return () => clearTimeout(timer);

  }, [workBagUsersToValidate]);

  const totalPages = useMemo(() => {
    return Math.ceil(users.length / ITEMS_PER_PAGE);
  }, [users]);

  const paginatedUsers = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return users.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [currentPage, users]);

  const openModal = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const handleFullscreen = () => {
    if (modalRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen(); // Salir del modo pantalla completa
      } else {
        modalRef.current.requestFullscreen(); // Activar pantalla completa
      }
    }
  }

  return (
    <div className="backoffice-layout">
      <aside className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Backoffice</h2>
        </div>
        <nav>
          <button className="sidebar-button">
            <FontAwesomeIcon icon={faUsers} />
            Users
          </button>
        </nav>
        
      </aside>

      <div className="main-content">
        <header>
          <div className="header-content">
            <h1>User Management</h1>
            <button className="menu-button" onClick={() => setSidebarOpen(!sidebarOpen)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </header>
        <main>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Verified</th>
                  <th>Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers && paginatedUsers?.map((user, index) => (

                  <tr key={index} >
                    <td>{user.jobBoard?.id}</td>
                    <td>{user.jobBoard?.name}</td>
                    <td>{user.jobBoard?.email}</td>

                    <td>
                      {user.jobBoard?.identity_validated === true ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="verified-icon" />
                      ) : user.jobBoard?.identity_validated === false ? (
                        <FontAwesomeIcon icon={faTimesCircle} className="rejected-icon" />
                      ) : (
                        <FontAwesomeIcon icon={faClock} className="pending-icon" />
                      )}
                    </td>
                    <td>
                      <button className="view-image-button" onClick={() => openModal(user)}>
                        <FontAwesomeIcon icon={faImage} />
                        View
                      </button>
                    </td>
                    <td>

                      {user.jobBoard?.identity_validated === null ? (
                        <>
                          <button
                            className="verify-button"
                            onClick={() => handleVerify(user.jobBoard?.id, true)}
                          >
                            <FontAwesomeIcon icon={faUserCheck} /> Aceptar
                          </button>
                          <button
                            className="reject-button"
                            onClick={() => handleVerify(user.jobBoard?.id, false)}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Rechazar
                          </button>
                        </>
                      ) : (
                        <button className="verify-button" disabled>
                          <FontAwesomeIcon icon={faUserCheck} /> Aceptar
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </main>
      </div>

      {modalOpen && selectedUser && (
        <>

          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal" ref={modalRef}>
            <div className="modal-content">
              <button className="close-button" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h2>{selectedUser.jobBoard.name}'s Identity Photos</h2>
              <Carousel
                showArrows={true}
                useKeyboardArrows={true}
                infiniteLoop={true}
                emulateTouch={true}
                dynamicHeight={true}
                showStatus={false}
                className="custom-carousel"
              >
                <div>
                  <img src={selectedUser.dni_front} alt="DNI Front" />
                  <p className="legend">DNI Front</p>
                </div>
                <div>
                  <img src={selectedUser.dni_back} alt="DNI Back" />
                  <p className="legend">DNI Back</p>
                </div>
                <div>
                  <img src={selectedUser.selfie} alt="Selfie" />
                  <p className="legend">Selfie</p>
                </div>
              </Carousel>

              <button className="fullscreen-button" onClick={handleFullscreen}>
                <FontAwesomeIcon icon={faExpand} />
              </button>
            </div>
          </div>
        </>)}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    workBagUsersToValidate: state.register.workBagUsersToValidate,
  };
};


export default connect(mapStateToProps, { fetchIdentityPhotos, validatedIdentityWorker })(BackofficeLayout);

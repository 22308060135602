import { fileTypeFromBlob } from 'file-type';

// Utility function to download files
export const downloadFile = (data, fileName, format) => {
    let blob;

    if (format === 'csv') {
        // Convert JSON data to CSV string
        const csvData = data.map(row => Object.values(row).join(',')).join('\n');
        const encodedData = new TextEncoder().encode(csvData);
        blob = new Blob([encodedData], { type: 'text/csv' });
    } else if (format === 'xlsx') {
        // Convert JSON data to Excel string using plain CSV as a placeholder
        const xlsData = data.map(row => Object.values(row).join('\t')).join('\n');
        const encodedData = new TextEncoder().encode(xlsData);
        blob = new Blob([encodedData], { type: 'application/vnd.ms-excel' });
    }

    // Determine the file type using file-type library
    fileTypeFromBlob(blob).then((result) => {
        if (result) {
            console.log(`Detected MIME type: ${result.mime}`);
        }

        // Create a link and download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.${format}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};

import React from "react";
import styles from "./LoadingDots.module.scss";

const LoadingDots = ({blackDots, blueDots}) => {
    return (
        <div className={styles.dots}>
            <div className={`${styles.dots_dot}  ${blackDots && styles.blackDots} ${blueDots && styles.blueDots}`}></div>
            <div className={`${styles.dots_dot}  ${blackDots && styles.blackDots} ${blueDots && styles.blueDots}`}></div>
            <div className={`${styles.dots_dot}  ${blackDots && styles.blackDots} ${blueDots && styles.blueDots}`}></div>
        </div>
    );
};

export default LoadingDots;
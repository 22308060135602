import React, { useState, useEffect, useRef } from "react";
import styles from "./SubQuotationItem.module.scss";
import {ReactComponent as Edit} from "../../../assets/svg/editarSkill.svg"
import {ReactComponent as Delete} from "../../../assets/svg/description-sub.svg";
import { connect } from "react-redux";
import { setEditSendSubProject,
        updateSubCotizacion,
        setSendSubQuotation, 
        deleteSubQuotationFromReducer,
        openMainModal,
        closeMainModal, 
        deleteSubQuotation,
        getQuotationById} from "../../../store/actions/index";
import { ReactComponent as Description } from "../../../assets/svg/addDescriptionPlaceholder.svg";
import { ReactComponent as Upload } from "../../../assets/svg/upload-sub.svg";
import { ReactComponent as Trash } from "../../../assets/svg/description-sub.svg";


const SubQuotationItem = ({
    subProjectId, 
    subProjectEdit, 
    projectIdForSub,
    subProjectsToEdit,
    projectToEditId,
    subCotizacionesParaEditar,
    setSendSubQuotation,
    updateSubCotizacion,
    deleteSubQuotationFromReducer,
    openMainModal,
    closeMainModal,
    deleteSubQuotation,
    getQuotationById,
    subproject,
    isEditing,
    ...props}) => {
  const [open, openHandler] = useState(false);
  const [description, setDescription] = useState(subproject.description);
  const [saveChanges, setSaveChanges] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);


const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fileInputRef = useRef(null);
  
  const editHandler = () => {
    const data = {
      id: subproject.id,
        name: subproject?.name,
        description: description,
        file: selectedFile !== null ? selectedFile : subproject?.plantillaDeCotizacion,
        subquotationListId: subproject?.subquotationListId,
        isEditing: true,
    }
    setSendSubQuotation(data);
    openHandler(!open)
    if (isEditing) {
      updateSubCotizacion(data, projectIdForSub, isEditing)
    }
  };
  
//Maneja el borrado de una subcotización
  const deleteSubQuotationHandler = () => {
    openMainModal({
        title: "¿Estás seguro que deseas eliminar el rubro de la solicitud?",
        body: "Una vez eliminado, no podrás recuperarlo.",
        confirmHandler: () => {
          if (isEditing) {
            deleteSubQuotation(subproject.id);     
            closeMainModal();
            openMainModal({
                title: "Rubro eliminado",
                body: "El rubro fue eliminado correctamente.",
                isSuccess: true,
                noActionNeeded: true,
            });
            setTimeout(() => {
                closeMainModal();
                getQuotationById(projectIdForSub, isEditing);
            }, 2000);
        } else {       
            deleteSubQuotationFromReducer(subproject.subquotationListId);
            closeMainModal();
            openMainModal({
                title: "Rubro eliminado",
                body: "El rubro fue eliminado correctamente.",
                isSuccess: true,
                noActionNeeded: true,
            });
            setTimeout(() => {
                closeMainModal();
            }, 2000);
        }
        },
    });
  };
    
  //Manejamos el estado del boton de guardar cambios
  useEffect(() => {
    if (subproject.description !== description ||
      selectedFile !== null) {
        setSaveChanges(true)
    } else {
      setSaveChanges(false)
    }
  }, [description, selectedFile, subproject]);


    return (
      <div className={`${styles.proyectItem} ${open ? styles.open : ""}`}>
        <div
          className={`${styles.proyectActions}`}
          onClick={() => {
            openHandler(!open);
          }}
        >
          <p className={`${styles.description} ${open ? styles.open : ""}`}>
            {subproject.name}
          </p>
          <div className={`${styles.iconContainer} ${open ? styles.iconContainer_openEditText : ""}`}>
            <div className={styles.editContainer}>
            <p>editar</p>
            <div className={`${styles.iconEdit} ${open ? styles.iconEdit_open : ""}`}>
              <Edit />
            </div>
            </div>
            <div className={styles.borderIconDelete} onClick={(e) => {
            e.stopPropagation();
            deleteSubQuotationHandler()}}>
            <p>eliminar</p>
            <div className={`${styles.iconDelete} ${open ? styles.iconDelete_open : ""}`}  >
              <Delete />
            </div>
            </div>
          </div>
        </div> 
        <div className={styles.proyectInput}>     
          {open ? (
            <div className={`${styles.formContainer} ${open ? styles.open : ""}`}>
                <div className={styles.fieldWrapper_descriptionHeader}>
                    <Description />
                    <h4>Descripción</h4>
                </div>
                <div className={styles.fieldWrapper}>
                <textarea
                    className={styles.container_tableBody_description_edit}
                    value={description}
                    onChange={(e) => {
                    setDescription(e.target.value);
                    }}
                />
                </div>
                <div className={styles.fieldWrapper_fileHeader}>
                      <Upload/>
                      <h4>Excel de cotizacion</h4>
                      </div>
                <div className={styles.fileInput} onClick={() => fileInputRef.current.click()}>
                <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        className={styles.fileInput_excel}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                />
                {isEditing ? <p className={styles.fileInput_placeHolder}>Archivo seleccionado: {selectedFile !== null ? selectedFile.name : subproject?.quotation_payroll}</p> : (
                <p className={styles.fileInput_placeHolder}>Archivo seleccionado: {selectedFile !== null ? selectedFile.name : subproject?.plantillaDeCotizacion?.name}</p>)}
                </div>
                <div className={styles.fieldWrapper_buttonContainer}>
                     <button type='button' className={styles.buttonCambios_cancelar} onClick={() => openHandler(!open)}>
                    <Trash/>
                    Cancelar
                  </button>
                  <button type='button' className={`${styles.buttonCambios} ${!saveChanges ? styles.disabled : ""}`} onClick={editHandler}>
                      Guardar Cambios
                    </button>          
                  </div>  
                </div>
          ) : null}
        </div>
      </div>
    );
  };

const mapStateToProps = (state) => {
  return {
    subProjectEdit: state.profile.subProjectId,
    subProjectId: state.profile.subProjectId?.id,
    projectToEditId: state.profile.projectToEdit.id,
    subProjectsToEdit: state.profile.projectToEdit.subproyecto,
    subCotizacionesParaEditar: state.profile.sendSubQuotations,
  };
};

export default connect(mapStateToProps, {
  setEditSendSubProject,
  deleteSubQuotation,
  updateSubCotizacion,
  setSendSubQuotation,
  deleteSubQuotationFromReducer,
  openMainModal,
  closeMainModal,
  getQuotationById,
})(SubQuotationItem);
import React, { useMemo, useState } from "react";
import styles from "./SuscriptionModal.module.scss";
import { ReactComponent as Edify } from "../../../assets/svg/edify.svg";
import alertWarning from "../../../assets/svg/modalAtencion.svg";
import {
  closeSuscriptionModal,
  getSuscriptionLink,
  loadUserData,
  setSuscriptionLoading,
} from "../../../store/actions";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../UI/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { CardPayment } from "@mercadopago/sdk-react";
import successIcon from "../../../assets/svg/success.svg";
import errorIcon from "../../../assets/svg/error.svg";
import { ReactComponent as ErrorIconPlan } from "../../../assets/svg/cancelBlack.svg";
import { ReactComponent as CheckIcon } from "../../../assets/svg/checkmark.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as MercadoPagoIcon } from "../../../assets/svg/mercadoPagoIcon.svg";


const planArray = [
  {
    planId: "0",
    title: "Plan Básico",
    subtitle: "Standard",
    pricing: 0,
    frecuency: "GRATIS",
    type: "Gratuito",
    pricing_aditional: "Gratis",
    itemList: [
      { id: 1, text: "Ingreso a la plataforma" },
    ],
  },
  {
    planId: "1",
    title: "Plan Mensual",
    subtitle: "Premium",
    pricing: 1500,
    frecuency: "(IVA Incluido)",
    type: "Premium",
    pricing_aditional: "*se ajustara de forma trimestral por el índice CAC",
    itemList: [
      { id: 1, text: "Ingreso a la plataforma" },
      { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
      { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
      { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },
    ],
  },
  {
    planId: "2",
    title: "Plan Semestral",
    subtitle: "Premium",
    pricing: 6000,
    frecuency: "(IVA Incluido)",
    type: "Premium",
    pricing_aditional: "20% de descuento",
    itemList: [
      { id: 1, text: "Ingreso a la plataforma" },
      { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
      { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
      { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },

    ],
  },
  {
    planId: "3",
    title: "Plan Anual",
    subtitle: "Premium",
    pricing: 10800,
    frecuency: "(IVA Incluido)",
    type: "Premium",
    pricing_aditional: "40% de descuento",
    itemList: [
      { id: 1, text: "Ingreso a la plataforma" },
      { id: 2, text: "Publicaciónes ilimitadas de obras privadas" },
      { id: 3, text: "Publicaciónes ilimitadas de artículos en el muro" },
      { id: 4, text: "Postulaciones para cotizar en proyectos existentes" },
    ],
  },
];

const backdrop = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};
const modal = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

const MpForm = ({ currentPlan, handleStartPayment, setSuscriptionLoading }) => {
  return (
    <div className={styles.SuscriptionModal_cardForm}>
      <CardPayment
        initialization={{ amount: currentPlan.pricing }}
        customization={{
          paymentMethods: {
            maxInstallments: 1,
          },
          visual: {
            texts: {
              formTitle: "Ingrese tarjeta de crédito o débito",
              formSubmit: `Pagar Suscripcion (${currentPlan.title})`,
            },
          },
        }}
        onReady={() => {
          setSuscriptionLoading();
        }}
        onSubmit={(cardData) => {
          handleStartPayment(cardData);
          return new Promise((resolve, reject) => {
            resolve();
          });
        }}
        onError={(error) => { }}
      />
    </div>
  );
};

const MpFormMemo = React.memo(MpForm);

const ResultModal = ({ closeModal, isMercadopagoError, isError, email }) => {
  return (
    <div className={styles.resultModalContainer}>
      {
        isError ? (
          isMercadopagoError ? (
            <>
              <div className={styles.SuscriptionModal_title_error}>
                <div className={styles.edifyIcon}><Edify /></div>
                <img src={errorIcon} className={styles.SuscriptionModal_titlealertWarning} alt={"errorIconImg"} />
              </div>
              <h2>Ocurrió un error</h2>
              <div className={styles.errorMsg}>La suscripción no se pudo crear correctamente. Inténtelo nuevamente o escríbanos a contacto@edify.la ¡Muchas gracias!</div>
            </>
          ) : (
            <>
              <div className={styles.SuscriptionModal_title_error}>
                <div className={styles.edifyIcon}><Edify /></div>
                <img src={errorIcon} className={styles.SuscriptionModal_titlealertWarning} alt={"errorIconImg"} />
              </div>
              <h2>Ocurrió un error</h2>
              <div className={styles.errorMsg}>Verifique que datos de la tarjeta sean correctos e inténtelo nuevamente. O escríbanos a contacto@edify.la ¡Muchas gracias!</div>
            </>
          )
        ) : (
          <>
            <div className={styles.SuscriptionModal_title_accepted}>
              <div className={styles.edifyIcon}><Edify /></div>
              <img src={successIcon} className={styles.SuscriptionModal_titlealertWarning} alt={"succesIconImg"} />
            </div>
            <h2>Tarjeta Aceptada</h2>
            <div className={styles.SuscriptionModal_ResultDescription}>
              <p>Ahora puede acceder a las funcionalidades premium.</p>
            </div>
            <div className={styles.SuscriptionModal_ResultDescription}>Le notificaremos al email del usuario cuando el pago se haya efectivizado ¡Muchas gracias!</div>
          </>
        )
      }
      <div
        className={styles.SuscriptionModal_PlanContaineritem_decline}
        onClick={closeModal}
      >
        Cerrar
      </div>
    </div>
  );
};

const SuscriptionModal = (props) => {
 const navigate = useNavigate();
  const [isCardForm, setisCardForm] = useState(false);
  const [currentPlan, setcurrentPlan] = useState(null);
  const [isSuscriptionComplete, setisSuscriptionComplete] = useState(false);
  const [isError, setisError] = useState(false);
  const [isMercadopagoError, setisMercadopagoError] = useState(false);
  const [selectPaymentStates, setSelectPaymentStates] = useState({});
  const [transferModal, setTransferModal] = useState(false);
  const [amountToTransfer, setAmountToTransfer] = useState(0);

  const handleOpenCardForm = (planId) => {
    props.setSuscriptionLoading();
    setcurrentPlan(planArray.find((plan) => plan.planId == planId));
    setisCardForm(true);
  };

  const handleStartPayment = (card_data) => {
    const afterSuccess = () => setisSuscriptionComplete(true);
    const afterFailure = (errorCode) => {
      if (errorCode < 500) {
        // the errorCode 4xx is error from mercadopago
        setisMercadopagoError(true);
      }
      setisError(true)
      setisSuscriptionComplete(true);
    };
    props.getSuscriptionLink(
      currentPlan.planId,
      props.userData.id,
      card_data,
      afterSuccess,
      afterFailure
    );
  };

  // memoize handleStartPayment to avoid infinite loading loop
  const handleStartPaymentMemo = useMemo(
    () => handleStartPayment,
    [currentPlan]
  );

  const closeModal = () => {
    if (isSuscriptionComplete) {
      props.loadUserData();
    }
    props.closeSuscriptionModal();
  };

  //Maneja los estados de los botones de pago 
  const handleToggleSelectPayment = (planId) => {
    setSelectPaymentStates(prevStates => ({
      ...prevStates,
      [planId]: !prevStates[planId]
    }));
  };
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={"overlay"}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.SuscriptionModal_overlay}
          onClick={closeModal}
        ></motion.div>
        <motion.div
          key={"modal"}
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.SuscriptionModal}
        >
          {isCardForm ? (
            isSuscriptionComplete ? (
              <ResultModal
                closeModal={closeModal}
                isMercadopagoError={isMercadopagoError}
                isError={isError}
                email={props.userData.username}
              />
            ) : (
              <MpFormMemo
                currentPlan={currentPlan}
                handleStartPayment={handleStartPaymentMemo}
                setSuscriptionLoading={props.setSuscriptionLoading}
              />
            )
          ) : (
            <>
              <div className={styles.SuscriptionModal_title}>
                <img
                  src={alertWarning}
                  className={styles.SuscriptionModal_titlealertWarning}
                  alt={"alertWarningImg"}
                />
                <h3>Contenido Premium</h3>
                <div
                  className={styles.SuscriptionModal_PlanContaineritem_decline}
                  onClick={closeModal}
                >
                  Cerrar
                </div>
              </div>
              <p className={styles.SuscriptionModal_subTitle}>Para acceder al contenido activá tu plan</p>
              {transferModal ? (
                <div className={styles.SuscriptionModal_transferModal}>

                  <h3>Datos para pagar por transferencia</h3>
                  <div className={styles.SuscriptionModal_transferModal_dataContainer}>
                    <div className={styles.SuscriptionModal_transferModal_mp}>
                      <MercadoPagoIcon width={150} height={150} />
                      <div className={styles.SuscriptionModal_transferModal_mp_data}>
                        <p>Cuenta de Mercado Pago</p>
                        <p>CVU: 0000003100016478555577</p>
                        <p>Alias: edify-web</p>
                        <p>CUIT: 30-71680733-5</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.SuscriptionModal_transferModal_footer}>
                    <p>El monto a transferir es de $ <b>{amountToTransfer}</b></p>
                    <p>Una vez realizada la transferencia, envianos el comprobante a&nbsp;
                      <a href="mailto: pagosmp@edify.la" className={styles.SuscriptionModal_transferModal_email}>
                        pagosmp@edify.la
                      </a>

                    </p>
                    <p> te enviaremos un email con la confirmación de tu suscripción y daremos de alta tu plan. </p>
                    <p>Si tenés alguna duda, escribinos a nuestro whatsApp 11 68580968</p>
                  </div>
                </div>
              ) : (
                <div className={styles.SuscriptionModal_PlanContainerGrid}>
                  <table className={styles.SuscriptionModal_PlanTable}>
                    <thead>
                      <tr>
                        <th>{null}</th>
                        {planArray.map((plan, index) => (
                          <th key={index} className={styles.SuscriptionModal_planTableHeader}>
                            {plan.planId === "0" && props.userData.free_trial?.status === "terminate" && !props.isUserPremium && (
                              <div className={styles.SuscriptionModal_planTableHeader_basicPlan}>
                                <p className={styles.SuscriptionModal_planTableHeader_basicPlan_txt}>PLAN ACTUAL</p>
                              </div>
                            )}
                            {plan.planId === "3" && (
                              <div className={styles.SuscriptionModal_planTableHeader_recommendPlan}>
                                <p className={styles.SuscriptionModal_planTableHeader_recommendPlan_txt}>RECOMENDADO</p>
                              </div>
                            )}
                            <div className={styles.SuscriptionModal_PlanContainerPlantitle}>
                              <h3>{plan.title}</h3>
                              <p className={styles.SuscriptionModal_planTableHeader_price}>$ {plan.pricing.toLocaleString("es-AR")}</p>
                            </div>

                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Tipo de plan</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.type}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Descuento</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.pricing_aditional}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Acceso a la plataforma</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.itemList.some(item => item.id === 1) ? (
                              <CheckIcon width={15} height={15} />
                            ) : (
                              <ErrorIconPlan width={15} height={15} />
                            )}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Publicaciónes ilimitadas de obras privadas</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.itemList.some(item => item.id === 2) ? (
                              <CheckIcon width={15} height={15} />
                            ) : (
                              <ErrorIconPlan width={15} height={15} />
                            )}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Publicaciónes ilimitadas de artículos en el muro</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.itemList.some(item => item.id === 3) ? (
                              <CheckIcon width={15} height={15} />
                            ) : (
                              <ErrorIconPlan width={15} height={15} />
                            )}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className={styles.SuscriptionModal_planTableItems_row}>Postulaciones para cotizar en proyectos existentes</td>
                        {planArray.map((plan, index) => (
                          <td key={index} className={styles.SuscriptionModal_planTableItems}>
                            {plan.itemList.some(item => item.id === 4) ? (
                              <CheckIcon width={15} height={15} />
                            ) : (
                              <ErrorIconPlan width={15} height={15} />
                            )}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>{null}</td>
                        {planArray.map((plan, index) => (
                          plan.planId === "0" ? (
                            <td key={index}>
                              {""}
                            </td>
                          ) : (
                            <td key={index} >
                              <div className={styles.SuscriptionModal_planTableItems_buttonsContainer}>
                                <div className={styles.SuscriptionModal_planTableItems_buttons} onClick={() => handleToggleSelectPayment(plan.planId)}>
                                  Elegir medio de pago
                                  {selectPaymentStates[plan.planId] ? <ArrowUp width={15} height={15} /> : <ArrowDown width={15} height={15} />}
                                </div>
                                {selectPaymentStates[plan.planId] && (
                                  <div className={styles.SuscriptionModal_planTableItems_buttons_menu}>
                                    <button className={styles.SuscriptionModal_planTableItems_buttons_menu_item} onClick={() => handleOpenCardForm(plan.planId)}>
                                      Tarjeta de crédito/débito
                                    </button>
                                    <button className={styles.SuscriptionModal_planTableItems_buttons_menu_item} onClick={() => { setTransferModal(true); setAmountToTransfer(plan.pricing) }}>
                                      Transferencia
                                    </button>
                                  </div>
                                )}
                              </div>
                            </td>
                          )

                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </motion.div>
      </AnimatePresence>
      {props.suscriptionLoading && <Loading />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    suscriptionLoading: state.profile.suscriptionLoading,
    isBackDashboard: state.general.isBackDashboard,
    isUserPremium: state.general.isUserPremium,
  };
};

export default connect(mapStateToProps, {
  closeSuscriptionModal,
  getSuscriptionLink,
  loadUserData,
  setSuscriptionLoading,
})(SuscriptionModal);

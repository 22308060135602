import React, { useEffect, useState } from "react";
import styles from "./SubProjectBudgets.module.scss";
import { getProfileDataOfUserSender, clearSubProjectBudgets, getSubProjectBudgets } from "../../../store/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Planos } from "../../../assets/svg/planos.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrowDownIcon.svg";

const SubProjectBudgets = ({
    subproject,
    getSubProjectBudgets,
    budgets,
}) => {
    const [showSubProjectInfo, setShowSubProjectInfo] = useState({});
    const [count, setCount] = useState(0);
   const navigate = useNavigate();
    const budgetsToMap = budgets[subproject.id] || [];

    useEffect(() => {
        getSubProjectBudgets(parseInt(subproject.id));
    }, [subproject.id])

    useEffect(() => {
        setCount(budgetsToMap.length);
    }, [budgetsToMap]);

    const handleRedirectToProfile = (userId, fullName) => {
        navigate(`/profesionales/${userId}/${fullName}`);
    }

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.container_body}>
                <div className={styles.container_header} onClick={() => toggleSubProjectInfo(subproject.id)}>
                    <p className={styles.container_title}>{subproject.name}</p>
                    <div className={styles.container_arrow}>
                        <div className={styles.container_arrow_count}>
                            {count}
                        </div>
                        <ArrowIcon
                            className={showSubProjectInfo[subproject.id] ? styles.arrowIconDown : styles.arrowIconRight}
                            height={15}
                            width={15}
                        />
                    </div>
                </div>
                {showSubProjectInfo[subproject.id] && (
                    <div className={styles.budgetsContainer}>
                        {budgetsToMap && budgetsToMap.length ?
                             budgetsToMap.map((budget, index) => {
                            const fullName = budget.user.name + " " + budget.user.last_name;
                            const userId = budget.user.id;
                            const profession = budget.user.profession === "undefined" || budget.user.profession === undefined ? "No especificado" || budget.user.profession === null : budget.user.profession;
                            return (
                                <div className={styles.container_body_budgetFile} key={index}>
                                    <div className={styles.container_body_budgetItem} onClick={() => handleRedirectToProfile(userId, fullName)}>
                                        <div className={styles.container_body_budgetItem_overLay}>
                                            <p>Ver perfil</p>
                                        </div>
                                        <span className={styles.container_body_budgetItem_name}>{fullName}</span>
                                        <span className={styles.container_body_budgetItem_profession}>{profession}</span>
                                    </div>
                                    <div className={styles.container_body_budgetFileContainer} key={index}>
                                        <Planos width={15} height={15} />
                                        <a href={budget.budget} target="_blank" rel="noreferrer" className={styles.container_body_budgetFile_link}>Descargar cotización</a>
                                    </div>
                                </div>
                            )
                        } 
                        ) : (
                            <div className={styles.container_body_budgetItem}>
                                <p className={styles.container_body_budgetItem_noBudget}>No hay cotizaciones</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div >
    );
}

const mapStateToProps = (state) => {

    return {
        budgets: state.profile.subProjectBudgets,
        profileBudgetSender: state.profile.profileBudgetSender,
    };
}

export default connect(mapStateToProps, { getProfileDataOfUserSender, clearSubProjectBudgets, getSubProjectBudgets })(SubProjectBudgets);
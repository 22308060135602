import React from "react";
import styles from "./ProyectoIdHeader.module.scss";
import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/svg/trashIcon.svg";
import { ReactComponent as Check } from "../../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../../assets/svg/xClose.svg";
import { ReactComponent as SendPostComment } from "../../../../assets/svg/sendPostComment.svg";
import { ReactComponent as Edit } from "../../../../assets/svg/edit-pencil.svg";
import SubProjectBudgets from "../../SubProjects/SubProjectBudgets";
import ButtonsProject from "../../../UI/ButtonsProject/ButtonsProject";

const ProyectoIdHeader = ({
    proyecto,
    showSubProjectsMenu,
    showPostulationsMenu,
    showBudgetsMenu,
    isActive,
    handleHeaderButtonsMenu,
    menuRef,
    showSubProjectInfo,
    toggleSubProjectInfo,
    userPostulationStatus,
    isOwnProject,
    postulacionesDeProyecto,
    handleAccessRequest,
    showPostulations,
    togglePostulations,
    isPostulationSent,
    isPostulationAllowed,
    isPostulationDenied,
    handlePostulationButton,
    handleEditProject,
    handleDeleteProject
}) => {

       //Formateamos fechas

       const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    return (
    <header className={styles.mainHeader}>
                <div className={styles.mainHeader_buttons}>
                    <div className={`${styles.mainHeader_subProyectos} ${isActive === "subProjects" && styles.isActive}`}  >
                        <h3 className={styles.mainHeader_subProyectos_title} onClick={(e) => handleHeaderButtonsMenu(e, "subProjects")}>RUBROS</h3>
                        {showSubProjectsMenu &&
                            <div className={styles.mainHeader_subProyectos_container} ref={menuRef}>
                                {proyecto?.subproyecto && proyecto?.subproyecto.length > 0 ? proyecto?.subproyecto.map((subProject, index) => {
                                    return (
                                        <div className={styles.mainHeader_subProyectos_container_subProject} key={index} >
                                            <div className={`${styles.mainHeader_subProyectos_container_subProject_header} ${showSubProjectInfo === index && styles.isActive}`} onClick={() => toggleSubProjectInfo(index)}>
                                                <p className={styles.mainHeader_subProyectos_container_subProject_title}>{subProject.name}</p>
                                                <div className={styles.mainHeader_subProyectos_container_subProject_arrow}>
                                                    <ArrowIcon
                                                        className={showSubProjectInfo === index ? styles.arrowIconDown : styles.arrowIconRight}
                                                        height={15}
                                                        width={15}
                                                    />
                                                </div>
                                            </div>
                                            {showSubProjectInfo === index &&
                                                <div className={styles.mainHeader_subProyectos_container_subProject_info}>
                                                    <div className={styles.mainHeader_subProyectos_container_subProject_item}>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_label}>Estado:</p>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_value}>{subProject.status === "OPEN" && "Abierto"}</p>
                                                    </div>
                                                    <div className={styles.mainHeader_subProyectos_container_subProject_item}>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_label}>Inicio de recepción de presupuestos:</p>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_value}>{formatDate(subProject.startDate)}</p>
                                                    </div>
                                                    <div className={styles.mainHeader_subProyectos_container_subProject_item}>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_label}>Fin de recepción de presupuestos:</p>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_value}>{formatDate(subProject.endDate) === "" ? formatDate(proyecto?.endDate) : formatDate(subProject.endDate)}</p>
                                                    </div>
                                                    <div className={styles.mainHeader_subProyectos_container_subProject_item}>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_label}>Descripcion:</p>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_description}>{subProject?.description === "undefined" ? "No especifica" : subProject.description}</p>
                                                    </div>
                                                    <div className={styles.mainHeader_subProyectos_container_subProject_item}>
                                                        <p className={styles.mainHeader_subProyectos_container_subProject_label}>Documentacion:</p>
                                                        <ButtonsProject mainProjectId={proyecto?.id} subproyecto={subProject} isAllowed={userPostulationStatus} isMyProject={isOwnProject} type={"isProject"} />
                                                    </div>
                                                </div>}
                                        </div>
                                    )
                                }) : <p className={styles.mainHeader_subProyectos_container_noResults}>No hay sub proyectos</p>}
                            </div>
                        }
                    </div>
                    {isOwnProject &&
                        <div className={`${styles.mainHeader_postulaciones} ${isActive === "postulations" && styles.isActive}`} >
                            <h3 className={styles.mainHeader_postulaciones_title} onClick={(e) => handleHeaderButtonsMenu(e, "postulations")}>
                                POSTULACIONES
                            </h3>
                            {showPostulationsMenu &&
                                <div className={styles.mainHeader_postulaciones_container} ref={menuRef}>
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Recibidas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "SEND").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "SEND").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[0] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(0)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[0] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "SEND" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainHeader_postulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_accept} title="Aceptar">
                                                        <Check height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "ALLOWED", "POSTULACION_ACCEPTED")} />
                                                    </p>
                                                    <p className={styles.mainHeader_postulaciones_container_status_deny} title="Rechazar">
                                                        <Close height={10} width={10} onClick={() => handleAccessRequest(parseInt(postulation.id), "NEGATED", "POSTULACION_NEGATED")} />
                                                    </p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Aceptadas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "ALLOWED").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "ALLOWED").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[1] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(1)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[1] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "ALLOWED" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainHeader_postulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_acceptedTxt}>Aceptada</p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                    <div className={styles.mainHeader_postulaciones_container_header}>
                                        Rechazadas
                                        <div className={styles.mainHeader_postulaciones_container_header_countContainer}>
                                            {postulacionesDeProyecto.filter(postulation => postulation.status === "DENIED").length > 0 &&
                                                <span className={styles.mainHeader_postulaciones_container_header_count}>{postulacionesDeProyecto.filter(postulation => postulation.status === "DENIED").length}</span>
                                            }
                                            <ArrowIcon
                                                className={showPostulations[2] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                                onClick={() => togglePostulations(2)}
                                            />
                                        </div>
                                    </div>
                                    {showPostulations[2] && postulacionesDeProyecto && postulacionesDeProyecto.length > 0 && postulacionesDeProyecto.map((postulation, index) => {
                                        return postulation.status === "DENIED" &&
                                            <div className={styles.mainHeader_postulaciones_container_userPostulation} key={index}>
                                                <div className={styles.mainConteHeaderulaciones_container_user}>
                                                    <img src={postulation.user.image_profile} alt="Imagen de perfil" className={styles.mainHeader_postulaciones_container_user_image} />
                                                    <p className={styles.mainHeader_postulaciones_container_user_name}>{postulation.user.type === "COMPANY" ?
                                                        postulation.user.razon_social : postulation.user.name + " " + postulation.user.last_name}</p>
                                                </div>
                                                <div className={styles.mainHeader_postulaciones_container_status}>
                                                    <p className={styles.mainHeader_postulaciones_container_status_deniedTxt}>Rechazada</p>
                                                </div>
                                            </div>
                                    }
                                    )}
                                </div>
                            }
                        </div>
                    }
                    {isOwnProject &&
                        <div className={`${styles.mainHeader_cotizaciones} ${isActive === "budgets" && styles.isActive} `}>
                            <h3 className={styles.mainHeader_cotizaciones_title} onClick={(e) => handleHeaderButtonsMenu(e, "budgets")}>
                                COTIZACIONES
                            </h3>
                            {showBudgetsMenu &&
                                <div className={styles.mainHeader_cotizaciones_container} ref={menuRef}>
                                    {proyecto?.subproyecto && proyecto?.subproyecto.length > 0 ? proyecto?.subproyecto.map((subProject, index) => {
                                        return <SubProjectBudgets subproject={subProject} key={index} mainProject={proyecto} />
                                    }
                                    ) : <p className={styles.mainHeader_cotizaciones_container_noResults}>No hay cotizaciones</p>}
                                </div>}
                        </div>
                    }

                </div>
                {!isOwnProject && (
                    <div className={styles.mainHeader_status} >
                        <h3 className={`${styles.mainHeader_status_title} ${isPostulationSent && styles.sent} ${isPostulationAllowed && styles.allowed}
                            ${isPostulationDenied && styles.denied}`} onClick={handlePostulationButton}>
                                <SendPostComment height={20} width={20} />
                                Quiero cotizar</h3>
                    </div>
                )}
                {isOwnProject && <div className={styles.mainHeader_status_buttons}>
                    <button className={styles.mainHeader_status_buttons_edit} onClick={() => handleEditProject()}>
                        <Edit width={15} height={15} />
                        Editar</button>
                    <button className={styles.mainHeader_status_buttons_delete} title="Borrar proyecto" onClick={() => handleDeleteProject()}><TrashIcon width={20} height={20} /></button>
                </div>}
            </header>
    )
}

export default ProyectoIdHeader;
import React, { useState } from "react";
import styles from "./ListedWorkersForMobile.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import useGetTodayDate from "../../../hooks/useGetTodayDate";
import MapsLoading from "../../UI/Loading/MapLoading";
import { ReactComponent as ProfileIcon } from "../../../assets/svg/profileIcon.svg";

const ListedWorkersForMobile = ({ workers, loading }) => {
    const [showTooltip, setShowTooltip] = useState(null); 

    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleMail = (email) => {
        const isGmail = email.includes("@gmail.com");
        const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
        window.open(mailtoLink, "_blank");
    }

    const handlePhone = (phone) => {
        const whatsappLink = `https://wa.me/${phone.replace(/\D/g, '')}`;
        window.open(whatsappLink, "_blank");
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {loading && <MapsLoading />}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.workersContainer}>
                {workers.map(worker => {
                    const isAvailable = new Date(worker?.employment_end_date) < formattedTodayDate;
                    const tooltipContent = isAvailable 
                        ? "Disponible"
                        : `Disponible a partir del ${formatEmploymentEndDate(worker?.employment_end_date)}`;

                    return (
                        <motion.div
                            key={worker.id}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className={styles.workerCard}>
                            <div className={styles.workerCard_header}>
                                <ProfileIcon className={styles.workerCard_profileIcon} width={50} height={50} />
                            </div>
                            <div className={styles.workerCard_info}>
                                <div className={styles.workerCard_dataContainer}>
                                    <h3 className={styles.workerCard_name}>{worker.name} {worker.last_name}</h3>
                                    <p className={styles.workerCard_itemInfo}>{worker?.jobBoardZones?.map(zone => zone.city.replace(", Argentina", "")).join(", ")}</p>
                                    <p className={styles.workerCard_itemInfo}>{worker?.skill?.map(skill => skill?.skill?.name).join(", ")}</p>
                                </div>
                                <div className={styles.workerCard_contactContainer}>
                                    <div className={styles.workerCard_itemContainer}>
                                        <img className={styles.workerCard_icon} onClick={() => handlePhone(worker.phone)} src="https://cdn-icons-png.flaticon.com/128/14025/14025200.png" alt="phone icon" />
                                    </div>
                                    <div className={styles.workerCard_itemContainer}>
                                        <img className={styles.workerCard_icon} onClick={() => handleMail(worker.email)} src="https://cdn-icons-png.flaticon.com/128/14025/14025140.png" alt="email icon" />
                                    </div>
                                    <div 
                                        className={styles.workerCard_header_isAvailable}
                                        onClick={() => setShowTooltip(prev => prev === worker.id ? null : worker.id)}>
                                        <div className={isAvailable ? styles.workerCard_header_isAvailable_icon : styles.workerCard_header_isAvailable_iconNo}></div>
                                        {showTooltip === worker.id && (
                                            <div className={styles.tooltip}>
                                                <p>{tooltipContent}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    );
                })}
            </motion.div>
        </AnimatePresence>
    );
}

export default ListedWorkersForMobile;


import React from "react";
import SubCotizacionesWrapper from "../../MarketPlace/subCotizacion/SubCotizacionesWrapper";
import NuevaSubCotizacion from "./nuevaSubCotizacion/NuevaSubCotizacion";
import styles from "./Subcotizaciones.module.scss";
import { connect } from "react-redux";

const Subcotizaciones = ({ subQuotations, subprojectsEdit, isEditing, projectIdForSub, subQuotationsFromNewProject}) => {

  const subQuotationsToMap = isEditing ? subQuotations : subQuotationsFromNewProject

  return (
    <div className={styles.subProyectos}>
      <NuevaSubCotizacion showProyects={true} isEditing={isEditing} projectIdForSub={projectIdForSub} />
  {subQuotationsToMap?.length > 0 ? <SubCotizacionesWrapper subprojectsEdit={subQuotationsToMap} isEditing={isEditing} projectIdForSub={projectIdForSub} type='subQuotations'/>
   : 
   <div className={styles.wrapper}>
    <h5 className={styles.wrapper_subTitle}>RUBROS CARGADOS</h5>
   <p className={styles.emptyDisclaimer}>No hay rubros a cotizar en este proyecto, debes seleccionar al menos 1 rubro de la lista.</p>
   </div>}  
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subQuotations: state.profile.quotationToEdit.subquotation,
    subQuotationsFromNewProject: state.profile.sendSubQuotations,
  };
};

export default connect(mapStateToProps)(Subcotizaciones);


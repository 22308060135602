import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  loginUserAction,
  recoverPasswordAction,
  closeAction,
  setLoginAnimation,
  loginWithGoogle,
} from "../../store/actions";
import * as Yup from "yup";
import { ReactComponent as ViewPassword } from "../../assets/svg/showPassword.svg";
import { ReactComponent as HidePassword } from "../../assets/svg/hidePassword.svg";
import styles from "./LoginPage.module.scss";
import Input from "../../components/UI/Forms/Input/Input";
import Button from "../../components/UI/Forms/Button/Button";
import InitialWrapper from "../../components/Layout/InitialWrapper/InitialWrapper";
import { fireAnalytics } from "../../utils/firebase.js";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const LoginPage = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(true);

  const navigate = useNavigate();


  useEffect(() => {
    fireAnalytics("login_landing", { category: "navigation" });
    if (localStorage.getItem("access-Token-Edify")) {
      if (props.userData.username == "invited@edify.la") {
        navigate("/dashboard");
        return;
      }
      if (!props.userData.name && !props.userData.rubro) {
        navigate("/select-type");
      }
    }
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Debes ingresar un formato de email válido")
      .required("Escribe tu e-mail"),

    password: Yup.string()
      .min(6, "La contraseña debe tener al menos 6 caracteres")
      .required("Escribe tu contraseña"),
  });

  const handleSubmit = (values) => {
    props.loginUserAction(values, navigate);
    handleFinish();
    fireAnalytics("try_login", { category: "authentication" });
  };

  const handleFinish = () => {
    props.setLoginAnimation(true);
    fireAnalytics('ob_step_5_next', { category: 'onboarding' })
  };

  setTimeout(() => {
    if (props.loginAnimation === true) {
      props.setLoginAnimation(false);
    }
  }, 3000);

  return (
    <InitialWrapper animation={props.loginAnimation}>
      <h1 className={styles.title}>
        ¡Bienvenido a Edify! <br />
      </h1>
      <span className={styles.subtitle}>
        Queremos cambiar la manera de construir a partir de tecnología, creá tu cuenta gratis.
      </span>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validateOnBlur={false}
        validationSchema={LoginSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ handleSubmit, isValid, values, errors }) => (
          <>
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <div className={styles.emailField}>
                <Field
                  className={`${styles.formInput} ${errors.email ? "error" : null}`}
                  placeholder={`${errors.email ? errors.email : "E-mail"}`}
                  type="email"
                  name="email"
                  component={Input}
                />
              </div>
              <div className={styles.passwordField}>
                {values.password !== "" ? (
                  <span
                    className={`${styles.viewPassword} ${errors.password ? styles.viewPasswordError : null
                      }`}
                    onClick={() => changeShowHidePassword(!showHidePassword)}
                  >
                    {showHidePassword ? <HidePassword /> : <ViewPassword />}
                  </span>
                ) : null}
                <Field
                  className={`${styles.formInput} ${errors.password ? "error" : null
                    }`}
                  placeholder={`${errors.password ? errors.password : "Contraseña"
                    }`}
                  type={showHidePassword ? "password" : "text"}
                  name="password"
                  component={Input}
                />
              </div>
              <Link
                className={styles.forgotPassword}
                onClick={() =>
                  fireAnalytics("login_to_password", {
                    category: "authentication",
                  })
                }
                to="/forgotpassword"
              >
                <p className={styles.forgotPasswordText}>
                  Olvidé la contraseña
                </p>
              </Link>
              <div className={styles.buttonField}>
                <button
                  disabled={!isValid}
                  className={`${styles.btn} ${errors.password || errors.email ? styles.error : null
                    }`}
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form>
          </>
        )}
      </Formik>
      <div className={styles.googleDivider}>
        <span className={styles.googleDivider_line}></span>
        <div className={styles.googleDivider_text}> O ingresá con </div>
        <span className={styles.googleDivider_line}></span>
      </div>
      <div className={styles.googleLogin}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            props.loginWithGoogle(credentialResponse.credential, navigate);
            handleFinish();
          }}
          onError={() => {
            console.log("Login failed");
          }}
          text="continue_with"
          width="350px"
          logo_alignment="center"
          itp_support
          theme="outline"
          shape="rectangular"
          type="icon"
        />
      </div>
      <div className={styles.registerField}>
        <Button
          to="/register"
          colored="white"
          typebtn="link"
          onClick={() =>
            fireAnalytics("login_to_register", {
              category: "navigation",
            })
          }
          style={{ border: "none", padding: "0" }}

        >
          <span style={{ color: "#000", opacity: "0.67" }}>¿Todavía no tenés cuenta? </span>
          <span className={styles.hover}>Regístrate</span>

        </Button>
      </div>
    </InitialWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    open: state.general.recoverOpen,
    error: state.general.error,
    success: state.general.success,
    loginAnimation: state.general.loginAnimation,
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  loginUserAction,
  recoverPasswordAction,
  closeAction,
  setLoginAnimation,
  loginWithGoogle,
})(LoginPage);

import React from "react";
import styles from "./CongratsSuscription.module.scss";
import Logo from "../../../assets/svg/Done.svg";
import { connect } from "react-redux";
import { closeSuscriptionModal, loadUserData } from "../../../store/actions";
import OnboardingLayout from "../../../components/Layout/OnboardingLayout/OnboardingLayout";
import { Link } from "react-router-dom";

class CongratsSuscription extends React.Component {
  componentDidMount() {
    this.props.closeSuscriptionModal();
    this.props.loadUserData()
  }

  render() {
    return (
      <OnboardingLayout tipo="onboard" title="Suscripción Completada" height="100vh">
        <section className={styles.sectionContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <div className={styles.suscriptionContainer}>
                <div className={styles.imgContainer}>
                  <img src={Logo} alt="Done" />
                </div>
                <div className={styles.suscriptionContent}>
                  <h1 className={styles.suscriptionContent_header}>¡Felicitaciones!</h1>
                  <p className={styles.subtitle}>
                    Acabas de confirmar tu <br /> suscripcion{" "}
                    {this.props.userPlan.name} a Edify. <br /> ¡Bienvenido!
                  </p>
                </div>
              </div>
            </div>

            <p>Tienes acceso a los siguientes beneficios</p>
            <div className={styles.textContainer}>
                <ul>
                    <li>Acceso a Sección Proyectos Privado</li>
                    <li>Acceso a Sección Obra Pública</li>
                    <li>Acceso a Sección Materiales</li>
                    <li>Publicación de Perfil en Sección Usuarios Registrados</li>
                    <li>Atención personalizada para consultas</li>
                    <li>Mailing de difusión a Base de Datos (Una vez al mes)</li>
                </ul>
            </div>

            <div className={styles.buttonContainer}>
              <Link to="/dashboard">Ir al dashboard</Link>
            </div>
          </div>
        </section>
      </OnboardingLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userPlan: state.register.userPlan,
});

export default connect(mapStateToProps, { closeSuscriptionModal, loadUserData })(CongratsSuscription);

import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as DniFront } from '../../assets/svg/DniFront.svg';
import { ReactComponent as DniBack } from '../../assets/svg/DniBack.svg';
import { ReactComponent as Selfie } from '../../assets/svg/SelfieIcon.svg';
import { motion } from 'framer-motion';
import './uploadPhotos.scss';

// Variantes de animación para framer-motion
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 100 : -100,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? -100 : 100,
      opacity: 0,
    }),
  };


const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const UploadPhotosMobile = ({ handleSendPhotos, closeModal }) => {
  const isMobile = useIsMobile();
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const [error, setError] = useState('');
  const [files, setFiles] = useState({
    dniFront: null,
    dniBack: null,
    selfie: null,
  });

  const onDrop = (acceptedFiles, field) => {
    const file = acceptedFiles[0];
    const validMimeTypes = ['image/jpeg', 'image/png'];

    if (file && validMimeTypes.includes(file.type)) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [field]: file,
      }));
      setError('');
    } else {
      setError('El tipo de archivo no es válido. Solo se aceptan imágenes en formato JPEG o PNG.');
    }
  };

  const { getRootProps: getRootPropsDniFront, getInputProps: getInputPropsDniFront } = useDropzone({
    multiple: false,
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] },
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'dniFront'),
  });

  const { getRootProps: getRootPropsDniBack, getInputProps: getInputPropsDniBack } = useDropzone({
    multiple: false,
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] },
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'dniBack'),
  });

  const { getRootProps: getRootPropsSelfie, getInputProps: getInputPropsSelfie } = useDropzone({
    multiple: false,
    accept: { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] },
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'selfie'),
  });

  const handleRemove = (field, event) => {
    event.stopPropagation();
    setFiles((prevFiles) => ({
      ...prevFiles,
      [field]: null,
    }));
  };

  const handleSubmit = () => {
    handleSendPhotos(files);
    closeModal();
  };

  const nextStep = () => {
    setDirection(1); 
    setStep((prevStep) => Math.min(prevStep + 1, 2));
  };

  const prevStep = () => {
    setDirection(-1);  
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const isFormValid = files?.dniFront && files?.dniBack && files?.selfie;


  // Render para versión móvil (steps)
  const renderMobileSteps = () => (
    <motion.div
      className="upload-section"
      key={step}
      custom={direction}
      initial="enter"
      animate="center"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      {step === 0 && (
        <div className="upload-item">
          <div {...getRootPropsDniFront({ className: 'dropzone' })}>
            <input {...getInputPropsDniFront()} />
            {files.dniFront ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.dniFront)} alt="DNI Front" className="preview" />
                <p className="file-name">{files.dniFront.name}</p>
                <button onClick={(event) => handleRemove('dniFront', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon">
                  <DniFront width={60} height={60} />
                </div>
                <p>Por favor, adjunta una foto del frente de tu DNI.</p>
              </div>
            )}
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="upload-item">
          <div {...getRootPropsDniBack({ className: 'dropzone' })}>
            <input {...getInputPropsDniBack()} />
            {files.dniBack ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.dniBack)} alt="DNI Back" className="preview" />
                <p className="file-name">{files.dniBack.name}</p>
                <button onClick={(event) => handleRemove('dniBack', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon" style={{ alignItems: 'flex-end' }}>
                  <DniBack width={60} height={60} />
                </div>
                <p>Por favor, adjunta una imagen del dorso de tu DNI</p>
              </div>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="upload-item">
          <div {...getRootPropsSelfie({ className: 'dropzone' })}>
            <input {...getInputPropsSelfie()} />
            {files.selfie ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.selfie)} alt="Selfie" className="preview" />
                <p className="file-name">{files.selfie.name}</p>
                <button onClick={(event) => handleRemove('selfie', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon">
                  <Selfie width={60} height={60} />
                </div>
                <p>Por favor, adjunta una imagen de tu rostro</p>
              </div>
            )}
          </div>
        </div>
      )}
    </motion.div>
  );

  // Render para versión de pantalla completa (todo en una sola pantalla)
  const renderFullScreen = () => (
    <>
      <div className="upload-section">
        <div className="upload-item">
          <div {...getRootPropsDniFront({ className: 'dropzone' })}>
            <input {...getInputPropsDniFront()} />
            {files.dniFront ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.dniFront)} alt="DNI Front" className="preview" />
                <p className="file-name">{files.dniFront.name}</p>
                <button onClick={(event) => handleRemove('dniFront', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon">
                  <DniFront width={60} height={60} />
                </div>
                <p>Por favor, adjunta una foto del frente de tu DNI.</p>
              </div>
            )}
          </div>
        </div>
        <div className="upload-item">
          <div {...getRootPropsDniBack({ className: 'dropzone' })}>
            <input {...getInputPropsDniBack()} />
            {files.dniBack ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.dniBack)} alt="DNI Back" className="preview" />
                <p className="file-name">{files.dniBack.name}</p>
                <button onClick={(event) => handleRemove('dniBack', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon" style={{ alignItems: 'flex-end' }}>
                  <DniBack width={60} height={60} />
                </div>
                <p>Por favor, adjunta una imagen del dorso de tu DNI</p>
              </div>
            )}
          </div>
        </div>

        <div className="upload-item">
          <div {...getRootPropsSelfie({ className: 'dropzone' })}>
            <input {...getInputPropsSelfie()} />
            {files.selfie ? (
              <div className="uploaded-file">
                <img src={URL.createObjectURL(files.selfie)} alt="Selfie" className="preview" />
                <p className="file-name">{files.selfie.name}</p>
                <button onClick={(event) => handleRemove('selfie', event)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ) : (
              <div className="dropzone-content">
                <div className="icon">
                  <Selfie width={60} height={60} />
                </div>
                <p>Por favor, adjunta una imagen de tu rostro</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="submit-button-container">
        <button className="submit-button"  disabled={!isFormValid} onClick={handleSubmit}>
          <FontAwesomeIcon icon={faCheckCircle} /> Enviar mis fotos
        </button>
      </div>
    </>
  );

  return (
    <div className="upload-container">
      <h2>Verifica tu identidad 
        <button className="x-button" onClick={closeModal}>
          Cancelar
        </button>
      </h2>
      {isMobile ? renderMobileSteps() : renderFullScreen()}

      {isMobile && (
        <>
        <div className="steps-navigation">
          {step > 0 && (
            <button className="prev-button" onClick={prevStep}>
              <FontAwesomeIcon icon={faArrowLeft} /> Anterior
            </button>
          )}
          {step < 2 && (
            <button className="next-button" onClick={nextStep}>
              Siguiente <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}    
        </div>
        {step === 2 && (
            <div className="submit-button-container">
            <button className="submit-button"  disabled={!isFormValid} onClick={handleSubmit}>
              <FontAwesomeIcon icon={faCheckCircle} /> Enviar mis fotos
            </button>
          </div>
          )}
        </>
      )}
    </div>
  );
};

export default UploadPhotosMobile;








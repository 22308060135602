import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getQuotationById,
    setQuotationToEdit,
    deleteQuotation
} from "../../../store/actions";
import Maps from "../../../components/UI/Maps/Maps";
import Botonera from "../../Botonera/Botonera";
import { useParams } from "react-router-dom";
import FondoObra from "../../../assets/images/fondoObraPrivada.jpg";
import styles from "./PublicQuotationById.module.scss";

const PublicQuotationById = ({
    publicQuotation,
    getQuotationById,
    userData,
    ...props }) => {
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const { id } = useParams();

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
    };

    // Ejecutar handleResize al cargar el componente
    useEffect(() => {
        handleResize();
        getQuotationById(id, false, true);
    }, []);

    // Agregar un event listener para el cambio de tamaño de la ventana
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate();
        return `${day}/${month}/${year}`;
    };

    //Formateamos el monto a mostrar
    const formatMoney = (amount) => {
        return new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(amount);
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainTitle}>
                <div className={styles.mainTitle_background}>
                    <img src={FondoObra} alt="Fondo Obra" className={styles.mainTitle_img} />
                    <div className={styles.mainTitle_overlay}></div>
                </div>
            </div>
            <section className={styles.mainContent}>
                <h2 className={styles.mainContent_title}>COTIZACIÓN PUBLICA DE MATERIALES: "{publicQuotation?.name}"</h2>
                <div className={styles.mainContent_cards}>
                <div className={styles.mainContent_cards_datos}>
                    <h3 className={styles.mainContent_cards_datos_title}>INFORMACIÓN GENERAL</h3>
                    <div className={styles.mainContent_cards_datos_container}>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Ubicacion:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{publicQuotation?.jurisdiccion?.name}</p>
                        </div>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Ciudad:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{publicQuotation?.zone !== "" ? publicQuotation?.zone : "No especificó"}</p>
                        </div>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Moneda:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{publicQuotation?.currency !== "undefined" ? publicQuotation?.currency : "Pesos Arg"}</p>
                        </div>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Presupuesto oficial:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{formatMoney(publicQuotation?.monto)}</p>
                        </div>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Fecha de apertura:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{formatDate(publicQuotation?.startDate)}</p>
                        </div>
                        <div className={styles.mainContent_cards_datos_container_item}>
                            <p className={styles.mainContent_cards_datos_container_item_label}>Descripcion:</p>
                            <p className={styles.mainContent_cards_datos_container_item_value}>{publicQuotation?.description}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.mainContent_cards}>

                    <div className={styles.mainContent_cards_map}>
                        <Maps ciudad={publicQuotation?.jurisdiccion?.name} />
                    </div>
                    <div className={styles.mainContent_cards_subProyectos}>
                        <h3 className={styles.mainContent_cards_subProyectos_title}>TIPO DE OBRA</h3>
                        <div className={styles.mainContent_cards_subProyectos_container}>
                            <p className={styles.mainContent_cards_subProyectos_container_value}>{publicQuotation?.obra_type?.name}</p>
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_user}>
                        <h3 className={styles.mainContent_cards_user_title}>
                            COMITENTE
                        </h3>
                        <div className={styles.mainContent_cards_user_container}>
                            <div className={styles.mainContent_cards_user_container}>
                                <p className={styles.mainContent_cards_user_container_value}>{publicQuotation?.comitente}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_user}>
                        <h3 className={styles.mainContent_cards_user_title}>
                            LINK A LA DOCUMENTACIÓN
                        </h3>
                        <div className={styles.mainContent_cards_user_containerBtn}>
                            <a href={publicQuotation?.link} target="_blank" rel="noreferrer" className={styles.mainContent_cards_user_containerBtn_btn}>Descargar pliego</a>
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_user}>
                        <h3 className={styles.mainContent_cards_user_title}>
                            ORGANISMO
                        </h3>
                        <div className={styles.mainContent_cards_user_container}>
                            <div className={styles.mainContent_cards_user_container}>
                                <p className={styles.mainContent_cards_user_container_value}>{publicQuotation?.organismo?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section >
            {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
                null}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        proyecto: state.profile.proyectoPrivadoPorId,
        publicQuotation: state.profile.publicQuotation.quotation,
    };
}

export default connect(mapStateToProps, {
    getQuotationById,
    setQuotationToEdit,
    deleteQuotation,
})(PublicQuotationById); 
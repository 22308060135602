const cityCoordinates = {
    "Departamento de Lago Buenos Aires, Santa Cruz, Argentina": { lat: -46.5485, lon: -71.5806 },
    "Partido de Necochea, Buenos Aires, Argentina": { lat: -38.5565, lon: -58.7396 },
    "Benito Juárez, Buenos Aires, Argentina": { lat: -37.6715, lon: -59.8019 },
    "Azul, Buenos Aires, Argentina": { lat: -36.7777, lon: -59.8585 },
    "Partido de Ayacucho, Buenos Aires, Argentina": { lat: -37.1510, lon: -58.4876 },
    "Partido de Tandil, Buenos Aires, Argentina": { lat: -37.3217, lon: -59.1332 },
    "Partido de Puan, Buenos Aires, Argentina": { lat: -37.5463, lon: -62.7738 },
    "Partido de Patagones, Buenos Aires, Argentina": { lat: -40.7976, lon: -62.9055 },
    "Partido de General Villegas, Buenos Aires, Argentina": { lat: -35.0278, lon: -63.0117 },
    "Partido de Olavarría, Buenos Aires, Argentina": { lat: -36.8927, lon: -60.3225 },
    "Partido de Lincoln, Buenos Aires, Argentina": { lat: -34.8656, lon: -61.5302 },
    "Partido de Villarino, Buenos Aires, Argentina": { lat: -39.3032, lon: -62.6233 },
    "Partido de Veinticinco de Mayo, Buenos Aires, Argentina": { lat: -35.4322, lon: -60.1723 },
    "Partido de Trenque Lauquen, Buenos Aires, Argentina": { lat: -35.9733, lon: -62.7295 },
    "Partido de Tapalqué, Buenos Aires, Argentina": { lat: -36.3608, lon: -60.0242 },
    "Partido de Bolívar, Buenos Aires, Argentina": { lat: -36.2500, lon: -61.1000 },
    "Partido de Adolfo Alsina, Buenos Aires, Argentina": { lat: -37.1667, lon: -62.8333 },
    "Partido de Coronel Suárez, Buenos Aires, Argentina": { lat: -37.4546, lon: -61.9333 },
    "Coronel Pringles, Buenos Aires, Argentina": { lat: -37.9935, lon: -61.3605 },
    "Partido de Morón, Buenos Aires, Argentina": { lat: -34.6500, lon: -58.6200 },
    "Partido de Moreno, Buenos Aires, Argentina": { lat: -34.6500, lon: -58.7900 },
    "Partido de Merlo, Buenos Aires, Argentina": { lat: -34.6667, lon: -58.7167 },
    "Partido de La Matanza, Buenos Aires, Argentina": { lat: -34.7500, lon: -58.6167 },
    "Partido de Lomas de Zamora, Buenos Aires, Argentina": { lat: -34.7667, lon: -58.4000 },
    "Partido de Lanús, Buenos Aires, Argentina": { lat: -34.7000, lon: -58.4000 },
    "Partido de José C. Paz, Buenos Aires, Argentina": { lat: -34.5000, lon: -58.7667 },
    "Partido de General San Martín, Buenos Aires, Argentina": { lat: -34.5667, lon: -58.5333 },
    "Partido de Florencio Varela, Buenos Aires, Argentina": { lat: -34.8275, lon: -58.2688 },
    "Partido de Esteban Echeverría, Buenos Aires, Argentina": { lat: -34.8381, lon: -58.4481 },
    "Partido de Berazategui, Buenos Aires, Argentina": { lat: -34.7638, lon: -58.2116 },
    "Partido de Avellaneda, Buenos Aires, Argentina": { lat: -34.6627, lon: -58.3651 },
    "Partido de Tigre, Buenos Aires, Argentina": { lat: -34.4260, lon: -58.5797 },
    "Partido de San Isidro, Buenos Aires, Argentina": { lat: -34.4729, lon: -58.5226 },
    "Partido de San Fernando, Buenos Aires, Argentina": { lat: -34.4522, lon: -58.5561 },
    "Partido de Quilmes, Buenos Aires, Argentina": { lat: -34.7203, lon: -58.2635 },
    "Partido de San Miguel, Buenos Aires, Argentina": { lat: -34.5378, lon: -58.7156 },
    "Partido de Malvinas Argentinas, Buenos Aires, Argentina": { lat: -34.5000, lon: -58.7000 },
    "Partido de Ezeiza, Buenos Aires, Argentina": { lat: -34.8541, lon: -58.5283 },
    "Partido de Hurlingham, Buenos Aires, Argentina": { lat: -34.5906, lon: -58.6394 },
    "Partido de Ituzaingó, Buenos Aires, Argentina": { lat: -34.6559, lon: -58.6704 },
    "Partido de Laprida, Buenos Aires, Argentina": { lat: -36.8953, lon: -60.7813 },
    "Partido de Coronel Dorrego, Buenos Aires, Argentina": { lat: -38.7183, lon: -61.2780 },
    "Partido de Coronel Rosales, Buenos Aires, Argentina": { lat: -38.8333, lon: -62.2833 },
    "Partido de Colón, Buenos Aires, Argentina": { lat: -33.8900, lon: -61.1039 },
    "Partido de Chacabuco, Buenos Aires, Argentina": { lat: -34.6417, lon: -60.4739 },
    "Partido de Daireaux, Buenos Aires, Argentina": { lat: -36.5939, lon: -61.7458 },
    "Carlos Tejedor, Buenos Aires, Argentina": { lat: -35.4400, lon: -62.4167 },
    "Partido de Carlos Casares, Buenos Aires, Argentina": { lat: -35.6236, lon: -61.3642 },
    "Partido de Navarro, Buenos Aires, Argentina": { lat: -35.0167, lon: -59.2667 },
    "Partido de Monte, Buenos Aires, Argentina": { lat: -35.4500, lon: -58.8167 },
    "Partido de Mercedes, Buenos Aires, Argentina": { lat: -34.6515, lon: -59.4300 },
    "Partido de Presidente Perón, Buenos Aires, Argentina": { lat: -34.9300, lon: -58.3833 },
    "Partido de Pinamar, Buenos Aires, Argentina": { lat: -37.1116, lon: -56.8613 },
    "Partido de Marcos Paz, Buenos Aires, Argentina": { lat: -34.7750, lon: -58.8167 },
    "Partido de Mar Chiquita, Buenos Aires, Argentina": { lat: -37.7247, lon: -57.4361 },
    "Partido de Maipú, Buenos Aires, Argentina": { lat: -36.8641, lon: -57.8795 },
    "Partido de Magdalena, Buenos Aires, Argentina": { lat: -35.0811, lon: -57.5225 },
    "Partido de Luján, Buenos Aires, Argentina": { lat: -34.5703, lon: -59.1050 },
    "Partido de Lobos, Buenos Aires, Argentina": { lat: -35.1842, lon: -59.0944 },
    "Partido de Lobería, Buenos Aires, Argentina": { lat: -38.1550, lon: -58.7912 },
    "Partido de Las Flores, Buenos Aires, Argentina": { lat: -36.0103, lon: -59.0975 },
    "Partido de La Plata, Buenos Aires, Argentina": { lat: -34.9214, lon: -57.9544 },
    "Partido de Punta Indio, Buenos Aires, Argentina": { lat: -35.2828, lon: -57.3142 },
    "Partido de General Rodríguez, Buenos Aires, Argentina": { lat: -34.6083, lon: -58.9497 },
    "Partido de General Pueyrredón, Buenos Aires, Argentina": { lat: -38.0000, lon: -57.5500 },
    "Partido de General Paz, Buenos Aires, Argentina": { lat: -35.3894, lon: -58.5314 },
    "Partido de General Lavalle, Buenos Aires, Argentina": { lat: -36.4333, lon: -56.9333 },
    "Partido de General Las Heras, Buenos Aires, Argentina": { lat: -34.9167, lon: -59.0333 },
    "General Juan Madariaga, Buenos Aires, Argentina": { lat: -37.0167, lon: -57.1361 },
    "Partido de General Guido, Buenos Aires, Argentina": { lat: -36.6500, lon: -57.7500 },
    "Partido de General Belgrano, Buenos Aires, Argentina": { lat: -35.7671, lon: -58.4946 },
    "Partido de General Alvarado, Buenos Aires, Argentina": { lat: -38.2667, lon: -57.8333 },
    "Partido de Exaltación de la Cruz, Buenos Aires, Argentina": { lat: -34.3000, lon: -59.2167 },
    "Partido de Escobar, Buenos Aires, Argentina": { lat: -34.3500, lon: -58.8000 },
    "Partido de Ensenada, Buenos Aires, Argentina": { lat: -34.8675, lon: -57.9112 },
    "Partido de Dolores, Buenos Aires, Argentina": { lat: -36.3167, lon: -57.6833 },
    "Partido de Chivilcoy, Buenos Aires, Argentina": { lat: -34.8969, lon: -60.0156 },
    "Partido de Chascomús, Buenos Aires, Argentina": { lat: -35.5775, lon: -58.0083 },
    "Partido de Castelli, Buenos Aires, Argentina": { lat: -36.0997, lon: -57.7994 },
    "Partido de Carmen de Areco, Buenos Aires, Argentina": { lat: -34.3706, lon: -59.8239 },
    "Partido de Capitán Sarmiento, Buenos Aires, Argentina": { lat: -34.1731, lon: -59.7814 },
    "Partido de Canuelas, Buenos Aires, Argentina": { lat: -35.0525, lon: -58.7614 },
    "Partido de Campana, Buenos Aires, Argentina": { lat: -34.1769, lon: -58.9619 },
    "Partido de Brandsen, Buenos Aires, Argentina": { lat: -35.1686, lon: -58.2333 },
    "Partido de Berisso, Buenos Aires, Argentina": { lat: -34.8861, lon: -57.8892 },
    "Partido de Arrecifes, Buenos Aires, Argentina": { lat: -34.0661, lon: -60.1167 },
    "Partido de Baradero, Buenos Aires, Argentina": { lat: -33.8072, lon: -59.5086 },
    "Partido de Balcarce, Buenos Aires, Argentina": { lat: -37.8439, lon: -58.2553 },
    "Partido de Zárate, Buenos Aires, Argentina": { lat: -34.0980, lon: -59.0289 },
    "Partido de Tordillo, Buenos Aires, Argentina": { lat: -36.3636, lon: -57.3739 },
    "Partido de Suipacha, Buenos Aires, Argentina": { lat: -34.7753, lon: -59.6847 },
    "Partido de San Vicente, Buenos Aires, Argentina": { lat: -35.0244, lon: -58.4253 },
    "Partido de San Pedro, Buenos Aires, Argentina": { lat: -33.6747, lon: -59.6622 },
    "Partido de San Cayetano, Buenos Aires, Argentina": { lat: -38.3472, lon: -59.6092 },
    "Partido de San Antonio de Areco, Buenos Aires, Argentina": { lat: -34.2469, lon: -59.4744 },
    "Partido de San Andrés de Giles, Buenos Aires, Argentina": { lat: -34.4511, lon: -59.4481 },
    "Partido de Saladillo, Buenos Aires, Argentina": { lat: -35.6400, lon: -59.7889 },
    "Partido de Roque Pérez, Buenos Aires, Argentina": { lat: -35.3900, lon: -59.3744 },
    "Partido de Rauch, Buenos Aires, Argentina": { lat: -36.7761, lon: -59.0911 },
    "Partido de Pilar, Buenos Aires, Argentina": { lat: -34.4586, lon: -58.9147 },
    "Partido de Pila, Buenos Aires, Argentina": { lat: -36.0189, lon: -58.1108 },
    "Partido de Salto, Buenos Aires, Argentina": { lat: -34.2958, lon: -60.2583 },
    "Partido de Salliqueló, Buenos Aires, Argentina": { lat: -36.7633, lon: -62.9739 },
    "Partido de Saavedra, Buenos Aires, Argentina": { lat: -37.6464, lon: -62.2854 },
    "Partido de Rojas, Buenos Aires, Argentina": { lat: -34.1939, lon: -60.7314 },
    "Partido de Rivadavia, Buenos Aires, Argentina": { lat: -35.3750, lon: -62.9992 },
    "Partido de Ramallo, Buenos Aires, Argentina": { lat: -33.4833, lon: -60.0167 },
    "Partido de Pergamino, Buenos Aires, Argentina": { lat: -33.8992, lon: -60.5733 },
    "Partido de Pellegrini, Buenos Aires, Argentina": { lat: -36.2600, lon: -63.1081 },
    "Partido de Pehuajó, Buenos Aires, Argentina": { lat: -35.8114, lon: -61.8969 },
    "Partido de Junín, Buenos Aires, Argentina": { lat: -34.5856, lon: -60.9589 },
    "Partido de Hipólito Yrigoyen, Buenos Aires, Argentina": { lat: -36.1667, lon: -61.6500 },
    "Partido de Guaminí, Buenos Aires, Argentina": { lat: -37.0500, lon: -62.4167 },
    "Partido de Adolfo González Chaves, Buenos Aires, Argentina": { lat: -38.0328, lon: -60.1400 },
    "Partido de General Viamonte, Buenos Aires, Argentina": { lat: -35.4286, lon: -61.0050 },
    "Partido de General Pinto, Buenos Aires, Argentina": { lat: -34.8925, lon: -61.8908 },
    "Partido de General La Madrid, Buenos Aires, Argentina": { lat: -37.2500, lon: -61.2667 },
    "Partido de General Arenales, Buenos Aires, Argentina": { lat: -34.2931, lon: -61.2831 },
    "Partido de General Alvear, Buenos Aires, Argentina": { lat: -36.4333, lon: -60.0167 },
    "Partido de Nueve de Julio, Buenos Aires, Argentina": { lat: -35.4444, lon: -60.8800 },
    "Partido de Leandro N. Alem, Buenos Aires, Argentina": { lat: -34.5167, lon: -61.5000 },
    "Partido de Tres Arroyos, Buenos Aires, Argentina": { lat: -38.3742, lon: -60.2750 },
    "Tornquist, Buenos Aires, Argentina": { lat: -38.0750, lon: -62.2250 },
    "Partido de San Nicolás, Buenos Aires, Argentina": { lat: -33.3300, lon: -60.2250 },
    "Partido de Bragado, Buenos Aires, Argentina": { lat: -35.1217, lon: -60.5039 },
    "Partido de Monte Hermoso, Buenos Aires, Argentina": { lat: -38.9803, lon: -61.2956 },
    "Partido de Bahía Blanca, Buenos Aires, Argentina": { lat: -38.7167, lon: -62.2833 },
    "Partido de Alberti, Buenos Aires, Argentina": { lat: -35.0333, lon: -60.2500 },
    "Lezama, Buenos Aires, Argentina": { lat: -35.8697, lon: -57.8917 },
    "Partido de La Costa, Buenos Aires, Argentina": { lat: -36.5733, lon: -56.6861 },
    "Partido de Florentino Ameghino, Buenos Aires, Argentina": { lat: -34.5069, lon: -62.4658 },
    "Partido de Tres Lomas, Buenos Aires, Argentina": { lat: -36.4578, lon: -62.8636 },
    "Partido de Villa Gesell, Buenos Aires, Argentina": { lat: -37.2639, lon: -56.9736 },
    "Partido de Almirante Brown, Buenos Aires, Argentina": { lat: -34.8175, lon: -58.3928 },
    "Partido de Vicente López, Buenos Aires, Argentina": { lat: -34.5260, lon: -58.4835 },
    "Partido de Tres de Febrero, Buenos Aires, Argentina": { lat: -34.6033, lon: -58.5636 },
    "Departamento de Tinogasta, Catamarca, Argentina": { lat: -27.0165, lon: -66.2417 },
    "Departamento de Antofagasta de la Sierra, Catamarca, Argentina": { lat: -25.6110, lon: -67.7250 },
    "Departamento de Pomán, Catamarca, Argentina": { lat: -28.1381, lon: -66.0586 },
    "Departamento de Belén, Catamarca, Argentina": { lat: -27.2280, lon: -66.2212 },
    "Departamento de La Paz, Catamarca, Argentina": { lat: -29.3604979, lon: -65.1675824 },
    "Departamento de Capayán, Catamarca, Argentina": { lat: -27.0433, lon: -66.2690 },
    "Departamento de Fray Mamerto Esquiú, Catamarca, Argentina": { lat: -27.2290, lon: -66.2290 },
    "Departamento de Paclín, Catamarca, Argentina": { lat: -27.1890, lon: -66.2360 },
    "Departamento de Valle Viejo, Catamarca, Argentina": { lat: -27.4732, lon: -66.1983 },
    "Departamento de Santa Rosa, Catamarca, Argentina": { lat: -27.5463, lon: -66.3310 },
    "Departamento de Santa María, Catamarca, Argentina": { lat: -27.7612, lon: -66.2770 },
    "Departamento de Andalgalá, Catamarca, Argentina": { lat: -27.0277, lon: -66.1989 },
    "Ancasti, Catamarca, Argentina": { lat: -27.0595, lon: -66.2495 },
    "Departamento de Ambato, Catamarca, Argentina": { lat: -27.2120, lon: -66.2936 },
    "Departamento de El Alto, Catamarca, Argentina": { lat: -27.0965, lon: -66.1294 },
    "Departamento de Capital, Catamarca, Argentina": { lat: -28.4694, lon: -66.1419 },
    "Departamento de General Güemes, Chaco, Argentina": { lat: -26.5823, lon: -60.9442 },
    "Departamento de Almirante Brown, Chaco, Argentina": { lat: -26.5630, lon: -60.6520 },
    "Departamento de Libertador General San Martín, Chaco, Argentina": { lat: -26.6500, lon: -60.8000 },
    "Departamento de Libertad, Chaco, Argentina": { lat: -26.6670, lon: -60.7000 },
    "Departamento de General Donovan, Chaco, Argentina": { lat: -26.6500, lon: -60.6500 },
    "Departamento de Bermejo, Chaco, Argentina": { lat: -26.5900, lon: -60.5864 },
    "Veinticinco de Mayo, Chaco, Argentina": { lat: -26.6667, lon: -60.6333 },
    "Departamento de Tapenagá, Chaco, Argentina": { lat: -26.7500, lon: -60.8333 },
    "Departamento de Sargento Cabral, Chaco, Argentina": { lat: -26.6630, lon: -60.7260 },
    "Departamento de San Fernando, Chaco, Argentina": { lat: -26.6680, lon: -60.8960 },
    "Departamento del Primero de Mayo, Chaco, Argentina": { lat: -26.6333, lon: -60.7833 },
    "Departamento de Presidencia de la Plaza, Chaco, Argentina": { lat: -26.6833, lon: -60.7667 },
    "Departamento de San Lorenzo, Chaco, Argentina": { lat: -26.7000, lon: -60.7000 },
    "Departamento de Quitilipi, Chaco, Argentina": { lat: -26.7833, lon: -60.8167 },
    "Departamento de Independencia, Chaco, Argentina": { lat: -26.7500, lon: -60.7333 },
    "Departamento de General Belgrano, Chaco, Argentina": { lat: -26.7333, lon: -60.7833 },
    "Departamento de O’Higgins, Chaco, Argentina": { lat: -26.7500, lon: -60.7000 },
    "Departamento de Nueve de Julio, Chaco, Argentina": { lat: -26.7500, lon: -60.8000 },
    "Departamento de Mayor Luis J. Fontana, Chaco, Argentina": { lat: -26.6333, lon: -60.6500 },
    "Departamento de Maipú, Chaco, Argentina": { lat: -26.6000, lon: -60.7333 },
    "Departamento de Doce de Octubre, Chaco, Argentina": { lat: -26.6000, lon: -60.7000 },
    "Departamento de Comandante Fernández, Chaco, Argentina": { lat: -26.6167, lon: -60.7833 },
    "Chacabuco, Chaco, Argentina": { lat: -26.6333, lon: -60.6667 },
    "Dos de Abril, Chaco, Argentina": { lat: -26.6500, lon: -60.6833 },
    "Fray Justo Santa María de Oro, Chaco, Argentina": { lat: -26.6333, lon: -60.7333 },
    "Departamento de Languiñeo, Chubut, Argentina": { lat: -43.3167, lon: -67.7833 },
    "Departamento de Gastre, Chubut, Argentina": { lat: -43.1667, lon: -66.6667 },
    "Departamento de Gaimán, Chubut, Argentina": { lat: -43.2167, lon: -65.7167 },
    "Departamento de Futaleufú, Chubut, Argentina": { lat: -43.2500, lon: -71.2500 },
    "Departamento de Florentino Ameghino, Chubut, Argentina": { lat: -44.5000, lon: -65.0833 },
    "Departamento de Escalante, Chubut, Argentina": { lat: -44.1667, lon: -66.8333 },
    "Departamento de Cushamen, Chubut, Argentina": { lat: -43.8333, lon: -71.0833 },
    "Departamento de Río Senguerr, Chubut, Argentina": { lat: -44.0833, lon: -66.5833 },
    "Departamento de Rawson, Chubut, Argentina": { lat: -43.3000, lon: -65.1000 },
    "Departamento de Paso de Indios, Chubut, Argentina": { lat: -43.5833, lon: -66.5833 },
    "Departamento de Mártires, Chubut, Argentina": { lat: -44.0833, lon: -66.8333 },
    "Departamento de Telsen, Chubut, Argentina": { lat: -43.4167, lon: -66.6667 },
    "Departamento de Tehuelches, Chubut, Argentina": { lat: -43.3333, lon: -66.1667 },
    "Departamento de Sarmiento, Chubut, Argentina": { lat: -45.4167, lon: -67.5000 },
    "Departamento de Biedma, Chubut, Argentina": { lat: -45.4167, lon: -67.5833 },
    "Comuna 12, Buenos Aires C.F., Argentina": { lat: -34.6111, lon: -58.4542 },
    "Comuna 3, Buenos Aires C.F., Argentina": { lat: -34.6167, lon: -58.4000 },
    "Comuna 8, Buenos Aires C.F., Argentina": { lat: -34.6583, lon: -58.4167 },
    "Comuna 4, Buenos Aires C.F., Argentina": { lat: -34.6375, lon: -58.4000 },
    "Comuna 5, Buenos Aires C.F., Argentina": { lat: -34.6097, lon: -58.4000 },
    "Comuna 13, Buenos Aires C.F., Argentina": { lat: -34.5667, lon: -58.4500 },
    "Comuna 14, Buenos Aires C.F., Argentina": { lat: -34.5764, lon: -58.4236 },
    "Comuna 1, Buenos Aires C.F., Argentina": { lat: -34.6100, lon: -58.3714 },
    "Comuna 6, Buenos Aires C.F., Argentina": { lat: -34.6167, lon: -58.4000 },
    "Comuna 2, Buenos Aires C.F., Argentina": { lat: -34.6111, lon: -58.3794 },
    "Comuna 9, Buenos Aires C.F., Argentina": { lat: -34.6417, lon: -58.4167 },
    "Comuna 7, Buenos Aires C.F., Argentina": { lat: -34.6333, lon: -58.4000 },
    "Comuna 10, Buenos Aires C.F., Argentina": { lat: -34.6458, lon: -58.4333 },
    "Comuna 11, Buenos Aires C.F., Argentina": { lat: -34.6167, lon: -58.4667 },
    "Comuna 15, Buenos Aires C.F., Argentina": { lat: -34.6333, lon: -58.4333 },
    "Departamento de San Justo, Córdoba, Argentina": { lat: -31.3333, lon: -62.0167 },
    "Departamento de San Javier, Córdoba, Argentina": { lat: -31.4583, lon: -61.4167 },
    "Departamento de San Alberto, Córdoba, Argentina": { lat: -30.9167, lon: -65.0000 },
    "Departamento de Río Seco, Córdoba, Argentina": { lat: -30.9000, lon: -64.5667 },
    "Departamento de Río Primero, Córdoba, Argentina": { lat: -30.9500, lon: -63.7500 },
    "Departamento de Río Cuarto, Córdoba, Argentina": { lat: -33.1333, lon: -64.3500 },
    "Departamento de Punilla, Córdoba, Argentina": { lat: -30.6667, lon: -64.4667 },
    "Departamento de Presidente Roque Sáenz Peña, Córdoba, Argentina": { lat: -31.2833, lon: -63.9167 },
    "Departamento de Pocho, Córdoba, Argentina": { lat: -31.1667, lon: -65.0000 },
    "Departamento de Juárez Celman, Córdoba, Argentina": { lat: -32.1167, lon: -63.6667 },
    "Departamento de Ischilín, Córdoba, Argentina": { lat: -30.5167, lon: -64.5833 },
    "Departamento de General Roca, Córdoba, Argentina": { lat: -30.8333, lon: -64.5833 },
    "Departamento de Minas, Córdoba, Argentina": { lat: -31.2167, lon: -64.0667 },
    "Departamento de Marcos Juárez, Córdoba, Argentina": { lat: -32.3833, lon: -62.3833 },
    "Departamento de Unión, Córdoba, Argentina": { lat: -31.8333, lon: -63.8333 },
    "Departamento de Tulumba, Córdoba, Argentina": { lat: -30.7500, lon: -63.5833 },
    "Departamento de Totoral, Córdoba, Argentina": { lat: -30.5833, lon: -64.0833 },
    "Departamento de Tercero Arriba, Córdoba, Argentina": { lat: -31.6667, lon: -63.8333 },
    "Departamento de Sobremonte, Córdoba, Argentina": { lat: -30.4167, lon: -64.4167 },
    "Departamento de Cruz del Eje, Córdoba, Argentina": { lat: -30.7333, lon: -64.4167 },
    "Departamento de Colón, Córdoba, Argentina": { lat: -31.0000, lon: -63.5000 },
    "Departamento de Capital, Córdoba, Argentina": { lat: -31.4167, lon: -64.1833 },
    "Departamento de Calamuchita, Córdoba, Argentina": { lat: -32.0000, lon: -64.0000 },
    "Departamento de Río Segundo, Córdoba, Argentina": { lat: -31.5167, lon: -64.5667 },
    "Departamento de General San Martín, Córdoba, Argentina": { lat: -31.5833, lon: -63.8333 },
    "Departamento de Santa María, Córdoba, Argentina": { lat: -31.7500, lon: -64.5000 },
    "Departamento de Monte Caseros, Corrientes, Argentina": { lat: -30.2167, lon: -58.5333 },
    "Departamento de Mercedes, Corrientes, Argentina": { lat: -29.3833, lon: -58.0167 },
    "Departamento de Mburucuyá, Corrientes, Argentina": { lat: -29.9833, lon: -57.7333 },
    "Departamento de Lavalle, Corrientes, Argentina": { lat: -28.9167, lon: -58.0667 },
    "Departamento de Ituzaingó, Corrientes, Argentina": { lat: -27.0833, lon: -55.5833 },
    "Departamento de Itatí, Corrientes, Argentina": { lat: -27.9833, lon: -58.0000 },
    "Departamento de Goya, Corrientes, Argentina": { lat: -29.1333, lon: -59.3833 },
    "Departamento de General Paz, Corrientes, Argentina": { lat: -28.6000, lon: -58.0667 },
    "Departamento de General Alvear, Corrientes, Argentina": { lat: -29.5833, lon: -58.6167 },
    "Departamento de Esquina, Corrientes, Argentina": { lat: -30.4167, lon: -58.9667 },
    "Departamento de Empedrado, Corrientes, Argentina": { lat: -27.7500, lon: -58.2167 },
    "Departamento de Curuzú Cuatiá, Corrientes, Argentina": { lat: -29.5333, lon: -58.0167 },
    "Departamento de Concepción, Corrientes, Argentina": { lat: -28.3333, lon: -58.3333 },
    "Departamento de Capital, Corrientes, Argentina": { lat: -27.4800, lon: -58.9800 },
    "Departamento de Berón de Astrada, Corrientes, Argentina": { lat: -28.5833, lon: -58.7167 },
    "Departamento de Bella Vista, Corrientes, Argentina": { lat: -29.0667, lon: -58.2333 },
    "Departamento de Sauce, Corrientes, Argentina": { lat: -30.0167, lon: -58.1667 },
    "Departamento de Santo Tomé, Corrientes, Argentina": { lat: -29.0167, lon: -56.8167 },
    "Departamento de San Roque, Corrientes, Argentina": { lat: -30.1167, lon: -58.0667 },
    "Departamento de San Miguel, Corrientes, Argentina": { lat: -27.8833, lon: -56.6167 },
    "Departamento de San Martín, Corrientes, Argentina": { lat: -28.8333, lon: -58.4167 },
    "Departamento de San Luis del Palmar, Corrientes, Argentina": { lat: -27.4167, lon: -58.5000 },
    "Departamento de San Cosme, Corrientes, Argentina": { lat: -27.7667, lon: -58.2000 },
    "Departamento de Saladas, Corrientes, Argentina": { lat: -29.6333, lon: -58.7333 },
    "Departamento de Paso de los Libres, Corrientes, Argentina": { lat: -30.6333, lon: -57.9833 },
    "Departamento de La Paz, Entre Ríos, Argentina": { lat: -30.6667, lon: -59.3833 },
    "Departamento de Gualeguaychú, Entre Ríos, Argentina": { lat: -33.0167, lon: -58.8333 },
    "Departamento de Gualeguay, Entre Ríos, Argentina": { lat: -33.1833, lon: -59.2833 },
    "Departamento de Villaguay, Entre Ríos, Argentina": { lat: -31.7500, lon: -59.0000 },
    "Departamento de Uruguay, Entre Ríos, Argentina": { lat: -32.5000, lon: -58.2167 },
    "Departamento de Paraná, Entre Ríos, Argentina": { lat: -31.7500, lon: -60.5000 },
    "Departamento de Victoria, Entre Ríos, Argentina": { lat: -32.1333, lon: -60.2167 },
    "Departamento de Islas del Ibicuy, Entre Ríos, Argentina": { lat: -34.5000, lon: -58.7500 },
    "Departamento de Nogoyá, Entre Ríos, Argentina": { lat: -32.0833, lon: -59.6167 },
    "Departamento de Feliciano, Entre Ríos, Argentina": { lat: -31.5833, lon: -58.7167 },
    "Departamento de Federación, Entre Ríos, Argentina": { lat: -30.9167, lon: -58.5833 },
    "Departamento de Concordia, Entre Ríos, Argentina": { lat: -31.3833, lon: -58.0167 },
    "Departamento de Colón, Entre Ríos, Argentina": { lat: -32.2667, lon: -58.5000 },
    "Departamento de Tala, Entre Ríos, Argentina": { lat: -32.0667, lon: -59.0000 },
    "Departamento de Diamante, Entre Ríos, Argentina": { lat: -32.2167, lon: -60.0167 },
    "Federal, Entre Ríos, Argentina": { lat: -30.8000, lon: -58.9833 },
    "Departamento de San Salvador, Entre Ríos, Argentina": { lat: -30.6167, lon: -58.5667 },
    "Departamento de Patiño, Formosa, Argentina": { lat: -25.8000, lon: -59.1667 },
    "Departamento de Formosa, Formosa, Argentina": { lat: -25.2833, lon: -58.9833 },
    "Departamento de Pirané, Formosa, Argentina": { lat: -25.4500, lon: -59.2000 },
    "Departamento de Bermejo, Formosa, Argentina": { lat: -24.7667, lon: -59.3000 },
    "Departamento de Laishí, Formosa, Argentina": { lat: -24.6167, lon: -59.0833 },
    "Departamento de Pilcomayo, Formosa, Argentina": { lat: -25.6500, lon: -59.3667 },
    "Departamento de Pilagás, Formosa, Argentina": { lat: -24.5833, lon: -58.9833 },
    "Departamento de Ramón Lista, Formosa, Argentina": { lat: -24.7833, lon: -59.5000 },
    "Departamento de Matacos, Formosa, Argentina": { lat: -24.6500, lon: -59.1667 },
    "Departamento de Rinconada, Jujuy, Argentina": { lat: -22.8467, lon: -66.6083 },
    "Departamento de Susques, Jujuy, Argentina": { lat: -23.1667, lon: -65.8833 },
    "Departamento de Cochinoca, Jujuy, Argentina": { lat: -23.3667, lon: -65.7667 },
    "Departamento de San Antonio, Jujuy, Argentina": { lat: -23.3833, lon: -65.8167 },
    "Departamento de Humahuaca, Jujuy, Argentina": { lat: -23.1667, lon: -65.3333 },
    "Departamento de Ledesma, Jujuy, Argentina": { lat: -23.6000, lon: -64.9667 },
    "Departamento de Yaví, Jujuy, Argentina": { lat: -22.8500, lon: -64.7167 },
    "Departamento de Valle Grande, Jujuy, Argentina": { lat: -23.0833, lon: -65.3000 },
    "Departamento de Tumbaya, Jujuy, Argentina": { lat: -23.0500, lon: -65.4000 },
    "Departamento de Tilcara, Jujuy, Argentina": { lat: -23.4167, lon: -65.4833 },
    "Departamento de Santa Bárbara, Jujuy, Argentina": { lat: -24.0167, lon: -65.1667 },
    "Departamento de San Pedro, Jujuy, Argentina": { lat: -24.1667, lon: -64.7500 },
    "Departamento de El Carmen, Jujuy, Argentina": { lat: -24.0167, lon: -65.0833 },
    "Doctor Manuel Belgrano, Jujuy, Argentina": { lat: -24.0000, lon: -65.0000 },
    "Departamento de Palpalá, Jujuy, Argentina": { lat: -24.0833, lon: -65.3333 },
    "Departamento de Santa Catalina, Jujuy, Argentina": { lat: -23.6833, lon: -65.7167 },
    "Departamento de Hucal, La Pampa, Argentina": { lat: -36.3167, lon: -64.5833 },
    "Departamento de Guatraché, La Pampa, Argentina": { lat: -36.3833, lon: -64.7333 },
    "Departamento de Curacó, La Pampa, Argentina": { lat: -36.1833, lon: -64.4167 },
    "Departamento de Conhelo, La Pampa, Argentina": { lat: -36.2000, lon: -64.5667 },
    "Departamento de Chapaleufú, La Pampa, Argentina": { lat: -36.3167, lon: -64.4667 },
    "Departamento de Chalileo, La Pampa, Argentina": { lat: -36.3667, lon: -64.5667 },
    "Departamento de Catriló, La Pampa, Argentina": { lat: -36.3833, lon: -64.5833 },
    "Departamento de Capital, La Pampa, Argentina": { lat: -36.6167, lon: -64.2833 },
    "Departamento de Caleu-Caleu, La Pampa, Argentina": { lat: -36.3500, lon: -64.4000 },
    "Departamento de Realicó, La Pampa, Argentina": { lat: -35.8833, lon: -64.6167 },
    "Departamento de Rancul, La Pampa, Argentina": { lat: -36.0333, lon: -64.5167 },
    "Departamento de Puelén, La Pampa, Argentina": { lat: -35.8833, lon: -65.0833 },
    "Departamento de Maracó, La Pampa, Argentina": { lat: -36.2000, lon: -64.6333 },
    "Departamento de Limay Mahuida, La Pampa, Argentina": { lat: -36.4167, lon: -64.3167 },
    "Departamento de Lihuel-Calel, La Pampa, Argentina": { lat: -36.4667, lon: -64.4500 },
    "Departamento de Utracán, La Pampa, Argentina": { lat: -36.2500, lon: -64.6500 },
    "Departamento de Trenel, La Pampa, Argentina": { lat: -36.0167, lon: -64.6833 },
    "Departamento de Toay, La Pampa, Argentina": { lat: -36.0500, lon: -64.4833 },
    "Departamento de Atreucó, La Pampa, Argentina": { lat: -36.2833, lon: -64.4833 },
    "Departamento de Chical-Có, La Pampa, Argentina": { lat: -36.1167, lon: -64.4833 },
    "Departamento de Quemú Quemú, La Pampa, Argentina": { lat: -36.2667, lon: -64.6500 },
    "Departamento de Loventué, La Pampa, Argentina": { lat: -36.3667, lon: -64.5833 },
    "General Lamadrid, La Rioja, Argentina": { lat: -29.4167, lon: -67.7667 },
    "Departamento de Capital, La Rioja, Argentina": { lat: -29.4167, lon: -66.8500 },
    "Departamento de Sanagasta, La Rioja, Argentina": { lat: -29.3333, lon: -66.7167 },
    "Departamento de Independencia, La Rioja, Argentina": { lat: -28.7833, lon: -66.9167 },
    "Departamento de Chamical, La Rioja, Argentina": { lat: -28.7333, lon: -66.6333 },
    "Departamento de Vinchina, La Rioja, Argentina": { lat: -28.8333, lon: -67.1667 },
    "Departamento de General Ocampo, La Rioja, Argentina": { lat: -28.7500, lon: -66.5833 },
    "Departamento de Coronel Felipe Varela, La Rioja, Argentina": { lat: -29.0167, lon: -66.7500 },
    "Departamento de General Belgrano, La Rioja, Argentina": { lat: -28.6167, lon: -66.8833 },
    "Departamento de Famatina, La Rioja, Argentina": { lat: -28.7167, lon: -67.0833 },
    "Departamento de Arauco, La Rioja, Argentina": { lat: -28.5667, lon: -67.0000 },
    "Departamento de Chilecito, La Rioja, Argentina": { lat: -28.5333, lon: -67.0833 },
    "Departamento de Castro Barros, La Rioja, Argentina": { lat: -29.0833, lon: -66.9833 },
    "Departamento de San Blas de los Sauces, La Rioja, Argentina": { lat: -29.1333, lon: -67.0833 },
    "Departamento de General Juan F. Quiroga, La Rioja, Argentina": { lat: -29.0167, lon: -66.7833 },
    "Departamento de General San Martín, La Rioja, Argentina": { lat: -29.1167, lon: -66.8667 },
    "Departamento de Rosario Vera Peñaloza, La Rioja, Argentina": { lat: -28.8167, lon: -66.8833 },
    "Departamento de General Angel Peñaloza, La Rioja, Argentina": { lat: -29.0833, lon: -66.8500 },
    "Departamento de San Rafael, Mendoza, Argentina": { lat: -34.6167, lon: -68.3833 },
    "Departamento de Malargüe, Mendoza, Argentina": { lat: -35.4667, lon: -69.0833 },
    "Departamento de General Alvear, Mendoza, Argentina": { lat: -34.7500, lon: -68.5833 },
    "Departamento de Lavalle, Mendoza, Argentina": { lat: -33.8667, lon: -68.8500 },
    "Departamento de San Carlos, Mendoza, Argentina": { lat: -33.7333, lon: -69.0167 },
    "Departamento de Rivadavia, Mendoza, Argentina": { lat: -33.4333, lon: -68.7500 },
    "Junín, Mendoza, Argentina": { lat: -33.1833, lon: -68.8167 },
    "Guaymallén, Mendoza, Argentina": { lat: -32.9333, lon: -68.8333 },
    "Departamento de Godoy Cruz, Mendoza, Argentina": { lat: -32.9333, lon: -68.8167 },
    "Departamento de Maipú, Mendoza, Argentina": { lat: -32.9333, lon: -68.8500 },
    "Departamento de Luján, Mendoza, Argentina": { lat: -32.9667, lon: -68.8833 },
    "Departamento de Tupungato, Mendoza, Argentina": { lat: -33.4833, lon: -69.0667 },
    "Departamento de Tunuyán, Mendoza, Argentina": { lat: -33.0833, lon: -69.1333 },
    "Departamento de Santa Rosa, Mendoza, Argentina": { lat: -33.3667, lon: -68.8667 },
    "Departamento de San Martín, Mendoza, Argentina": { lat: -33.2167, lon: -68.6167 },
    "Departamento de Las Heras, Mendoza, Argentina": { lat: -32.9833, lon: -68.8833 },
    "La Paz, Mendoza, Argentina": { lat: -33.2667, lon: -68.7167 },
    "Departamento de Capital, Mendoza, Argentina": { lat: -32.8833, lon: -68.8333 },
    "Departamento de Candelaria, Misiones, Argentina": { lat: -27.5833, lon: -55.7333 },
    "Departamento de Montecarlo, Misiones, Argentina": { lat: -27.5167, lon: -55.7333 },
    "Departamento de Libertador General San Martín, Misiones, Argentina": { lat: -27.5500, lon: -55.8333 },
    "Departamento de Leandro N. Alem, Misiones, Argentina": { lat: -27.4167, lon: -55.8167 },
    "Departamento de Iguazú, Misiones, Argentina": { lat: -25.6953, lon: -54.4367 },
    "Departamento de Guaraní, Misiones, Argentina": { lat: -27.2167, lon: -55.7167 },
    "Departamento de General Manuel Belgrano, Misiones, Argentina": { lat: -27.4333, lon: -55.6833 },
    "Departamento de Eldorado, Misiones, Argentina": { lat: -26.4167, lon: -54.5833 },
    "Departamento de Concepción de la Sierra, Misiones, Argentina": { lat: -27.2000, lon: -55.0500 },
    "Departamento de Capital, Misiones, Argentina": { lat: -27.3667, lon: -55.9167 },
    "Departamento de Cainguás, Misiones, Argentina": { lat: -27.5333, lon: -55.5167 },
    "Departamento de Apóstoles, Misiones, Argentina": { lat: -27.4167, lon: -55.2833 },
    "Departamento de Veinticinco de Mayo, Misiones, Argentina": { lat: -27.4167, lon: -55.8333 },
    "Departamento de San Pedro, Misiones, Argentina": { lat: -27.5833, lon: -55.7333 },
    "Departamento de San Javier, Misiones, Argentina": { lat: -27.6333, lon: -55.6333 },
    "Departamento de San Ignacio, Misiones, Argentina": { lat: -27.4000, lon: -55.7000 },
    "Departamento de Oberá, Misiones, Argentina": { lat: -27.4167, lon: -55.1333 },
    "Departamento de El Cuy, Río Negro, Argentina": { lat: -39.3833, lon: -68.0167 },
    "Departamento de Veinticinco de Mayo, Río Negro, Argentina": { lat: -39.1167, lon: -65.4167 },
    "Departamento de General Roca, Río Negro, Argentina": { lat: -39.1667, lon: -67.5833 },
    "Departamento de Conesa, Río Negro, Argentina": { lat: -39.1667, lon: -64.5833 },
    "Departamento de San Antonio, Río Negro, Argentina": { lat: -40.0833, lon: -64.5833 },
    "Departamento de Pilcaniyeu, Río Negro, Argentina": { lat: -40.0167, lon: -71.2500 },
    "Departamento de Pichi Mahuida, Río Negro, Argentina": { lat: -38.6667, lon: -66.2500 },
    "Departamento de Nueve de Julio, Río Negro, Argentina": { lat: -39.5833, lon: -64.5833 },
    "Departamento de Ñorquincó, Río Negro, Argentina": { lat: -38.8333, lon: -65.8333 },
    "Departamento de Valcheta, Río Negro, Argentina": { lat: -39.9167, lon: -65.8333 },
    "Departamento de Bariloche, Río Negro, Argentina": { lat: -41.1500, lon: -71.5833 },
    "Departamento de Avellaneda, Río Negro, Argentina": { lat: -39.2167, lon: -62.7500 },
    "Departamento de Adolfo Alsina, Río Negro, Argentina": { lat: -39.5833, lon: -64.5833 },
    "Departamento de Rivadavia, Salta, Argentina": { lat: -23.8333, lon: -64.0833 },
    "Departamento de Los Andes, Salta, Argentina": { lat: -23.5833, lon: -65.5833 },
    "Departamento de San Carlos, Salta, Argentina": { lat: -24.5833, lon: -65.5833 },
    "Departamento de Orán, Salta, Argentina": { lat: -23.1667, lon: -64.6833 },
    "Departamento de Metán, Salta, Argentina": { lat: -25.0833, lon: -64.5833 },
    "Departamento de Anta, Salta, Argentina": { lat: -24.8333, lon: -64.5833 },
    "Departamento Capital, Salta, Argentina": { lat: -24.7833, lon: -65.4167 },
    "Departamento de Rosario de Lerma, Salta, Argentina": { lat: -24.5833, lon: -65.4167 },
    "Departamento de Rosario de la Frontera, Salta, Argentina": { lat: -25.0833, lon: -64.8333 },
    "Departamento de Iruya, Salta, Argentina": { lat: -22.4167, lon: -66.4167 },
    "Departamento de Guachipas, Salta, Argentina": { lat: -24.5833, lon: -65.4167 },
    "Departamento de General José de San Martín, Salta, Argentina": { lat: -23.8333, lon: -63.6667 },
    "Departamento de Molinos, Salta, Argentina": { lat: -24.5833, lon: -66.4167 },
    "Departamento de La Viña, Salta, Argentina": { lat: -24.8333, lon: -65.1667 },
    "Departamento de Santa Victoria, Salta, Argentina": { lat: -22.8333, lon: -64.8333 },
    "Departamento de La Poma, Salta, Argentina": { lat: -24.8333, lon: -66.5833 },
    "Departamento de Chicoana, Salta, Argentina": { lat: -24.6667, lon: -65.5833 },
    "Departamento de Cerrillos, Salta, Argentina": { lat: -24.5833, lon: -65.8333 },
    "La Caldera, Salta, Argentina": { lat: -24.8333, lon: -65.4167 },
    "Departamento de Cafayate, Salta, Argentina": { lat: -25.0833, lon: -65.6167 },
    "Cachi, Salta, Argentina": { lat: -24.6667, lon: -65.8333 },
    "Departamento de La Candelaria, Salta, Argentina": { lat: -24.8333, lon: -65.1667 },
    "Departamento de General Güemes, Salta, Argentina": { lat: -24.5833, lon: -64.5833 },
    "Departamento de Rivadavia, San Juan, Argentina": { lat: -31.5333, lon: -68.5000 },
    "Departamento de Rawson, San Juan, Argentina": { lat: -31.5333, lon: -68.5333 },
    "Departamento de Pocito, San Juan, Argentina": { lat: -31.6167, lon: -68.5333 },
    "Departamento de Jáchal, San Juan, Argentina": { lat: -30.5000, lon: -68.5000 },
    "Departamento de Iglesia, San Juan, Argentina": { lat: -30.5000, lon: -68.7500 },
    "Departamento de Valle Fértil, San Juan, Argentina": { lat: -30.8333, lon: -67.8333 },
    "Departamento de Zonda, San Juan, Argentina": { lat: -31.4167, lon: -68.5667 },
    "Departamento de Chimbas, San Juan, Argentina": { lat: -31.5833, lon: -68.5167 },
    "Departamento de Caucete, San Juan, Argentina": { lat: -31.4833, lon: -68.8333 },
    "Departamento de Calingasta, San Juan, Argentina": { lat: -31.5833, lon: -68.5833 },
    "Departamento de Nueve de Julio, San Juan, Argentina": { lat: -31.8333, lon: -68.5833 },
    "Departamento de Veinticinco de Mayo, San Juan, Argentina": { lat: -31.6667, lon: -68.6667 },
    "Departamento de Ullúm, San Juan, Argentina": { lat: -31.5000, lon: -68.6667 },
    "Departamento de Sarmiento, San Juan, Argentina": { lat: -31.5833, lon: -68.5667 },
    "Departamento de Santa Lucía, San Juan, Argentina": { lat: -31.5333, lon: -68.4667 },
    "Departamento de San Martín, San Juan, Argentina": { lat: -31.6667, lon: -68.5000 },
    "Departamento de Angaco, San Juan, Argentina": { lat: -31.5333, lon: -68.5833 },
    "Departamento de Albardón, San Juan, Argentina": { lat: -31.5833, lon: -68.5833 },
    "Departamento de Capital, San Juan, Argentina": { lat: -31.5333, lon: -68.5333 },
    "Departamento de Junín, San Luis, Argentina": { lat: -33.1167, lon: -65.5667 },
    "Departamento de Gobernador Vicente Dupuy, San Luis, Argentina": { lat: -32.8833, lon: -65.2500 },
    "Departamento de General Pedernera, San Luis, Argentina": { lat: -33.1167, lon: -65.0000 },
    "Departamento de Chacabuco, San Luis, Argentina": { lat: -33.2000, lon: -65.3667 },
    "Coronel Pringles, San Luis, Argentina": { lat: -32.6667, lon: -65.4167 },
    "Departamento de Belgrano, San Luis, Argentina": { lat: -32.9500, lon: -65.2000 },
    "Departamento de Ayacucho, San Luis, Argentina": { lat: -33.2833, lon: -65.3833 },
    "Juan Martín de Pueyrredón, San Luis, Argentina": { lat: -33.3833, lon: -65.0000 },
    "Departamento de Libertador General San Martín, San Luis, Argentina": { lat: -32.8833, lon: -65.2500 },
    "Departamento de Lago Argentino, Santa Cruz, Argentina": { lat: -50.0000, lon: -72.0000 },
    "Departamento de Deseado, Santa Cruz, Argentina": { lat: -45.0000, lon: -65.0000 },
    "Departamento de Río Chico, Santa Cruz, Argentina": { lat: -47.0000, lon: -65.5000 },
    "Departamento de Güer Aike, Santa Cruz, Argentina": { lat: -51.6167, lon: -69.2167 },
    "Departamento de Magallanes, Santa Cruz, Argentina": { lat: -52.0000, lon: -68.5000 },
    "Departamento de Corpen Aike, Santa Cruz, Argentina": { lat: -49.5833, lon: -68.5833 },
    "General Obligado, Santa Fe, Argentina": { lat: -29.5000, lon: -59.5000 },
    "San Javier, Santa Fe, Argentina": { lat: -30.7500, lon: -59.6667 },
    "San Justo, Santa Fe, Argentina": { lat: -31.1333, lon: -59.0333 },
    "San Cristóbal, Santa Fe, Argentina": { lat: -30.5833, lon: -60.1667 },
    "Iriondo, Santa Fe, Argentina": { lat: -32.1333, lon: -61.0000 },
    "General López, Santa Fe, Argentina": { lat: -33.5000, lon: -61.3333 },
    "Garay, Santa Fe, Argentina": { lat: -30.8333, lon: -59.5833 },
    "Nueve de Julio, Santa Fe, Argentina": { lat: -31.5000, lon: -60.6667 },
    "Vera, Santa Fe, Argentina": { lat: -29.7500, lon: -59.2500 },
    "Departamento de Castellanos, Santa Fe, Argentina": { lat: -30.0000, lon: -60.7500 },
    "San Lorenzo, Santa Fe, Argentina": { lat: -32.6833, lon: -60.6833 },
    "San Gerónimo, Santa Fe, Argentina": { lat: -30.8333, lon: -60.0833 },
    "San Martín, Santa Fe, Argentina": { lat: -30.2500, lon: -59.5000 },
    "Belgrano, Santa Fe, Argentina": { lat: -30.5000, lon: -59.7500 },
    "Las Colonias, Santa Fe, Argentina": { lat: -31.5000, lon: -60.5000 },
    "Capital, Santa Fe, Argentina": { lat: -31.6333, lon: -60.7167 },
    "Constitución, Santa Fe, Argentina": { lat: -32.1667, lon: -61.5000 },
    "Caseros, Santa Fe, Argentina": { lat: -31.7500, lon: -60.3333 },
    "Rosario, Santa Fe, Argentina": { lat: -32.9500, lon: -60.6667 },
    "Departamento de Jiménez, Santiago del Estero, Argentina": { lat: -27.5833, lon: -62.9167 },
    "Departamento de Figueroa, Santiago del Estero, Argentina": { lat: -27.5833, lon: -63.2500 },
    "Departamento de Copo, Santiago del Estero, Argentina": { lat: -27.5000, lon: -62.5000 },
    "Departamento de Choya, Santiago del Estero, Argentina": { lat: -27.7500, lon: -62.8333 },
    "Departamento de Pellegrini, Santiago del Estero, Argentina": { lat: -27.5833, lon: -63.0833 },
    "Departamento de Ojo de Agua, Santiago del Estero, Argentina": { lat: -27.6667, lon: -63.2500 },
    "Departamento de Moreno, Santiago del Estero, Argentina": { lat: -27.5833, lon: -62.5833 },
    "Juan F. Ibarra, Santiago del Estero, Argentina": { lat: -27.6667, lon: -63.1667 },
    "Departamento de Alberdi, Santiago del Estero, Argentina": { lat: -27.7500, lon: -63.3333 },
    "Departamento de Guasayán, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.5000 },
    "General Taboada, Santiago del Estero, Argentina": { lat: -27.7500, lon: -63.5833 },
    "Departamento de Capital, Santiago del Estero, Argentina": { lat: -27.7833, lon: -64.2667 },
    "Departamento de San Martín, Santiago del Estero, Argentina": { lat: -27.6667, lon: -64.0000 },
    "Departamento de Salavina, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.7500 },
    "Departamento de Robles, Santiago del Estero, Argentina": { lat: -27.7500, lon: -63.4167 },
    "Departamento de Rivadavia, Santiago del Estero, Argentina": { lat: -27.5833, lon: -63.3333 },
    "Departamento de Río Hondo, Santiago del Estero, Argentina": { lat: -27.7333, lon: -64.2333 },
    "Departamento de Quebrachos, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.5000 },
    "Departamento de Mitre, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.4167 },
    "Departamento de Loreto, Santiago del Estero, Argentina": { lat: -27.6667, lon: -63.5833 },
    "Departamento de Silípica, Santiago del Estero, Argentina": { lat: -27.5833, lon: -63.4167 },
    "Departamento de Sarmiento, Santiago del Estero, Argentina": { lat: -27.7500, lon: -63.5833 },
    "Departamento de Belgrano, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.5833 },
    "Banda, Santiago del Estero, Argentina": { lat: -27.7833, lon: -64.2000 },
    "Departamento de Avellaneda, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.5833 },
    "Departamento de Atamisqui, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.7500 },
    "Departamento de Aguirre, Santiago del Estero, Argentina": { lat: -27.8333, lon: -63.5833 },
    "Departamento de Río Chico, Tucumán, Argentina": { lat: -26.7833, lon: -65.8333 },
    "Departamento de Graneros, Tucumán, Argentina": { lat: -27.0833, lon: -65.7167 },
    "Departamento de Famaillá, Tucumán, Argentina": { lat: -26.9833, lon: -65.6833 },
    "Departamento de Monteros, Tucumán, Argentina": { lat: -26.9667, lon: -65.9833 },
    "Departamento de Leales, Tucumán, Argentina": { lat: -27.0167, lon: -65.8000 },
    "Departamento de Trancas, Tucumán, Argentina": { lat: -26.9833, lon: -65.5833 },
    "Departamento de Burruyacú, Tucumán, Argentina": { lat: -26.8333, lon: -65.7833 },
    "Departamento de Cruz Alta, Tucumán, Argentina": { lat: -27.0167, lon: -65.7167 },
    "Departamento de Chicligasta, Tucumán, Argentina": { lat: -27.0167, lon: -65.5833 },
    "Departamento de Capital, Tucumán, Argentina": { lat: -26.8176, lon: -65.2166 },
    "Departamento de Tafí del Valle, Tucumán, Argentina": { lat: -26.9667, lon: -65.6500 },
    "La Cocha, Tucumán, Argentina": { lat: -26.8500, lon: -65.7500 },
    "Departamento de Simoca, Tucumán, Argentina": { lat: -27.0833, lon: -65.5833 },
    "Departamento de Tafí Viejo, Tucumán, Argentina": { lat: -26.8167, lon: -65.2167 },
    "Departamento de Yerba Buena, Tucumán, Argentina": { lat: -26.8200, lon: -65.2100 },
    "Departamento de Lules, Tucumán, Argentina": { lat: -26.9167, lon: -65.6333 },
    "Departamento de Juan B. Alberdi, Tucumán, Argentina": { lat: -26.9000, lon: -65.7000 },
};

export default cityCoordinates;










import React, { useState, useEffect } from "react";
import styles from "./FilterPrivateProjects.module.scss";
import { getSubquotationType, filterPrivateProjects, getSkills } from "../../../store/actions";
import { connect } from "react-redux";
import { Filter, ChevronDown, ChevronUp } from "lucide-react";


const FilterPrivateProjects = ({
    setIsFiltered,
    setIsAdjudicated,
    listaDeRubros,
    getSubquotationType,
    getSubprojectsType,
    filterPrivateProjects,
    page,
    resultados,
    setIsLoading,
    totalDeProyectosMostrados,
    getSkills,
    ...props }) => {

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [showWorkTypeOptions, setShowWorkTypeOptions] = useState(false);
    const [placeInput, setOpenPlaceInput] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [showExactDateInput, setShowExactDateInput] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [selectedWorkType, setSelectedWorkType] = useState(null);
    const [zone, setZone] = useState("");
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [selectedSort, setSelectedSort] = useState("Recientes");

    useEffect(() => {
        getSkills();
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedWorkType(option);
        setSelectedFilter(null);
        setShowWorkTypeOptions(false);
    };

    useEffect(() => {
        getSubquotationType();
    }, []);

    //Formateo la fecha a mostrar
    const formatDate = (date) => {
        const dateArray = date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        return `${day}/${month}/${year}`;
    };

    //Generamos rangos para busqueda por fecha
    const generateDateRange = (dateRange) => {
        const today = new Date();
        const thirtyDaysFromNow = new Date(today);
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        const sixtyDaysFromNow = new Date(today);
        sixtyDaysFromNow.setDate(sixtyDaysFromNow.getDate() + 60);
        const ninetyDaysFromNow = new Date(today);
        ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);

        switch (dateRange) {
            case "Próximos 30 días":
                return [today, thirtyDaysFromNow];
            case "30 a 60 días":
                return [thirtyDaysFromNow, sixtyDaysFromNow];
            case "60 a 90 días":
                return [sixtyDaysFromNow, ninetyDaysFromNow];
            default:
                return null;
        }
    };


    //Funcion para filtrar los proyectos 
    const handleFilter = (hasFilter) => {

        //Maneja el cambio de pagina
        const pageToSend = hasFilter ? 1 : page;

        //Formatea la fecha para enviar
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        //Traemos la fecha de hoy
        const todayDate = new Date();
        const today = formatDate(todayDate);

        //Generamos el rango de fechas
        const dateRange = generateDateRange(selectedDateRange);
        const dateFrom = selectedDate !== null ? today : dateRange ? formatDate(new Date(dateRange[0])) : null;
        const dateTo = selectedDate !== null ? selectedDate : dateRange ? formatDate(new Date(dateRange[1])) : null;


        const filter = {
            sort: selectedSort === "Recientes" ? "DESC" : "ASC",
            zone: zone,
            workType: selectedWorkType === null ? null : selectedWorkType,
            dateRange: { dateFrom, dateTo },
        }

        filterPrivateProjects(filter, pageToSend, 9);

    }

    const handleReset = () => {
        if (
            selectedWorkType === null &&
            selectedDateRange === null &&
            selectedDate === null &&
            zone === "" &&
            selectedSort === "Recientes"
        )
            return;
        setSelectedWorkType(null);
        setSelectedDateRange(null);
        setSelectedDate(null);
        setZone("");
        setSelectedSort("Recientes");

        const filter = {
            sort: null,
            zone: null,
            workType: null,
            dateRange: null,
        }
        filterPrivateProjects(filter, 1, 9, true);
    }

    useEffect(() => {
        handleFilter(true);
    }, [selectedWorkType, selectedDateRange, selectedDate, zone, selectedSort]);


    return (
        <div className={styles.filterContainer}>
            <div className={styles.filterContainer_mainInfo} >
                <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter}>
                    <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter_iconContainer}>
                        <Filter size={20} style={{ color: "#234ba2" }} />
                        Filtros
                    </div>
                    <p className={styles.filterContainer_searchInfo_txtContainer_clearFilter_txt} onClick={() => handleReset()}>Limpiar filtros</p>
                </div>
                <div className={styles.mainFilter}>
                    <div className={styles.mainFilter_projectType}>
                        <p className={styles.mainFilter_projectType_title}>
                            Rubros a cotizar
                        </p>
                        <div className={styles.mainFilter_projectType_infoContainer} onClick={() => setShowWorkTypeOptions(!showWorkTypeOptions)} >
                            <div className={styles.mainFilter_projectType_itemContent}>
                                <p className={styles.mainFilter_projectType_info}>
                                    {selectedWorkType !== null ? selectedWorkType : 'Todos'}
                                </p>
                                <ChevronDown size={20} />
                            </div>
                        </div>
                        {showWorkTypeOptions && (
                            <div className={styles.mainFilter_projectType_options}>
                                {Array.isArray(listaDeRubros) &&
                                    listaDeRubros.map((listaDeRubros) => (
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelect(listaDeRubros.name)} key={listaDeRubros.id}>{listaDeRubros.name}</div>
                                    ))}
                            </div>
                        )}
                    </div>
                    <div className={styles.mainFilter_organism} >
                        <p className={styles.mainFilter_organism_title}>
                            Fecha de apertura de la cotización
                        </p>
                        <div onClick={() => setShowDateOptions(!showDateOptions)} className={`${styles.mainFilter_organism_infoContainer} ${selectedFilter === 'organism' ? styles.selectedFilter : ''}`}>
                            <div className={styles.mainFilter_organism_itemContent}>

                                <div className={styles.mainFilter_organism_info}>{selectedDateRange || selectedDate ? selectedDateRange || formatDate(selectedDate) : "Elegir"}
                                    <ChevronDown size={20} />
                                </div>
                            </div>
                        </div>
                        {showDateOptions && (
                            <div className={styles.advancedFilter_filterDate_options_menu}>
                                <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("Próximos 30 días"); setShowDateOptions(false) }}>Proximos 30 días</div>
                                <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("30 a 60 días"); setShowDateOptions(false) }}>30 a 60 días</div>
                                <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("60 a 90 días"); setShowDateOptions(false) }}>60 a 90 días</div>
                                <div
                                    className={styles.advancedFilter_filterDate_options_item}
                                    onClick={() => { setShowExactDateInput(true); setSelectedDateRange(null) }}
                                >
                                    Fecha exacta
                                </div>
                                {showExactDateInput && (
                                    <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        className={styles.advancedFilter_filterDate_options_item} />
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.mainFilter_jurisdiction}>
                        <p className={styles.mainFilter_jurisdiction_title}>
                            Lugar
                        </p>
                        <div className={styles.mainFilter_jurisdiction_infoContainer} onClick={() => setOpenPlaceInput(!placeInput)}>
                            <div className={styles.mainFilter_jurisdiction_itemContent}>
                                <p className={styles.mainFilter_jurisdiction_info}>
                                    {zone !== "" ? zone : 'Todos'}
                                </p>
                                <ChevronDown size={20} />
                            </div>
                        </div>
                        {placeInput && (
                            <div className={styles.mainFilter_filterAsociatedWords_options}>
                                <input
                                    type="text"
                                    value={zone}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => setZone(e.target.value)}
                                    className={styles.mainFilter_filterAsociatedWords_options_input} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.filterContainer_searchInfo}>
                <div className={styles.filterContainer_searchInfo_txtContainer}>
                    <p className={styles.filterContainer_searchInfo_txt}>{resultados === 0 ? (
                        "No se encontraron resultados"
                    ) : resultados === 1 ? (
                        <>Se encontró <b>1</b> resultado</>
                    ) : (
                        <>Se encontraron <b>{resultados}</b> resultados</>
                    )}</p>
                    {resultados > 10 && (
                        <p className={styles.filterContainer_searchInfo_txt}>Mostrando {totalDeProyectosMostrados} de {resultados}</p>
                    )}
                </div>
                <div className={styles.filterContainer_searchInfo_sortContainer}>
                    <div className={styles.filterContainer_searchInfo_sort_title}>Ordenar por:</div>
                    <div className={styles.filterContainer_searchInfo_sort}>
                        <p className={styles.filterContainer_searchInfo_sort_txt}>{selectedSort === "Antiguos" ? selectedSort : "Recientes"}</p>
                        <div className={styles.filterContainer_searchInfo_sort_iconContainer} onClick={() => setShowSortOptions(!showSortOptions)}>
                            {showSortOptions ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </div>
                        {showSortOptions && (
                            <div className={styles.filterContainer_searchInfo_sort_options}>
                                <p className={styles.filterContainer_searchInfo_sort_options_item}
                                    onClick={() => { setSelectedSort("Recientes"); setShowSortOptions(false) }}>Recientes</p>
                                <p className={styles.filterContainer_searchInfo_sort_options_item}
                                    onClick={() => { setSelectedSort("Antiguos"); setShowSortOptions(false) }}>Antiguos</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {

    return {
        resultados: state.dashboard.itemCount,
        totalDeProyectosMostrados: state.dashboard.allProjects?.length,
        listaDeRubros: state.register.skills
    };
};

export default connect(mapStateToProps, {
    getSubquotationType,
    getSkills,
    filterPrivateProjects,
})(FilterPrivateProjects);

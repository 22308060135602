import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Backdrop from "../Modal/Backdrop/Backdrop";
import styles from "./NewModal.module.scss";
import Edify from "../../../assets/svg/edify.svg";


const WrapperModal = styled.div`
  position: fixed;
  top: 50px;
  border-radius: 11px;
  display: ${({ opened }) => (opened ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1005;
  background-color: #ffffff;

  .title {
    width: 100%;
    background-color: #2f6aae;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    h1 {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 61px;
      text-align: left;
      margin-left: 40px;
    }
  }
`; 

const InsideWrapper = styled.div`
  position: fixed;
  width: 100%;
  padding: 50px 70px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding:0px;
`;

const NewModal = ({  Icon, description, opened=true, buttonSend={}, buttonBack={}, close, children, height, width, noMargin, title }) => {
  return ReactDOM.createPortal(
    <div className={styles.generalContainer}>
      <Backdrop closeModal={close} opened={opened} />
      <WrapperModal opened={opened} height={height} width={width} noMargin={noMargin}>
      <div className={styles.container}>
      <img className={styles.edifyImg} src={Edify} alt={"edifyImg"}/>

      <div className={styles.vector}> <Icon/></div>

     <div className={styles.title}> {title} </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.btnSend} onClick={buttonSend.onClick}>
        {buttonSend.title}
      </div>
      <div className={styles.btnBack} onClick={buttonBack.onClick}>
        {buttonBack.title}
      </div>
    </div>
        <InsideWrapper>{children}</InsideWrapper>
      </WrapperModal>
    </div>,
    document.getElementById("root-modal")
  );
};

export default NewModal;

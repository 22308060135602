import * as actionTypes from "../actions/types";
import axios from "../../axios";
import img from "../../assets/images/user.jpg";
import { closeMainModal, openEdifySpinner, openMainModal } from "./profileActions";
import { loadUserData } from "./authActions";


function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const setImage = (file) => {
  return {
    type: actionTypes.SET_FILE,
    payload: file,
  };
};

const setPortfolioToReducer = (data) => {
  return {
    type: actionTypes.SET_PORTFOLIO,
    payload: data,
  };
};

const setSkillsToReducer = (data) => {
  return {
    type: actionTypes.SET_SKILLS,
    payload: data,
  };
};

export const resetWorkOfferPostulation = () => {
  return {
    type: actionTypes.SEND_WORK_OFFER_POSTULATION,
    payload: false,
  };
};

export const fetchIdentityPhotosRequest = () => {
  return {
    type: actionTypes.FETCH_IDENTITY_PHOTOS_WORKERS_REQUEST
  }
}

export const FetchIdentityPhotosSuccess = (data) => {

  return {
    type: actionTypes.FETCH_IDENTITY_PHOTOS_WORKERS_SUCCESS,
    payload: data
  }
}

export const FetchIdentityPhotosFailure = ({ error }) => {
  return {
    type: actionTypes.FETCH_IDENTITY_PHOTOS_WORKERS_FAILURE,
    error: error
  }
}

export const openIdentityModal = (value, email) => {
  return {
    type: actionTypes.OPEN_IDENTITY_MODAL,
    payload: value,
    email: email
  }
}

export const closeAll = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_PORTFOLIO_ADD });
    dispatch({ type: actionTypes.CLOSE_MODAL });
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_SKILL });
    dispatch({ type: actionTypes.CLOSE_EDIT_PROFILE_DATA });
  };
};

export const setPersona = (personaForm, file) => {

  const formData = new FormData();

  if (!personaForm.allCountry && personaForm.workZone) {
    formData.append("workZones", JSON.stringify(personaForm.workZone.map(zone => ({
      city: zone.city,
      jurisdiccion: zone.jurisdiccion,
      name: zone.name
    }))));
  }

  let fileToSend;
  if (!file.name) {
    fileToSend = dataURLtoFile(img, "defaultImage.jpg");
    formData.append("image", fileToSend);
  } else {
    formData.append("image", file);
  }
  formData.append("active", true);
  formData.append("dni_cuit", personaForm.dniCuil !== undefined ? personaForm.dniCuil : "");
  formData.append("name", personaForm.name !== undefined ? personaForm.name : "");
  formData.append("last_name", personaForm.lastName !== undefined ? personaForm.lastName : "");
  formData.append("phone", personaForm.tel !== undefined ? personaForm.tel : "");
  formData.append("profession", personaForm.field !== undefined || personaForm.field !== null ? personaForm.field : "");
  formData.append("address", personaForm.address !== undefined ? personaForm.address : "");
  formData.append("url_site_web", personaForm.webSite !== undefined ? personaForm.webSite : "");
  formData.append("allCountry", personaForm.allCountry || false);
  formData.append("type", "PERSON");

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch(loadUserData());
        dispatch({
          type: actionTypes.SET_PERSONA_FORM,
          payload: personaForm,
          file: file,
        });
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const setEmpresa = (empresaForm, file) => {

  const formData = new FormData();

  if (!empresaForm.allCountry && empresaForm.workZone) {
    formData.append("workZones", JSON.stringify(empresaForm.workZone.map(zone => ({
      city: zone.city,
      jurisdiccion: zone.jurisdiccion,
      name: zone.name
    }))));
  }

  let fileToSend;
  if (!file.name) {
    fileToSend = dataURLtoFile(img, "defaultImage.jpg");
    formData.append("image", fileToSend);
  } else {
    formData.append("image", file);
  }
  formData.append("active", true);
  formData.append("razon_social", empresaForm.companyName !== undefined ? empresaForm.companyName : "");
  formData.append("name", empresaForm.name !== undefined ? empresaForm.name : "");
  formData.append("last_name", empresaForm.last_name !== undefined ? empresaForm.last_name : "");
  formData.append("rubro", empresaForm.rubro !== undefined || empresaForm.rubro !== null ? empresaForm.rubro : "");
  formData.append("dni_cuit", empresaForm.cuit !== undefined ? empresaForm.cuit : "");
  formData.append("profession", empresaForm.field !== undefined ? empresaForm.field : "");
  formData.append("phone", empresaForm.tel !== undefined ? empresaForm.tel : "");
  formData.append("address", empresaForm.address !== undefined ? empresaForm.address : "");
  formData.append("url_site_web", empresaForm.webSite !== undefined ? empresaForm.webSite : "");
  formData.append("allCountry", empresaForm.allCountry || false);
  formData.append("type", "COMPANY");
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .patch("/user/update", formData, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_EMPRESA_FORM,
          payload: empresaForm,
          file: file,
        });
        dispatch(loadUserData());
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const getPortfolio = (userId) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = userId ? `/portfolio/user?userFind=${userId}` : "/portfolio/user";
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch(setPortfolioToReducer(res.data));
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const setPortfolio = (portfolioForm, files) => {
  const formData = new FormData();

  files.forEach((image) => {
    formData.append("images", image);
  });
  formData.append("name", portfolioForm.name);
  formData.append("startDate", portfolioForm.startDate);
  formData.append("endDate", portfolioForm.endDate);
  formData.append("description", portfolioForm.description);
  formData.append("zone", portfolioForm.zone);
  formData.append("type", portfolioForm.type);
  formData.append("priceUnit", portfolioForm.priceUnit);
  formData.append("price", portfolioForm.price);
  formData.append("currencyType", portfolioForm.currencyType);


  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .post("/portfolio/assign", formData, bearerToken)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Acabas de crear un nuevo proyecto en tu porfolio!",
            body: "El proyecto ya se encuentra publicado en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getPortfolio());
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar la carga de tu portafolio, por favor inténtalo nuevamente en unos minutos o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const deletePortfolio = (id, navigate) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios
      .delete("/portfolio/delete", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          idPortfolio: +id,
        },
      })
      .then((res) => {
        dispatch(getPortfolio());
        dispatch(
          openMainModal({
            title: "¡Acabas de eliminar tu portafolio!",
            body: "El portafolio ya no estará visible en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No pudimos eliminar el portafolio. Por favor vuelve a intentarlo mas tarde.",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const updatePortfolio = (portfolioForm, newImages, id) => {
  const formData = new FormData();
  let updatedAt = new Date();

  formData.append("name", portfolioForm.nameEdit);
  formData.append("startDate", portfolioForm.startDateEdit);
  formData.append("endDate", portfolioForm.endDateEdit);
  formData.append("description", portfolioForm.descriptionEdit);
  formData.append("zone", portfolioForm.zoneEdit);
  formData.append("updatedAt", updatedAt.toISOString());
  formData.append("id", id);

  if (newImages.length > 0) {
    newImages.forEach((image) => {
      formData.append(`images`, image);
    });
  }


  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = {
    headers: { Authorization: `Bearer ${authToken}` },
    'Content-Type': 'multipart/form-data'
  };

  return (dispatch) => {
    if (portfolioForm.startDateEdit > portfolioForm.endDateEdit) {
      dispatch(
        openMainModal({
          title: "¡Error!",
          body: "La fecha de inicio no puede ser mayor a la fecha de cierre",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    } else {
      axios
        .patch("/portfolio/update", formData, bearerToken)
        .then((res) => {
          dispatch(getPortfolio());
          dispatch(
            openMainModal({
              title: "¡Acabas de actualizar tu portfolio!",
              body: "Los cambios ya están visibles en tu perfil",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            openMainModal({
              title: "¡Error!",
              body: "No pudimos actualizar los cambios. Revisa los datos ingresados y vuelve a intentar.",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        });
    }
  };
};

//Borrar imagenes de portfolio

export const deleteImageFromPortfolio = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");

  return (dispatch) => {
    axios
      .delete("/portfolio/deletePortfoliotImage", {
        headers: { Authorization: `Bearer ${authToken}` },
        data: {
          id: id,
        },
      })
      .then((res) => {
        dispatch(getPortfolio());
        dispatch(
          openMainModal({
            title: "¡Acabas de eliminar una imagen de tu portafolio!",
            body: "La imagen ya no estará visible en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );

      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No pudimos eliminar la imagen. Por favor vuelve a intentarlo mas tarde.",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

//Bolsa de Trabajo 

//Trae todas las ciudades de una jurisdicción
export const getCities = (id) => {

  const sendUrl = `/bolsa-de-trabajo/list?province=${id}`;

  return (dispatch) => {
    axios
      .post(sendUrl, {}, {
        headers: {
          Authorization: "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
        },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CITIES,
          payload: res.data.cities,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Obtiene todas las ofertas de trabajo existentes

export const getAllWorkOffers = () => {

  return (dispatch) => {
    axios
      .get(`/bolsa-de-trabajo/all-job-requests`)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_JOB_REQUESTS,
          payload: { workOffers: res.data[0], total: res.data[1] }
        });
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


//Crea una nueva oferta de trabajo

export const createWorkOffer = (workOfferData) => {

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  const skillsIdToSend = workOfferData.skills.map((el) => parseInt(el.id));

  const workZonesToSend = workOfferData.workZone.map(zone => ({
    "city": zone.city,
    "jurisdiccion": zone.jurisdiccion,
    "name": zone.name
  }));

  const body = {
    "contactMethod": workOfferData.contactMethod,
    "alternativeContact": workOfferData.alternativeContact,
    "workZones": workZonesToSend,
    "skills": skillsIdToSend,
    "description": workOfferData.description,
    "salary": workOfferData.salary,
    "salaryType": workOfferData.salaryType,
    "immediateStart": workOfferData.inmediateStart,
    "startDate": workOfferData.startDate,
    "activeUntil": workOfferData.activeUntil,
    "currency": "$ARS"
  }

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post(`/bolsa-de-trabajo/new-job-request`, body, bearerToken)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Gracias por publicar tu oferta de trabajo!",
            body: "Tu oferta ya está publicada en nuestra bolsa de trabajo. ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No hemos podido procesar tu oferta, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};



//Crea un nuevo usuario en bolsa de trabajo

export const createWorkBagUser = (workBagData, navigate) => {

  const skillsIdToSend = workBagData.skills.map((el) => el.id);

  const body = {
    name: workBagData.name,
    lastname: workBagData.lastname,
    email: workBagData.email,
    phone: workBagData.phone,
    skills: skillsIdToSend,
    workZone: workBagData.workZone,
    unemploymentFund: workBagData.unemploymentFund,
    currentJob: workBagData.currentJob,
    employmentEndDate: workBagData.employmentEndDate
  }

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post(`/bolsa-de-trabajo/create`, body, {
        headers: {
          Authorization: "Basic ZWRpZnk6bGFjbGF2ZXNlY3JldGExMjM0",
        },
      })
      .then((res) => {
        console.log(res)
        dispatch(openEdifySpinner(false));
        if (res.data.register.identity_validated === null) {
          dispatch(
            openMainModal({
              title: "¡Gracias por registrarte!",
              body: "Tus datos se han cargado exitosamente en nuestra bolsa de trabajo. Si lo deseas, puedes validar tu identidad para mejorar tus chances de ser contactado por empleadores.",
              isSuccess: false,
              iconType: "success",
              yesLabel: "Validar identidad",
              noLabel: "No, gracias lo haré después",
              confirmHandler: () => {
                dispatch(openIdentityModal(true, res.data.register.email));
                dispatch(closeMainModal());
              },
              customCloseMainModal: () => {
                navigate("/register");
                dispatch(closeMainModal());
              }
            })
          );
        } else {
          dispatch(
            openMainModal({
              title: "¡Gracias por registrarte!",
              body: "Tus datos se han cargado exitosamente en nuestra bolsa de trabajo. Te contactaremos cuando tengamos una oferta que se ajuste a tu perfil. ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        if (err.response.status === 409) {
          dispatch(
            openMainModal({
              title: "¡Ups!",
              iconType: "error",
              body: "Ya existe un usuario registrado con ese email. Por favor intenta con otro email o escríbenos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
              noLabel: "Cerrar",
            })
          );
        } else {
          dispatch(
            openMainModal({
              title: "¡Ups!",
              iconType: "error",
              body: "No hemos podido procesar tus datos, por favor inténtelo nuevamente en unos minutos o escríbanos a contacto@edify.la ¡Muchas gracias!",
              isSuccess: true,
              confirmHandler: null,
            })
          );
        }
      });
  };
};


//Recupera los datos de todos los registros en bolsa de trabajo

export const getAllWorkBagUsers = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(`/bolsa-de-trabajo/all-registers`, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_WORKBAG_USERS,
          payload: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Filtra los registros de la bolsa de trabajo

export const filterWorkBagUsers = (filterParams) => {
  // Inicializa la URL base
  let sendUrl = `/bolsa-de-trabajo/get/getByFilter`;

  // Construye los parámetros de consulta
  let queryParams = [];
  if (filterParams.jurisdicciones && filterParams.jurisdicciones.length > 0) {
    queryParams.push(`jurisdicciones=${filterParams.jurisdicciones.join('&jurisdicciones=')}`);
  }
  if (filterParams.skills && filterParams.skills.length > 0) {
    queryParams.push(`skills=${filterParams.skills.join('&skills=')}`);
  }

  // Agrega los parámetros de consulta a la URL base
  if (queryParams.length > 0) {
    sendUrl += `?${queryParams.join('&')}`;
  }

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

  return (dispatch) => {
    axios
      .get(sendUrl, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.FILTER_WORKBAG_USERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//Trae los datos de una oferta de trabajo por id
export const getWorkOfferById = (id) => {
  return (dispatch) => {
    axios
      .get(`/bolsa-de-trabajo/job-request/${id}`)
      .then((res) => {
        dispatch({
          type: actionTypes.CURRENT_WORK_OFFER,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Envía una solicitud de trabajo a una oferta
export const sendWorkOfferPostulation = (workOfferId) => {
  const formData = new FormData();
  formData.append("jobRequestId", workOfferId.jobRequestId);
  formData.append("email", workOfferId.email);
  formData.append("name", workOfferId.name);
  formData.append("phone", workOfferId.phone);
  formData.append("cv", workOfferId.cv);
  formData.append("coverLetter", workOfferId.coverLetter);

  return (dispatch) => {
    axios
      .post(`/bolsa-de-trabajo/job-request-postulation`, formData)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Excelente!",
            body: "Ya le enviamos tus datos al empleador. En breve se pondrán en contacto contigo ¡Mucha suerte!",
            isSuccess: true,
            confirmHandler: null,
            customCloseMainModal: () => {
              dispatch({
                type: actionTypes.CLOSE_MAIN_MODAL,
              });
              dispatch({
                type: actionTypes.SEND_WORK_OFFER_POSTULATION,
                payload: true,
              });
            },
          })
        );
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Ups!",
            body: "No pudimos procesar tu postulación, por favor inténtalo nuevamente en unos minutos o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch({
          type: actionTypes.SEND_WORK_OFFER_POSTULATION,
          payload: false,
        });
      });
  };
};


//Trae la lista de todas las skills

export const getSkills = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get(`/skill/list`, bearerToken)
      .then((res) => {
        let datos = res.data.map((el) => {
          let name = el.name.charAt(0).toUpperCase() + el.name.slice(1);
          return { id: el.id, name: name };
        });
        dispatch(setSkillsToReducer(datos));
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Trae las Skills del usuario

export const getUserSkill = (id) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `/skill/user?userFind=${id}`;
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_SKILL,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Busca la skill dentro de la lista general de skills
export const searchSkill = (skill) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `skill/listByFilter?name=${skill}`;
  return (dispatch) => {
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.SEARCH_SKILL,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//Elimina Skill del usuario en el perfil

export const deleteSkillFromProfile = (skillId, id) => {

  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  let query = parseInt(skillId);
  return (dispatch) => {
    axios
      .delete(`skill/delete?skillId=${query}`, bearerToken)
      .then((res) => {
        dispatch(
          openMainModal({
            title: "¡Acabas de eliminar un rubro de tu perfil!",
            body: "El mismo no estará visible en tu perfil",
            isSuccess: true,
            confirmHandler: null,
          })
        );
        dispatch(getUserSkill(id));
      })
      .catch((err) => {
        dispatch(
          openMainModal({
            title: "¡Error!",
            body: "No pudimos eliminar el rubro. Por favor vuelve a intentarlo mas tarde o escríbenos a contacto@edify.la ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      });
  };
};

export const clearUserSkills = () => {
  return {
    type: actionTypes.CLEAR_USER_SKILLS,
  };
};

export const addSkill = (id) => {
  return {
    type: actionTypes.ADD_SKILL,
    payload: id,
  };
};

export const removeSkill = (id) => {
  return {
    type: actionTypes.REMOVE_SKILL,
    payload: id,
  };
};

const getPlanReducer = (data) => {
  return {
    type: actionTypes.GET_PLANS,
    payload: data,
  };
};

export const setTerminos = () => {
  return {
    type: actionTypes.SET_TERMINOS,
  };
};

export const getAllPlan = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get("/plan/list", bearerToken)
      .then((res) => {
        dispatch(getPlanReducer(res.data));
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const getUserPlan = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  return (dispatch) => {
    axios
      .get("/plan/user", bearerToken)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_PLAN,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

export const setPlanId = (id) => {
  return {
    type: actionTypes.SET_PLANID,
    payload: id,
  };
};

export const sendPlan = (planId, navigate) => {
  let planPost = { idPlan: planId };

  let authToken = localStorage.getItem("access-Token-Edify");
  return (dispatch) => {
    axios({
      method: "post",
      url: "/plan/assign",
      params: planPost,
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {

      })
      .catch((error) => {

      });
  };
};

export const sendSkills = (userSkills, navigate, values, file, isPerson) => {

  return async (dispatch) => {

    let userSkillPost = userSkills.map((elem) => parseInt(elem.id));
    let authToken = localStorage.getItem("access-Token-Edify");

    let bearerToken = {
      headers: { Authorization: `Bearer ${authToken}` },
      "Content-Type": "application/json",
    };

    const skillPostObj = {
      skillId: userSkillPost,
    };

    dispatch(openEdifySpinner(true));

    try {
      const res = await axios.post("/skill/assign", skillPostObj, bearerToken);
      if (isPerson)
        dispatch(setPersona(values, file));
      else dispatch(setEmpresa(values, file));

      if (navigate) navigate("/resume");

    } catch (err) {
      console.error(err);
    } finally {
      dispatch(openEdifySpinner(false));
    }
  };
};

export const fetchIdentityPhotos = () => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };
  const url = `bolsa-de-trabajo/identity-photos-with-job-board`;
  return (dispatch) => {
    dispatch(fetchIdentityPhotosRequest());
    axios
      .get(url, bearerToken)
      .then((res) => {
        dispatch(
          FetchIdentityPhotosSuccess(res.data)
        );
      })
      .catch((err) => {
        dispatch(FetchIdentityPhotosFailure(err.message));
      });
  };
};

export const validatedIdentityWorker = (id, isValid) => {
  let authToken = localStorage.getItem("access-Token-Edify");
  let bearerToken = {
    headers: { Authorization: `Bearer ${authToken}` },
    'Content-Type': 'application/json',
  };
  return async (dispatch) => {
    try {
      dispatch(openEdifySpinner(true));
      const res = await axios.patch(`/bolsa-de-trabajo/upadate/super/${id}`, {
        identity_validated: isValid,
        job_board_id: id
      }, bearerToken);

      dispatch(openEdifySpinner(false));

      if (res.data.identity_validated) {
        dispatch(
          openMainModal({
            title: "¡Identidad validada!",
            body: "La identidad de este trabajador ha sido validada",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      } else {
        dispatch(
          openMainModal({
            title: "¡Identidad Rechazada!",
            body: "La identidad de este trabajador ha sido rechazada.",
            isSuccess: true,
            confirmHandler: null,
          })
        );
      }
      await dispatch(fetchIdentityPhotos());

    } catch (err) {
      dispatch(openEdifySpinner(false));
      dispatch(
        openMainModal({
          title: "¡Error!",
          body: "No pudimos actualizar los cambios. Revisa los datos ingresados y vuelve a intentar.",
          isSuccess: true,
          confirmHandler: null,
        })
      );
    }
  };
};


export const sendIdentityPhotos = (file, email, navigate) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("dni_front", file.dniFront);
  formData.append("dni_back", file.dniBack);
  formData.append("selfie", file.selfie);

  return (dispatch) => {
    dispatch(openEdifySpinner(true));
    axios
      .post("/bolsa-de-trabajo/upload-identity-photos", formData)
      .then((res) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "¡Imagenes enviadas!",
            body: "El proceso de validación de tu identidad no es inmediato. Recibirás una notificación en tu correo electrónico una vez que se haya completado. Por favor, revisa también la carpeta de spam en caso de que no lo encuentres. Mientras tanto, te invitamos a registrarte en la plataforma completa de Edify y acceder a todas nuestras funcionalidades y servicios.",
            isSuccess: false,
            yesLabel: "Quiero registrarme en Edify",
            noLabel: "No, gracias",
            confirmHandler: () => {
              dispatch(closeMainModal());
              navigate("/register");
            },
          })
        )
      })
      .catch((err) => {
        dispatch(openEdifySpinner(false));
        dispatch(
          openMainModal({
            title: "Ups!",
            body: "Ocurrió un error al enviar las imágenes. Por favor, inténtalo nuevamente en unos minutos o escríbenos a contacto@edify.la. ¡Muchas gracias!",
            isSuccess: true,
            confirmHandler: null,
            noLabel: "Cerrar",
          })
        );
      });
  };
};


export const clearOnboardingForms = () => {
  return {
    type: actionTypes.CLEAR_ONBOARDING_FORM,
  };
};

export const setSuccess = () => {
  return {
    type: actionTypes.SET_SUCCESS,
  };
};

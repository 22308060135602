//Tipos de animaciones: https://www.framer.com/api/motion/animation/
//Tipos de transiciones: https://www.framer.com/api/motion/types/#transition

export const inOutVariants = {
  in: {
    opacity: 1,
    x: "-50%",
    y: "-50%"
  },
  out: {
    opacity: 0,
    x: "100%",
    y: "-50%"
  },
  exit: {
    opacity: 0,
    x: "-100%",
    y: "-50%"
  },
  transition: { ease: "easeInOut", duration: 0.5 }
};

export const opacityVariants = {
  in: (delay = 0) => ({
    opacity: 1,
    transition: { ease: "easeInOut", duration: 0.5, delay },
  }),
  out: {
    opacity: 0,
    transition: { ease: "easeInOut", duration: 0.5 },
  },
};

export const inOutVisualizer = {
  in: {
    scale: 1,
    opacity: 1
  },
  out: {
    scale: 0,
    opacity: 0
  },
  transition: { ease: "easeInOut", duration: 0.2 }
};

export const inOutNavbar = {
  in: {
    x: i => {
      return;
    }
  },
  out: {
    x: "-100%"
  },
  transition: { ease: "easeInOut", duration: 0.5, delay: 1 }
};

const pathD = "M0,98 L0,84.6441096 L28.2200096,84.6441096 C47.5499758,84.6441096 63.2200096,68.9740758 63.2200096,49.6441096 L63.2200096,42.6496991 C63.2200096,19.4568265 82.0215476,0.655288537 105.21442,0.655288537 L105.303038,0.655288537 C128.495911,0.655288537 147.297449,19.4568265 147.297449,42.6496991 L147.297449,49.6441096 C147.297449,68.9740758 162.967483,84.6441096 182.297449,84.6441096 L210,84.6441096 L210,84.6441096 L210,98 L0,98 Z";
// const pathInitial = "m-0.145982,97.708036l0,-7.146897l28.22001,0c19.32997,0 35,-8.385222 35,-18.728921l0,-3.742793c10.364715,-3.797852 20.845287,-7.58156 41.99441,-22.471714l0.08862,0c17.499576,14.014262 23.308727,18.673863 41.99441,22.471714l0,3.742793c0,10.343699 15.67003,18.728921 35,18.728921l27.70255,0l0,0l0,7.146897l-210,0z";

export const pathNavEffect = {
  in: {
    fill: "rgba(255, 255, 255, 1)",
    d: pathD
  },
  out: {
    fill: "rgba(255, 255, 255, 0)",
    d: ""
  },
  transition: { ease: "easeInOut", duration: 0.5, delay: 1 }
};

export const inOutTransition = {};

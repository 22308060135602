import moment from "moment";
import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  isAuth: false,
  componentLoading: false,
  error: false,
  success: false,
  recoverOpen: false,
  portfolioAdd: false,
  commentPosted: false,
  userData: {},
  usersRegisteredData: [],
  usersFeaturedData: [],
  accessData: {},
  compActive: "portfolio",
  profileVisualizer: {},
  isLoaded: false,
  loginType: "LOGIN",
  loginAnimation: false,
  openMainModal: false,
  openFeedTextModal: false,
  openNDAModal: false,
  mainModalConfig: {
    title: "",
    body: "",
    isSuccess: false,
    isPublished: false,
    confirmHandler: null,
    customCloseMainModal: null,
    noHandler: null,
    noActionNeeded: false,
    iconType: "",
  },
  feedModalConfig: {
    isSuccess: false,
    image: false,
    video: false,
    text: false,
  },
  edifySpinner: false,
  responsiveFall: false,
  showSuscriptionModal: false,
  isFreeTrialAvailable: false,
  workOfferModal: false,
  isFreeTrialActive: false,
  isUserPremium: false,
  isGuestAccount: false,
  isSuscriptionCancelled: false,
  isCancelledSuscriptionStillActive: false,
  reducedNav: true,
  userInfoNavStyle: false,
  showSendContactNotificationModal: {},
  calificationModal: {},
  filterUsersModal: false,
};

const generalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCESS:
      return {
        ...state,
        accessData: action.payload,
      };
    case actionTypes.OPEN_SEND_CONTACT_NOTIFICATION_MODAL:
      return {
        ...state,
        showSendContactNotificationModal: action.payload,
      };
    case actionTypes.CLOSE_SEND_CONTACT_NOTIFICATION_MODAL:
      return {
        ...state,
        showSendContactNotificationModal: false,
      };
    case actionTypes.OPEN_CALIFICATION_MODAL:
      return {
        ...state,
        calificationModal: action.payload,
      };
    case actionTypes.CLOSE_CALIFICATION_MODAL:
      return {
        ...state,
        calificationModal: false,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
      };

    case actionTypes.GET_DATA:
      const isFreeTrialActivated = action.payload.free_trial?.status === "in-course";
      const isPaymentPending = action.payload.subscription?.status === "pending";
      const isPaymentPaid = action.payload.subscription?.status === "active";
      const isSuscriptionCancelled = action.payload.subscription?.status === "expired";
      const isUserSuscribed = isPaymentPending || isPaymentPaid;
      const isCancelledSuscriptionStillActive = isSuscriptionCancelled && moment().isBefore(action.payload.subscription?.end_date);
      return {
        ...state,
        userData: action.payload,
        isFreeTrialAvailable:
          action.payload.free_trial?.status === "no-activated",
        isUserPremium: isUserSuscribed || isFreeTrialActivated || isCancelledSuscriptionStillActive,
        isFreeTrialActive: isFreeTrialActivated,
        isGuestAccount: action.payload.username === "invited@edify.la",
        isSuscriptionCancelled: isSuscriptionCancelled,
        isCancelledSuscriptionStillActive: isCancelledSuscriptionStillActive,
      };
    case actionTypes.NAV_STYLE:
      return {
        ...state,
        reducedNav: action.payload,
      }
    case actionTypes.USER_INFO_NAV_STYLE:
      return {
        ...state,
        userInfoNavStyle: action.payload,
      }
    case actionTypes.MY_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          stars: action.payload.stars,
          likeCount: action.payload.likeCount,
        },
      };
    case actionTypes.RECOVERY_START:
      return {
        ...state,
        success: false,
      };
    case actionTypes.RECOVERY_FAIL:
      return {
        ...state,
        error: true,
      };
    case actionTypes.RECOVERY_SUCCESS:
      return {
        ...state,
        success: true,
        recoverOpen: false,
        error: false,
      };
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        recoverOpen: true,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        recoverOpen: false,
        success: false,
      };
    case actionTypes.OPEN_FILTER_USERS_MODAL:
      return {
        ...state,
        filterUsersModal: true,
      };
    case actionTypes.CLOSE_FILTER_USERS_MODAL:
      return {
        ...state,
        filterUsersModal: false,
      };
    case actionTypes.OPEN_PORTFOLIO_ADD:
      return {
        ...state,
        portfolioAdd: true,
      };
    
    case actionTypes.CLOSE_PORTFOLIO_ADD:
      return {
        ...state,
        portfolioAdd: false,
        profileVisualizer: {},
      };
    case actionTypes.SET_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case actionTypes.SET_NEW_PROFILE_IMAGE:
      return {
        ...state,
        success: false,
      };
    case actionTypes.SET_PROFILE_VISUALIZER:
      return {
        ...state,
        profileVisualizer: action.payload,
        commentPosted: false,
      };
    case actionTypes.COMMENT_POSTED:
      return {
        ...state,
        commentPosted: action.payload,
      };
    case actionTypes.SET_SKILLS:
      return {
        ...state,
        isAuth: true,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuth: false,
      };
    case actionTypes.SKELETON_START:
      return {
        ...state,
        isLoaded: false,
      };
    case actionTypes.SKELETON_END:
      return {
        ...state,
        isLoaded: true,
      };
    case actionTypes.LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case actionTypes.SET_LOGIN_ANIMATION:
      return {
        ...state,
        loginAnimation: action.payload,
      };
    case actionTypes.OPEN_EDIFY_SPINNER:
      return {
        ...state,
        edifySpinner: action.payload,
      };
    case actionTypes.CLOSE_EDIFY_SPINNER:
      return {
        ...state,
        edifySpinner: false,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        componentLoading: true,
      };
    case actionTypes.END_LOADING:
      return {
        ...state,
        componentLoading: false,
      };
    case actionTypes.OPEN_WORKOFFER_MODAL:
      return {
        ...state,
        workOfferModal: true,
      };
    case actionTypes.CLOSE_WORKOFFER_MODAL:
      return {
        ...state,
        workOfferModal: false,
      };
    case actionTypes.OPEN_MAIN_MODAL: {
      return {
        ...state,
        openMainModal: true,
        mainModalConfig: { ...action.payload },
      };
    }
    case actionTypes.OPEN_FEED_TEXT_MODAL: {
      return {
        ...state,
        openFeedTextModal: true,
        feedModalConfig: { ...action.payload },
      };
    }
    case actionTypes.CLOSE_MAIN_MODAL: {
      return {
        ...state,
        openMainModal: false,
        mainModalConfig: { ...INITIAL_STATE.mainModalConfig },
      };
    }
    case actionTypes.CLOSE_FEED_TEXT_MODAL: {
      return {
        ...state,
        openFeedTextModal: false,
        feedModalConfig: { ...INITIAL_STATE.feedModalConfig },
      };
    }
    case actionTypes.OPEN_NDA_MODAL:
      return {
        ...state,
        openNDAModal: true,
      };
    case actionTypes.CLOSE_NDA_MODAL:
      return {
        ...state,
        openNDAModal: false,
      };
    case actionTypes.SET_RESPONSIVE_FALL:
      return {
        ...state,
        responsiveFall: action.payload,
      };
    case actionTypes.USERS_REGISTERED_DATA:
      return {
        ...state,
        usersRegisteredData: action.payload,
      };
    case actionTypes.USERS_FEATURED_DATA:
      return {
        ...state,
        usersFeaturedData: action.payload,
      };

    case actionTypes.OPEN_SUSCRIPTION_MODAL: {
      return {
        ...state,
        showSuscriptionModal: true,
      };
    }
    case actionTypes.CLOSE_SUSCRIPTION_MODAL: {
      return {
        ...state,
        showSuscriptionModal: false,
      };
    }
    
    default:
      return state;
  }
};

export default generalReducer;




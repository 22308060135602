import React, { useEffect } from "react";
import styles from "./ResponsiveFall.module.scss";
import Lottie from "react-lottie";
import blueScreenData from "../../../assets/lotties/blue-screen-mobile.json";
import fallbackVideo from "../../../assets/video/EdifyVideoComprimido1.mp4";
import { fireAnalytics } from "../../../utils/firebase";
import Expire from '../../Expire/Expire'
import { connect } from "react-redux";
import  { setResponsiveFall } from "../../../store/actions";

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ResponsiveFall = (props) => {
  useEffect(() => {
    fireAnalytics('mobile_project_video', { category: 'navigation' });
    setTimeout(function() {
      props.setResponsiveFall(false)
    }, 5000);
  }, []);

  return (
    <Expire delay={5000}>
    <div className={styles.wrap}>
      <video autoPlay loop muted playsInline>
        <source src={fallbackVideo} type='video/mp4' />
      </video>
      <Lottie
        options={{
          animationData: blueScreenData,
          ...defaultOptions,
        }}
      />
    </div>
    </Expire>
  );
};

const mapStateToProps = state => {
  return {
    modalOpen: state.general.recoverOpen,
  };
};

export default connect(mapStateToProps, {  setResponsiveFall })(
  ResponsiveFall
);

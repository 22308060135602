import React from "react";
import styles from "./MainContentWrapper.module.scss";

import { motion } from "framer-motion";
import { inOutVariants, inOutVisualizer } from "../../../utils/FramerConfigs";

const MainContentWrapper = ({
  children,
  title,
  isOnboarding,
  isVisualizer,
  hasAnimation,
  ...props
}) => {
  let variantsType;
  if (hasAnimation) variantsType = inOutVariants;
  else if (isVisualizer) variantsType = inOutVisualizer;

  //initial={false} animate={{scale: [0, 1.1, 1], opacity: [0, 1]}} transition={{ ease: "easeInOut", duration: .4 }}  exit={{ opacity: 0 }}

  return (
    // <motion.section className={`${styles.MainContentWrapper} ${isOnboarding ? styles.onboarding : ""} ${isVisualizer ? styles.visualizer : ""}`} style={props.styleCustom} initial={false} transition={{ ease: "easeInOut", duration: 0.2 }} animate={isVisualizer ? { scale: [0, 1.1, 1], opacity: [0, 1] } : {}} exit={{ opacity: 0 }}>
    <motion.section
      className={`${styles.MainContentWrapper} ${
        isOnboarding ? styles.onboarding : ""
      } ${isVisualizer ? styles.visualizer : ""}`}
      style={props.styleCustom}
      variants={variantsType}
      initial="out"
      animate="in"
      exit="exit">
      <div className={styles.title}>
        <h1>{title}</h1>
      </div>
      <div
        className={`${styles.container} ${
          isOnboarding ? styles.onboarding : ""
        }`}>
        {children}
      </div>
    </motion.section>
  );
};

export default MainContentWrapper;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./SendContactModal.module.scss";
import { sendContactNotification, closeSendContactNotificationModal, getCalificationsByUser } from "../../../store/actions";
import { ReactComponent as XIcon } from "../../../assets/svg/x.svg";
import { ReactComponent as ContactMessageIconRight } from "../../../assets/svg/contactMessageIconRight.svg";
import { ReactComponent as StarFull } from "../../../assets/svg/starFullSvg.svg";
import { ReactComponent as HalfStar } from "../../../assets/svg/halfStarSvg.svg";
import { ReactComponent as StarEmpty } from "../../../assets/svg/emptyStarSvg.svg";

const SendContactModal = ({
    sendContactNotification,
    closeSendContactNotificationModal,
    showContactNotificationModal,
    getCalificationsByUser,
    userSenderData,
}) => {
    const userNameInfo = showContactNotificationModal?.userData.type === "COMPANY" ? showContactNotificationModal?.userData.razon_social
        : showContactNotificationModal?.userData.name + " " + showContactNotificationModal?.userData.last_name;

    const userNameMail = userSenderData?.username;
    const userNamePhone = userSenderData && userSenderData.phone !== null ? userSenderData.phone : "";
    const userStars = showContactNotificationModal?.userData?.stars?.toFixed(1 - (showContactNotificationModal?.userData?.stars % 1 === 0));
    const [messageToSend, setMessageToSend] = useState(`Hola ${userNameInfo}! Me gustaría contactarte para hablar sobre tu perfil en Edify. ¿Podríamos agendar una reunión?${userNamePhone ? `\nContacto: ${userNameMail} - ${userNamePhone}` : `\nContacto: ${userNameMail}`}`);
    const defaultMessage = `Hola ${userNameInfo}! Me gustaría contactarte para hablar sobre tu perfil en Edify. ¿Podríamos agendar una reunión?${userNamePhone ? `\nContacto: ${userNameMail} - ${userNamePhone}` : `\nContacto: ${userNameMail}`}`;

    let stars = [];
    const starsCount = userStars !== null ? Math.floor(userStars) : 0;

    for (let i = 0; i < 5; i++) {
        if (i < starsCount) {
            stars.push(<StarFull key={i} width={12} height={12} />);
        } else if (i === starsCount && userStars % 1 !== 0) {
            stars.push(<HalfStar key={i} width={12} height={12} />);
        } else {
            stars.push(<StarEmpty key={i} width={12} height={12} />);
        }
    }



    return (
        <div className={styles.sendNotification}>
            <div className={styles.sendNotification_container}>
                <div className={styles.sendNotification_content}>
                    <div className={styles.sendNotification_header}>
                        <div className={styles.sendNotification_header_body}>                         
                         <div className={styles.sendNotification_userInfo}>
                            <img className={styles.sendNotification_userInfo_img} src={showContactNotificationModal?.userData.image_profile} alt="profile" />
                            <div className={styles.sendNotification_userInfo_txt}>
                                <div className={styles.sendNotification_header_txt}>
                            <p>Enviar mensaje a:</p>
                            <XIcon width={15} height={15} onClick={() => closeSendContactNotificationModal()} />
                            </div>
                                <p className={styles.sendNotification_userInfo_txt_name} >{userNameInfo}</p> 
                                <div className={styles.sendNotification_userInfo_stars}>
                                    {userStars > 0 && <p className={styles.sendNotification_userInfo_stars_number}>{userStars}</p>}
                                    <span className={styles.sendNotification_userInfo_stars_icons}>
                                    {stars}
                                    </span>
                                </div>
                            </div>
                        </div >   
                        </div>                                      
                    </div>
                    <div className={styles.sendNotification_content_body}>
                        
                        <p className={styles.sendNotification_subTitle}>Mensaje:</p>
                        <textarea
                            onChange={(e) => setMessageToSend(e.target.value)}
                            placeholder={defaultMessage}
                            className={styles.sendNotification_textarea}
                        ></textarea>
                        <div className={styles.sendNotification_buttons}>
                            <div className={styles.sendNotification_buttons_send}
                                onClick={() => sendContactNotification(parseInt(showContactNotificationModal?.userData.id), messageToSend, closeSendContactNotificationModal)}>
                                Enviar mensaje
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        showContactNotificationModal: state.general.showSendContactNotificationModal,
        userStars: state.profile.stars,
        userSenderData: state.general.userData,
    };
}

export default connect(mapStateToProps, { sendContactNotification, closeSendContactNotificationModal, getCalificationsByUser })(SendContactModal);
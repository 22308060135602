import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useSelector, useDispatch , connect} from 'react-redux';
import { getQuotationById, getMaterialsBySubquotationId, getSubQuotationsPostulation, deleteQuotation, openMainModal, PauseQuotation } from '../../../store/actions/profileActions';
import styles from './QuotationDetails.module.scss'; 
import QuotationForm from '../CotizadorDeMateriales/Quotationform';
import MaterialTable from '../CotizadorDeMateriales/MaterialTable';
import { useNavigate, useParams } from 'react-router-dom';
import NewNav from '../../Nav/NewNav';
import Botonera from '../../Botonera/Botonera';
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const QuotationDetails = ({ quotationData, materialsBySubquotation, getQuotationById, getMaterialsBySubquotationId, loading, getSubQuotationsPostulation, subQuotationsPostulations, deleteQuotation, openMainModal, PauseQuotation}) => {
    const [materials, setMaterials] = useState([]);
    const [receivedQuotes, setReceivedQuotes] = useState([]);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

  useEffect(() => {
    if (id) {
      getQuotationById(id, false, null);
      getMaterialsBySubquotationId(quotationData?.subquotation[0].id); 
      getSubQuotationsPostulation(quotationData?.subquotation[0].id);
     
    }
  }, [id]);

  useEffect(() => {
    if (materialsBySubquotation) {
      const transformedMaterials = Object.values(materialsBySubquotation).flat(); 
      setMaterials(transformedMaterials.map((category) => ({
        category_id: category.category_id,
        name: category.name,
        materials: category.materials,
      })));
    }
    if(subQuotationsPostulations) {
      setReceivedQuotes(subQuotationsPostulations);
    }
  }, [materialsBySubquotation, subQuotationsPostulations]);

  const handleEditClick = () => {
    navigate(`/cotizaciones/edit/${id}`);  
  };
  const handleDeleteQuotation = (id) => {
    openMainModal({
        title: "¿Estas seguro de querer eliminar la cotizacion?",
        body: "Si la eliminas no podrás recuperarla",
        isSuccess: false,
        confirmHandler: () => {
            deleteQuotation(id)
        }
    })
}

const handlePauseQuotation = (id) => {
    openMainModal({
        title: "¿Estas seguro de querer pausar la cotizacion?",
        body: "Si la pausas no podra ser vista por los usuarios de edify",
        isSuccess: false,
        confirmHandler: () => {
            PauseQuotation(id)
        }
    })
}

  if (loading) return <p>Cargando...</p>;

  return (
    <Container> 
    {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
    <div className={styles['quotation-details']}>
    <div className={styles['header']}>
      <h2>Solicitud de Cotización: {quotationData.name}</h2>
    </div>
  
    <div className={styles['main-content']}>
      <div className={styles['left-section']}>
        <div className={styles['general-info']}>
          <QuotationForm quotation={quotationData} />
        </div>
  
        <div className={styles['materials-section']}>
          <h3>Materiales</h3>
          <MaterialTable materials={materials} isEditable={false} />
          <p className={styles['iva-notice']}>*Impuesto al Valor Agregado (21%)</p>
        </div>
      </div>
  
      <div className={styles['right-section']}>
        <div className={styles['actions']}>
          <button className={styles['edit']} onClick={handleEditClick}>Editar</button>
          <button className={styles['pause']} onClick={() => handlePauseQuotation(id)}>Pausar</button>
          <button className={styles['delete']} onClick={() => handleDeleteQuotation(id)}>Eliminar</button>
        </div>
  
        <section className={styles['received-quotes']}>
          <h3>Recibidos</h3>
          {receivedQuotes.map((quote, idx) => (
            <div key={idx} className={styles['received-quote']}>
              <p>
                <strong>Creador:</strong> {quote.user.type === "COMPANY" ? quote.user.razon_social : `${quote.user.name} ${quote.user.last_name}`}
              </p>
              <p><strong>Válido hasta:</strong> {quote.endDate}</p>
            </div>
          ))}
        </section>
      </div>
    </div>
  </div>
  
  </Container>
  );
};

const mapStateToProps = (state) => ({
    quotationData: state.profile.quotation,
    materialsBySubquotation: state.profile.materialsBySubquotation, 
    loading: state.profile.loading,
    error: state.profile.error,
    subQuotationsPostulations: state.profile.subQuotationsPostulations,
  });
  
  const mapDispatchToProps = {
    getQuotationById,
    getMaterialsBySubquotationId,
    getSubQuotationsPostulation,
    deleteQuotation,
    openMainModal,
    PauseQuotation
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import styles from "./WorkOfferById.module.scss";
import { getWorkOfferById, sendWorkOfferPostulation, resetWorkOfferPostulation } from "../../store/actions";
import { useFormik } from "formik";
import Facebook from '../../assets/svg/facebookSvg.svg';
import Twitter from '../../assets/svg/twitterSvg.svg';
import LinkedIN from '../../assets/svg/linkedinSvg.svg';
import Instragram from '../../assets/svg/instagramSvg.svg';
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const variants = {
    hidden: { opacity: 0, x: '-100vw' },
    visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 50 } }
};

const successAnimation = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.6,
            ease: "easeInOut",
        },
    },
};

const WorkOfferById = ({ workOffer, getWorkOfferById, sendWorkOfferPostulation, workOfferPostulation, resetWorkOfferPostulation }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getWorkOfferById(id);
        resetWorkOfferPostulation();
    }, [id, getWorkOfferById]);

    const formik = useFormik({
        initialValues: {
            jobRequestId: parseInt(id),
            email: "",
            name: "",
            phone: "",
            cv: "",
            coverLetter: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Email inválido").required("*Campo requerido"),
            name: Yup.string().required("*Campo requerido"),
            phone: Yup.string()
                .matches(/^(?:\+54\s?)?(?:\(?[1-9]{2,4}\)?\s?)?[1-9]{1}[0-9]{6,7}$/, "*Número de teléfono inválido")
                .required("*Campo requerido"),
        }),
        onSubmit: (values) => {
            sendWorkOfferPostulation(values);
        }
    });

    if (!workOffer) return <p>Loading...</p>;

    const jobOfferUser = workOffer?.user;
    const jobOfferUserFullName = jobOfferUser?.type === "COMPANY"
        ? jobOfferUser?.razon_social
        : `${jobOfferUser?.name} ${jobOfferUser?.last_name}`;


    const handleRedirect = () => {
        navigate("/register");
        resetWorkOfferPostulation();
    }

    return (
        <main className={styles.container}>
            <motion.section
                className={styles.container_body}
                initial="hidden"
                animate="visible"
                variants={variants}
            >
                {workOfferPostulation ? (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className={styles.container_body_postulation_success}>
                        <h3 className={styles.container_body_postulation_success_title}>¡Tu postulación ha sido enviada con éxito!</h3>
                        <p className={styles.container_body_postulation_success_subTitle}>Hemos enviado toda tu información al empleador</p>
                        <div className={styles.socialsContainer}>

                            <motion.div
                                className={styles.successIconContainer}
                                variants={successAnimation}
                                initial="hidden"
                                animate="visible"
                            >
                                <img src="https://cdn-icons-png.flaticon.com/128/14025/14025534.png" alt="success" />
                            </motion.div>
                            <div className={styles.container_body_postulation_success_joinUs}>
                                ¿Querés formar parte de nuestra comunidad?
                            </div>
                            <div className={styles.container_body_postulation_success_joinUs_btnContainer}>
                                <button type="button" className={styles.container_body_postulation_success_joinUs_btnContainer_button} onClick={() => handleRedirect()} >Registrate</button>
                            </div>

                            <div className={styles.followUs}>
                                Seguinos en:
                            </div>
                            <div className={styles.icons}>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/p/Edify-100063884114544/"><img src={Facebook} alt="" /></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com"><img src={Twitter} alt="" /></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://ar.linkedin.com/company/edifyargentina"><img src={LinkedIN} alt="" /></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/edify.la/?hl=es-la"><img src={Instragram} alt="" /></a>
                            </div>
                        </div>
                    </motion.div>
                ) : (
                    <>
                        <div className={styles.container_header}>
                            <div className={styles.container_header_user}>
                                <img
                                    className={styles.container_header_user_img}
                                    src={jobOfferUser?.image_profile}
                                    alt="profile"
                                />
                            </div>
                            <h1>{jobOfferUserFullName}</h1>
                        </div>
                        <div className={styles.container_body_skills}>
                            <h4 className={styles.container_body_skills_title}>Rubro/s requeridos:</h4>
                            {workOffer.skill && workOffer?.skill.map((skill, index) => (
                                <span className={styles.container_body_description_text} key={index}>
                                    {skill?.skill?.name}
                                </span>
                            ))}
                        </div>
                        {workOffer?.description && (
                            <div className={styles.container_body_description}>
                                <h4 className={styles.container_body_description_title}>Descripción de tareas:</h4>
                                <p className={styles.container_body_description_text}>
                                    {workOffer.description}
                                </p>
                            </div>
                        )}
                        <div className={styles.container_body_postulation}>
                            <h4 className={styles.container_body_postulation_title}>Aplicar a esta oferta:</h4>
                            <form className={styles.container_body_postulation_form} onSubmit={formik.handleSubmit}>
                                <div className={styles.container_body_postulation_form_group}>
                                    <input
                                        className={styles.container_body_postulation_form_input}
                                        type="text"
                                        placeholder=""
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor="name" className={styles.container_body_postulation_form_label}>Nombre</label>
                                    <span className={styles.container_body_postulation_form_span}></span>
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className={styles.error}>{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className={styles.container_body_postulation_form_group}>
                                    <input
                                        className={styles.container_body_postulation_form_input}
                                        type="file"
                                        placeholder=""
                                        name="cv"
                                        id="cvInput"
                                        onChange={(event) => {
                                            formik.setFieldValue("cv", event.currentTarget.files[0]);
                                        }}
                                        onBlur={formik.handleBlur}
                                        accept=".pdf, .doc, .docx"
                                    />
                                    <label htmlFor="cvInput" className={styles.container_body_postulation_form_label}>Adjuntar CV</label>
                                    <span className={styles.container_body_postulation_form_span}></span>
                                </div>
                                <div className={styles.container_body_postulation_form_group}>
                                    <textarea
                                        className={styles.container_body_postulation_form_input}
                                        type="text"
                                        placeholder=""
                                        name="coverLetter"
                                        value={formik.values.coverLetter}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor="coverLetter" className={styles.container_body_postulation_form_label}>Carta de presentación</label>
                                    <span className={styles.container_body_postulation_form_span}></span>
                                </div>
                                <div className={styles.container_body_postulation_form_group}>

                                    <input
                                        className={styles.container_body_postulation_form_input}
                                        type="tel"
                                        placeholder=""
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor="phone" className={styles.container_body_postulation_form_label}>Teléfono</label>
                                    <span className={styles.container_body_postulation_form_span}></span>
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className={styles.error}>{formik.errors.phone}</div>
                                    ) : null}
                                </div>
                                <div className={styles.container_body_postulation_form_group}>
                                    <input
                                        className={styles.container_body_postulation_form_input}
                                        type="email"
                                        placeholder=""
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label htmlFor="email" className={styles.container_body_postulation_form_label}>Email</label>
                                    <span className={styles.container_body_postulation_form_span}></span>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className={styles.error}>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <button
                                    type="submit"
                                    className={styles.container_body_postulation_form_button}
                                    disabled={!formik.isValid || formik.isSubmitting}
                                >
                                    Aplicar
                                </button>
                            </form>
                        </div>
                    </>
                )}
            </motion.section>
        </main>
    );
}

const mapStateToProps = (state) => {
    return {
        workOffer: state.register.currentWorkOffer,
        workOfferPostulation: state.register.workOfferPostulation,
    };
}

export default connect(mapStateToProps, { getWorkOfferById, sendWorkOfferPostulation, resetWorkOfferPostulation })(WorkOfferById);



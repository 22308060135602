import React from "react";
import styles from "./FilterUsersModal.module.scss";
import AllRegisteredUsers from "../../RegisteredUsers/AllRegisteredUsers";
import { closeFilterUsersModal } from "../../../store/actions";
import { connect } from "react-redux";

const FilterUsersModal = ({ 
    setShowInfo,
    setIsLoading,
    setSelectedSkillsList,
    selectedSkillsList,
    initialState,
    setInitialState,
    closeFilterUsersModal,
    setCheckedItems,
    checkedItems,
    handleMakeSearch,
    resetCheckboxState,
    ...props
}) => {

    return (
        <div className={styles.backgroundOverlay}>
            <AllRegisteredUsers
              setShowInfo={setShowInfo}
              setIsLoading={setIsLoading}
              setSelectedSkillsList={setSelectedSkillsList}
              selectedSkillsList={selectedSkillsList}
              initialState={initialState}
              setInitialState={setInitialState}
              setCheckedItems={setCheckedItems}
              checkedItems={checkedItems}
              handleMakeSearch={handleMakeSearch}
              resetCheckboxState={resetCheckboxState}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
}

export default connect(mapStateToProps, {closeFilterUsersModal})(FilterUsersModal);
import React, { useState, useEffect } from 'react';
import styles from './WorkBag.module.scss';
import { connect } from 'react-redux';
import Logo from "../../assets/images/logoBig.png";
import LogoMobile from "../../assets/images/logo-navbar.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { getSkills, getCities, createWorkBagUser, openIdentityModal } from '../../store/actions';
import useGetTodayDate from '../../hooks/useGetTodayDate';
import PDF from "../../components/Footer/PDF/Terminos-y-condiciones-edify-2021.pdf";
import { Link } from "react-router-dom";
import WorkOffer from './WorkOffer';
import { ReactComponent as CheckIcon } from "../../assets/svg/check-white.svg";
import { useNavigate } from 'react-router-dom';

const WorkBag = ({
    getSkills,
    skills,
    getCities,
    cities,
    createWorkBagUser,
    openIdentityModal,
    ...props }) => {
    const [showJurisdictionList, setShowJurisdictionList] = useState(false);
    const [showCityList, setShowCityList] = useState(false);
    const [showSkillsList, setShowSkillsList] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [onHover, setOnHover] = useState("");
    const [showCitySuggestions, setShowCitySuggestions] = useState(false);
    const [cityInputValue, setCityInputValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isPhone, setIsPhone] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formViewInphone, setFormViewInPhone] = useState(false);

    useEffect(() => {
        getSkills()
    }, [])


    const today = useGetTodayDate();

    const navigate = useNavigate();

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth < 600) {
            setIsPhone(true);
        } else {
            setIsPhone(false);
            setFormViewInPhone(false);
        }
    }

    useEffect(() => {
        handleResize();
      }, []);
    
      // Agregar un event listener para el cambio de tamaño de la ventana
      useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        // Limpiar el event listener al desmontar el componente
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    


    const convertDateToISOFormat = (date) => {
        const [dd, mm, yyyy] = date.split('/');
        return `${yyyy}-${mm}-${dd}`;
    };

    const initialValues = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        skills: [],
        workZone: [],
        city: "",
        unemploymentFund: false,
        currentJob: false,
        employmentEndDate: "",
        termsAndConditions: false,
        selectedJurisdiction: null
    }

    const telRegex = /[0-9+]/;

    const formSchema = Yup.object({
        name: Yup.string().required("Nombre*"),
        lastname: Yup.string().required("Apellido*"),
        email: Yup.string().email("Email invalido").required("Email*"),
        phone: Yup.string().matches(telRegex, "*Solo se permiten números").min(6, "*Número no válido").required("Teléfono*"),
        skills: Yup.array().required("Debes seleccionar al menos un rubro de trabajo*"),
        workZone: Yup.array().required("Elige al menos una provincia*"),
        unemploymentFund: Yup.boolean().required("*Campo obligatorio"),
        currentJob: Yup.boolean().required("*Campo obligatorio"),
        employmentEndDate: Yup.string().when('currentJob', {
            is: true,
            then: Yup.string().required("*Debes seleccionar una fecha aprox."),
            otherwise: Yup.string()
        }),
        selectedJurisdiction: Yup.object().nullable(),
        termsAndConditions: Yup.boolean().oneOf([true], "*Debes aceptar los términos y condiciones")
    })

    const formik = useFormik({
        initialValues,
        validationSchema: formSchema,
        onSubmit: (values) => {
            createWorkBagUser(values, navigate);
            formik.setErrors({});
            formik.resetForm();
            
        }
    })

    useEffect(() => {
        if (formik.values.selectedJurisdiction) {
            getCities(formik.values.selectedJurisdiction?.id)
        }
    }, [formik.values.selectedJurisdiction?.id])

    //Definimos el array de jurisdicciones
    const jurisdictionArray = [
        { id: 1, name: "Buenos Aires" },
        { id: 2, name: "Catamarca" },
        { id: 3, name: "Chaco" },
        { id: 4, name: "Chubut" },
        { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
        { id: 6, name: "Córdoba" },
        { id: 7, name: "Corrientes" },
        { id: 8, name: "Entre Ríos" },
        { id: 9, name: "Formosa" },
        { id: 10, name: "Jujuy" },
        { id: 11, name: "La Pampa" },
        { id: 12, name: "La Rioja" },
        { id: 13, name: "Mendoza" },
        { id: 14, name: "Misiones" },
        { id: 15, name: "Neuquén" },
        { id: 16, name: "Río Negro" },
        { id: 17, name: "Salta" },
        { id: 18, name: "San Juan" },
        { id: 19, name: "San Luis" },
        { id: 20, name: "Santa Cruz" },
        { id: 21, name: "Santa Fe" },
        { id: 22, name: "Santiago del Estero" },
        { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
        { id: 24, name: "Tucumán" },
    ]

    const handleAddSkill = (skillName, skillId) => {
        const skillExists = formik.values.skills.some(skill => skill.id === skillId);
        if (!skillExists) {
            formik.setFieldValue('skills', [...formik.values.skills, { id: skillId, name: skillName }]);
        }
        setShowSkillsList(false);
        setShowSuggestions(false);
        setInputValue('');
    };

    const handleSuggestions = (value) => {

        if (value.length < 3) {
            setShowSuggestions(false)
        } else if (value.length >= 3) {
            setShowSkillsList(false)
            setShowSuggestions(true)
        }
        setInputValue(value)

    }

    const matchingSkills = skills?.filter((rubro) => {
        return rubro.name.toLowerCase().includes(inputValue.toLowerCase())
    })

    const handleCitySuggestions = (value) => {
        if (value.length < 3) {
            setShowCitySuggestions(false)
        } else if (value.length >= 3) {
            setShowCityList(false);
            setShowCitySuggestions(true);
        }
        setCityInputValue(value)
    }

    const matchingCities = cities?.filter((city) => {
        return city.name.toLowerCase().includes(cityInputValue.toLowerCase())
    })

    const handleMouseEnter = (index) => setOnHover(index);
    const handleMouseLeave = () => setOnHover("");
    const handleTouchStart = (index) => setOnHover(index);
    const handleTouchEnd = () => setOnHover("");


    return (
        <div>
            {!formViewInphone && (
                <main className={styles.workBagContainer}>
                    <section className={styles.workBag}>
                        <div className={styles.workBag_logoContainer}>
                            <img src={isPhone ? LogoMobile : Logo} alt="Logo de Edify" className={styles.workBag_logo} />
                        </div>
                        <div className={styles.workBag_main}>
                            <h1 className={styles.workBag_title}>Formulario de inscripción</h1>
                            <p className={styles.workBag_title_text}>
                                Estamos creando la bolsa de trabajo de la construcción más grande de Argentina.
                            </p>
                            <form onSubmit={formik.handleSubmit}>
                                <h2 className={styles.workBag_subtitle}>
                                    Datos personales
                                </h2>
                                <div className={styles.formGroup}>
                                    <div className={styles.formGroup_textInputContainer}>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder={formik.errors.name ? formik.errors.name : 'Nombre'}
                                            className={`${styles.workBag_input} ${formik.errors.name && styles.error}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.name} />
                                    </div>
                                    <div className={styles.formGroup_textInputContainer}>
                                        <input
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            placeholder={formik.errors.lastname ? formik.errors.lastname : 'Apellido'}
                                            className={`${styles.workBag_input} ${formik.errors.lastname && styles.error}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastname} />
                                    </div>
                                </div>
                                <div className={styles.formGroup}>
                                    <div className={styles.formGroup_textInputContainer}>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder={formik.errors.email ? formik.errors.email : 'Email'}
                                            className={`${styles.workBag_input} ${formik.errors.email && styles.error}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.email} />
                                    </div>
                                    <div className={styles.formGroup_textInputContainer}>
                                        <input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            placeholder={formik.errors.phone ? formik.errors.phone : 'Teléfono'}
                                            className={`${styles.workBag_input} ${formik.errors.phone && styles.error}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.phone} />
                                    </div>
                                </div>
                                <h2 className={styles.workBag_subtitle}>
                                    Contanos sobre tus areas de trabajo
                                </h2>
                                <div className={styles.formGroup_skills}
                                    onMouseEnter={() => handleMouseEnter(1)}
                                    onMouseLeave={handleMouseLeave}
                                    onTouchStart={() => handleTouchStart(1)}
                                    onTouchEnd={handleTouchEnd}
                                >
                                    {onHover === 1 && (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ delay: 0.5 }}
                                            className={styles.workBag_infoContainer}>
                                            <p className={styles.workBag_infoText}>
                                                Seleccioná los rubros en los que te desempeñás, podés elegir más de 1.
                                            </p>
                                        </motion.div>
                                    )}
                                    {formik.values.skills.length > 0 && (
                                        <div className={styles.workBag_skillsSelected}>
                                            {formik.values.skills.map((skill, index) => (
                                                <div
                                                    key={index}
                                                    className={styles.workBag_skillsSelected_item}
                                                    onClick={() => {
                                                        formik.setFieldValue('skills', formik.values.skills.filter((_, i) => i !== index))
                                                    }}
                                                >
                                                    {skill.name}&nbsp;X
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className={`${styles.workBag_workZoneContainer} ${formik.errors.skills && styles.error}`}>
                                        <input
                                            type='text'
                                            id='skills'
                                            name='skills'
                                            placeholder={formik.errors.skills ? formik.errors.skills : 'Ingresá 3 caracteres para ver sugerencias'}
                                            value={inputValue}
                                            className={`${styles.workBag_workZoneContainer_input} ${formik.errors.skills && styles.error}`}
                                            onChange={(e) => handleSuggestions(e.target.value)}
                                        />
                                        <ArrowDownIcon
                                            title='Ver lista de rubros'
                                            width={15}
                                            height={15}
                                            className={showSkillsList ? styles.workBag_skillsArrowDown : styles.workBag_skillsArrowUp}
                                            onClick={() => setShowSkillsList(!showSkillsList)} />
                                    </div>
                                    {showSkillsList && !showSuggestions && (
                                        <ul className={styles.workBag_skillsSelected_list}>
                                            {skills.map((skill) => (
                                                <li
                                                    key={skill.id}
                                                    className={styles.workBag_skillsSelected_itemList}
                                                    onClick={() => handleAddSkill(skill.name, skill.id)}
                                                >
                                                    {skill.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {showSuggestions && !showSkillsList && (
                                        <ul className={styles.workBag_skillsSelected_list}>
                                            {matchingSkills.map((suggestion) => (
                                                <li
                                                    key={suggestion.id}
                                                    className={styles.workBag_skillsSelected_itemList}
                                                    onClick={() => handleAddSkill(suggestion.name, suggestion.id)}
                                                >
                                                    {suggestion.name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <h2 className={styles.workBag_subtitle}>
                                    Lugares donde estas dispuesto a trabajar
                                </h2>
                                <div className={styles.formGroup_workZone}
                                    onMouseEnter={() => handleMouseEnter(2)}
                                    onMouseLeave={handleMouseLeave}
                                    onTouchStart={() => handleTouchStart(2)}
                                    onTouchEnd={handleTouchEnd}>
                                    {onHover === 2 && (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ delay: 0.5 }}
                                            className={styles.workBag_infoContainer}>
                                            <p className={styles.workBag_infoText}>
                                                Elegí la provincia y la ciudad donde estás dispuesto a trabajar, podés elegir más de 1.
                                            </p>
                                        </motion.div>
                                    )}
                                    {formik.values.workZone.length > 0 && (
                                        <div className={styles.workBag_workZoneSelected}>
                                            {formik.values.workZone.map((workZone, index) => (
                                                <div
                                                    key={index}
                                                    className={styles.workBag_workZoneSelected_item}
                                                    onClick={() => { formik.setFieldValue('workZone', formik.values.workZone.filter((_, i) => i !== index)) }}>
                                                    {workZone.name} - {workZone.city.split(",")[0]}&nbsp;X
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className={styles.formGroup_workZone_inputsContainer}>
                                        <div className={`${styles.workBag_workZoneContainer} ${formik.values.workZone.length === 0 && !formik.isValid && styles.error}`}>
                                            <input
                                                type='text'
                                                id='jurisdictionList'
                                                name='jurisdictionList'
                                                placeholder={formik.values.workZone.length === 0 && !formik.isValid ? formik.errors.workZone : 'Elegir provincia'}
                                                className={`${styles.workBag_workZoneContainer_input} ${formik.values.workZone.length === 0 && !formik.isValid && styles.error}`}
                                                value={formik.values.selectedJurisdiction?.name || ''}
                                                readOnly
                                                onChange={formik.handleChange}
                                            />
                                            <ArrowDownIcon
                                                title='Ver lista de provincias'
                                                width={15}
                                                height={15}
                                                className={showJurisdictionList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp}
                                                onClick={() => setShowJurisdictionList(!showJurisdictionList)} />
                                        </div>
                                        {showJurisdictionList && (
                                            <ul className={styles.workBag_workZoneList}>
                                                {jurisdictionArray.map((jurisdiction) => (
                                                    <li
                                                        key={jurisdiction.id}
                                                        className={styles.workBag_workZoneList_item}
                                                        onClick={() => {
                                                            formik.setFieldValue('selectedJurisdiction', { id: jurisdiction.id, name: jurisdiction.name });
                                                            setShowJurisdictionList(false);
                                                        }}>
                                                        {jurisdiction.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <div className={`${styles.workBag_workZoneCityContainer} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}>
                                            <input
                                                type='text'
                                                id='cityList'
                                                name='cityList'
                                                placeholder='Elegir ciudad'
                                                value={cityInputValue}
                                                onChange={(e) => handleCitySuggestions(e.target.value)}
                                                className={`${styles.workBag_workZoneInput} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                            />
                                            <ArrowDownIcon
                                                title='Ver lista de ciudades'
                                                width={15}
                                                height={15}
                                                className={`${showCityList ? styles.workBag_workZoneArrowDown : styles.workBag_workZoneArrowUp} ${!formik.values.selectedJurisdiction && styles.disabledInput}`}
                                                onClick={() => setShowCityList(!showCityList)} />
                                        </div>
                                        {showCityList && !showCitySuggestions && (
                                            <ul className={styles.workBag_workCityList}>
                                                {cities?.map((city) => (
                                                    <li
                                                        key={city.name}
                                                        className={styles.workBag_workCityList_item}
                                                        onClick={() => {
                                                            const isCityDuplicate = formik.values.workZone.some((zone) => zone.city === city.name && zone.jurisdiccion === formik.values.selectedJurisdiction.id);

                                                            if (!isCityDuplicate) {
                                                                const newWorkZone = [
                                                                    ...formik.values.workZone,
                                                                    {
                                                                        jurisdiccion: formik.values.selectedJurisdiction.id,
                                                                        name: formik.values.selectedJurisdiction.name,
                                                                        city: city.name
                                                                    }
                                                                ];

                                                                formik.setFieldValue('workZone', newWorkZone);
                                                                formik.setFieldValue('city', city.name);
                                                                formik.setFieldValue('selectedJurisdiction', null);
                                                                setShowCityList(false);
                                                                setShowCitySuggestions(false);
                                                                setCityInputValue('');
                                                            }
                                                        }}
                                                    >
                                                        {city.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {showCitySuggestions && !showCityList && (
                                            <ul className={styles.workBag_workCityList}>
                                                {matchingCities.map((city) => (
                                                    <li
                                                        key={city.name}
                                                        className={styles.workBag_workCityList_item}
                                                        onClick={() => {
                                                            const newWorkZone = [
                                                                ...formik.values.workZone,
                                                                {
                                                                    jurisdiccion: formik.values.selectedJurisdiction.id,
                                                                    name: formik.values.selectedJurisdiction.name,
                                                                    city: city.name
                                                                }
                                                            ];
                                                            formik.setFieldValue('workZone', newWorkZone);
                                                            formik.setFieldValue('city', city.name);
                                                            formik.setFieldValue('selectedJurisdiction', null);
                                                            formik.setFieldValue('city', "");
                                                            setShowCityList(false);
                                                            setShowCitySuggestions(false);
                                                            setCityInputValue('');
                                                        }}
                                                    >
                                                        {city.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <h2 className={styles.workBag_subtitle}>
                                    Situación laboral
                                </h2>
                                <div className={styles.formGroup_checkBox}>
                                    <label htmlFor="unemploymentFund">¿Posee libreta de fondo de desempleo?</label>
                                    <input type="checkbox" id="unemploymentFund" name="unemploymentFund" onChange={formik.handleChange} value={formik.values.unemploymentFund} />
                                    {formik.errors.unemploymentFund && formik.touched.unemploymentFund ? <span>{formik.errors.unemploymentFund}</span> : null}
                                </div>
                                <div className={styles.formGroup_checkBox}>
                                    <label htmlFor="currentJob">¿Tiene trabajo actualmente?</label>
                                    <input type="checkbox" id="currentJob" name="currentJob" onChange={formik.handleChange} value={formik.values.currentJob} />
                                    {formik.errors.currentJob && formik.touched.currentJob ? <span>{formik.errors.currentJob}</span> : null}
                                </div>
                                {formik.values.currentJob && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: 0.5 }}
                                        className={styles.formGroup_checkBox}>
                                        <label htmlFor="employmentEndDate" className={styles.formGroup_label}>Fecha aprox. de finalización de empleo</label>
                                        <div className={styles.formGroup_dateInputContainer}>
                                            <input
                                                type="date"
                                                id="employmentEndDate"
                                                name="employmentEndDate"
                                                min={convertDateToISOFormat(today)}
                                                className={styles.workBag_inputDate}
                                                onChange={formik.handleChange}
                                                value={formik.values.employmentEndDate} />
                                            {formik.errors.employmentEndDate ? <span className={styles.workBag_error}>{formik.errors.employmentEndDate}</span> : null}
                                        </div>
                                    </motion.div>
                                )}
                                <div className={styles.termsContainer}>
                                    <input
                                        id="termsAndConditions"
                                        name="termsAndConditions"
                                        type="checkbox"
                                        onChange={formik.handleChange}
                                    />
                                    <label
                                        htmlFor="termsAndConditions"
                                        className={`${styles.inputHelper} ${styles.inputHelperCheckbox} `}
                                    >
                                        <span className={styles.termsContainer_text}>
                                            Acepto los{" "}
                                            <Link
                                                to={PDF}
                                                style={{
                                                    color: "rgba(0, 0, 0, 0.37)",
                                                    textDecorationLine: "revert",
                                                }}
                                                target="_blank"
                                                download
                                            >
                                                Términos y Condiciones
                                            </Link>
                                        </span>
                                    </label>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    
                                    <button className={styles.buttonsContainer_btn} type="submit" disabled={!formik.isValid || !formik.dirty}>
                                        <CheckIcon className={styles.checkIcon} />
                                        Enviar</button>
                                </div>
                                {isPhone && (
                                    <div className={styles.buttonsContainer}>
                                <p className={styles.workBag_subtitle_phoneView} onClick={() => setFormViewInPhone(true)}>
                                    Ver búsquedas abiertas
                                </p>
                                </div>
                            )}
                            </form>
                            {!showForm && !isPhone && (
                                <AnimatePresence >
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className={styles.workBag_backGround_offers}>
                                        <div className={styles.workBag_backGround_overlay} />
                                        <p className={styles.workBag_backGround_text} onClick={() => setShowForm(!showForm)}>Quiero inscribirme</p>
                                    </motion.div>
                                </AnimatePresence>
                            )}
                        </div>
                    </section>
                    {!isPhone && (
                        <section className={styles.workOffersContainer}>
                            {showForm && (
                                <AnimatePresence >
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className={styles.workBag_backGround}>
                                        <div className={styles.workBag_backGround_overlay} />
                                        <p className={styles.workBag_backGround_text} onClick={() => setShowForm(!showForm)}> Ver búsquedas abiertas</p>
                                    </motion.div>
                                </AnimatePresence>
                            )}
                            <WorkOffer isPhone={isPhone} showForm={showForm} setShowForm={setShowForm}  />
                        </section>
                    )}
                </main>
            )}
            {isPhone && formViewInphone && (
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className={styles.workBag_viewInPhone}
                    >
                        <WorkOffer 
                            isPhone={isPhone} 
                            showForm={showForm} 
                            setShowForm={setShowForm} 
                            setFormViewInPhone={setFormViewInPhone} />
                    </motion.div>
                </AnimatePresence>
            )}
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        skills: state.register.skills,
        cities: state.register.cities,
    }
}

export default connect(mapStateToProps, { getSkills, getCities, createWorkBagUser, openIdentityModal })(WorkBag);
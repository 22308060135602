import React from "react";
import edifyLogo from "../../../assets/images/edify-logo.png";
import styles from "./Loading.module.scss";

export const loading = ({ noModal }) => {
  return (
    <div
      className={`${styles.container_loading} ${noModal ? styles.noModal : ""}`}
    >
      <div className={styles.spinnerEdifyContainer}>
            <img src={edifyLogo} alt="Edify" className={styles.spinnerEdify} />
            <div className={styles.spinner}></div>
          </div>
      <div className={styles.lds_roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default loading;

import React from "react";
import edifyLogo from "../../../assets/images/edify-logo.png";
import styles from "./SpinnerEdify.module.scss";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

const SpinnerEdify = ({ edifySpinner, noModal }) => {
  const spinnerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.2 },
    },
  };

  return (
    noModal ? (<div
      className={`${styles.container_loading} ${noModal ? styles.noModal : ""}`}
    >
      <div className={styles.spinnerEdifyContainer}>
        <img src={edifyLogo} alt="Edify" className={styles.spinnerEdify} />
        <div className={styles.spinner}></div>
        <p className={styles.loadingText}>Cargando...</p>
      </div>
      
      <div className={styles.lds_roller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>

      </div>

    </div>) : (
      <AnimatePresence>
        {edifySpinner && (
          <motion.div
            key="spinner"
            variants={spinnerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={styles.overlay}
          >
            <div className={styles.spinnerEdifyContainer}>
              <img src={edifyLogo} alt="Edify" className={styles.spinnerEdify} />
              <div className={styles.spinner}></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    )
  );
};


const mapStateToProps = (state) => {
  return {
    edifySpinner: state.general.edifySpinner,
  };
};

export default connect(mapStateToProps)(SpinnerEdify);
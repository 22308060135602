import React, { useState, useEffect } from "react";
import styles from "./FilterQuotation.module.scss";
import { filterQuotations, getSubquotationType } from "../../../store/actions";
import { ReactComponent as ArrowUp } from "../../../assets/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { connect } from "react-redux";
import { getCategories } from '../../../store/actions/categoryActions';
import { motion } from "framer-motion";
import { Filter, ChevronDown, ChevronUp, ClipboardList } from "lucide-react";

const FilterQuotation = ({
    filterQuotations,
    setIsFiltered,
    setIsAdjudicated,
    listaDeRubros,
    getSubquotationType,
    getCategories,
    page,
    resultados,
    setIsLoading,
    totalDeProyectosMostrados,
    ...props }) => {
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Publicas");
    const [selectedOptionOrganism, setSelectedOptionOrganism] = useState({ id: 0, name: "Todos" });
    const [selectedOptionJurisdiction, setSelectedOptionJurisdiction] = useState({ id: 0, name: "Todas" });
    const [showWorkTypeOptions, setShowWorkTypeOptions] = useState(false);
    const [showCurrencyOptions, setShowCurrencyOptions] = useState(false);
    const [showAmountOptions, setShowAmountOptions] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [showExactDateInput, setShowExactDateInput] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [selectedWorkType, setSelectedWorkType] = useState({ id: 0, name: "Todas" });
    const [selectedCurrency, setSelectedCurrency] = useState("Pesos");
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [asociatedWords, setAsociatedWords] = useState("");
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [selectedSort, setSelectedSort] = useState("Recientes");
    const [dateFilterHeader, setDateFilterHeader] = useState("Fecha de apertura");
    const [isFilteredQuotation, setIsFilteredQuotation] = useState(false);


    //Funcion que maneja el click en los filtros
    const handleFilterClick = (filterName) => {
        if (selectedFilter === filterName) {
            setSelectedFilter(null);
        } else {
            setSelectedFilter(filterName);
        }
    };

    //Maneja el el estado del título del filtro por fecha y los valores del rango de monto
    useEffect(() => {
        if (selectedCurrency === "Dólares") {
            setMinAmount("");
            setMaxAmount("");
        } else {
            setMinAmount("");
            setMaxAmount("");
        }
        if (selectedOption === "Privadas") {
            setDateFilterHeader("Fecha de cierre");
        } else {
            setDateFilterHeader("Fecha de apertura");
        }
    }, [selectedOption, selectedCurrency]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setSelectedFilter(null);
    };

    useEffect(() => {
        /* getCategories(); */
        getSubquotationType();
    }, []);

    const handleOptionSelectOrganism = (option, id) => {
        setSelectedOptionOrganism({ name: option, id: id });
        setSelectedFilter(null);
    };

    const handleOptionSelectJurisdiction = (option, id) => {
        setSelectedOptionJurisdiction({ name: option, id: id });
        setSelectedFilter(null);
    };


    //Formateo la fecha a mostrar
    const formatDate = (date) => {
        const dateArray = date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        return `${day}/${month}/${year}`;
    };

    //Formateo el monto a mostrar
    const formatAmount = (amount) => {
        const numericAmount = parseInt(amount, 10);
        if (!isNaN(numericAmount)) {
            return numericAmount.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0,
            });
        }
        return amount;
    };

    //Generamos rangos para busqueda por fecha
    const generateDateRange = (dateRange) => {
        const today = new Date();
        const thirtyDaysFromNow = new Date(today);
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        const sixtyDaysFromNow = new Date(today);
        sixtyDaysFromNow.setDate(sixtyDaysFromNow.getDate() + 60);
        const ninetyDaysFromNow = new Date(today);
        ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);

        switch (dateRange) {
            case "Próximos 30 días":
                return [today, thirtyDaysFromNow];
            case "30 a 60 días":
                return [thirtyDaysFromNow, sixtyDaysFromNow];
            case "60 a 90 días":
                return [sixtyDaysFromNow, ninetyDaysFromNow];
            default:
                return null;
        }
    };


    //Funcion para filtrar los proyectos 
    const handleFilter = (hasFilter) => {
        setIsLoading(true)
        setIsFiltered(hasFilter);
        setIsFilteredQuotation(true);

        //Maneja el cambio de pagina
        const pageToSend = hasFilter ? 1 : page;

        //Formatea la fecha para enviar
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        //Traemos la fecha de hoy
        const todayDate = new Date();
        const today = formatDate(todayDate);

        //Generamos el rango de fechas
        const dateRange = generateDateRange(selectedDateRange);
        const dateFrom = selectedDate !== null ? today : dateRange ? formatDate(new Date(dateRange[0])) : null;
        const dateTo = selectedDate !== null ? selectedDate : dateRange ? formatDate(new Date(dateRange[1])) : null;


        const filter = {
            sort: selectedSort === "Recientes" ? "DESC" : "ASC",
            asociatedWords: asociatedWords,
            zone: null,
            minAmount: minAmount,
            maxAmount: maxAmount,
            currency: selectedCurrency === "Dólares" ? "USD" : selectedOption === "Publicas" ? "$" : "ARS",
            jurisdiction: selectedOptionJurisdiction === "Todas" ? null : selectedOptionJurisdiction?.id,
            organism: selectedOptionOrganism === "Todos" ? null : selectedOptionOrganism?.id,
            workType: selectedWorkType === "Todas" ? null : selectedWorkType?.id,
            dateRange: { dateFrom, dateTo },
            projectType: selectedOption,
        }

        filterQuotations(filter, pageToSend, 9);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }

    const handleReset = () => {
        if (selectedOption === "Privadas") {
            const filter = {
                sort: "DESC",
                asociatedWords: "",
                zone: null,
                minAmount: "",
                maxAmount: "",
                currency: "ARS",
                jurisdiction: null,
                organism: null,
                workType: null,
                dateRange: { dateFrom: null, dateTo: null },
                projectType: "Privadas",
            }
            filterQuotations(filter, 1, 9);
            setSelectedOption("Privadas");
            setSelectedWorkType({ id: null, name: null });
            setSelectedCurrency("Pesos");
            setSelectedDate(null);
            setSelectedDateRange(null);
            setAsociatedWords("");
            setSelectedSort("Recientes");
            setSelectedFilter(null);
            setSelectedOptionJurisdiction("Todas");
        } else {
            if (selectedOption === "Publicas") {
                const filter = {
                    sort: "DESC",
                    asociatedWords: "",
                    zone: null,
                    minAmount: "",
                    maxAmount: "",
                    currency: "ARS",
                    jurisdiction: null,
                    organism: null,
                    workType: null,
                    dateRange: { dateFrom: null, dateTo: null },
                    projectType: "Publicas",
                }
                filterQuotations(filter, 1, 9);
                setSelectedOption("Publicas");
                setSelectedWorkType({ id: null, name: null });
                setSelectedCurrency("Pesos");
                setSelectedDate(null);
                setSelectedDateRange(null);
                setAsociatedWords("");
                setSelectedSort("Recientes");
                setSelectedFilter(null);
                setSelectedOptionJurisdiction("Todas");
            }
        }
    }

    //Definimos el array de jurisdicciones
    const jurisdictionArray = [
        { id: 0, name: "Todas" },
        { id: 1, name: "Buenos Aires" },
        { id: 2, name: "Catamarca" },
        { id: 3, name: "Chaco" },
        { id: 4, name: "Chubut" },
        { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
        { id: 6, name: "Córdoba" },
        { id: 7, name: "Corrientes" },
        { id: 8, name: "Entre Ríos" },
        { id: 9, name: "Formosa" },
        { id: 10, name: "Jujuy" },
        { id: 11, name: "La Pampa" },
        { id: 12, name: "La Rioja" },
        { id: 13, name: "Mendoza" },
        { id: 14, name: "Misiones" },
        { id: 15, name: "Neuquén" },
        { id: 16, name: "Río Negro" },
        { id: 17, name: "Salta" },
        { id: 18, name: "San Juan" },
        { id: 19, name: "San Luis" },
        { id: 20, name: "Santa Cruz" },
        { id: 21, name: "Santa Fe" },
        { id: 22, name: "Santiago del Estero" },
        { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
        { id: 24, name: "Tucumán" },
    ]

    //Definimos el array de tipo de obra
    const typeOfWorkArray = [
        { id: 0, name: "Todas" },
        { id: 1, name: "Obras Viales" },
        { id: 2, name: "Obras eléctricas" },
        { id: 3, name: "Obras de gas" },
        { id: 4, name: "Obras Sanitarias / Saneamiento" },
        { id: 5, name: "Obras de Arquitectura (Nuevas)" },
        { id: 6, name: "Obras de Arquitectura (Remodelaciones)" },
        { id: 7, name: "Servicios profesionales" },
        { id: 8, name: "Servicios de consultoría" },
        { id: 9, name: "Servicios varios" },
        { id: 10, name: "Obras menores y Pequeñas" },
        { id: 11, name: "Obras Hidráulicas" },
        { id: 12, name: "Materiales de Construcción" },
        { id: 13, name: "Maquinarias y Herramientas" },
        { id: 14, name: "Otras Obras" },
    ]

    useEffect(() => {
        handleFilter(true);
    }, [selectedOption, selectedOptionOrganism, selectedOptionJurisdiction, selectedWorkType, selectedCurrency, minAmount, maxAmount, selectedDate, selectedDateRange, asociatedWords, selectedSort]);

    return (
        <div className={styles.filterContainer}>
            <div className={styles.filterContainer_toggleContainer}>
                <div className={styles.filterContainer_toggleContainer_iconContainer}>
                    <ClipboardList size={20} style={{ color: "#234ba2" }} />
                    <p className={styles.filterContainer_toggleContainer_txt}>Tipo de cotización</p>
                </div>
                <div className={styles.filterContainer_toggleContainer_buttons}>
                    <button className={`${styles.filterContainer_toggleContainer_button} ${selectedOption === "Publicas" && styles.isSelected}`} onClick={() => handleOptionSelect("Publicas")}>
                        Públicas
                        {selectedOption === "Publicas" && (
                            <span className={styles.filterContainer_searchType_referenceContainer_spanPublic}></span>
                        )}
                    </button>
                    <button className={`${styles.filterContainer_toggleContainer_button} ${selectedOption === "Privadas" && styles.isSelected}`} onClick={() => handleOptionSelect("Privadas")}>
                        Privadas
                        {selectedOption === "Privadas" && (
                            <span className={styles.filterContainer_searchType_referenceContainer_spanPrivate}></span>
                        )}
                    </button>
                </div>
            </div>

            <div className={styles.filterContainer_mainInfo}>
                <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter}>
                    <div className={styles.filterContainer_searchInfo_txtContainer_clearFilter_iconContainer}>
                        <Filter size={20} style={{ color: "#234ba2" }} />
                        Filtros
                    </div>
                    <p className={styles.filterContainer_searchInfo_txtContainer_clearFilter_txt} onClick={() => handleReset()}>Limpiar filtros</p>
                </div>
                <div className={styles.mainFilter}>
                    {(selectedOption === "Publicas" || selectedOption === "Todas") && (
                        <div className={styles.mainFilter_projectType} onClick={() => handleFilterClick('organism')}>
                            <div className={styles.mainFilter_projectType_infoContainer}>
                                <p className={styles.mainFilter_projectType_title}>
                                    Tipo de organismo
                                </p>
                            </div>
                            <div className={styles.mainFilter_projectType_itemContent}>
                                <p className={styles.mainFilter_projectType_info}>
                                    {selectedOptionOrganism.name === null || selectedOptionOrganism.name === undefined ? 'Todos' : selectedOptionOrganism.name}
                                </p>
                                <ChevronDown size={20} />
                                {selectedFilter === 'organism' && (
                                    <div className={styles.mainFilter_projectType_options}>
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelectOrganism('Todos', 0)}>Todos</div>
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelectOrganism('Nacional', 1)}>Nacional</div>
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelectOrganism('Provincial', 2)}>Provincial</div>
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelectOrganism('Municipal', 3)}>Municipal</div>
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => handleOptionSelectOrganism('Empresa Pública', 4)}>Empresa Pública</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={styles.mainFilter_projectType} >
                        <div className={styles.mainFilter_projectType_infoContainer}>
                            <p className={styles.mainFilter_projectType_title}>
                                Jurisdicción
                            </p>
                        </div>
                        <div className={styles.mainFilter_projectType_itemContent} onClick={() => handleFilterClick('jurisdiction')}>
                            <p className={styles.mainFilter_projectType_info}>
                                {selectedOptionJurisdiction.name === null || selectedOptionJurisdiction.name === undefined ? "Todas" : selectedOptionJurisdiction.name}
                            </p>
                            <ChevronDown size={20} />
                            {selectedFilter === 'jurisdiction' && (
                                <>
                                    <div className={styles.mainFilter_projectType_options}>
                                        {jurisdictionArray.map((jurisdiction) => (
                                            <div className={styles.mainFilter_projectType_options_item} key={jurisdiction.id} onClick={() => handleOptionSelectJurisdiction(jurisdiction.name, jurisdiction.id)}>{jurisdiction.name}</div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.mainFilter_projectType} >
                        <div className={styles.mainFilter_projectType_infoContainer}>
                            <p className={styles.mainFilter_projectType_title}>
                                {selectedOption === "Privadas" ? "Materiales" : "Tipo de obra"}
                            </p>
                        </div>
                        <div className={styles.mainFilter_projectType_itemContent} onClick={() => handleFilterClick('workType')}>
                            <p className={styles.mainFilter_projectType_info}>
                                {selectedWorkType.name === null || selectedWorkType.name === undefined ? "Todas" : selectedWorkType.name}
                            </p>
                            <ChevronDown size={20} />
                            {selectedFilter === 'workType' && (
                                <div className={styles.mainFilter_projectType_options}>
                                    {selectedOption === "Privadas" ? listaDeRubros?.map((listaDeRubros) => (
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => { setSelectedWorkType({ name: listaDeRubros.name, id: listaDeRubros.id }); setSelectedFilter(null) }} key={listaDeRubros.id}>{listaDeRubros.name}</div>
                                    )) : typeOfWorkArray?.map((typeOfWork) => (
                                        <div className={styles.mainFilter_projectType_options_item} onClick={() => { setSelectedWorkType({ name: typeOfWork.name, id: typeOfWork.id }); setSelectedFilter(null) }} key={typeOfWork.id}>{typeOfWork.name}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.filterContainer_searchType} >
                    <p className={styles.filterContainer_searchType_txt} onClick={() => setAdvancedSearch(!advancedSearch)}>
                        Búsqueda avanzada
                        {advancedSearch ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </p>
                </div>
                {advancedSearch && (
                    <motion.div initial={{ y: -10 }} animate={{ y: 0 }} className={styles.advancedFilter}>
                        <div className={styles.advancedFilter_filterCurrency} onClick={() => setShowCurrencyOptions(!showCurrencyOptions)}>
                            <h4 className={styles.advancedFilter_filterCurrency_header}>Moneda</h4>
                            <div className={styles.advancedFilter_filterCurrency_options}>
                                <div className={styles.advancedFilter_filterCurrency_options_placeHolder}>
                                    <p className={styles.advancedFilter_filterCurrency_options_placeHolder_txt}>{selectedCurrency !== "Pesos" ? selectedCurrency : "Pesos"}</p>
                                    <div className={styles.advancedFilter_filterCurrency_options_iconContainer}>
                                        {showCurrencyOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                    </div>
                                </div>
                                {showCurrencyOptions && (
                                    <div className={styles.advancedFilter_filterCurrency_options_menu}>
                                        <div className={styles.advancedFilter_filterCurrency_options_item} onClick={() => { setSelectedCurrency("Pesos"); setShowCurrencyOptions(false) }}>Pesos</div>
                                        <div className={styles.advancedFilter_filterCurrency_options_item} onClick={() => { setSelectedCurrency("Dólares"); setShowCurrencyOptions(false) }}>Dólares</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.advancedFilter_filterAmount} onClick={() => setShowAmountOptions(!showAmountOptions)}>
                            <h4 className={styles.advancedFilter_filterAmount_header}>{selectedOption === "Privadas" ? "Presupuesto estimado" : "Monto"}</h4>
                            <div className={styles.advancedFilter_filterAmount_options}>
                                <div className={styles.advancedFilter_filterAmount_options_placeHolder}>
                                    <p className={styles.advancedFilter_filterAmount_options_placeHolder_txt}>{maxAmount > 0 ? `Desde ${formatAmount(minAmount)} Hasta ${formatAmount(maxAmount)}` : "$0 - $500.000.000 +"}</p>
                                    <div className={styles.advancedFilter_filterAmount_options_iconContainer}>
                                        {showAmountOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                    </div>
                                </div>
                                {showAmountOptions && (
                                    <div className={styles.advancedFilter_filterAmount_options_menu}>
                                        <div className={styles.advancedFilter_filterAmount_options_container}>
                                            <div className={styles.advancedFilter_filterAmount_options_header}>
                                                Elegí un rango
                                            </div>
                                            <div className={styles.advancedFilter_filterAmount_options_headerSub}>
                                                Monto mínimo
                                            </div>
                                            <input
                                                type="range"
                                                min="0"
                                                max={selectedCurrency === "Pesos" ? "50000000000" : "50000000"}
                                                step={selectedCurrency === "Pesos" ? "1000000" : "1000"}
                                                value={minAmount}
                                                onChange={(e) => {
                                                    const newMinAmount = Math.min(parseInt(e.target.value), maxAmount);
                                                    setMinAmount(newMinAmount);
                                                }}
                                                className={styles.advancedFilter_filterAmount_options_slider}
                                            />
                                            <div className={styles.advancedFilter_filterAmount_options_headerSub}>
                                                Monto máximo
                                            </div>
                                            <input
                                                type="range"
                                                min="0"
                                                max={selectedCurrency === "Pesos" ? "50000000000" : "50000000"}
                                                step={selectedCurrency === "Pesos" ? "1000000" : "1000"}
                                                value={maxAmount}
                                                onChange={(e) => {
                                                    const newMaxAmount = Math.max(parseInt(e.target.value), minAmount);
                                                    setMaxAmount(newMaxAmount);
                                                }}
                                                className={styles.advancedFilter_filterAmount_options_slider}
                                            />
                                            <div className={styles.advancedFilter_filterAmount_options_item} onClick={() => setShowAmountOptions(false)}>
                                                <p className={styles.advancedFilter_filterAmount_options_item_number}>
                                                    {formatAmount(minAmount)} - {formatAmount(maxAmount)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.advancedFilter_filterDate} onClick={() => setShowDateOptions(!showDateOptions)}>
                            <h4 className={styles.advancedFilter_filterDate_header}>{dateFilterHeader}</h4>
                            <div className={styles.advancedFilter_filterDate_options}>
                                <div className={styles.advancedFilter_filterDate_options_placeHolder}>
                                    <p className={styles.advancedFilter_filterDate_options_placeHolder_txt}>{selectedDateRange || selectedDate ? selectedDateRange || formatDate(selectedDate) : "Elegir"}</p>
                                    <div className={styles.advancedFilter_filterDate_options_iconContainer}>
                                        {showDateOptions ? <ArrowDown width={15} height={25} /> : <ArrowUp width={15} height={25} />}
                                    </div>
                                </div>
                                {showDateOptions && (
                                    <div className={styles.advancedFilter_filterDate_options_menu}>
                                        <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("Próximos 30 días"); setShowDateOptions(false) }}>Proximos 30 días</div>
                                        <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("30 a 60 días"); setShowDateOptions(false) }}>30 a 60 días</div>
                                        <div className={styles.advancedFilter_filterDate_options_item} onClick={() => { setSelectedDateRange("60 a 90 días"); setShowDateOptions(false) }}>60 a 90 días</div>
                                        <div
                                            className={styles.advancedFilter_filterDate_options_item}
                                            onClick={() => { setShowExactDateInput(true); setSelectedDateRange(null) }}
                                        >
                                            Fecha exacta
                                        </div>
                                        {showExactDateInput && (
                                            <input
                                                type="date"
                                                value={selectedDate}
                                                onChange={(e) => setSelectedDate(e.target.value)}
                                                className={styles.advancedFilter_filterDate_options_item} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.advancedFilter_filterAsociatedWords}>
                            <h4 className={styles.advancedFilter_filterAsociatedWords_header}>Palabras clave</h4>
                            <div className={styles.advancedFilter_filterAsociatedWords_options}>
                                <input
                                    type="text"
                                    value={asociatedWords}
                                    onChange={(e) => setAsociatedWords(e.target.value)}
                                    className={styles.advancedFilter_filterAsociatedWords_options_input} />
                            </div>
                        </div>
                    </motion.div>
                )}
            </div>
            <div className={styles.filterContainer_searchInfo}>
                <div className={styles.filterContainer_searchInfo_txtContainer}>
                    <p className={styles.filterContainer_searchInfo_txt}>{resultados === 0 ? (
                        "No se encontraron resultados"
                    ) : resultados === 1 ? (
                        <>Se encontró <b>1</b> resultado</>
                    ) : (
                        <>Se encontraron <b>{resultados}</b> resultados</>
                    )}</p>
                    {resultados > 10 && (
                        <p className={styles.filterContainer_searchInfo_txt}>Mostrando {totalDeProyectosMostrados?.length} de {resultados}</p>
                    )}
                </div>
                <div className={styles.filterContainer_searchInfo_sortContainer}>
                    <div className={styles.filterContainer_searchInfo_sort_title}>Ordenar por:</div>
                    <div className={styles.filterContainer_searchInfo_sort}>
                        <p className={styles.filterContainer_searchInfo_sort_txt}>{selectedSort === "Antiguos" ? selectedSort : "Recientes"}</p>
                        <div className={styles.filterContainer_searchInfo_sort_iconContainer} onClick={() => setShowSortOptions(!showSortOptions)}>
                            {showSortOptions ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </div>
                        {showSortOptions && (
                            <div className={styles.filterContainer_searchInfo_sort_options}>
                                <p className={styles.filterContainer_searchInfo_sort_options_item}
                                    onClick={() => { setSelectedSort("Recientes"); setShowSortOptions(false) }}>Recientes</p>
                                <p className={styles.filterContainer_searchInfo_sort_options_item}
                                    onClick={() => { setSelectedSort("Antiguos"); setShowSortOptions(false) }}>Antiguos</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        resultados: state.profile.itemCount,
        totalDeProyectosMostrados: state.profile.quotations.entities?.length,
        /* listaDeRubros: state.category.categoryList.data, */
        listaDeRubros: state.profile.subQuotations,
    };
};

export default connect(mapStateToProps, {
    filterQuotations,
    getSubquotationType,
    getCategories,
})(FilterQuotation);


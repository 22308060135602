import React from 'react';
import styles from './QuotationForm.module.scss';
import { Calendar } from 'lucide-react';
import moment from 'moment/moment';


function QuotationForm({ quotation }) {
    const quo = quotation?.quotation;

    return (
        <div className={styles.flexContainer}>
            {/* <div className={styles.contentContainer}> */}
            {/* <div className={styles.formContainer}> */}
            <div className={styles.formHeader}>
                <h1 className={styles.formHeader_title}>Solicitud de Cotización: {quo?.name}</h1>
                <div className={styles.formContainer}>
                    {/* <div className={styles.leftContainer}> */}
                    <div className={styles.infoHeader}>
                        <h2 className={styles.infoTitle}>INFORMACIÓN GENERAL</h2>
                    </div>
                    <div className={styles.infoBox}>
                        <div className={styles.infoGrid}>
                            <div className={styles.infoRow}>
                                <div>
                                    <label className={styles.infoLabel}>Ubicación:</label>
                                    <div className={styles.infoText}>{quo?.jurisdiccion?.name}</div>
                                </div>

                            </div>
                            <div className={styles.infoRow2}>
                                <div>
                                    <label className={styles.infoLabel}>Ciudad:</label>
                                    <div className={styles.infoText}>{quo?.zone}</div>
                                </div>
                            </div>
                            <div>
                                <label className={styles.infoLabel}>Descripción:</label>
                                <div className={styles.description}>
                                    {quo?.description}
                                </div>
                            </div>
                        </div>
                        <div className={styles.infoGrid2}>
                            <div className={styles.dateInfo}>
                                <div className={styles.dateItem}>
                                    <Calendar style={{ width: "20px", height: "20px", color: "#888989" }} />
                                    <span className={styles.dateText}>Inicio: {moment(quo?.startDate).format("DD/MM/YYYY")}</span>
                                </div>
                                <div className={styles.dateItem}>
                                    <Calendar style={{ width: "20px", height: "20px", color: "#888989" }} />
                                    <span className={styles.dateText}>Fin: {moment(quo?.endDate).format("DD/MM/YYYY")}</span>
                                </div>
                            </div>
                            <div className={styles.currency}>
                                Moneda:  {quo?.currency}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
}


export default QuotationForm;

import React from "react";
import styles from "./ResumenSkeleton.module.scss";

const ResumenSkeleton = () => {
    return (
        <div className={styles.resumenSkeleton}>
        <div className={styles.resumenSkeleton__header}></div>
        <div className={styles.resumenSkeleton__body}></div>
        <div className={styles.resumenSkeleton__body}></div>
        </div>
    );
    }

export default ResumenSkeleton;
import React from "react";
import styles from "./Boton.module.scss";

const Boton = ({ nombre, isSelected, icono, onClick}) => {

  return (
    <div
      onClick={onClick}
    className={styles.boton} 
    >

      <div className={styles.boton_iconWrapper}>
        <div className={`${styles.boton_icon} ${isSelected.name === nombre ? styles.isSelected : "" }`}>
          {icono}
        </div>
        <p className={`${styles.boton_nombre} ${isSelected.name === nombre && styles.isSelected }`}>{nombre}</p>
      </div>
      
    </div>

  );
};

export default Boton;



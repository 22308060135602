import React from "react";
import styles from "./RegisteredUserProfileSkeleton.module.scss";
import edifyLogo from "../../../assets/images/edify-logo.png";

const RegisteredUserProfileSkeleton = () => {
    return (
        <div className={styles.loaderContainer}>
            <img src={edifyLogo} alt="Edify" className={styles.spinnerEdify} />
            <div className={styles.loadingText}>Cargando</div>
            <div className={styles.spinner}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}

export default RegisteredUserProfileSkeleton;

import * as actionType from "../actions/types";

const INITIAL_STATE = {
  name: "",
  mail: "",
  type: "",
  file: false, //este dato se puso false y no null, por que genera un error en formik en el onboarding paso 2
  personaForm: {
    name: "",
    lastName: "",
    dniCuil: "",
    profession: "",
    tel: "",
    address: "",
    webSite: "",
  },
  empresaForm: {
    cuit: "",
    companyName: "",
    field: "",
    tel: "",
    address: "",
    webSite: "",
  },
  cities: [],
  codeValidation: false,
  identityModal: { isOpen: false, emailToValidate: "" },
  usersBySkill: [],
  searchSkill: [],
  skillsList: [],
  totalPages: 0,
  totalSkillsListed: 0,
  selectedSkill: [],
  usersSkillList: [],
  skillContent: [],
  userSkills: [],
  userPortfolios: [],
  openEdit: false,
  portfolioId: null,
  workBagUsers: [],
  workBagUsersToValidate: [],
  currentWorkOffer: {},
  workOfferPostulation: false,
  allJobRequests: [],
  plans: [],
  userPlan: {},
  planID: null,
  terminosStatus: false,
  errorMessage: "",
};

const clearUserSkills = (state) => {
  return {
    ...state,
    userSkills: [],
  };
};

const addSkillToUser = (state, skillId) => {

  let skill = state.skills.find((element) => {
    return element.id === skillId;
  });
  let add = state.userSkills.find((elSkill) => {
    return elSkill.id === skill.id;
  });
  if (!add) {
    return {
      ...state,
      userSkills: state.userSkills.concat(skill),
    };
  } else {
    return {
      ...state,
    };
  }
};

const removeUserSkill = (state, skillId) => {
  let userSkill = state.userSkills.filter((element) => {
    return element.id !== skillId;
  });

  return {
    ...state,
    userSkills: userSkill,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.REGISTER_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        mail: action.payload.mail,
      };
    case actionType.VALIDATION_SUCCESS:
      return {
        ...state,
        codeValidation: action.payload,
      };
    case actionType.OPEN_IDENTITY_MODAL:
      return {
        ...state,
        identityModal: {isOpen: action.payload, emailToValidate: action.email},
      };
    case actionType.CHANGE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case actionType.SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case actionType.SET_PORTFOLIO:
      return {
        ...state,
        portfolios: action.payload,
      };
    case actionType.GET_CITIES: {
      return {
        ...state,
        cities: action.payload,
      };
    }
    case actionType.GET_ALL_JOB_REQUESTS:
      return {
        ...state,
        allJobRequests: action.payload,
      };
    case actionType.CURRENT_WORK_OFFER: {
      return {
        ...state,
        currentWorkOffer: action.payload,
      };
    }
    case actionType.GET_ALL_WORKBAG_USERS: {
      return {
        ...state,
        workBagUsers: action.payload,
      };
    }
    case actionType.SEND_WORK_OFFER_POSTULATION: {
      return {
        ...state,
        workOfferPostulation: action.payload,
      };
    }
    case actionType.FILTER_WORKBAG_USERS: {
      return {
        ...state,
        workBagUsers: action.payload,
      };
    }
    case actionType.GET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case actionType.SEARCH_SKILL:
      return {
        ...state,
        searchSkill: action.payload,
      };
    case actionType.GET_USER_SKILL:
      return {
        ...state,
        userSkills: action.payload,
      };
    case actionType.SET_SKILLS:
      return {
        ...state,
        skills: action.payload,
        skillContent: action.payload,
      };
    case actionType.SET_SKILLS_LIST:
      return {
        ...state,
        skillsList: action.payload,
      };
    case actionType.SET_USERS_BY_SKILL:
      return {
        ...state,
        usersBySkill: action.payload.users,
        selectedSkill: action.payload.selectedSkill,
      };
    case actionType.RESET_SELECTEDSKILL:
      return {
        ...state,
        selectedSkill: [],
      };
    case actionType.SET_USERS_IN_SKILL_NAME:
      return {
        ...state,
        usersSkillList: action.payload.userList,
        totalPages: action.payload.totalPages,
        totalSkillsListed: action.payload.totalSkillsListed,
      };
    case actionType.CLEAR_USER_SKILLS:
      return clearUserSkills({ ...state });
    case actionType.ADD_SKILL:
      return addSkillToUser({ ...state }, action.payload);
    case actionType.REMOVE_SKILL:
      return removeUserSkill({ ...state }, action.payload);
    case actionType.GET_USER_PLAN:
      return {
        ...state,
        userPlan: action.payload,
      };
    case actionType.SET_TERMINOS:
      return {
        ...state,
        terminosStatus: !state.terminosStatus,
      };
    case actionType.SET_PLANID:
      return {
        ...state,
        planID: action.payload,
      };
    case actionType.REGISTER_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case actionType.SET_PERSONA_FORM:
      return {
        ...state,
        personaForm: action.payload,
        file: action.file,
      };
    case actionType.SET_EMPRESA_FORM:
      return {
        ...state,
        empresaForm: action.payload,
        file: action.file,
      };
    case actionType.CLEAR_ONBOARDING_FORM:
      return {
        ...state,
        empresaForm: INITIAL_STATE.empresaForm,
        personaForm: INITIAL_STATE.personaForm,
        file: INITIAL_STATE.file,
      };
    case actionType.FETCH_IDENTITY_PHOTOS_WORKERS_REQUEST:
      return { ...state, loading: true, error: null };
    case actionType.FETCH_IDENTITY_PHOTOS_WORKERS_SUCCESS:
      return { ...state, loading: false, workBagUsersToValidate: action.payload };
    case actionType.FETCH_IDENTITY_PHOTOS_WORKERS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state
  }
}
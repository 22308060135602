import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './ValidationCode.module.scss';

const ValidationLoading = () => {
  const [loadingMessage, setLoadingMessage] = useState('Estamos validando el código');

  useEffect(() => {
    const messages = [
      'Ya casi terminamos...',
      'Todo listo, ¡procediendo!'
    ];

    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setLoadingMessage(messages[randomIndex]);
    }, 1000); 

    return () => {clearInterval(interval); setLoadingMessage('Estamos validando el código')}; 
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <motion.div 
        className={styles.spinnerContainer} 
        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }} 
      >
        <div className={styles.spinner}></div>
      </motion.div>
      <motion.div
        className={styles.loadingAnimation}
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5 }} 
      >
        <div className={styles.loadingText}>{loadingMessage}</div>
      </motion.div>
    </div>
  );
};

export default ValidationLoading;
import React, { useState } from "react";
import styles from "./RegisteredUserItem.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as StarEmpty } from "../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as StarFull } from "../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarHalf } from "../../assets/svg/halfStarSvg.svg";
import { ReactComponent as NoImage } from "../../assets/svg/noImageIcon.svg";
import { ImageOff } from "lucide-react";
import { connect } from "react-redux";
import { openSendContactNotificationModal } from "../../store/actions";

const RegisteredUserItem = ({ user, openSendContactNotificationModal, stars, portfolio }) => {
    const [imageError, setImageError] = useState(false);
    const navigate = useNavigate();

    const userFullName = user?.type === "COMPANY" ? user?.razon_social : user?.name + " " + user?.last_name;

    const handleRedirectToUser = () => {
        navigate(`/profesionales/${user?.id}/${userFullName}`);
    }

    const getInitials = () => {
        if (user?.type === "PERSON") {
            const firstNameInitial = user?.name?.charAt(0) || "";
            const lastNameInitial = user?.last_name?.charAt(0) || "";
            return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
        }
        if (user?.type === "COMPANY") {
            return user?.razon_social?.charAt(0)?.toUpperCase() || "";
        }
        return null;
    };

    const profileImage = user?.image_profile || null;
    const initials = getInitials();

    let starsArray = [];
    for (let i = 0; i < 5; i++) {
        if (i < stars) {
            starsArray.push(<StarFull key={i} width={10} height={10} />);
        } else if (i === stars && stars % 1 !== 0) {
            starsArray.push(<StarHalf key={i} width={10} height={10} />);
        } else {
            starsArray.push(<StarEmpty key={i} width={10} height={10} />);
        }
    }

    let portfolioImages = [];
    if (portfolio && portfolio.length > 0) {
        for (let i = 0; i < Math.min(portfolio.length, 3); i++) {
            const portfolioItem = portfolio[i];
            if (portfolioItem.portfolio_images && portfolioItem.portfolio_images.length > 0) {
                const firstImage = portfolioItem.portfolio_images[0].image;
                portfolioImages.push(
                    <img key={i} src={firstImage} alt={`Portfolio ${i + 1}`} className={styles.portfolioImage} />
                );
            }

        }

    }

    if (portfolioImages.length === 0) {
        portfolioImages.push(
            <NoImage key="noImage" width={100} height={100} />
        );
    }

    const handleContact = (e) => {
        e.stopPropagation();
        openSendContactNotificationModal(user)
    }

    return (
        <div className={styles.container} onClick={() => handleRedirectToUser()}>
            <div className={styles.container_header} >
                <div className={styles.container_image}>
                    {!imageError && profileImage ? (
                        <img
                            className={styles.container_image_img}
                            src={profileImage}
                            alt="user"
                            onError={() => setImageError(true)}
                        />
                    ) : initials ? (
                        <div className={styles.initialsContainer}>{initials}</div>
                    ) : (
                        <div className={styles.initialsContainer}>{<ImageOff />}</div>
                    )}
                </div>
                <div className={styles.container_info}>
                    <p className={styles.container_info_name}>{userFullName}</p>
                    <div className={styles.container_info_stars}>
                        {starsArray}
                    </div>
                </div>
            </div>
            <div className={styles.container_portfolio}>
                {portfolioImages.length === 1 ? (
                    <div className={styles.portfolioFullWidth}>
                        {portfolioImages}
                    </div>
                ) : (
                    portfolioImages.length === 2 ? (
                        <>
                            <div className={styles.portfolioFirstImage}>
                                {portfolioImages[0]}
                            </div>
                            <div className={styles.portfolioSecondThirdImages}>
                                {portfolioImages.slice(1)}
                            </div>
                        </>
                    ) : portfolioImages.length === 3 ? (
                        <>
                            <div className={styles.portfolioFirstImage}>
                                {portfolioImages[0]}
                            </div>
                            <div className={styles.portfolioSecondThirdImages}>
                                {portfolioImages.slice(1).map((img, index) => (
                                    <img key={index} src={img.props.src} alt={`Portfolio ${index + 2}`} className={`${styles.threeImages}`} />
                                ))}
                            </div>
                        </>
                    ) : null)}
            </div>
            <div className={styles.container_footer}>
                <div className={styles.container_footer_portfolioLength}>
                    {portfolio?.length > 0 ? (
                        <p>{portfolio?.length} {portfolio?.length === 1 ? "Proyecto" : "Proyectos"}</p>
                    ) : (
                        <p>Sin proyectos</p>
                    )}
                </div>
                <div className={styles.container_footer_contactContainer} onClick={(e) => handleContact(e)}>
                    <p className={styles.container_footer_contact}>Contactar</p>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, { openSendContactNotificationModal })(RegisteredUserItem);
import React from "react";
import styles from "./ProjectSkeleton.module.scss";

const ProyectoSkeletonLoader = () => {
    return (
        <main className={styles.mainContainer}>
            <div className={styles.sectionWrapper}>
                <header className={styles.skeletonCards_header}>
                    <div className={styles.skeletonTitle} />
                    <div className={styles.skeletonButton} />
                </header>
                <section className={styles.skeletonCards_datos}>
                    <div className={styles.skeletonImageContainer}>
                        <div className={styles.skeletonImage} />
                    </div>
                    <div className={styles.skeletonTextContainer}>
                        <div className={styles.skeletonBox} >
                        <div className={styles.skeletonTitle} />
                        <div className={styles.skeletonItemsContainer}>
                            <div className={styles.skeletonItem} />
                            <div className={styles.skeletonItem} />
                            <div className={styles.skeletonItem} />
                        </div>
                        </div>
                        <div className={styles.skeletonBox} >
                        <div className={styles.skeletonTitle} />
                        <div className={styles.skeletonItemsContainer}>
                            <div className={styles.skeletonItem} />
                            <div className={styles.skeletonItem} />
                            <div className={styles.skeletonItem} />
                        </div>
                        </div>
                    </div>
                    <div className={styles.skeletonCards_user}>
                        <div className={styles.skeletonUserContainer}>
                            <div className={styles.skeletonUserImage} />
                            <div className={styles.skeletonUserName} />
                            <div className={styles.skeletonImage} />
                        </div>
                        <div className={styles.skeletonCards_questions}>
                            <div className={styles.skeletonQuestionContainer}>
                                <div className={styles.skeletonQuestion} />
                                <div className={styles.skeletonQuestion} />
                                <div className={styles.skeletonQuestion} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default ProyectoSkeletonLoader;
import React from "react";
import styles from "./RegisterStepsPage.module.scss";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const ball = {
    hidden: {
      backgroundColor: "#edf0f7",
      scale: 0,
    },
    visibleActive: {
      backgroundColor: "var(--color-mainBlue)",
      scale: 1,
    },
    visible: {
      backgroundColor: "#edf0f7",
      border: "1px solid #d5d5d5",
      opacity: 0.64,
      scale: 1,
    },
  };

const RegisterStepsPage = ({ stepCount }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      className={styles.container}
      transition={{ ease: "easeInOut", duration: 0.3, delay: 0.3 }}
      animate={{ opacity: [0, 1] }}
    >
      <div className={styles.stepsContainer}>
        <div className={styles.stepsProgress}>
          <motion.div
            variants={ball}
            onClick={stepCount >= 1 ? () => navigate("/select-type") : () => {}}
            initial="hidden"
            style={{ cursor: stepCount > 1 && "pointer" }}
            animate={stepCount >= 1 ? "visibleActive" : "visible"}
            className={`${styles.ball} ${stepCount >= 1 ? styles.active : ""}`}
          >
            <span>1</span>
          </motion.div>
          <div className={`${styles.line} ${stepCount >= 2 ? styles.activeLine : ""}`} />
          <motion.div
            variants={ball}
            initial="hidden"
            style={{ cursor: stepCount > 2 && "pointer"}}
            onClick={stepCount >= 2 ? () => navigate("/register-form") : () => {}}
            animate={stepCount >= 2 ? "visibleActive" : "visible"}
            className={`${styles.ball} ${stepCount >= 2 ? styles.active : ""}`}
          >
            <span>2</span>
          </motion.div>
          <div className={`${styles.line} ${stepCount >= 3 ? styles.activeLine : ""}`} />
          <motion.div
            variants={ball}
            initial="hidden"
            onClick={stepCount >= 3 ? () => navigate("/register-form") : () => {}}
            style={{ cursor: stepCount > 3 && "pointer" }}
            animate={stepCount >= 3 ? "visibleActive" : "visible"}
            className={`${styles.ball} ${stepCount >= 3 ? styles.active : ""}`}
          >
            <span>3</span>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default RegisterStepsPage;
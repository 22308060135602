import React, { useState } from "react";
import styles from "./SmallWorkerMapItem.module.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ReactCarousel.css";
import { ReactComponent as LocationIcon } from "../../../../assets/svg/Location_Black.svg";
import useGetTodayDate from "../../../../hooks/useGetTodayDate";
import Tooltip from "../../../UI/ToolTip/ToolTip";

const SmallWorkerMapItem = ({ worker, onClose, onContact, isMarker }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tooltipVisibility, setTooltipVisibility] = useState({});

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const todayDate = useGetTodayDate();
    const formattedTodayDate = parseDate(todayDate);

    const handleMouseEnter = (index) => setTooltipVisibility((prev) => ({ ...prev, [index]: true }));
    const handleMouseLeave = (index) => setTooltipVisibility((prev) => ({ ...prev, [index]: false }));

    const handleSlideChange = (index) => setActiveIndex(index);

    const simplifyCityName = (city) => city.includes(",") ? city.split(",").slice(0, 2).join(", ") : city;

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const renderAvailability = (workerItem, index) => {
        const isAvailable = workerItem?.userInfo?.current_job
            ? new Date(workerItem?.userInfo?.employment_end_date) <= formattedTodayDate
            : true;

        return (
            <div
                className={isAvailable ? styles.workerInfo_body_text_name_available : styles.workerInfo_body_text_name_notAvailable}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
            >
                {tooltipVisibility[index] && (
                    <Tooltip text={isAvailable
                        ? 'Disponible para trabajar'
                        : `Disponible a partir del ${formatEmploymentEndDate(workerItem?.userInfo?.employment_end_date)}`} />
                )}
            </div>
        );
    };

    const renderWorkerInfo = (workerItem, index) => (
        <div key={index} className={styles.workerInfo}>
            <div key={index} className={`${styles.workerInfo_body} ${index !== activeIndex ? styles.invertedColors : ''}`}>
                <div className={styles.workerInfo_body_imageContainer}>
                    <img style={{borderRadius: "50%", width: "60px", height: "60px"}} className={styles.workerInfo_body_img} src="https://cdn-icons-png.flaticon.com/128/149/149071.png" alt="profile" />
                    <button className={styles.workerInfo_contact} onClick={() => onContact(workerItem.userInfo)}>Contactar</button>
                </div>
                <div className={styles.workerInfo_body_text}>
                    <h3 className={styles.workerInfo_body_text_name}>
                        {workerItem?.userInfo?.name} {workerItem?.userInfo?.last_name}
                        {renderAvailability(workerItem, index)}
                    </h3>
                    <div className={styles.workerInfo_body_text_jobContainer}>
                        {workerItem?.userInfo?.skill?.map((skill, idx) => (
                            <p className={styles.workerInfo_body_text_job} key={idx}>{skill.skill?.name}</p>
                        ))}
                    </div>
                    <div className={styles.workerInfo_body_location}>
                        <LocationIcon width={15} height={15} />
                        <p className={styles.workerInfo_body_location_city}>{simplifyCityName(workerItem?.city)}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.smallWorkerMapItem}>
            <div className={styles.title}>
                <h2>Resultados {worker.length && `(${worker.length})`}</h2>
                <button className={styles.title_close} onClick={onClose}>X</button>
            </div>

            {isMarker ? (
                renderWorkerInfo(worker, 0)
            ) : (
                <div className={styles.workersCards}>
                    <Carousel
                        showArrows
                        showThumbs={false}
                        showStatus={false}
                        infiniteLoop={false}
                        swipeable
                        renderIndicator={false}
                        centerMode={worker?.length >= 2}
                        centerSlidePercentage={worker?.length > 2 ? 80 : 50}
                        onChange={handleSlideChange}
                    >
                        {worker?.map((workerItem, index) => renderWorkerInfo(workerItem, index))}
                    </Carousel>
                </div>
            )}
        </div>
    );
};

export default SmallWorkerMapItem;



import React, { useEffect, useState } from "react";
import styles from "./AllProjectsItem.module.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearSubProjectBudgets,
} from "../../../store/actions/profileActions";
import defaultImage from "../../../assets/images/foto-bienvenida.jpg";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import { Pickaxe } from "lucide-react";
import { CalendarDays } from "lucide-react";
import { MapPin } from "lucide-react";
import Tooltip from "../../UI/ToolTip/ToolTip";

const AllProjectsItem = ({
  clearSubProjectBudgets,
  item,
  ...props
}) => {

  const [imageUrls, setImageUrls] = useState([]);
  const [showToolTip, setShowToolTip] = useState({
    lugar: { show: false, text: "Lugar" },
    fechaInicio: { show: false, text: "Fecha de inicio" },
    fechaFin: { show: false, text: "Fecha de fin" },
    rubro: { show: false, text: "Rubro/s del proyecto" },
  });
  const navigate = useNavigate();
  const premiumCheck = usePremiumCheck();
  let {
    name,
    startDate,
    endDate,
    zone,
    user,
  } = item;

  // Lee el contenido de los archivos y genera las URLs de imagen
  useEffect(() => {
    if (item?.proyecto_images) {
      if (Array.isArray(item?.proyecto_images) && item.proyecto_images.length > 0) {
        const urls = item.proyecto_images.map((img) => img.image);
        setImageUrls(urls);
      } else if (item?.image) {
        setImageUrls([item.image]);
      }
    } else if (item?.image) {
      setImageUrls([item.image]);
    } else {
      setImageUrls([defaultImage]);
    }
  }, [item]);

  const handleTooltip = (tooltip) => {
    setShowToolTip((prevState) => ({
      ...prevState,
      [tooltip]: { ...prevState[tooltip], show: !prevState[tooltip].show },
    }));
  };

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Formatear los campos
  const formattedName = capitalizeFirstLetter(name);

  const handleRedirectToProject = () => {
    clearSubProjectBudgets();
    navigate(`/obrasprivadas/${item.id}`)
  };

  const userNameToShow = user?.type === "COMPANY" ? user.razon_social : user?.name + " " + user?.last_name;


  return (
    <div className={styles.allProjectsItemCard}>
      <div className={styles.allProjectsItemCard_backGround}>
        {item?.proyecto_images && item?.proyecto_images?.length > 0 ? (
          <img src={item?.proyecto_images[0].image} alt="" className={styles.allProjectsItemCard_backGround_img} />
        ) : (
          <img src={defaultImage} alt="" className={styles.allProjectsItemCard_backGround_img} />
        )}
        <div className={styles.allProjectsItemCard_backGround_data}>
          <img src={user?.image_profile} alt="Foto de perfil" className={styles.allProjectsItemCard_backGround_data_logo} />
          <div className={styles.allProjectsItemCard_backGround_data_info}>
            <h4 className={styles.allProjectsItemCard_backGround_data_info_name}>
              {userNameToShow}
            </h4>
            <p>{formattedName}</p>
          </div>
        </div>
      </div>
      <div className={styles.allProjectsItemCard_content}>
        <div className={styles.allProjectsItemCard_content_zone} onMouseEnter={() => handleTooltip("lugar")} onMouseLeave={() => handleTooltip("lugar")}>
          <MapPin size={20} style={{ minWidth: "2rem" }} />
          {zone}
          {showToolTip.lugar.show && (
            <Tooltip text={showToolTip.lugar.text} />
          )}
        </div>
        <div className={styles.allProjectsItemCard_content_subProjects} onMouseEnter={() => handleTooltip("rubro")} onMouseLeave={() => handleTooltip("rubro")}>
          <Pickaxe size={20} style={{ minWidth: "2rem" }} />
          {Array.isArray(item?.subproyecto) &&
            item?.subproyecto.map((subproject, index) => {
              return (
                <div className={styles.allProjectsItemCard_content_subProjects_titles} key={index}>
                  <p className={styles.allProjectsItemCard_content_subProjects_titles_rubro}>{subproject.name}</p>
                </div>
              );
            })}
          {showToolTip.rubro.show && (
            <Tooltip text={showToolTip.rubro.text} />
          )}
        </div>
        <div className={styles.allProjectsItemCard_content_dates}>
          <CalendarDays size={20} style={{ minWidth: "2rem" }} />
          <div className={styles.allProjectsItemCard_content_dates_dates}>
          <span className={styles.allProjectsItemCard_content_dates_dateContainer} onMouseEnter={() => handleTooltip("fechaInicio")} onMouseLeave={() => handleTooltip("fechaInicio")}>
            {formatDate(startDate)}
            {showToolTip.fechaInicio.show && (
              <Tooltip text={showToolTip.fechaInicio.text} />
            )}
          </span>
          - <span className={styles.allProjectsItemCard_content_dates_date} onMouseEnter={() => handleTooltip("fechaFin")} onMouseLeave={() => handleTooltip("fechaFin")}>
            {formatDate(endDate)}
            {showToolTip.fechaFin.show && (
              <Tooltip text={showToolTip.fechaFin.text} />
            )}
          </span>
          </div>
        </div>
      </div>
      <div className={styles.allProjectsItemCard_content_button}>
        <button className={styles.allProjectsItemCard_content_button_btn} onClick={premiumCheck(handleRedirectToProject)}>Ver más</button>
      </div>
    </div>
  );
};





const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  clearSubProjectBudgets,
})(AllProjectsItem);

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styles from './Tutorial.module.scss';
import { closeTutorialModal, openTutorialModal } from "../../store/actions";
import TutorialItem from './TutorialItem';
import { ReactComponent as CreateIcon } from "../../assets/svg/addPublicationIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus-icon.svg";
import { ReactComponent as MarketPlace } from "../../assets/svg/marketplaceActive.svg";
import { ReactComponent as MuroIcon } from "../../assets/svg/nav-proyectos-active.svg";
import { connect } from 'react-redux';

const Tutorial = ({tutorialModal, closeTutorialModal, openTutorialModal}) => {
    const [showTutorial, setShowTutorial] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    

    useEffect(() => {
        const lastTutorialDate = localStorage.getItem('lastTutorialDate');
        const today = new Date().toDateString();
        if (!lastTutorialDate || lastTutorialDate !== today) {
            openTutorialModal();
            localStorage.setItem('lastTutorialDate', today);
        }
    }, []);

    const closeTutorial = () => {
        setShowTutorial(false);
        closeTutorialModal();
    }

    const handleSeeMore = (itemId) => {
        setSelectedItem(itemId);
    }

    return (
        <div>
            {(showTutorial || tutorialModal) && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={styles.tutorialBackDrop}
                >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.tutorialModalSide}
                    >
                    <motion.div
                        initial={{ y: "-100vh" }}
                        animate={{ y: 0 }}
                        className={styles.tutorialModal}
                    >                 
                        {selectedItem === null ? (
                            <div>
                          <h1 className={styles.tutorialModal_title}>¿Qué hacer en edify?</h1>  
                        <ul className={styles.tutorialModal_contentList}>
                            <li className={styles.tutorialModal_contentList_item} onClick={() => handleSeeMore(1)}>
                                <div className={styles.tutorialModal_contentList_item_header}>
                                <h2 className={styles.tutorialModal_contentList_item_headerTxt}>Publicá una obra privada</h2>
                                <CreateIcon width={30} height={30} />
                                </div>
                                <p className={styles.tutorialModal_contentList_item_subText}>Si estás buscando presupuestos para tu obra podés crear una publicación en minutos.</p>
                                <p className={styles.tutorialModal_contentList_item_seeMore} >Ver más <PlusIcon width={15} height={15} /></p>
                            </li>
                            <li className={styles.tutorialModal_contentList_item} onClick={() => handleSeeMore(2)}>
                                <div className={styles.tutorialModal_contentList_item_header}>
                                <h2 className={styles.tutorialModal_contentList_item_headerTxt}>Publicá una cotización de materiales</h2>
                                <MarketPlace width={30} height={30} />
                                </div>
                                <p className={styles.tutorialModal_contentList_item_subText}>
                                    ¿Tenés un listado de materiales que queres cotizar? Arma una solicitud en minutos.
                                </p>
                                <p className={styles.tutorialModal_contentList_item_seeMore} >Ver más <PlusIcon width={15} height={15} /></p>
                            </li>
                            <li className={styles.tutorialModal_contentList_item} onClick={() => handleSeeMore(3)}>
                                <div className={styles.tutorialModal_contentList_item_header}>
                                <h2 className={styles.tutorialModal_contentList_item_headerTxt}>Promocioná tus productos o servicios en el muro</h2>
                                <MuroIcon width={40} height={40} />
                                </div>
                                <p className={styles.tutorialModal_contentList_item_subText}>
                                    Si tenés un producto o servicio que queres promocionar, podés hacerlo en la pantalla principal.
                                </p>
                                <p className={styles.tutorialModal_contentList_item_seeMore}>Ver más <PlusIcon width={15} height={15} /></p>
                            </li>
                        </ul>
                        </div>
                        ) : (<TutorialItem selectedItem={selectedItem} setSelectedItem={setSelectedItem} />)}                                                 
                </motion.div>
                <button className={styles.closeTutorial} onClick={closeTutorial}>Cerrar</button> 
                 </motion.div>   
                        
                </motion.div>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        tutorialModal: state.dashboard.tutorialModal,
    }
}

export default connect(mapStateToProps, { closeTutorialModal, openTutorialModal })(Tutorial);

import React, { useEffect, useState } from "react";
import styles from "./Resumen.module.scss";
import { loadUserData, setSuccess, setLoginAnimation } from "../../../store/actions";
import FileUpload from "../../../components/FileUpload/FileUpload";
import { motion } from "framer-motion";
import { opacityVariants } from "../../../utils/FramerConfigs";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ResumenSkeleton from "./ResumenSkeleton";
import { ReactComponent as CheckIcon } from "../../../assets/svg/check-white.svg";
import RegisterSteps from "../RegisterStepsPage";

const childCompressionVariants = {
  visible: { 
    opacity: 1, 
    scale: 1, 
    x: 0, 
    y: 0, 
    borderRadius: "0%", 
    transition: { duration: 0.5 } 
  },
  hidden: { 
    opacity: 0, 
    scale: 0.01, 
    x: 0, 
    y: 0, 
    borderRadius: "50%", 
    transition: { duration: 0.8, ease: "easeInOut" } 
  },
};

const Resumen = ({
  loadUserData,
  setSuccess,
  setLoginAnimation,
  loginAnimation,
  userData,
  file,
  userSkills,
}) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUserData();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  const handleFinish = () => {
    setLoginAnimation(true);
  };

  useEffect(() => {
    if (loginAnimation) {
      setTimeout(() => {
        setLoginAnimation(false);
        navigate("/dashboard");
      }, 1000);
    }
  }, [loginAnimation, navigate]);

  let {
    last_name,
    name,
    phone,
    type,
    url_site_web,
    razon_social,
    workZones,
  } = userData;

  return (
    <div className={`${styles.resumenContainer} ${loginAnimation && styles.loginAnimation}`}>
        {loading ? (
          <ResumenSkeleton />
        ) : (
          <motion.div
          className={styles.resumenContainer_resumen}
          initial="visible"
          animate={loginAnimation ? "hidden" : "visible"}
          variants={childCompressionVariants}
          style={{ overflow: "hidden" }}>
            <RegisterSteps stepCount={3} />
            <h1 className={styles.title}>
              ¡Registro completo! <br />
            </h1>
            <section className={styles.sectionContainer}>
              <div className={styles.borderWrapper}>
                <motion.div
                  className={styles.readyContainer}
                  variants={opacityVariants.in(0.5)}
                  initial="out"
                  animate="in"
                >
                  <p className={styles.formSubtitle}>
                    Por favor, revisa tus datos para finalizar
                  </p>
                </motion.div>
                <motion.div
                  className={styles.mainContainer}
                  variants={opacityVariants.in(1)}
                  initial="out"
                  animate="in"
                >
                  <div className={styles.infoContainer}>
                    <div className={styles.imagesWrapper} onClick={() => setSuccess()}>
                      <FileUpload userType='person' file={file} name='file' />
                    </div>
                    {type === "PERSON" ? (
                      <>
                        <div className={styles.dataContainer}>
                          <div className={styles.dataTitle} title={name}>
                            {name}
                          </div>
                        </div>
                        <div className={styles.dataContainer}>
                          <div className={styles.dataTitle} title={last_name}>
                            {last_name}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={styles.dataContainer} style={{ width: "100%" }}>
                        <div className={styles.dataTitle} title={razon_social}>
                          {razon_social !== "" ? razon_social : "Razon Social"}
                        </div>
                      </div>
                    )}
                    <div className={styles.dataContainer}>
                      <div className={styles.dataTitle} title={url_site_web}>
                        {url_site_web !== null ? url_site_web : "Sitio Web"}
                      </div>
                    </div>
                    <div className={styles.dataContainer}>
                      <div className={styles.dataTitle} title={phone}>
                        {phone !== "" ? phone : "Telefono"}
                      </div>
                    </div>
                    <h4 className={styles.sectionTitle}>Lugares de trabajo</h4>
                    <div className={styles.dataContainerBig}>
                      <div className={styles.dataTitle}>
                        {workZones && workZones.length > 0 ? (
                          workZones.some(zone => zone.allCountry) ? (
                            <div className={styles.dataTitle_item}>Todo el país</div>
                          ) : (
                            workZones.map((zone, index) => (
                              <div key={index} className={styles.dataTitle_item}>
                                {zone?.city?.split(",")[0]}
                              </div>
                            ))
                          )
                        ) : null}
                      </div>
                    </div>
                    <h4 className={styles.sectionTitle}>Rubros</h4>
                    <div className={styles.dataContainerBig}>
                      {userSkills?.length > 0 &&
                        userSkills.map((skill, index) => {
                          return (
                            <div key={index} className={styles.dataTitle_item}>
                              {skill.name}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className={styles.buttonsContainer}>
                    <div className={styles.btn} onClick={handleFinish}>
                      <CheckIcon width={20} height={20} />
                      Finalizar
                    </div>
                  </div>
                </motion.div>
              </div>
            </section>
          </motion.div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    userSkills: state.register.userSkills,
    file: state.register.file,
    loginAnimation: state.general.loginAnimation,
  };
};

export default connect(mapStateToProps, {
  loadUserData,
  setSuccess,
  setLoginAnimation,
})(Resumen);



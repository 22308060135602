import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { openSuscriptionModal } from "../store/actions";

export default function useSuscriptionModalTimeout() {
  const isUserPremium = useSelector((state) => state.general.isUserPremium)
  const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();

  useEffect(() => {
    let timerId;
    const OPEN_MODAL_TIMEOUT = 15000;
    const isDashboardCurrentLocation =
      location.pathname === "/dashboard";
    if (!isUserPremium && !isDashboardCurrentLocation) {
      timerId = setTimeout(() => {
        dispatch(openSuscriptionModal());
      }, OPEN_MODAL_TIMEOUT);
    }

    return () => clearTimeout(timerId);
  }, []);
}

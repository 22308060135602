import React from "react";
import { ReactComponent as EmptyProyectIcon } from "../../../assets/svg/reputation-comments.svg";
import styles from "./EmptyStateComments.module.scss";

const EmptyState = ({ title = "" }) => {
  return (
    <div className={styles.EmptyState}>
      <EmptyProyectIcon />
      <h3>{title}</h3>
    </div>
  );
};

export default EmptyState;
import React from "react";
import { motion } from "framer-motion"; 
import styles from "./ToolTip.module.scss";

const Tooltip = ({ text, fromNav, fromHeaderNav }) => {
  const isComplexContent = typeof text === "object";

  const tooltipVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <motion.div
      className={`${styles.tooltip} ${fromNav && styles.fromNav} ${fromHeaderNav && styles.fromHeaderNav}`}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={tooltipVariants}
      transition={{ duration: 0.3 }} 
    >
      {isComplexContent ? (
        <>
          <p className={styles.tooltipCompletionText}>{text.completionText}</p>
          {fromNav && text.tips && (
            <ul className={styles.tooltipList}>
              {text.tips.map((tip, index) => (
                <li key={index} className={styles.tooltipListItem}>{tip}</li>
              ))}
            </ul>
          )}
        </>
      ) : (
        <p>{text}</p>
      )}
    </motion.div>
  );
};

export default Tooltip;
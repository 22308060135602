import React from "react";
import styles from "./CommingSoonBanner.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as Edify } from "../../../assets/svg/edifyMarketplace.svg";

  const modal = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.2 },
    },
  };

  const handleClickOnMail = (provider) => {
    window.location.href = `mailto: gonzalo@edify.la ${provider ? `?subject=Quiero publicar mis precios` : `?subject=Quiero recibir notificaciones`}`;
    }

const CommingSoonBanner = () => {
    return (
        <AnimatePresence exitBeforeEnter >
        <motion.div
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={styles.banner}
        >
          <div>
            <Edify />
          </div>
          <h3 className={styles.banner_title}>Estamos construyendo una nueva herramienta para vos.</h3>
          <p className={styles.banner_subTitle}>
              Pronto vas a poder obtener una estimación de costos de materiales para tu proyecto de nuestra base de datos y si querés un presupuesto real
              vas a poder publicar tu pedido para que la comunidad de edify te cotice.
            </p>
          <div className={styles.banner_buttonsContainer}>
            <div className={styles.banner_button} onClick={() =>  handleClickOnMail(true)}>Soy proveedor de materiales, quiero publicar mis precios</div>
            <div className={styles.banner_button} onClick={() => handleClickOnMail(false)}>Quiero recibir notificaciones cuando esté disponible</div>
            </div>         
        </motion.div>
      </AnimatePresence>
    );
    }
export default CommingSoonBanner;
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Feed from "../../components/Dashboard/Feed/Feed";
import AllProjectsSkeleton from "../../components/Dashboard/AllProjects/AllProjectsSkeleton/AllProjectsSkeleton";
import { connect } from "react-redux";
import { fireAnalytics } from "../../utils/firebase";
import {
  clearOnboardingForms,
  clearProjects,
  closeMainModal,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
} from "../../store/actions";
import Modal from "../../components/Modal/Modal";
import Botonera from "../../components/Botonera/Botonera";
import NewMaterialBudget from "../../components/NewMaterialBudget/NewMaterialBudget";
import NewProject from "../../components/NewProject/NewProject";
import QuePasaEnEdify from "../../components/QuePasaEnEdify/QuePasaEnEdify";
import FinancialData from "../../components/FinancialData/FinancialData";
import NewNav from "../../components/Nav/NewNav";


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
 `;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    max-width: auto;
    min-width: auto;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ProjectsPostulationsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 313px;
  max-width: 313px;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isSticky }) => (isSticky ? "flex-start" : "center")};
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  top: ${({ isSticky }) => (isSticky ? "60px" : "0")};
  transition: top 0.3s ease, transform 0.3s ease; 
  
  @media (max-width: 900px) {
    display: none;
  }
`;

const Dashboard = ({ openFeedTextModal, ...props }) => {
  const [isOpenMaterialBudget, setIsOpenMaterialBudget] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const financialDataRef = useRef(null);


  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
    setMobileStylesFromParent(windowWidth < 768);
  };

  const handleScroll = () => {
    if (financialDataRef.current) {
      const offSetBottom = financialDataRef.current.getBoundingClientRect().bottom;
      setIsSticky(offSetBottom <= 0);
    } else if (window.scrollY <= 0) {
      setIsSticky(false);
    }
  };
  
  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    fireAnalytics("dashboard", { category: "navigation" });
    clearOnboardingForms();
    props.clearProjects();
  }, []);

  const setModalOpenMaterialBudget = (isOpen) => {
    props.closeMainModal();
    props.setProjectFormStyle(false);
    props.setSubProjectStyle(false);
    props.resetProjectToEdit();
    setIsOpenMaterialBudget(isOpen);
  };

  const setModalOpenProject = (isOpen) => {
    props.closeMainModal();
    props.setProjectFormStyle(false);
    props.setSubProjectStyle(false);
    props.resetProjectToEdit();
    setIsOpenProject(isOpen);
  };

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      <Wrapper >
        {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
          null}
        {props.isLoaded ? (
          <Feed />
        ) : (
          <AllProjectsSkeleton />
        )}
      </Wrapper>
      <ProjectsPostulationsWrapper isSticky={isSticky}>
        <QuePasaEnEdify />
        {!isSticky && (
        <div ref={financialDataRef}>
          <FinancialData />
        </div>
        )}
      </ProjectsPostulationsWrapper>
      <Modal isOpen={isOpenMaterialBudget} setIsOpen={(newValue) => setModalOpenMaterialBudget(newValue)}>
        <NewMaterialBudget closeModal={() => setModalOpenMaterialBudget(false)} />
      </Modal>
      <Modal
        isOpen={isOpenProject}
        setIsOpen={(newValue) => setModalOpenProject(newValue)}
      >
        <NewProject closeModal={() => setModalOpenProject(false)} />
      </Modal>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    openVisualizer: state.general.recoverOpen,
    isLoaded: state.general.isLoaded,
  };
};

export default connect(mapStateToProps, {
  clearProjects,
  closeMainModal,
  setSubProjectStyle,
  resetProjectToEdit,
  openFeedTextModal,
})(Dashboard);


import React, { useEffect, useState } from "react";
import styles from "./ButtonsProject.module.scss";
import LoadingDots from "../Loading/LoadingDots";
import { connect } from "react-redux";
import { sendBudget, sendQuotationPostulation, getSubProjectBudgets } from "../../../store/actions";
import { fireAnalytics } from "../../../utils/firebase";

const ButtonsProject = ({
  subproyecto,
  isAllowed,
  sendBudget,
  userData,
  type,
  sendQuotationPostulation,
  getSubProjectBudgets,
  mainProjectId,
  subProjectBudgets,
  isMyProject,
}) => {

  const [fileName, setFileName] = useState(
    (subproyecto && subproyecto.fileName) || ""
  );
  const [fileUpload, setFileUpload] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [hasSentBudgets, setHasSentBudgets] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFileOrLink, setIsFileOrLink] = useState(false);
  const dsa = type === "subquotation" ? "quotation_payroll" : "budget_payroll";
  const projectIdWithSubprojectName = mainProjectId + '_' + subproyecto.name;

  //Definimos si es una cotizacion o un presupuesto
  const isQuotation = type === "subquotation";

  useEffect(() => {
    if (subproyecto && subproyecto.status === "SEND") {
      setFileUpload(true);
    }
  }, [subproyecto]);

  useEffect(() => {
    getSubProjectBudgets(subproyecto.id);

    if (subproyecto?.link  === "" || subproyecto?.link === "undefined" || subproyecto?.link === null) {
      setIsFileOrLink(true);
    }
  }, []);

  const handlePostulating = () => {
    setNewModalOpen(!newModalOpen);
  };

  const uploadFile = (file) => {
    setFileName(file.name);
    setFileUpload(true);
    if (type === "subquotation") {
      sendQuotationPostulation(file, subproyecto.id);
      return;
    }
    sendBudget(file, subproyecto.id, projectIdWithSubprojectName);
  };

  //Definimos si ya se envio un presupuesto
  const hasSentBudgetForSubproject = (subprojectId) => {
    return subProjectBudgets[subprojectId]?.some(
      (budget) => budget.user.id === userData.id
    );
  };

  useEffect(() => {
    setHasSentBudgets((prev) => ({
      ...prev,
      [subproyecto.id]: hasSentBudgetForSubproject(subproyecto.id),
    }));
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

  }, [subProjectBudgets]);

  const hasSentBudget = hasSentBudgets[subproyecto.id] || false;

  return (
    <div className={styles.buttonsContainer}>
      {isMyProject ? (
        type !== "subquotation" ? (
          <div className={styles.containterFlex}>
            <div className={styles.planosNoIcon}>
              <p className={styles.planosNoIcon_buttonText}>Acceder a los planos:</p>
              {isFileOrLink ? (
                ["", "null", "undefined"].includes(subproyecto?.planos) ? (
                  <p>No hay planos cargados</p>) : (<p>(1)Archivo cargado</p>)
              ) : (
              subproyecto?.link === "" || subproyecto?.link === "undefined" ? (
                <p>No hay planos cargados</p>) : (<p>(1)Link cargado</p>
              ))}

            </div>
            <div className={styles.planosNoIcon}>
              <p className={styles.planosNoIcon_buttonText}>Planilla de cotización:</p>
              {subproyecto?.budget_payroll !== null ? (
                <p className={styles.planosNoIcon_text}>(1)Archivo cargado</p>) : (<p className={styles.planosNoIcon_text}>No hay planilla de cotización</p>)}
            </div>
            <div className={styles.planosNoIcon}>
              <p className={styles.planosNoIcon_buttonText}>Especificaciónes técnicas:</p>
              {subproyecto?.technical_specifications !== null ? (
                <p className={styles.planosNoIcon_text}>(1)Archivo cargado</p>) : (<p className={styles.planosNoIcon_text}>No hay especificaciones técnicas</p>)}
            </div>
            <div className={styles.planosNoIcon}>
              <p className={styles.planosNoIcon_buttonText}>Cómputo métrico:</p>
              {subproyecto?.metric_computation !== null ? (
                <p className={styles.planosNoIcon_text}>(1)Archivo cargado</p>) : (<p className={styles.planosNoIcon_text}>No hay cómputo métrico</p>)}
            </div>
          </div>
        ) : (
          <div className={styles.containterFlex}>
             <div className={styles.planosNoIcon}>
              <p className={styles.planosNoIcon_buttonText}>Excel de cotización:</p>
              {subproyecto?.quotation_payroll !== null ? (
                <p className={styles.planosNoIcon_text}>(1)Archivo cargado</p>) : (<p className={styles.planosNoIcon_text}>No hay archivo de cotización</p>)}
            </div>
          </div>
        )) : (
        <div className={styles.containterFlex}>
          {type !== "subquotation" && (
            <div className={styles.planos}>
              <div className={styles.planos_buttonText}>
                <p className={styles.planos_buttonText_subTitle}>Planos:</p>
                  {isFileOrLink ? (
                    subproyecto?.planos === "" || subproyecto?.planos === null || subproyecto?.planos === "null" ? (
                      <p className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}>No hay archivo disponible</p>
                    ) : (
                      <a
                        disabled={isAllowed !== "ALLOWED"}
                        className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}
                        href={`${subproyecto.planos && subproyecto.planos.includes("http") ? subproyecto.planos : `http://${subproyecto.planos}`}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className={styles.planos_buttonText}>{isAllowed === "ALLOWED" ? subproyecto?.planos : "Debes solicitar acceso"}</p>
                      </a>
                    ) 
                       ) : ( 
                  subproyecto?.link === "" || subproyecto?.link === null || subproyecto?.link === "undefined" ? (    
                    <p className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}>No hay link disponible</p>
                  ) : (                    
                  <a
                    disabled={isAllowed !== "ALLOWED"}
                    className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}
                    href={`${subproyecto.link && subproyecto.link.includes("http") ? subproyecto.link : `http://${subproyecto.link}`}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className={styles.planos_buttonText}>{isAllowed  === "ALLOWED" ? subproyecto?.link : "Debes solicitar acceso"}</p>
                  </a>
                ))}
              </div>
              <div className={styles.planos_buttonText}>
                <p className={styles.planos_buttonText_subTitle}>Cómputo métrico:</p>
                {subproyecto?.metric_computation !== null ? (
                  <a
                    disabled={isAllowed !== "ALLOWED"}
                    className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}
                       href={`${subproyecto.metric_computation && subproyecto.metric_computation.includes("http") ? subproyecto.metric_computation : `http://${subproyecto.metric_computation}`}`}
                    
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className={styles.planos_infoText}>{isAllowed === "ALLOWED" ? "Descargar archivo" : "Debes solicitar acceso"}</p>
                  </a>
                ) : (<p className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""
                  }`}>No hay archivo disponible</p>)}
              </div>
              <div className={styles.planos_buttonText}>
                <p className={styles.planos_buttonText_subTitle}>Especificaciónes técnicas:</p>
                {subproyecto?.technical_specifications !== null ? (
                  <a
                    disabled={isAllowed !== "ALLOWED"}
                    className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""}`}
                    href={`${subproyecto.technical_specifications && subproyecto.technical_specifications.includes("http") ? subproyecto.technical_specifications : `http://${subproyecto.technical_specifications}`}`}                   
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className={styles.planos_infoText}>{isAllowed === "ALLOWED" ? "Descargar archivo" : "Debes solicitar acceso"}</p> 
                  </a>
                ) : (<p className={`${styles.planos_text} ${isAllowed !== "ALLOWED" ? styles.disabled : ""
                  }`}>No hay archivo disponible</p>)}
              </div>
            </div>
          )}

          <div className={styles.plantilla}>
            <p className={styles.plantilla_buttonText}>Planilla de cotización:</p>
            {subproyecto?.budget_payroll === null ? (
              <p className={`${styles.plantilla_text} ${isAllowed !== "ALLOWED" && !isQuotation ? styles.disabled : ""
                }`}>No hay planilla disponible</p>
            ) :
              (<a
                disabled={isAllowed !== "ALLOWED" && !isQuotation}
                href={isAllowed !== "ALLOWED" && !isQuotation ? null : subproyecto[dsa]}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.plantilla_text} ${isAllowed !== "ALLOWED" && !isQuotation ? styles.disabled : ""
                  }`}
                onClick={
                  isAllowed === "ALLOWED" || isQuotation
                    ? () =>
                      fireAnalytics("download_template", { category: "budgeting" })
                    : () => handlePostulating()
                }
              >
                <p className={styles.plantilla_infoText}>{isAllowed === "ALLOWED" ? "Descargar planilla de cotización" : "Debes solicitar acceso"}</p>
              </a>
              )}
          </div>
          <div className={`${styles.newPresupuesto} ${hasSentBudget ? styles.disabled : ""}`}>
            <p className={styles.newPresupuestoTitle}>Cotizar:</p>
            <div className={styles.newPresupuesto_buttonText}>
              <p className={styles.newPresupuesto_subTitle}>Enviar presupuesto:</p>
              {isLoading ? <LoadingDots blackDots /> : (
                isAllowed === "ALLOWED" || isQuotation ? (
                  !hasSentBudget || !fileUpload ? (
                    <input
                      type="file"
                      name={`subir${subproyecto.id}`}
                      id={`subir${subproyecto.id}`}
                      className={styles.inputBudget}
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          uploadFile(e.target.files[0]);
                        }
                      }}
                      accept=".xlsx, .pdf, .xls"
                    />
                  ) : (
                    <p className={styles.inputBudget_allowed}>Ya subiste un presupuesto</p>
                  )
                ) : (
                  <p title={"Debes solicitar acceso haciendo click en 'Quiero cotizar'"} className={styles.inputBudget_notAllowed}>No estás autorizado para cotizar</p>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    subProjectBudgets: state.profile.subProjectBudgets,
  };
};

export default connect(mapStateToProps, {
  sendBudget,
  sendQuotationPostulation,
  getSubProjectBudgets,
})(ButtonsProject);



import React, { useState } from "react";
import styles from "./ProjectQuestions.module.scss";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { sendProjectQuestions } from "../../../../store/actions";

const ProjectQuestions = ({ projectId, userData, closeModal, sendProjectQuestions }) => {
    const [questionValue, setQuestionValue] = useState("");

    const suggestions = [
        "¿Cuánto tiempo tomará completar la obra?",
        "¿Qué tipo de materiales utilizas en el proyecto?",
        "¿Es necesario algún permiso especial para la construcción?",
        "¿Cuánto personal involucras en este tipo de proyectos?",
        "¿Podés mostrar ejemplos de proyectos anteriores similares?",
        "¿Cuál es el proceso de pago y facturación?",
        "¿Hay costos adicionales que no están incluidos en el presupuesto inicial?"
    ];

    const handleSubmit = () => {
        if (questionValue) {
            sendProjectQuestions(projectId, questionValue) ;
            closeModal();
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.question}>
                    <div className={styles.question_body}>
                    <button className={styles.closeButton} onClick={closeModal}>
                        X
                    </button>

            <h4 className={styles.questionTitle}>¿Qué querés saber?</h4>
            <div className={styles.suggestions}>
                {suggestions.map((suggestion, index) => (
                    <button key={index} className={styles.suggestion} onClick={() => setQuestionValue(suggestion)}>{suggestion}</button>
                ))}
            </div>
            <div className={styles.inputContainer}>
            <input 
                onChange={(e) => setQuestionValue(e.target.value)}
                value={questionValue}
                type="text" 
                className={styles.input} 
                placeholder="Escribí tu pregunta" />
            <button className={styles.sendButton} onClick={handleSubmit}>Enviar</button>
            </div>
            </div>
        </motion.div>
        </AnimatePresence>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.register.userData,
    };
}

export default connect(mapStateToProps, {sendProjectQuestions})(ProjectQuestions);
import React from "react";
import { ReactComponent as EmptyProyectIcon } from "../../../assets/svg/servicesIcon.svg";
import styles from "./EmptyState.module.scss";

const EmptyState = ({ title = "", icon, isPortfolio }) => {
  return (
    <div className={`${styles.EmptyState} ${isPortfolio && styles.emptyStatePortfolio}`}>
      {icon ? icon : <EmptyProyectIcon width={50} height={50} className={isPortfolio && styles.icon}/>}
      <h3 className={isPortfolio ? styles.isPortfolio : styles.title}>{title}</h3>
    </div>
  );
};

export default EmptyState;

import React, { useState } from "react";
import styles from "./PortfolioQuestion.module.scss";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { sendPortfolioQuestions } from "../../../store/actions";

const PortfolioQuestion = ({ portfolioId, userData, closeModal, sendPortfolioQuestions }) => {
    const [questionValue, setQuestionValue] = useState("");

    const suggestions = [
        "¿Cuánto cuesta?",
        "¿Qué incluye?",
        "¿Cuánto tiempo dura?",
        "¿Cuenta con garantía?",
        "¿Hay stock?",
        "¿Hacés envíos a todo el país?",
        "¿Qué formas de pago aceptás?",
    ];

    const handleSubmit = () => {
        if (questionValue) {
            sendPortfolioQuestions(userData.id, portfolioId, questionValue) ;
            closeModal();
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.question}>
                    <div className={styles.question_body}>
                    <button className={styles.closeButton} onClick={closeModal}>
                        X
                    </button>

            <h4 className={styles.questionTitle}>¿Qué querés saber?</h4>
            <div className={styles.suggestions}>
                {suggestions.map((suggestion, index) => (
                    <button key={index} className={styles.suggestion} onClick={() => setQuestionValue(suggestion)}>{suggestion}</button>
                ))}
            </div>
            <div className={styles.inputContainer}>
            <input 
                onChange={(e) => setQuestionValue(e.target.value)}
                value={questionValue}
                type="text" 
                className={styles.input} 
                placeholder="Escribí tu pregunta" />
            <button className={styles.sendButton} onClick={handleSubmit}>Enviar</button>
            </div>
            </div>
        </motion.div>
        </AnimatePresence>
    );
}

export default connect(null, { sendPortfolioQuestions })(PortfolioQuestion);
const theme = {
  colors: {
    main: "#2D4EF5",
    mainDark: "#004F98",
    mainDarker: "#003667",
    mainLight: "#2F6AAE",
    mainBlue: "#3f66b3",
    mainBlueBorder: "#3259a7",
    textColor: "#333",
    whiteColor: "#fff",
    blackColor: "#000",
    errorRed: "#d6174a",
    warning: "#FFD166",
    success: "#4CE2A7",
    shadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
  },
  mediaQueries: {
    smallest: `only screen and (max-width:26em)`,
    small: `only screen and (max-width: 37.5em)`,
    medium: `only screen and (max-width: 56em)`,
    large: `only screen and (max-width: 80em)`,
    largest: `only screen and (max-width: 90em)`,
  },
};

export default theme;

import React, { useEffect, useState } from "react";
import styles from "./SideCards.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import usePremiumCheck from "../../../hooks/usePremiumCheck";

const SideCards = ({ workBagUsers, handleCreateJobOffer, setExpanded, expanded }) => {
    const [uniqueJurisdictionsCount, setUniqueJurisdictionsCount] = useState(0);
    const [mostFrequentJurisdiction, setMostFrequentJurisdiction] = useState(null);
    const [uniqueSkillsCount, setUniqueSkillsCount] = useState(0);
    const [showContent, setShowContent] = useState(true);

    const premiumCheck = usePremiumCheck();

    const countUniqueJurisdictionsAndSkills = (workBagUsers) => {
        const uniqueJurisdictions = new Set();
        const jurisdictionCounts = {};
        const uniqueSkills = new Set();

        workBagUsers.forEach(user => {
            user.jobBoardZones.forEach(zone => {
                const jurisdictionName = zone.jurisdiccion.name;
                uniqueJurisdictions.add(jurisdictionName);

                if (jurisdictionCounts[jurisdictionName]) {
                    jurisdictionCounts[jurisdictionName]++;
                } else {
                    jurisdictionCounts[jurisdictionName] = 1;
                }
            });

            user.skill.forEach(skill => {
                uniqueSkills.add(skill?.skill?.name);
            });
        });

        let mostFrequentJurisdiction = null;
        let maxCount = 0;

        for (const [name, count] of Object.entries(jurisdictionCounts)) {
            if (count > maxCount) {
                maxCount = count;
                mostFrequentJurisdiction = name;
            }
        }

        return {
            uniqueJurisdictionsCount: uniqueJurisdictions.size,
            mostFrequentJurisdiction,
            uniqueSkillsCount: uniqueSkills.size
        };
    };

    useEffect(() => {
        const {
            uniqueJurisdictionsCount,
            mostFrequentJurisdiction,
            uniqueSkillsCount
        } = countUniqueJurisdictionsAndSkills(workBagUsers);

        setUniqueJurisdictionsCount(uniqueJurisdictionsCount);
        setMostFrequentJurisdiction(mostFrequentJurisdiction);
        setUniqueSkillsCount(uniqueSkillsCount);
    }, [workBagUsers]);

    const handleToggleExpand = () => {
        if (expanded) {
            setShowContent(false);
            setTimeout(() => {
                setExpanded(false);
            }, 400);
        } else {
            setExpanded(true);
            setTimeout(() => {
                setShowContent(true);
            }, 400);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.5 }}
            className={styles.sideCards}
            style={{
                width: expanded ? '350px' : '0px',
                transition: 'width 0.5s ease',
                paddingLeft: expanded ? '20px' : '0px'
            }}
        >
            <button onClick={handleToggleExpand} className={`${styles.toggleButton} ${!expanded && styles.expanded}`}>
                {expanded ? <ArrowRightIcon width={15} height={15} /> : <ArrowRightIcon width={15} height={15} style={{ transform: 'rotate(180deg)' }} />}
            </button>
            {expanded && (
                <AnimatePresence>
                    {showContent && (
                        <>
                            <div className={styles.smallToast}>
                                <div className={styles.smallToast_header}>
                                    <img className={styles.smallToast_header_img} src="https://cdn-icons-png.flaticon.com/128/6947/6947573.png" alt="Trabajadores" />
                                    <h2>¿Sabías que...?
                                        <div className={styles.pill} />
                                    </h2>
                                </div>
                                <p className={styles.smallToast_item}>
                                Podés crear tu propia <b className={styles.smallToast_bold} onClick={premiumCheck(handleCreateJobOffer)}>oferta de trabajo</b>, y el equipo de Edify te ayudará a encontrar el candidato ideal para tu propuesta. Actualmente contamos con más de {workBagUsers.length} trabajadores registrados.</p>
                            </div>
                            <div className={styles.smallToast}>
                                <div className={styles.smallToast_header}>
                                    <img className={styles.smallToast_header_img} src="https://cdn-icons-png.flaticon.com/128/341/341195.png" alt="jurisdicciones" />
                                    <h2>Además...
                                    <div className={`${styles.pill} ${styles.secondToast}`}/>
                                    </h2>
                                </div>
                                <p>Tenemos presencia en {uniqueJurisdictionsCount} jurisdicciones. Siendo {mostFrequentJurisdiction} la más frecuente.</p>
                            </div>
                            <div className={styles.smallToast}>
                                <div className={styles.smallToast_header}>
                                    <img className={styles.smallToast_header_img} src="https://cdn-icons-png.flaticon.com/128/12020/12020770.png" alt="rubros" />
                                    <h2>¡Por último!
                                    <div className={`${styles.pill} ${styles.thirdToast}`}/>
                                    </h2>
                                </div>
    
                                <p>Si lo que buscas es variedad existen {uniqueSkillsCount} rubros diferentes registrados en la base.
                                    Pero si no encontrás lo que buscas podés crear una oferta de trabajo y nosotros te ayudamos.</p>
                            </div>
                        </>
                    )}
                </AnimatePresence>
            )}
        </motion.div>
    );
};

export default SideCards;




import React from "react";
import styles from "./OnboardingLayout.module.scss";
import Footer from "../../Footer/Footer";
import MainContentWrapper from "../MainContentWrapper/MainContentWrapper";

const OnboardingLayout = ({ children, tipo, title, padding, height }) => {
  return (
    <>
      <section className={styles.OnboardingContainer} style={{ minHeight: `${height}` }}>
        <div className={`${styles.content} ${styles.onboardingLeft}`}></div>
        <div className={`${styles.content} ${styles.onboardingRight}`}></div>
        <MainContentWrapper title={title} isOnboarding hasAnimation padding={padding}>
          {children}
        </MainContentWrapper>
      </section>
      <Footer />
    </>
  );
};

export default OnboardingLayout;

import { useState, useEffect } from 'react';

function useIsPhone(breakpoint = 600) {
  const [isPhone, setIsPhone] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < breakpoint);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isPhone;
}

export default useIsPhone;
import React from 'react';
import styled from 'styled-components';


const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
    
`;

const StyledInput = styled.input`
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    padding: 10px 15px;
    outline: none;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: rgb(112, 112, 112);
    text-align: left;
    border-radius: 30px;
    border: 2px solid rgb(191, 191, 191);
    text-align: center;
  &:focus,
  &:active {
    border: 2px solid var(--color-mainBlue);
  }
  &::placeholder {
    color: #bfbfbf;
    font-weight: 600;
    text-align: center;
  }
  &.error {
    border: 2px solid var(--color-error);
    &::placeholder {
      color: var(--color-error);
    }
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #707070;
  }
`;

const Input = ({ field, ...props }) => {
  return (
    <InputWrapper>
      <StyledInput {...field} {...props} />
    </InputWrapper>
  );
};

export default Input;
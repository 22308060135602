import React, { useEffect, useState, useRef } from "react";
import styles from "./PerfilMain.module.scss";
import { getPortfolio } from "../../../store/actions/registerActions";
import { testDescriptionEdit } from "../../../store/actions/profileActions";
import { userInfoNavStyle } from "../../../store/actions";
import styled from "styled-components";
import { connect } from "react-redux";
import ProfileEditModal from "../../UI/ProfileEditModal/ProfileEditModal";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svg/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/LinkedIN.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/svg/twitterSvg.svg";
import Reputacion from "./Reputacion/Reputacion";
import { ReactComponent as WhatsAppIcon } from "../../../assets/svg/whatsAppIcon.svg";
import MisSkills from "./MisSkills/MisSkills";
import UserInfo from "../../UI/UserInfo/UserInfo";
import MisComentarios from "./MisComentarios/MisComentarios";
import MisProyectos from "./MisProyectos/MisProyectos";
import MisProductos from "./MisProductos/MisProductos";
import MisAccesos from "./MisAccesos/MisAccesos";
import MisCotizacionesDeMateriales from "./MisCotizacionesDeMateriales/MisCotizacionesDeMateriales";
import MiCuenta from "./MiCuenta/MiCuenta";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import Maps from "../../UI/Maps/Maps";
import NewNav from "../../Nav/NewNav";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
    padding-right: 0;
    padding-left: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;

  @media (max-width: 768px) {
    padding-left: 0;
  }`;

const PerfilMain = ({
  userId,
  userData,
  getPortfolio,
  myPerson,
  perfilAjeno,
  profileVisualizerData,
  testDescriptionEdit,
  userInfoNavStyle,
  ...props
}) => {
  let isVisualizer = userData.id !== perfilAjeno ? true : false;
  const [createNewPortfolio, setCreateNewPortfolio] = useState(false);
  const [isActive, setIsActive] = useState(!myPerson ? "Resumen" : localStorage.getItem('isActive') || "Resumen");
  const [isLoading, setIsLoading] = useState(false);
  const [resumenSectionActive, setResumenSectionActive] = useState("rubros");
  const [isEditing, setIsEditing] = useState(false);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);

  const handleResize = () => {
    setOcultarEnMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Extraemos datos del perfil a renderizar
  const {
    linkedin,
    instagram,
    facebook,
    twitter,
    phone,
    address,
    type,
    description
  } = myPerson ? userData : profileVisualizerData;

  const misProductosRef = useRef(null);
  const comentariosRef = useRef(null);
  const obrasRef = useRef(null);
  const postulacionesRef = useRef(null);
  const cotizacionesRef = useRef(null);
  const resumenRef = useRef(null);
  const configuracionesRef = useRef(null);

   // Función para desplazar la vista hacia la sección correspondiente
   const scrollToRef = (ref) => {

    if (ref && ref.current) {
      const container = document.querySelector(`.${styles.mainContainer}`); // Selecciona el contenedor específico
      container.scrollTo({
        top: ref.current.offsetTop - 170,
        behavior: 'smooth',
      });
    }
  };

  const handleNewPortfolioClick = (value) => {
    setCreateNewPortfolio(value);
  };

  useEffect(() => {
    if (isLoading)
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem('isActive', isActive);
  }, [isActive]);


  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      <Wrapper>
        <UserInfo fromPage setIsEditing={setIsEditing} userData={perfilAjeno} profileVisualizerData={profileVisualizerData} myPerson={myPerson} />
        <div className={styles.mainContainer}>
          <div className={styles.sectionDivider}>
            <ul className={styles.sectionDivider_list}>
                  <li className={`${styles.sectionDivider_list_item} ${isActive === "Resumen" ? styles.isActive : ""}`} 
                onClick={() => { setIsActive("Resumen"); scrollToRef(resumenRef) }}>
                <p>Resúmen</p>
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Mis productos" ? styles.isActive : ""}`} 
                onClick={() => { setIsActive("Mis productos"); scrollToRef(misProductosRef) }}>
                {ocultarEnMobile ? <p>Prod/Serv</p> : <p>Productos - servicios</p>}
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Comentarios" ? styles.isActive : ""}`} 
                onClick={() => { setIsActive("Comentarios"); scrollToRef(comentariosRef) }}>
                <p>Calificaciones</p>
              </li>
              <li className={`${styles.sectionDivider_list_item} ${isActive === "Obras privadas" ? styles.isActive : ""}`} 
                onClick={() => { setIsActive("Obras privadas"); scrollToRef(obrasRef) }}>
                {ocultarEnMobile ? <p>Proyectos</p> : <p>Proyectos publicados</p>}
              </li>
              {myPerson && (
                <>
                  <li className={`${styles.sectionDivider_list_item} ${isActive === "Postulaciones" ? styles.isActive : ""}`} 
                    onClick={() => { setIsActive("Postulaciones"); scrollToRef(postulacionesRef) }}>
                    <p>Accesos solicitados a obras</p>
                  </li>
                  <li className={`${styles.sectionDivider_list_item} ${isActive === "Cotizaciones" ? styles.isActive : ""}`} 
                    onClick={() => { setIsActive("Cotizaciones"); scrollToRef(cotizacionesRef) }}>
                    <p>{ocultarEnMobile ? "Materiales" : "Solicitud de materiales"}</p>
                  </li>
                  <li className={`${styles.sectionDivider_list_item} ${isActive === "Configuraciones" ? styles.isActive : ""}`} 
                    onClick={() => { setIsActive("Configuraciones"); scrollToRef(configuracionesRef) }}>
                    <p>Mi cuenta</p>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className={styles.container}>
            <main>
              <div className={styles.container}>
                <main
                  className={`${styles.PerfilMain} ${isVisualizer && styles.visualizer
                    }`}
                >
                  <section className={styles.PerfilMain_section_resumen} ref={resumenRef}>
                    <div className={styles.PerfilMain_section_resumenContent}>
                      <div className={styles.description}>
                        <h4 className={styles.description_header}>
                          {type === "COMPANY" ? "Sobre nosotros" : "Sobre mi"}
                        </h4>
                        <div className={styles.description_infoContainer}>
                          <p className={styles.description_txt}>{description === null ? "No especificado" : description}</p>
                          <div className={styles.description_infoContainer_socialNetworksContainer}>
                            <h4 className={styles.description_infoContainer_socialNetworksContainer_title}>Redes</h4>
                            <div className={styles.description_infoContainer_socialNetworksContainer_icons}>
                              {facebook && (
                                <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                  <a href={facebook} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                    <FacebookIcon width={20} height={20} />
                                  </a>
                                </div>
                              )}
                              {instagram && (
                                <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                  <a href={instagram} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                    <InstagramIcon width={20} height={20} />
                                  </a>
                                </div>
                              )}
                              {linkedin && (
                                <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                  <a href={linkedin} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                    <LinkedinIcon width={20} height={20} />
                                  </a>
                                </div>
                              )}
                              {twitter && (
                                <div className={styles.description_infoContainer_socialNetworksContainer_item} >
                                  <a href={twitter} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                    <TwitterIcon width={20} height={20} />
                                  </a>
                                </div>
                              )}
                              {phone && (
                                <div className={styles.description_infoContainer_socialNetworksContainer_item}>
                                  <a href={`https://wa.me/${phone}`} target="_blank" rel="noreferrer" className={styles.description_infoContainer_socialNetworksContainer_item_label}>
                                    <WhatsAppIcon width={20} height={20} />
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.PerfilMain_section_resumenContent_infoContainer}>
                        <div className={styles.PerfilMain_section_resumenContent_headers}>
                          <h4 className={`${styles.PerfilMain_section_resumenContent_headers_title} ${resumenSectionActive === "rubros" ? styles.isActive : ""}`} onClick={() => setResumenSectionActive("rubros")}>Rubros</h4>
                          <h4 className={`${styles.PerfilMain_section_resumenContent_headers_title} ${resumenSectionActive === "ubicacion" ? styles.isActive : ""}`} onClick={() => setResumenSectionActive("ubicacion")}>Ubicación</h4>
                        </div>
                        {resumenSectionActive === "rubros" && (
                          <MisSkills perfilAjeno={perfilAjeno} />
                        )}
                        {resumenSectionActive === "ubicacion" && (
                          <div className={styles.PerfilMain_section_resumen_info}>
                            <Maps ciudad={address} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.PerfilMain_section_resumen_rewiew}>
                      <Reputacion userId={perfilAjeno} myPerson={myPerson} />
                    </div>
                  </section>
                  <section ref={misProductosRef} className={styles.PerfilMain_section}>
                    <header className={styles.PerfilMain_headerSection} >
                      <h2 className={styles.PerfilMain_headerSection_header}>Productos - servicios</h2>
                      {myPerson && (
                        <div
                          className={styles.newPortfolio}
                          onClick={handleNewPortfolioClick}
                        >
                          Cargar nuevo
                          <ArrowRightIcon width={10} height={10} />
                        </div>
                      )}
                    </header>
                    <MisProductos
                      createNewPortfolio={createNewPortfolio}
                      perfilAjeno={perfilAjeno}
                      myPerson={myPerson}
                      handleNewPortfolioClick={handleNewPortfolioClick}
                      userData={userData}
                    />
                  </section>
                </main>
              </div>
              <section className={styles.PerfilMain_section} ref={comentariosRef}>
                <div className={styles.PerfilMain_headerSection}>
                  <h2 className={styles.PerfilMain_headerSection_header}>Calificaciones</h2>
                </div>
                <MisComentarios perfilAjeno={perfilAjeno} myPerson={myPerson} />
              </section>
              <section className={styles.PerfilMain_section}>
                <div className={styles.PerfilMain_headerSection} ref={obrasRef}>
                  <h2 className={styles.PerfilMain_headerSection_header}>Proyectos publicados</h2>
                </div>
                <MisProyectos fromPage myPerson={myPerson} perfilAjeno={perfilAjeno} userData={userData} />
              </section>
              {myPerson && (
                <>
                  <section className={styles.PerfilMain_section} ref={postulacionesRef}>
                    <div className={styles.PerfilMain_headerSection}>
                      <h2 className={styles.PerfilMain_headerSection_header}>Accesos solicitados a obras</h2>
                    </div>
                    <MisAccesos fromPage myPerson={myPerson} />
                  </section>
                  <section className={styles.PerfilMain_section} ref={cotizacionesRef}>
                    <div className={styles.PerfilMain_headerSection}>
                      <h2 className={styles.PerfilMain_headerSection_header}>Solicitud de materiales</h2>
                    </div>
                    <MisCotizacionesDeMateriales myPerson={myPerson} />
                  </section>
                  <section className={styles.PerfilMain_section} >
                    <div className={styles.PerfilMain_headerSection} ref={configuracionesRef} >
                      <h2 className={styles.PerfilMain_headerSection_header}>Mi cuenta</h2>
                    </div>
                    <MiCuenta />
                  </section>
                </>
              )}
            </main>
          </div>
        </div>
        {isEditing && (
          <ProfileEditModal setIsEditing={setIsEditing} userData={userData} />)}
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {

  return {
    perfilAjeno: ownProps.perfilAjeno,
  };
};

export default connect(mapStateToProps, {
  getPortfolio,
  testDescriptionEdit,
  userInfoNavStyle,
})(PerfilMain);

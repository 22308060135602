import React from "react";
import "./Footer.scss";
import Logo from "../../assets/images/logoBig.png";
import LogoIg from "../../assets/svg/Instagram.svg";
import LogoFb from "../../assets/svg/Facebook.svg";
import LogoLinkedn from "../../assets/svg/LinkedIN.svg";
import { Link } from "react-router-dom";
import PDF from "./PDF/Terminos-y-condiciones-edify-2021.pdf";
import PDF2 from "./PDF/Politicas-De-Privacidad-Edify-2021.pdf";

export default function Footer() {
  return (
    <footer className="section-container1">
      <div className="footer-container">
        <div className="columna-chica">
          <div className="footer-logo">
            <img src={Logo} alt="edify" />
          </div>
        </div>
        <div className="columna-grande">
          <div className="footer-navigation__container">
            <div className="footer-navigation__content">
              <a className="footer-navigation__title" href="/dashboard">
                Inicio
              </a>
            </div>
            <div className="footer-navigation__content">
              <ul
                className="footer-navigation__title"
                onClick={() =>
                  window.open("https://edify.la/servicios.html", "_blank")
                }
              >
                Servicios
              </ul>
            </div>
            <div className="footer-navigation__content">
              <ul className="footer-navigation__title">
                <span className="footer-navigation__edify">Edify</span>
                <li onClick={() => window.open("https://edify.la", "_blank")}>
                  Nosotros
                </li>
                <li>
                  <Link
                    to={PDF}
                    style={{ color: "rgba(0, 0, 0, 0.37)" }}
                    target="_blank"
                    download
                  >
                    Términos y Condiciones
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link
                    to={PDF2}
                    style={{ color: "rgba(0, 0, 0, 0.37)" }}
                    target="_blank"
                    download
                  >
                    Políticas de Privacidad
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="columna-chica">
          <div className="footer-social__container">
            <div className="footer-img">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/edifyargentina/"
              >
                <img src={LogoLinkedn} alt="Linkedin" />
              </a>
            </div>
            <div className="footer-img">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/edify.la/?hl=es"
              >
                <img src={LogoIg} alt="Instagram" />
              </a>
            </div>
            <div className="footer-img">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Edify-105512194154003"
              >
                <img src={LogoFb} alt="facebook" />
              </a>
            </div>
          </div>
        </div>
        <div className="paisanos-container">
          <span className="designers">
            {" "}
            Design by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.paisanos.io/"
            >
              paisanos.io
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}

import BuenosAiresIcono from "../../../assets/images/BuenosAiresIcono.jpg";
import CabaIcono from "../../../assets/images/cabaIcon.jpg";
import CatamarcaIcono from "../../../assets/images/catamarcaIcono.jpg";
import ChacoIcono from "../../../assets/images/chacoIcono.jpg";
import ChubutIcono from "../../../assets/images/chubutIcono.jpg";
import CordobaIcono from "../../../assets/images/cordobaIcono.jpg";
import CorrientesIcono from "../../../assets/images/corrientesIcono.jpg";
import EntreRiosIcono from "../../../assets/images/entreRiosIcono.jpg";
import FormosaIcono from "../../../assets/images/formosaIcono.jpg";
import JujuyIcono from "../../../assets/images/jujuyIcono.jpg";
import LaPampaIcono from "../../../assets/images/laPampaIcono.jpg";
import LaRiojaIcono from "../../../assets/images/laRiojaIcono.jpg";
import MendozaIcono from "../../../assets/images/mendozaIcono.jpg";
import MisionesIcono from "../../../assets/images/misionesIcono.jpg";
import NeuquenIcono from "../../../assets/images/neuquenIcono.jpg";
import RioNegroIcono from "../../../assets/images/rioNegroIcono.jpg";
import SaltaIcono from "../../../assets/images/saltaIcono.jpg";
import SanJuanIcono from "../../../assets/images/sanJuanIcono.jpg";
import SanLuisIcono from "../../../assets/images/sanLuisIcono.jpg";
import SantaCruzIcono from "../../../assets/images/santaCruzIcono.jpg";
import SantaFeIcono from "../../../assets/images/santaFeIcono.jpg";
import SantiagoDelEsteroIcono from "../../../assets/images/santiagoDelEsteroIcono.jpg";
import TierraDelFuegoIcono from "../../../assets/images/tierraDelFuegoIcono.jpg";
import TucumanIcono from "../../../assets/images/tucumanIcono.jpg";
import ArgentinaIcono from "../../../assets/images/argentinaIcon.jpg";

export const iconJPG = [
  { id: 1, name: "Ciudad Autónoma de Buenos Aires", icon: CabaIcono },
  { id: 2, name: "Buenos Aires", icon: BuenosAiresIcono },
  { id: 3, name: "Catamarca", icon: CatamarcaIcono },
  { id: 4, name: "Chaco", icon: ChacoIcono },
  { id: 5, name: "Chubut", icon: ChubutIcono },
  { id: 6, name: "Córdoba", icon: CordobaIcono },
  { id: 7, name: "Corrientes", icon: CorrientesIcono },
  { id: 8, name: "Entre Ríos", icon: EntreRiosIcono },
  { id: 9, name: "Formosa", icon: FormosaIcono },
  { id: 10, name: "Jujuy", icon: JujuyIcono },
  { id: 11, name: "La Pampa", icon: LaPampaIcono },
  { id: 12, name: "La Rioja", icon: LaRiojaIcono },
  { id: 13, name: "Mendoza", icon: MendozaIcono },
  { id: 14, name: "Misiones", icon: MisionesIcono },
  { id: 15, name: "Neuquén", icon: NeuquenIcono },
  { id: 16, name: "Río Negro", icon: RioNegroIcono },
  { id: 17, name: "Salta", icon: SaltaIcono },
  { id: 18, name: "San Juan", icon: SanJuanIcono },
  { id: 19, name: "San Luis", icon: SanLuisIcono },
  { id: 20, name: "Santa Cruz", icon: SantaCruzIcono },
  { id: 21, name: "Santa Fe", icon: SantaFeIcono },
  { id: 22, name: "Santiago del Estero", icon: SantiagoDelEsteroIcono },
  { id: 23, name: "Tierra del Fuego,Antártida e Isla del Atlántico Sur", icon: TierraDelFuegoIcono },
  { id: 24, name: "Tucumán", icon: TucumanIcono },
  { id: 25, name: "Argentina", icon: ArgentinaIcono }
];

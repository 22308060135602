import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    closeMainModal,
    setProjectFormStyle,
    getQuotationById,
    setQuotationToEdit,
    deleteQuotation
} from "../../store/actions";
import Modal from "../../components/Modal/Modal";
import Maps from "../../components/UI/Maps/Maps";
import ButtonsProject from "../UI/ButtonsProject/ButtonsProject";

import NewMaterialBudget from "../NewMaterialBudget/NewMaterialBudget";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/svg/trashIcon.svg";
import SubQuotationBudget from "./subCotizacion/subCotizacionPostulante/SubQuotationBudget";
import Botonera from "../Botonera/Botonera";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./QuotationId.module.scss";
import NewNav from "../../components/Nav/NewNav";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 0 8%;
    height: 100%;

    @media (max-width: 768px) {
    padding: 0;
`

const QuotationId = ({ 
    quotation,
    getQuotationById,
    proyecto, 
    setProjectFormStyle,
    setQuotationToEdit,
    deleteQuotation,
    budgets,
    userData,
     ...props }) => {
        const [isOpenMaterialBudget, setModalOpenMaterialBudget] = useState(false);
    const [showSubProjectInfo, setShowSubProjectInfo] = useState({});
    const [editProject, setEditProject] = useState(false);
    const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
    const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
    const { id } = useParams();
   const navigate = useNavigate();
    //Definimos si es proycto propio o de otro usuario
    const isOwnProject = quotation?.quotation?.user?.id === userData?.id;

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setOcultarEnMobile(windowWidth < 768);
        setMobileStylesFromParent(windowWidth < 768);
      };
    
      // Ejecutar handleResize al cargar el componente
      useEffect(() => {
        handleResize();
        getQuotationById(id);
      }, []);
    
      // Agregar un event listener para el cambio de tamaño de la ventana
      useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        // Limpiar el event listener al desmontar el componente
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    //Formateamos fechas
    const formatDate = (date) => {
        const utcDate = new Date(date);
        const year = utcDate.getUTCFullYear();
        const month = utcDate.getUTCMonth() + 1;
        const day = utcDate.getUTCDate(); 
        return `${day}/${month}/${year}`;
      };


    const handleNewOpenProject = () => {
        setProjectFormStyle(false);
        setModalOpenMaterialBudget(true);
        closeMainModal();
    };

    //Muestra la info de los subproyectos
    const toggleSubProjectInfo = (index) => {
        setShowSubProjectInfo((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

  //Muestra el PopUp de edicion de proyecto 
    const handleEditProject = () => {
        setQuotationToEdit(quotation);
        setEditProject(true);
    }

    //Borrar el proyecto 
    const handleDeleteProject = () => {
        const userFullName = userData.type === "COMPANY" ? userData.razon_social : userData.name + " " + userData.last_name;
        const sanitizedUrl = userFullName.replace(/ /g, "-");
        deleteQuotation(parseInt(quotation.quotation?.id));
        navigate(`/profesionales/${userData.id}/${sanitizedUrl}`);
    }

    //Volver atrás
    const goBack = () => {
        navigate.goBack();
    }

    //Maneja la redirección al perfil del creador del proyecto
    const handleUserRedirect = () => {
        const userId = quotation?.quotation?.user?.id;
        const userFullName = quotation?.quotation?.user?.type === "COMPANY" ? quotation?.quotation?.user.razon_social : quotation?.quotation?.user?.name + " " + quotation?.quotation?.user?.last_name;
        navigate(`/profesionales/${userId}/${userFullName}`);
    }

    return (
        <Container>
            {!ocultarEnMobile && <NewNav />}
        <div className={styles.mainContainer}>
            <section className={styles.mainContent}>
                <h2 className={styles.mainContent_title}>SOLICITUD DE COTIZACIÓN: "{quotation?.quotation?.name}"

                  {isOwnProject && <div className={styles.mainContent_buttons}>
                    <button title="Eliminar proyecto" className={styles.mainContent_buttons_delete} onClick={() => handleDeleteProject()}><TrashIcon width={15} height={15}/></button>
                    <button className={styles.mainContent_buttons_postularme} onClick={() => handleEditProject()}>Editar cotización</button>                
                </div>}  
                </h2>
                
                <div className={styles.mainContent_cards}>
                    <div className={styles.mainContent_cards_datos}>
                        <h3 className={styles.mainContent_cards_datos_title}>INFORMACIÓN GENERAL</h3>
                        <div className={styles.mainContent_cards_datos_container}>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Ubicacion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{quotation?.quotation?.jurisdiccion?.name}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Ciudad:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{quotation?.quotation?.zone !== "" ? quotation?.quotation?.zone : "No especificó"}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Moneda:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{quotation?.quotation?.currency !== "undefined" ? quotation?.quotation?.currency : "Pesos Arg"}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Fecha de inicio:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{formatDate(quotation?.quotation?.startDate)}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Fecha de finalizacion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{formatDate(quotation?.quotation?.endDate)}</p>
                            </div>
                            <div className={styles.mainContent_cards_datos_container_item}>
                                <p className={styles.mainContent_cards_datos_container_item_label}>Descripcion:</p>
                                <p className={styles.mainContent_cards_datos_container_item_value}>{quotation?.quotation?.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContent_cards_map}>
                        <Maps ciudad={quotation?.quotation?.jurisdiccion?.name} />
                    </div>
                    <div className={styles.mainContent_cards_subProyectos}>
                        <h3 className={styles.mainContent_cards_subProyectos_title}>RUBROS A COTIZAR</h3>
                        <div className={styles.mainContent_cards_subProyectos_container}>
                            {quotation?.subquotation && quotation?.subquotation.length > 0 ? quotation?.subquotation.map((subProject, index) => {
                                return <div className={styles.mainContent_cards_subProyectos_container_subProject} key={index} >
                                    <div className={styles.mainContent_cards_subProyectos_container_subProject_header} onClick={() => toggleSubProjectInfo(index)}>
                                        <p className={styles.mainContent_cards_subProyectos_container_subProject_title}>{subProject.name}</p>
                                        <div className={styles.mainContent_cards_subProyectos_container_subProject_arrow}>
                                            <ArrowIcon
                                                className={showSubProjectInfo[index] ? styles.arrowIconDown : styles.arrowIconRight}
                                                height={15}
                                                width={15}
                                            />
                                        </div>
                                    </div>
                                    {showSubProjectInfo[index] &&
                                        <div className={styles.mainContent_cards_subProyectos_container_subProject_info}>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Estado:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{subProject.status === "OPEN" && "Abierto"}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Inicio de recepción de presupuestos:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{subProject?.startDate === undefined ? formatDate(quotation?.quotation?.startDate) : formatDate(subProject?.endDate)}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Fin de recepción de presupuestos:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_value}>{subProject?.endDate === undefined ? formatDate(quotation?.quotation?.endDate) : formatDate(subProject?.endDate)}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Descripcion:</p>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_description}>{subProject?.description === "undefined" ? "No especificó" : subProject?.description}</p>
                                            </div>
                                            <div className={styles.mainContent_cards_subProyectos_container_subProject_item}>
                                                <p className={styles.mainContent_cards_subProyectos_container_subProject_label}>Documentacion:</p>
                                                <ButtonsProject mainProjectId={quotation?.quotation?.id} subproyecto={subProject} isMyProject={isOwnProject} type={"subquotation"} />
                                            </div>
                                        </div>}
                                </div>
                            }) : <p className={styles.mainContent_cards_subProyectos_container_noResults}>No hay sub proyectos</p>}
                        </div>
                    </div>
                    {isOwnProject &&
                    <div className={styles.mainContent_cards_cotizaciones}>
                    <h3 className={styles.mainContent_cards_cotizaciones_title}>
                        COTIZACIONES RECIBIDAS
                    </h3>
                    <div className={styles.mainContent_cards_cotizaciones_container}>
                    {quotation?.subquotation && quotation?.subquotation.length > 0 ? quotation?.subquotation.map((subProject, index) => {
                        return <SubQuotationBudget subproject={subProject} key={index}  mainProject={quotation}/>
                    }
                    ) : <p className={styles.mainContent_cards_cotizaciones_container_noResults}>No hay cotizaciones</p>}
                    </div>
                </div>}
                    <div className={styles.mainContent_cards_user}>
                        <h3 className={styles.mainContent_cards_user_title}>
                            CREADOR
                        </h3>
                        <div className={styles.mainContent_cards_user_container} onClick={() => handleUserRedirect()}>
                            <div className={styles.mainContent_cards_user_container_user}>
                                <img src={quotation?.quotation?.user?.image_profile} alt="Imagen de perfil" className={styles.mainContent_cards_user_container_user_image} />
                                <p className={styles.mainContent_cards_user_container_user_name}>{quotation?.quotation?.user?.type === "COMPANY" ?
                                    quotation?.quotation?.user?.razon_social : quotation?.quotation?.user?.name + " " + quotation?.quotation?.user?.last_name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Modal isOpen={isOpenMaterialBudget}>
          <NewMaterialBudget
            closeModal={() => setModalOpenMaterialBudget(false)}
          />
        </Modal>
            {editProject && 
            <div className={styles.editBackDrop}>
                <NewMaterialBudget
                        isEditing
                        handleClose={() => setEditProject(false)}
                        closeModal={() => setEditProject(false)}
                      />
            </div>}
            {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
          null}
        </div >
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.general.userData,
        proyecto: state.profile.proyectoPrivadoPorId,
        quotation: state.profile.quotation,
    };
}

export default connect(mapStateToProps, {
    closeMainModal,
    setProjectFormStyle,
    getQuotationById,
    setQuotationToEdit,
    deleteQuotation,
})(QuotationId);
import { useState, useEffect } from "react";

const useGetTodayDate = () => {
    const [fecha, setFecha] = useState("");

    useEffect(() => {
        const hoy = new Date();
        let dd = hoy.getDate();
        let mm = hoy.getMonth() + 1;
        let yyyy = hoy.getFullYear();

        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }

        const formattedDate = `${dd}/${mm}/${yyyy}`;
        setFecha(formattedDate);
    }, []);

    return fecha;
};

export default useGetTodayDate;

import { useEffect, useRef } from "react";

const UserSkillsUpdater = ({ userSkills, setFieldValue }) => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; 
        }

        if (userSkills.length === 0) {
            setFieldValue("skills", []);
        }
    }, [userSkills.length, setFieldValue]);

    return null;
};

export default UserSkillsUpdater;
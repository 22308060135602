import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Input from "../UI/Forms/Input/Input";
import Button from "../UI/Forms/Button/Button";
import FileUpload from "../FileUpload/FileUpload";
import { connect } from "react-redux";
import {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  closeMainModal,
} from "../../store/actions/index";
import styles from "./FormularioEmpresa.module.scss";
import { fireAnalytics } from "../../utils/firebase.js";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowDownIcon.svg";
import { useNavigate } from "react-router-dom";
import WorkZoneSelector from "../FormularioPersona/WorkZoneSelector";
import UserSkillsUpdater from "../FormularioPersona/UserSkillsUpdater.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ArgentinaMap } from "../../assets/svg/argentinaMap.svg";

const ButtonContainer = styled.div`
display: flex;
gap: 10px;
padding-top: 20px;

@media (max-width: 600px) {
  flex-direction: column;
  gap: 5px;
}
`;

const FormularioEmpresa = ({
  openMainModal,
  closeMainModal,
  file,
  getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  skills,
  userSkills,
}) => {
  const [showSkillsSuggestions, setShowSkillsSuggestions] = useState(false);
  const [showSkillsList, setShowSkillsList] = useState(false);
  const [inputValue, setInputValue] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    fireAnalytics("ob_step_2", { category: "navigation" });
    getSkills();
    clearUserSkills();
  }, []);

  const webSiteRegex = /(^[a-zA-Z_-]{3})([.]{1})([a-zA-Z0-9_.+-]+)([.]{1})([a-zA-Z_-]+)/;
  const telRegex = /[0-9+]/;

  const initialValues = {
    companyName: "",
    tel: "",
    address: "",
    webSite: "",
    skills: [],
    allCountry: null,
    workZone: [],
  };

  const companySchema = Yup.object().shape({
    companyName: Yup.string().required("El campo es requerido."),
    tel: Yup.string()
      .matches(telRegex, "Introduzca un formato válido")
      .min(7, "Ingrese un telefono válido")
      .max(20, "Ingrese un telefono válido"),
    webSite: Yup.string().matches(webSiteRegex, "Introduzca un formato válido"),
    skills: Yup.array().min(1, "Seleccioná al menos 1 rubro").nullable(),
    allCountry: Yup.boolean().nullable(),
    workZone: Yup.array().when("allCountry", {
      is: false,
      then: Yup.array().min(1, "Seleccioná al menos 1 lugar de trabajo").nullable(),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: (values) => {
      sendSkills(userSkills, navigate, values, file, false);
    },
  });

  const matchedSkills = skills?.filter((skill) => skill.name.toLowerCase().includes(inputValue.toLowerCase())) || [];

  const handleShowSuggestions = (value) => {
    if (value.length < 3) {
      setShowSkillsSuggestions(false);
    } else if (value.length >= 3) {
      setShowSkillsSuggestions(true);
    }
    setInputValue(value);
  };

  const handleAddSkill = (id) => {
    addSkill(id);
    setShowSkillsSuggestions(false);
    setInputValue("");
    setShowSkillsList(false);
  };

  const defaultCompanyValues = {
    companyName: "Empresa sin identificar",
    allCountry: true,
  }

  const defaultUserSkills = [
    {
      id: "232",
      name: "Otros",
    }
  ]


  const handleSkipButton = () => {
    openMainModal({
      title: "¿Estás seguro?",
      body: "Si omites este paso, no podrás acceder a todas las funcionalidades de Edify.",
      yesLabel: "Omitir",
      noLabel: "Cancelar",
      iconType: "warning",
      confirmHandler: () => {
        addSkill("232");
        sendSkills(defaultUserSkills, navigate, defaultCompanyValues, file, false);
        closeMainModal()
      }
    })
  }

  useEffect(() => {
    if (formik.values.allCountry) {
      formik.setFieldValue("workZone", []);
    }
  }, [formik.values.allCountry]);

  const handleToggleCheckbox = () => {
    const newValue = !formik.values.allCountry;
    formik.setFieldValue("allCountry", newValue);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
        <UserSkillsUpdater userSkills={userSkills} setFieldValue={formik.setFieldValue} />
        <FileUpload file={file} userType="company" />
        <div className={styles.formGroup_container}>
          <div className={styles.formGroup_container_form}>
            <h4 className={styles.skillsContainer_title}>Datos de la empresa</h4>
            <div className={styles.formGroup_inputs}>
              <div className={styles.formGroup}>
                <Input
                  placeholder="Nombre de la empresa*"
                  type="text"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${formik.errors.companyName ? "error" : "isValid"}`}
                />
              </div>
              <div className={styles.inputRow}>
                <div className={styles.formGroup}>
                  <Input
                    placeholder="Página Web"
                    type="text"
                    name="webSite"
                    value={formik.values.webSite}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${formik.errors.webSite ? "error" : "isValid"}`}
                  />
                </div>
                <div className={styles.formGroup}>
                  <Input
                    placeholder="Teléfono"
                    type="number"
                    name="tel"
                    value={formik.values.tel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${formik.errors.tel ? "error" : "isValid"}`}
                  />
                  {formik.errors.tel && <div className={styles.formGroup_errorMsg}>{formik.errors.tel}</div>}
                </div>
              </div>
              <div className={styles.formGroup_lastOne}>
                <div className={styles.skillsContainer}>
                  <div
                    className={`${styles.skillsContainer_inputContainer} ${userSkills?.length === 0 && !formik.isValid && styles.inputError
                      }`}
                  >
                    {userSkills?.length > 0 && (
                      <div className={styles.skillsContainer_containerSelected}>
                        <div className={styles.skillsContainer_selected}>
                          {userSkills.map((skill) => {
                            return (
                              <div
                                className={styles.skillsContainer_skillSelected}
                                key={skill.id}
                                onClick={() => removeSkill(skill.id)}
                              >
                                {skill.name} X
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <input
                      className={`${styles.skillsContainer_input} ${userSkills?.length > 0 && styles.withSkills}`}
                      type="text"
                      name="skills"
                      placeholder={userSkills?.length === 0 && !formik.isValid ? "Seleccioná al menos 1 rubro" : `${userSkills?.length > 0 ? "" : "Rubro (ej: Electricista)"}`}
                      value={inputValue}
                      onChange={(e) => handleShowSuggestions(e.target.value)}
                      onClick={() => formik.setFieldTouched("skills", true)}
                      readOnly={userSkills?.length > 0}
                    />
                    <ArrowDownIcon
                      width={15}
                      title="Ver lista completa de rubros"
                      height={15}
                      className={`${styles.arrow} ${showSkillsList ? styles.arrowIconUp : styles.arrowIconDown}`}
                      onClick={() => setShowSkillsList(!showSkillsList)}
                    />
                  </div>
                  {showSkillsList && (
                    <div className={styles.skillsContainer_list}>
                      {skills?.map((skill) => {
                        if (skill.name.toLowerCase().includes(inputValue.toLowerCase())) {
                          return (
                            <div
                              className={styles.skillsContainer_list_item}
                              key={skill.id}
                              onClick={() => {
                                handleAddSkill(skill.id);
                                formik.setFieldValue("skills", skill.name);
                              }}
                            >
                              {skill.name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                  {showSkillsSuggestions && (
                    <div className={styles.skillsContainer_list}>
                      {matchedSkills?.length > 0 ? (
                        matchedSkills.map((skill) => {
                          return (
                            <div
                              className={styles.skillsContainer_list_item}
                              key={skill.id}
                              onClick={() => {
                                handleAddSkill(skill.id);
                                formik.setFieldValue("skills", skill.name);
                              }}
                            >
                              {skill.name}
                            </div>
                          );
                        })
                      ) : (
                        <div className={styles.skillsContainer_list_item}>No existe ese rubro</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.workZoneSelector}>
            <h4 className={styles.skillsContainer_title}>Lugares donde trabajas</h4>
            <div className={styles.workZoneSelector_optionsContainer}>
              <div
                className={`${styles.workZoneSelector_byCity} ${formik.values.allCountry === false && styles.selected} ${(formik.values.allCountry || formik.values.allCountry === null) && styles.notSelected}`}
                onClick={() => {
                  formik.setFieldValue("allCountry", false);
                }}
              >
                Elegir por ciudad
                <FontAwesomeIcon icon={faMapLocationDot} size="3x" />
              </div>
              <div
                className={`${styles.checkboxLabel} ${formik.values.allCountry && styles.selected} ${!formik.values.allCountry && styles.notSelected}`}
                onClick={handleToggleCheckbox}
              >
                Vendo productos y/o servicios en todo el país.
                <ArgentinaMap className={styles.map} width={60} height={60} />
                <input
                  name="allCountry"
                  type="checkbox"
                  className={styles.checkBox}
                  onChange={() => { }}
                  checked={formik.values.allCountry === true}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            {formik.values.allCountry === false && (
              <WorkZoneSelector formik={formik} values={formik.values} setFieldValue={formik.setFieldValue} />
            )}
          </div>
        </div>
        <ButtonContainer >
          <Button
            colored="white"
            typebtn="link"
            to="/select-type"
            onClick={() => fireAnalytics("ob_step_2_back", { category: "onboarding" })}
          >
            Volver
          </Button>
          <button
            className={styles.btn}
            disabled={!(userSkills?.length > 0 && formik.isValid)}
            type="submit"
            onClick={() => {
              fireAnalytics("ob_step_2_profesional", {
                category: "onboarding",
              });
            }}
          >
            Siguiente
          </button>
          <button
            className={styles.btn_skip}
            type="button"
            onClick={() => handleSkipButton()}
          >
            Omitir paso
          </button>
        </ButtonContainer>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyName: state.register.empresaForm.companyName,
    tel: state.register.empresaForm.tel,
    file: state.register.file,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
    skillContent: state.register.skillContent,
  };
};

export default connect(mapStateToProps, {
  setEmpresa, openMainModal, getSkills,
  sendSkills,
  addSkill,
  removeSkill,
  clearUserSkills,
  closeMainModal
})(FormularioEmpresa);

import React, { useEffect } from "react";
import styles from "./MisSkills.module.scss";
import { connect } from "react-redux";
import {
  getSkills,
  getUserSkill,
} from "../../../../store/actions";


const MisSkills = ({
  perfilAjeno,
  getSkills,
  getUserSkill,
  userSkills,
}) => {

  useEffect(() => {
    getSkills();
    getUserSkill(perfilAjeno);
  }, [perfilAjeno, getSkills, getUserSkill]);


  return (
    <div className={styles.Skills}>
      <div className={styles.Skills_main}>
        <div className={styles.Skills_main_userSkills}>
          {userSkills && userSkills.length > 0 ? (
            userSkills.map((skill, index) => {
              return (
                <div key={index} className={styles.Skills_main_skill}>
                  {skill.name}
                </div>
              );
            })
          ) : (
            <div className={styles.Skills_main_skill}>
              No hay rubros especificados
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    skills: state.register.skills,
    userSkills: state.register.userSkills,
  };
};

export default connect(mapStateToProps, {
  getSkills,
  getUserSkill,
})(MisSkills);
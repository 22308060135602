import React from "react";
import styles from "./WorkerContact.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as ProfileIcon } from "../../../../../assets/svg/profileIcon.svg";

const variants = {
    visible: {
      scale: 1.0,
    },
    hidden: {
      scale: 1.1,
    },
  };

const WorkerContact = ({ worker, setShowWorkerContact, setWorkerToPass }) => {

    const handleClose = () => {
        setShowWorkerContact(false);
        setWorkerToPass(null);
    }

    const handleMail = () => {
        const isGmail = worker.email.includes("@gmail.com");
        const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${worker.email}` : `mailto:${worker.email}`;
        window.open(mailtoLink, "_blank");
    }

    const handlePhone = () => {
        const whatsappLink = `https://wa.me/${worker.phone.replace(/\D/g, '')}`;
        window.open(whatsappLink, "_blank");
    }

    return (
        <AnimatePresence exitBeforeEnter>
            <div className={styles.overlay}>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    
                className={styles.workerContact}>
                    <div className={styles.workerContact_header}>
                        <button className={styles.workerContact_closeButton} onClick={() => handleClose()}>X</button>
                        <ProfileIcon className={styles.workerContact_profileIcon} width={80} height={80} />                 
                    </div>
                    <div className={styles.workerContact_info}>
                        <h3 className={styles.workerContact_name}>{worker.name} {worker.last_name}</h3>
                        <div className={styles.workerContact_itemContainer}>
                            <p className={styles.workerContact_phone}>{worker.phone}</p>
                            <img className={styles.workerContact_icon} onClick={() => handlePhone()} src="https://cdn-icons-png.flaticon.com/128/14025/14025200.png" alt="phone icon" />
                        </div>
                        <div className={styles.workerContact_itemContainer}>
                            <p className={styles.workerContact_email}>{worker.email}</p>
                            <img className={styles.workerContact_icon} onClick={() => handleMail()} src="https://cdn-icons-png.flaticon.com/128/14025/14025140.png" alt="email icon" />
                        </div>
                    </div>
                </motion.div>
            </div>
        </ AnimatePresence>
    );
}

export default WorkerContact;
import React, { useState } from "react";
import styles from "./SubCotizacionesWrapper.module.scss";
import SubQuotationItem from "./SubQuotationItem";
import { connect } from "react-redux";

const SubCotizacionesWrapper = ({ isEditing, subprojectsEdit, projectIdForSub, type, sendSubQuotations, subQuotations, subProjectsToEdit, ...props }) => {

return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${subprojectsEdit?.length > 0 ? styles.active : ""}`}>
        <h5 className={styles.wrapper_subTitle}>RUBROS CARGADOS</h5>
        {subprojectsEdit?.length > 0 && (
          subprojectsEdit?.map((item, index) => {
            return <SubQuotationItem subproject={item} key={item.name} index={index} type={type} isEditing={isEditing} projectIdForSub={projectIdForSub} />;
          }))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subQuotations: state.profile.sendSubQuotations,
    subProjectsToEdit: state.profile.projectToEdit.subproyecto,
    sendSubQuotations: state.profile.sendSubQuotations,
  };
};

export default connect(mapStateToProps)(SubCotizacionesWrapper);